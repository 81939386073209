(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrMaterial', TrMaterial);

	TrMaterial.$inject = ['$resource', 'DateUtils'];

	function TrMaterial($resource, DateUtils) {
		var resourceUrl = 'api/tr-materials/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGeofence', TrMaterialGeofence);

	TrMaterialGeofence.$inject = ['$resource'];

	function TrMaterialGeofence($resource) {
		var service = $resource('/api/tr-materials/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialCustomTime', TrMaterialCustomTime);

	TrMaterialCustomTime.$inject = ['$resource'];

	function TrMaterialCustomTime($resource) {
		var service = $resource('/api/tr-materials-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGeofenceCustomTime', TrMaterialGeofenceCustomTime);

	TrMaterialGeofenceCustomTime.$inject = ['$resource'];

	function TrMaterialGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-materials-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrMaterialCheckAsset', TrMaterialCheckAsset);

	TrMaterialCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrMaterialCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
    'use strict';
    angular.module('trakeyeApp').factory('MaterialsForAsset', MaterialsForAsset);

    MaterialsForAsset.$inject = ['$resource'];

    function MaterialsForAsset($resource) {
        var resourceUrl = 'api/materials-for-asset/:assetId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-materials/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getMaterialImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyematerialimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id,data,callback) {
			$http.post('api/assets/nearby-fixed/' + id,data).then(function (response) {
				callback(response.data);
			}); 
		};
		return service;
	}]);
})(); 

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-materials-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('MaterialAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-material-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialById', TrMaterialById);

	TrMaterialById.$inject = [ '$resource' ];

	function TrMaterialById($resource) {
		var service = $resource('/api/tr-materials/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/materialcountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadMaterialReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-materials').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/materialcountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialPriority', TrMaterialPriority);

	TrMaterialPriority.$inject = ['$resource'];

	function TrMaterialPriority($resource) {
		var service = $resource('/api/tr-materials/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, {geofenceId : '@geofenceId'},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialAttendance', TrMaterialAttendance);

	TrMaterialAttendance.$inject = ['$resource'];

	function TrMaterialAttendance($resource) {
		var service = $resource('/api/tr-materials/attendance/:login/:fromTime/:toTime/:materialStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialSearch', TrMaterialSearch);

	TrMaterialSearch.$inject = ['$resource'];

	function TrMaterialSearch($resource) {
		var service = $resource('/api/tr-materials/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialSearchCustom', TrMaterialSearchCustom);

	TrMaterialSearchCustom.$inject = ['$resource'];

	function TrMaterialSearchCustom($resource) {
		var service = $resource('/api/tr-materials-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGeofenceSearch', TrMaterialGeofenceSearch);

	TrMaterialGeofenceSearch.$inject = ['$resource'];

	function TrMaterialGeofenceSearch($resource) {
		var service = $resource('/api/tr-materials/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGeofenceSearchCustom', TrMaterialGeofenceSearchCustom);

	TrMaterialGeofenceSearchCustom.$inject = ['$resource'];

	function TrMaterialGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-materials-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrMaterialGroup', TrMaterialGroup);

	TrMaterialGroup.$inject = ['$resource', 'DateUtils'];

	function TrMaterialGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-materials-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGroupSearch', TrMaterialGroupSearch);

	TrMaterialGroupSearch.$inject = ['$resource'];

	function TrMaterialGroupSearch($resource) {
		var service = $resource('/api/tr-materials-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGroupPrioritySearch', TrMaterialGroupPrioritySearch);

	TrMaterialGroupPrioritySearch.$inject = ['$resource'];

	function TrMaterialGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-materials-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialGroupPriority', TrMaterialGroupPriority);

	TrMaterialGroupPriority.$inject = ['$resource'];

	function TrMaterialGroupPriority($resource) {
		var service = $resource('/api/tr-materials-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialImages', TrMaterialImages);

	TrMaterialImages.$inject = ['$resource'];

	function TrMaterialImages($resource) {
		var service = $resource('/api/material-images/:materialId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialsForType', TrMaterialsForType);

	TrMaterialsForType.$inject = ['$resource'];

	function TrMaterialsForType($resource) {
		var service = $resource('/api/trmaterialsbytype/:materialTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
 (function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data,success,error) {
			$http.post('api/tr-materials/nearby-bgoh-assets',data).then(function (response) {
				success(response.data);
			},function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();  

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialRecordings', TrMaterialRecordings);

	TrMaterialRecordings.$inject = ['$resource'];

	function TrMaterialRecordings($resource) {
		var service = $resource('/api/material-recordings/:materialId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllMaterialTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/material-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialPrioritySearch', TrMaterialPrioritySearch);

	TrMaterialPrioritySearch.$inject = ['$resource'];

	function TrMaterialPrioritySearch($resource) {
		var service = $resource('/api/tr-materials/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrMaterialUpdateGroup', TrMaterialUpdateGroup);

	TrMaterialUpdateGroup.$inject = ['$resource'];

	function TrMaterialUpdateGroup($resource) {
		var resourceUrl = 'api/tr-materials/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrMaterialBulkDelete', TrMaterialBulkDelete);

	TrMaterialBulkDelete.$inject = ['$resource'];

	function TrMaterialBulkDelete($resource) {
		var resourceUrl = 'api/tr-materials/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrmaterialsuser = function (callback) {
			$http.get('api/tr-materials/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrmaterialsuserbyid = function (id, callback) {
			$http.get('api/tr-materials/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrmaterialsuserbyvalue = function (id, callback) {
			$http.get('api/tr-materials/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrmaterialsanduserbypriority = function (priority, callback) {
			$http.get('api/tr-materials/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrmaterials = function (callback) {
			$http.get('api/tr-allmaterials').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrmaterial = function (data, callback) {
			$http.post('api/tr-materialedit/' + data.userId + '/' + data.materialId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-materialedit/'+data.userId+'/'+data.materialId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrMaterialUpload', TrMaterialUpload);

	TrMaterialUpload.$inject = ['$http'];

	function TrMaterialUpload($http) {

		var service = {};
		service.trmaterialupload = function (data, callback) {

			$http.post('api/materialimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupMaterialSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrmaterialformap = function (search, callback) {
			$http.get('api/tr-groupmaterialsformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupMaterialForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllMaterials = function (callback) {
			$http.get('api/tr-groupmaterialsformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrmaterialformap = function (search, callback) {
			$http.get('api/tr-materialsformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrmaterialformap = function (priority, callback) {
			$http.get('api/tr-materialsformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrMaterialLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('MaterialsForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.materialsformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-materialsformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrMaterialForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllMaterials = function (data, callback) {
			// $http.get('api/tr-materialsformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-materialsformap', data).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ShowMaterialById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (materialid, callback) {
            $http.put('api/show-material/' + materialid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('HideMaterialById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (materialid, callback) {
            $http.put('api/hide-material/' + materialid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();
