(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('LoginControllers', LoginControllers);

    LoginControllers.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'Principal'];

    function LoginControllers($rootScope, $state, $timeout, Auth, Principal) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function () { angular.element('#username').focus(); });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            //  $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $rootScope.has_ROLE_SUPER_ADMIN = false;
                $rootScope.has_ROLE_USER_ADMIN = false;
                $rootScope.has_ROLE_USER = false;
                $rootScope.has_ROLE_VENDOR_ADMIN = false;
                $rootScope.has_ROLE_HOD = false;
                $rootScope.has_ROLE_CITY_MANAGER = false;
                $rootScope.has_ROLE_CITY_PLANNER = false;
                $rootScope.has_ROLE_ASSISTANT_MANAGER = false;
                $rootScope.has_ROLE_TEAM_LEADER = false;
                $rootScope.has_ROLE_NETWORK_ENGINEER = false;
                $rootScope.has_ROLE_FRT = false;
                $rootScope.has_ROLE_PATROLLER = false;

                $rootScope.has_ROLE_ROLLOUT_SURVEY = false;
                $rootScope.has_ROLE_ROLLOUT_INFRA = false;
                $rootScope.has_ROLE_ROLLOUT_ADMIN = false;
                $rootScope.has_ROLE_PLAN_SURVEY = false;
                $rootScope.has_ROLE_PLAN_INFRA = false;
                $rootScope.has_ROLE_PLAN_ADMIN = false;

                $rootScope.has_ROLE_ROLLOUT_FE = false;
                $rootScope.has_ROLE_ROLLOUT_VENDOR = false;
                $rootScope.has_ROLE_ROLLOUT_MANAGER =  false

                Principal.identity().then(function (identity) {
                    if (identity && identity.authorities && identity.authorities.indexOf('ROLE_SUPER_ADMIN') !== -1) {
                        $rootScope.has_ROLE_SUPER_ADMIN = true;
                        $state.go('tenant');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_USER_ADMIN') !== -1) {
                        $rootScope.has_ROLE_USER_ADMIN = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_VENDOR') !== -1) {
                        $rootScope.has_ROLE_VENDOR = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_HOD') !== -1) {
                        $rootScope.has_ROLE_HOD = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_CITY_MANAGER') !== -1) {
                        $rootScope.has_ROLE_CITY_MANAGER = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_CITY_PLANNER') !== -1) {
                        $rootScope.has_ROLE_CITY_PLANNER = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_ASSISTANT_MANAGER') !== -1) {
                        $rootScope.has_ROLE_ASSISTANT_MANAGER = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_TEAM_LEADER') !== -1) {
                        $rootScope.has_ROLE_TEAM_LEADER = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_NETWORK_ENGINEER') !== -1) {
                        $rootScope.has_ROLE_NETWORK_ENGINEER = true;
                        $state.go('dashboard');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_FRT') !== -1) {
                        $rootScope.has_ROLE_FRT = true;
                        $state.go('tr-case');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_PATROLLER') !== -1) {
                        $rootScope.has_ROLE_PATROLLER = true;
                        $state.go('tr-case');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_USER') !== -1) {
                        $rootScope.has_ROLE_USER = true;
                        // $state.go('location-log');
                        $state.go('tr-case');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_ROLLOUT_SURVEY') !== -1) {
                        $rootScope.has_ROLE_ROLLOUT_SURVEY = true;
                        $state.go('work-flow');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_ROLLOUT_ADMIN') !== -1) {
                        $rootScope.has_ROLE_ROLLOUT_ADMIN = true;
                        $state.go('tr-project');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_ROLLOUT_MANAGER') !== -1) {
                        $rootScope.has_ROLE_ROLLOUT_MANAGER = true;
                        $state.go('tr-project');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_ROLLOUT_INFRA') !== -1) {
                        $rootScope.has_ROLE_ROLLOUT_INFRA = true;
                        $state.go('work-flow');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_PLAN_SURVEY') !== -1) {
                        $rootScope.has_ROLE_PLAN_SURVEY = true;
                        $state.go('work-order');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_PLAN_INFRA') !== -1) {
                        $rootScope.has_ROLE_PLAN_INFRA = true;
                        $state.go('work-order');
                    }  if (identity && identity.authorities && identity.authorities.indexOf('ROLE_PLAN_ADMIN') !== -1) {
                        $rootScope.has_ROLE_PLAN_ADMIN = true;
                        $state.go('tr-project');
                        //$state.go('work-order');
                    }

                    if (
                        identity &&
                        identity.authorities &&
                        identity.authorities.indexOf("ROLE_ROLLOUT_FE") !== -1
                    ) {
                        
                        $state.go('tr-project');
                        $rootScope.has_ROLE_ROLLOUT_FE = true
                    }
                  /* if (
                        identity &&
                        identity.authorities &&
                        identity.authorities.indexOf("ROLE_ROLLOUT_MANAGER") !== -1
                    ) {
                        
                        $state.go('tr-project');
                    } */ 
                    if (
                        identity &&
                        identity.authorities &&
                        identity.authorities.indexOf("ROLE_ROLLOUT_VENDOR") !== -1
                    ) {
                        $rootScope.has_ROLE_ROLLOUT_VENDOR = true
                        $state.go('tr-task');
                    }

                    if (
                        identity &&
                        identity.authorities &&
                        identity.authorities.indexOf("ROLE_VENDOR_ADMIN") !== -1
                    ) {
                        $rootScope.has_ROLE_VENDOR_ADMIN = true;
                        $state.go('tr-workorder');
                    }

                    if (identity) {
                        $rootScope.userName = identity.login;
                        $rootScope.image = identity.userImage;
                    }
                });

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState

                ///////////////////No use with this code ///////////////////
                /*if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }*/
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register() {
            //$uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword() {
            //$uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();