(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomProjectTypeAttributeDialogController', CustomProjectTypeAttributeDialogController);

    CustomProjectTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$state','$stateParams', 'entity', 'CustomProjectTypeAttribute'];

    function CustomProjectTypeAttributeDialogController ($timeout, $scope, $state ,$stateParams, entity, CustomProjectTypeAttribute) {
        var vm = this;

        vm.customProjectTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
       // vm.customprojecttypeattributevalues = CustomProjectTypeAttributeValue.query();
        
        vm.items = [ {
			value : ''
		} ];
        
        if (vm.customProjectTypeAttribute.customProjectTypeAttributeValues != null && vm.customProjectTypeAttribute.customProjectTypeAttributeValues.length != 0) {
			vm.items = vm.customProjectTypeAttribute.customProjectTypeAttributeValues;
		}
        vm.addmore = function() {
			vm.items.push({
				value : ''
			});
		};

		vm.remove = function(index) {
			vm.items.splice(index, 1);
		};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if  ( vm.customProjectTypeAttribute.id !== null) {
            	
            	 vm.customProjectTypeAttribute.customProjectTypeAttributeValues = [];
				for (var i = 0; i < vm.items.length; i++) {
					 vm.customProjectTypeAttribute.customProjectTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
                CustomProjectTypeAttribute.update(vm.customProjectTypeAttribute, onSaveSuccess, onSaveError);
            } else {
            	
            	
            	 vm.customProjectTypeAttribute.customProjectTypeAttributeValues = [];

				for (var i = 0; i < vm.items.length; i++) {
					 vm.customProjectTypeAttribute.customProjectTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
				
                CustomProjectTypeAttribute.save(vm.customProjectTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customProjectTypeAttributeUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('custom-project-type-attribute');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
