(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkFlowDeleteController", WorkFlowDeleteController);

    WorkFlowDeleteController.$inject = [
        "$uibModalInstance",
        "entity",
        "WorkFlow",
    ];

    function WorkFlowDeleteController($uibModalInstance, entity, WorkFlow) {
        var vm = this;

        vm.workFlow = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmDelete(id) {
            WorkFlow.delete({ id: id }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
