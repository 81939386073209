(function() {
	'use strict';

	angular.module('trakeyeApp').controller('CaseTatReportController', CaseTatReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    	};
	}]);

	CaseTatReportController.$inject = [ '$scope','$sce' ,'$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval','Principal' ];

	function CaseTatReportController($scope, $sce,$state, Report, $rootScope, $timeout, $window, $filter, $interval,Principal) {
		var vm = this;
		vm.users = [];
		$scope.reporthide = false;
		vm.reportUser = $rootScope.userName;
		vm.openCalendar = openCalendar;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		vm.datePickerOpenStatus = {};
		vm.tat = 5;
		vm.users = loadPage();
		$scope.loader = false;
		vm.search='';
		
		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		$(".select2").select2(); // Added search for select box
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		
		function loadPage(){
			Report.getUsers('', function(response) {
				vm.users = response;
			})
		}
		
		
		$scope.report = function() {
		 
			Report.getCasesTatReport({
					reportType:"USER",
					ageType:vm.dateRange,
					login:vm.reportUser,
					 tat : vm.tat,
					 fromDateTime : vm.fromTime,
					 toDateTime : vm.toTime
				}, function(response) {
					$scope.myhtml = response;
				});

		}
		var strokeWidth = 1.5;
		var margin = { top: 20, bottom: 20, left: 30, right: 20 };
		var width = 600 - margin.left - margin.right - (strokeWidth * 2);
		var height = 200 - margin.top - margin.bottom;

		var x0 = d3.scale.ordinal()
			.rangeRoundBands([0, width], .1);

		var x1 = d3.scale.ordinal();

		var y = d3.scale.linear()
			.range([height, 0]);

		var color = d3.scale.ordinal()
		.range(["#00FF00", "#000080", "#FFA500", "#FF0000"]);

		var xAxis = d3.svg.axis()
			.scale(x0)
			.orient("bottom");

		var yAxis = d3.svg.axis()
			.scale(y)
			.orient("left")
			.tickFormat(d3.format(".2s"));

		var svg = d3.select("#verticalBarChart").append("svg")
			.attr("viewBox", "0 0 600 200")
			// .attr("width", width + margin.left + margin.right)
			// .attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		// d3.csv("data.csv", function(error, data) {
		var data = [
			{
				"State": 0,
				"Low": 38,
				"Medium": 10,
				"High": 88,
				"Critical": 41
			},
			{
				"State": 1,
				"Low": 38,
				"Medium": 10,
				"High": 88,
				"Critical": 41
			},
			{
				"State": 2,
				"Low": 38,
				"Medium": 10,
				"High": 88,
				"Critical": 41
			},
			{
				"State": 3,
				"Low": 38,
				"Medium": 10,
				"High": 88,
				"Critical": 41
			},
			{
				"State": 4,
				"Low": 38,
				"Medium": 10,
				"High": 88,
				"Critical": 41
			},
			{
				"State": 5,
				"Low": 38,
				"Medium": 10,
				"High": 88,
				"Critical": 41
			},


		]

		var ageNames = d3.keys(data[0]).filter(function (key) { return key !== "State"; });

		data.forEach(function (d) {
			d.ages = ageNames.map(function (name) { return { name: name, value: +d[name] }; });
		});

		x0.domain(data.map(function (d) { return d.State; }));
		x1.domain(ageNames).rangeRoundBands([0, x0.rangeBand()]);
		y.domain([0, d3.max(data, function (d) { return d3.max(d.ages, function (d) { return d.value; }); })]);

		svg.append("g")
			.attr("class", "x axis")
			.attr("transform", "translate(0," + height + ")")
			.call(xAxis)
			.append("text")
			.attr("text-anchor", "end")
			.attr("x", 560)
			.attr("dx", ".71em")
			.text("Weeks")
			;

		svg.append("g")
			.attr("class", "y axis")
			.call(yAxis)
			.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 4)
			.attr("dy", ".71em")
			.style("text-anchor", "end")
			.text("Cases");

		var state = svg.selectAll(".state")
			.data(data)
			.enter().append("g")
			.attr("class", "g")
			.attr("transform", function (d) { return "translate(" + x0(d.State) + ",0)"; });

		state.selectAll("rect")
			.data(function (d) { return d.ages; })
			.enter().append("rect")
			.attr("class", "bars")
			.attr("width", x1.rangeBand())
			.attr("x", function (d) { return x1(d.name); })
			.attr("y", function (d) { return y(d.value); })
			.attr("height", function (d) { return height - y(d.value); })
			.style("fill", function (d) { return color(d.name); });

		state.selectAll("text")
			.data(function (d) { return d.ages; })
			.enter().append("text")
			.attr("class", "barstext")
			.attr("x", function (d) { return x1(d.name); })
			.attr("y", function (d) { return y(d.value); })
			.text(function (d) { return (d.value) })
		// .text(function (d) { console.log(d.value / 1000000); return (d.value / 1000000).toFixed(2)})

		var legend = svg.selectAll(".legend")
			.data(ageNames.slice().reverse())
			.enter().append("g")
			.attr("class", "legend")
			.attr("transform", function (d, i) { return "translate(0," + i * 20 + ")"; });

		legend.append("rect")
			.attr("x", width - 22)
			.attr("width", 10)
			.attr("height", 10)
			.style("fill", color);

		legend.append("text")
			.attr("x", width - 12)
			.attr("y", 5)
			.attr("dy", ".20em")
			// .style("text-anchor", "end")
			.text(function (d) { return d; });

		// });
	}
})();
