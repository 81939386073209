(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomTaskTypeAttributeDialogController', CustomTaskTypeAttributeDialogController);

    CustomTaskTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$state','$stateParams', 'entity', 'CustomTaskTypeAttribute'];

    function CustomTaskTypeAttributeDialogController ($timeout, $scope, $state ,$stateParams, entity, CustomTaskTypeAttribute) {
        var vm = this;

        vm.customTaskTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
       // vm.customtasktypeattributevalues = CustomTaskTypeAttributeValue.query();
        
        vm.items = [ {
			value : ''
		} ];
        
        if (vm.customTaskTypeAttribute.customTaskTypeAttributeValues != null && vm.customTaskTypeAttribute.customTaskTypeAttributeValues.length != 0) {
			vm.items = vm.customTaskTypeAttribute.customTaskTypeAttributeValues;
		}
        vm.addmore = function() {
			vm.items.push({
				value : ''
			});
		};

		vm.remove = function(index) {
			vm.items.splice(index, 1);
		};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if  ( vm.customTaskTypeAttribute.id !== null) {
            	
            	 vm.customTaskTypeAttribute.customTaskTypeAttributeValues = [];
				for (var i = 0; i < vm.items.length; i++) {
					 vm.customTaskTypeAttribute.customTaskTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
                CustomTaskTypeAttribute.update(vm.customTaskTypeAttribute, onSaveSuccess, onSaveError);
            } else {
            	
            	
            	 vm.customTaskTypeAttribute.customTaskTypeAttributeValues = [];

				for (var i = 0; i < vm.items.length; i++) {
					 vm.customTaskTypeAttribute.customTaskTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
				
                CustomTaskTypeAttribute.save(vm.customTaskTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customTaskTypeAttributeUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('custom-task-type-attribute');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
