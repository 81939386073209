(function () {
    'use strict';

    angular.module('trakeyeApp').controller('FiberStatusReportController', FiberStatusReportController)

    FiberStatusReportController.$inject = ['$scope', 'UsersList', 'Principal', 'AssetsForMap', 'AssetsForMapByAdmin'];

    function FiberStatusReportController($scope, UsersList, Principal, AssetsForMap, AssetsForMapByAdmin) {
        var vm = this;
        vm.userlist = [];
        $scope.reporthide = false;
        vm.select2 = select2;
        vm.select2();
        vm.users = loadPage();
        vm.multiassets = [];
        vm.assets = [];

    	var result = [];

        function loadPage() {
            var loggedInUserId = 0;
            Principal.identity().then(function (identity) {
                if (identity) {
                    loggedInUserId = identity.id;
                }
            })
            UsersList.getusers(function (response) {
                var users = [];
                response.forEach(function (item, index) {
                    if (item.authorities.includes("ROLE_USER_ADMIN") && (item.id != loggedInUserId)) {
                        users.push(item)
                    }
                });
                vm.userlist = users;

                vm.userlist.forEach(function (item, index) {
                    var vendorName = item.firstName + ' ' + item.lastName;
                    AssetsForMapByAdmin.assetsformapbyadmin(item.id, function (response) {
                    	var onAirDis = 0.0,
                            deployedDis = 0.0,
                            plannedDis = 0.0;
                        response.forEach(function (item) {
                        	
                            var disVal = 0.0;
                            var assetLocations = item.assetCoordinates;
                            if (assetLocations.length > 1) {
                                for (var index in assetLocations) {
                                    if (index >= 1) {
                                        var disCal = distanceCal(assetLocations[index].latitude, assetLocations[index].longitude, assetLocations[index - 1].latitude, assetLocations[index - 1].longitude);
                                        if (disCal > 0) {
                                            disVal += disCal;
                                        }
                                    }
                                }
                            }
                            var assetAV = item.assetTypeAttributeValues;
                            assetAV.forEach(function (item1) {
                                if (item1.assetDataTypeName == 'Fiber Status') {
                                    if (item1.attributeValue == 'On Air') {
                                        onAirDis = onAirDis + disVal;
                                    } else if (item1.attributeValue == 'Under Deployment') {
                                        deployedDis = deployedDis + disVal;
                                    } else if (item1.attributeValue == 'Planned') {
                                        plannedDis = plannedDis + disVal;
                                    }
                                }
                            });
                        });
                        vm.multiassets.push({
                            name: vendorName,
                            onair: onAirDis.toFixed(2),
                            deployed: deployedDis.toFixed(2),
                            planned: plannedDis.toFixed(2)
                        });

                        var vendorData = [];
                        var fiberStatusData = [];
                        var onAirDis = 0.0,
                            deployedDis = 0.0,
                            plannedDis = 0.0;
                        vm.multiassets.forEach(function (item2) {
                            vendorData.push({
                                label: item2.name,
                                value: item2.onair
                            })
                            onAirDis += parseFloat(item2.onair);
                            deployedDis += parseFloat(item2.deployed);
                            plannedDis += parseFloat(item2.planned);

                        });

                        fiberStatusData.push({
                            label: "ON AIR",
                            value: onAirDis.toFixed(2)
                        });
                        fiberStatusData.push({
                            label: "UNDER DEPLOYMENT",
                            value: deployedDis.toFixed(2)
                        });
                        fiberStatusData.push({
                            label: "PLANNED",
                            value: plannedDis.toFixed(2)
                        });
                        var donut = new Morris.Donut({
                            element: 'vendor-chart',
                            resize: true,
                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                            data: vendorData,
                            hideHover: 'auto'
                        });

                        var donut1 = new Morris.Donut({
                            element: 'fiber-chart',
                            resize: true,
                            colors: ["#5668e2", "#56aee2", "#56e2cf", "#56e289", "#68e256", "#aee256", "#e2cf56", "#e28956", "#e25668", "#e256ae", "#cf56e2", "#8a56e2"],
                            data: fiberStatusData,
                            hideHover: 'auto'
                        });
                    });


                });
            });
            vm.reportType = 'agent';
        }
        
        vm.caseTypeClick = function (caseTypeName, value) {
        	vm.assets = [];
        	
        	 vm.userlist.forEach(function (item, index) {
                 var vendorName = item.firstName + ' ' + item.lastName;
                 if(caseTypeName == vendorName){
	                 AssetsForMapByAdmin.assetsformapbyadmin(item.id, function (response) {
	                     response.forEach(function (item) {
	                    	 if(item.assetType.layout == "SPREAD"){
	                    		 var isFiberStatusVal = false; 
	                    		 item.assetTypeAttributeValues.forEach(function(item1){
	                    			 if(item1.assetDataTypeName == "Fiber Status" && item1.attributeValue == value){
	                    				 isFiberStatusVal = true;
	                    			 }
	                    		 });
	                    		 if(isFiberStatusVal){
	                    			 result.push(item);
	                    		 }
	                    	 }
	                     });
	                    
	                 });
                 }
             });
        	 
        	 result.forEach(function(item2){
        		 var disVal = 0.0;
                 var assetLocations = item2.assetCoordinates;
                 if (assetLocations.length > 1) {
                     for (var index in assetLocations) {
                         if (index >= 1) {
                             var disCal = distanceCal(assetLocations[index].latitude, assetLocations[index].longitude, assetLocations[index - 1].latitude, assetLocations[index - 1].longitude);
                             if (disCal > 0) {
                                 disVal += disCal;
                             }
                         }
                     }
                 }
                 var linkStatusVal = '';
                 item2.assetTypeAttributeValues.forEach(function(item3){
        			 if(item3.name == "Link Status"){
        				 if(item3.attributeValue == "True"){
        					 linkStatusVal = "Active";
        				 }else{
        					 linkStatusVal = "Inactive";
        				 }
        			 }
        		 });
                 vm.assets.push({name:item2.name,status:value, distance:disVal.toFixed(2), linkstatus:linkStatusVal });
        	 });
  
        	 result = [];
        };

        function select2() {
            $(".select2").select2();
        }


        $("#userlist").change(function () {
        	vm.assets = [];
            vm.multiassets = [];
            var result = $("#userlist").val();
            if (result != "") {
                vm.multiassets = getAssetObj(result);
            } else {
                vm.userlist.forEach(function (item, index) {
                    var vendorName = item.firstName + ' ' + item.lastName;
                    AssetsForMapByAdmin.assetsformapbyadmin(item.id, function (response) {
                        var onAirDis = 0.0,
                            deployedDis = 0.0,
                            plannedDis = 0.0;
                        response.forEach(function (item) {
                            var disVal = 0.0;
                            var assetLocations = item.assetCoordinates;
                            if (assetLocations.length > 1) {
                                for (var index in assetLocations) {
                                    if (index >= 1) {
                                        var disCal = distanceCal(assetLocations[index].latitude, assetLocations[index].longitude, assetLocations[index - 1].latitude, assetLocations[index - 1].longitude);
                                        if (disCal > 0) {
                                            disVal += disCal;
                                        }
                                    }
                                }
                            }
                            var assetAV = item.assetTypeAttributeValues;
                            assetAV.forEach(function (item1) {
                                if (item1.assetDataTypeName == 'Fiber Status') {
                                    if (item1.attributeValue == 'On Air') {
                                        onAirDis = onAirDis + disVal;
                                    } else if (item1.attributeValue == 'Under Deployment') {
                                        deployedDis = deployedDis + disVal;
                                    } else if (item1.attributeValue == 'Planned') {
                                        plannedDis = plannedDis + disVal;
                                    }
                                }
                            });
                        });
                        vm.multiassets.push({
                            name: vendorName,
                            onair: onAirDis.toFixed(2),
                            deployed: deployedDis.toFixed(2),
                            planned: plannedDis.toFixed(2)
                        });

                        var vendorData = [];
                        var fiberStatusData = [];
                        var onAirDis = 0.0,
                            deployedDis = 0.0,
                            plannedDis = 0.0;
                        vm.multiassets.forEach(function (item1) {
                            vendorData.push({
                                label: item1.name,
                                value: item1.onair
                            })
                            onAirDis += parseFloat(item1.onair);
                            deployedDis += parseFloat(item1.deployed);
                            plannedDis += parseFloat(item1.planned);

                        });

                        fiberStatusData.push({
                            label: "ON AIR",
                            value: onAirDis.toFixed(2)
                        });
                        fiberStatusData.push({
                            label: "UNDER DEPLOYMENT",
                            value: deployedDis.toFixed(2)
                        });
                        fiberStatusData.push({
                            label: "PLANNED",
                            value: plannedDis.toFixed(2)
                        });
                        var donut = new Morris.Donut({
                            element: 'vendor-chart',
                            resize: true,
                            colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                            data: vendorData,
                            hideHover: 'auto'
                        });

                        var donut1 = new Morris.Donut({
                            element: 'fiber-chart',
                            resize: true,
                            colors: ["#5668e2", "#56aee2", "#56e2cf", "#56e289", "#68e256", "#aee256", "#e2cf56", "#e28956", "#e25668", "#e256ae", "#cf56e2", "#8a56e2"],
                            data: fiberStatusData,
                            hideHover: 'auto'
                        });
                    });
                });
            }
        });

        $('#userlist').trigger('change');
        
        function getAssetObj(adminId) {
            var vendorName = "";
            var assetObj = [];
            vm.userlist.forEach(function (item, index) {
                if (item.id == adminId) {
                    vendorName = item.login;
                }
            });
            AssetsForMapByAdmin.assetsformapbyadmin(adminId, function (response) {
                var onAirDis = 0.0,
                    deployedDis = 0.0,
                    plannedDis = 0.0;
                response.forEach(function (item) {
                    var disVal = 0.0;
                    var assetLocations = item.assetCoordinates;
                    if (assetLocations.length > 1) {
                        for (var index in assetLocations) {
                            if (index >= 1) {
                                var disCal = distanceCal(assetLocations[index].latitude, assetLocations[index].longitude, assetLocations[index - 1].latitude, assetLocations[index - 1].longitude);
                                if (disCal > 0) {
                                    disVal += disCal;
                                }
                            }
                        }
                    }
                    var assetAV = item.assetTypeAttributeValues;
                    assetAV.forEach(function (item1) {
                        if (item1.assetDataTypeName == 'Fiber Status') {
                            if (item1.attributeValue == 'On Air') {
                                onAirDis = onAirDis + disVal;
                            } else if (item1.attributeValue == 'Under Deployment') {
                                deployedDis = deployedDis + disVal;
                            } else if (item1.attributeValue == 'Planned') {
                                plannedDis = plannedDis + disVal;
                            }
                        }
                    });
                });
                assetObj.push({
                    name: vendorName,
                    onair: onAirDis.toFixed(2),
                    deployed: deployedDis.toFixed(2),
                    planned: plannedDis.toFixed(2)
                });

                var vendorData = [];
                var fiberStatusData = [];
                var onAirDis = 0.0,
                    deployedDis = 0.0,
                    plannedDis = 0.0;
                vm.multiassets.forEach(function (item1) {
                    vendorData.push({
                        label: item1.name,
                        value: item1.onair
                    })
                    onAirDis += parseFloat(item1.onair);
                    deployedDis += parseFloat(item1.deployed);
                    plannedDis += parseFloat(item1.planned);

                });

                fiberStatusData.push({
                    label: "ON AIR",
                    value: onAirDis.toFixed(2)
                });
                fiberStatusData.push({
                    label: "UNDER DEPLOYMENT",
                    value: deployedDis.toFixed(2)
                });
                fiberStatusData.push({
                    label: "PLANNED",
                    value: plannedDis.toFixed(2)
                });
                var donut = new Morris.Donut({
                    element: 'vendor-chart',
                    resize: true,
                    colors: ["#8a56e2", "#cf56e2", "#e256ae", "#e25668", "#e28956", "#e2cf56", "#aee256", "#68e256", "#56e289", "#56e2cf", "#56aee2", "#5668e2"],
                    data: vendorData,
                    hideHover: 'auto'
                });

                var donut1 = new Morris.Donut({
                    element: 'fiber-chart',
                    resize: true,
                    colors: ["#5668e2", "#56aee2", "#56e2cf", "#56e289", "#68e256", "#aee256", "#e2cf56", "#e28956", "#e25668", "#e256ae", "#cf56e2", "#8a56e2"],
                    data: fiberStatusData,
                    hideHover: 'auto'
                });
            });
            return assetObj;
        }

        

        // Added for generating pdf start
        $scope.close = function () {
            $('#genericReport').show();
            $scope.reporthide = true;
            $('#report').hide();
        }
        $scope.loader = false;



    }

    function distanceCal(lat1, lon1, lat2, lon2) {
        var theta = lon1 - lon2;
        var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
            Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
        dist = Math.acos(dist);
        dist = radians_to_degrees(dist);
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        if (isNaN(dist)) {
            return 0.0;
        } else {
            return (dist);
        }
    }

    function degrees_to_radians(degrees) {
        var pi = Math.PI;
        return degrees * (pi / 180);
    }

    function radians_to_degrees(radians) {
        var pi = Math.PI;
        return radians * (180 / pi);
    }
})();
