(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('AssetDetailController', AssetDetailController);

    AssetDetailController.$inject = ['$scope', '$rootScope', '$stateParams','$state', 'pagingParams', 'ParseLinks', 'paginationConstants','previousState', 'entity', 'Asset', 'User', 'AssetType','$localStorage','Configs','MappedFixedAssets'];

    function AssetDetailController($scope, $rootScope, $stateParams,$state, pagingParams, ParseLinks, paginationConstants,previousState, entity, Asset, User, AssetType,$localStorage,Configs,MappedFixedAssets) {
        var vm = this;

        vm.asset = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        $scope.tab = 1;
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };
        $scope.setTab = function (newTab) {
            $scope.tab = newTab; 
            if ($scope.tab == 2) { 
                MappedFixedAssets.get(vm.asset.id,pagingParams.page - 1,vm.itemsPerPage, function(response){
                    vm.links = ParseLinks.parse(response.headers("link"));
                    vm.totalItems = response.headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.totalItems = Math.ceil(vm.queryCount / vm.itemsPerPage) * 10;
                    vm.listMappedAssets = response.data
                    vm.page = pagingParams.page;
                })
            }
        }
        vm.previousState = previousState.name;
        //vm.showAssetImage = false

        /* Configs.get("AssetImage",function(response){
            if(response.length > 0){
                if(response[0].configValue == "True"){
                    vm.showAssetImage = true
                }
            }
        }) */
        if(vm.asset.assetReferenceAttributes.length){
            vm.assetRefAttributes = []
            vm.asset.assetReferenceAttributes.forEach(function (get){
                if (get["assetAttributeName"] != null) {
                    get['assetAttributeName'].assetTypeAttribute.attributeValue = get['assetAttributeName'].attributeValue;
                    vm.assetRefAttributes.push(get['assetAttributeName'].assetTypeAttribute);
                }

                if (get["assetAttributeValue"] != null) {
                    get['assetAttributeValue'].assetTypeAttribute.attributeValue = get['assetAttributeValue'].attributeValue;
                    vm.assetRefAttributes.push(get['assetAttributeValue'].assetTypeAttribute);
                }

                if (get["assetAttributeImage"] != null) {
                    get['assetAttributeImage'].assetTypeAttribute.imageId = get['assetAttributeImage'].imageId;
                    get['assetAttributeImage'].assetTypeAttribute.attributeName = get['assetAttributeImage'].imageName;
                    vm.assetRefAttributes.push(get['assetAttributeImage'].assetTypeAttribute);
                }
            })
        }

       /*  vm.imageInNewTab = function (img) {
		    var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
            );
            imgWindow.document.close(); 
		}; */
        vm.transition = function transition() {
          /*  if(vm.queryCount )
            MappedFixedAssets.get(vm.asset.id,vm.page,vm.itemsPerPage, function(response){
                vm.links = ParseLinks.parse(response.headers("link"));
                vm.totalItems = response.headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.totalItems = Math.ceil(vm.queryCount / vm.itemsPerPage) * 10;
                vm.listMappedAssets = response.data
                vm.page = pagingParams.page;
            }) */
        }

        $scope.newTabDisplay = function(id,name,str){
            var request = new XMLHttpRequest(),apiImage = name == 'reference' ? "api/trakeyeassetrefimages/" : "api/trakeyeassetimages/";;
            request.open('GET',apiImage+vm.asset.id+"/"+id, true);
            request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;
            
            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                  
                    
                    if(str == "MP4"){
                        var videoUrl = urlCreator.createObjectURL(this.response);
                        
                        var video = document.createElement('video');
                        video.src = videoUrl;
                        video.controls = true;
                        video.autoplay = true;
                
                        var videoWindow = window.open("", "_blank");
                        videoWindow.document.body.appendChild(video);
                    }else{
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                   
                }
            } 
            request.send();
        } 

        var unsubscribe = $rootScope.$on('trakeyeApp:assetUpdate', function(event, result) {
            vm.asset = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
