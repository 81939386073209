(function () {
    'use strict';

    angular.module('trakeyeApp').factory('Vehicle', Vehicle);

    Vehicle.$inject = ['$resource'];

    function Vehicle($resource) {
        var service = $resource('api/vehicles/:id', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            }
        });

        return service;
    }
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('VehicleValueSearch', VehicleValueSearch);

	VehicleValueSearch.$inject = ['$resource'];

	function VehicleValueSearch($resource) {
		var service = $resource('/api/vehicles/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();