(function () {
	'use strict';

	angular.module('trakeyeApp').controller('UserLoginHistoryReportController', UserLoginHistoryReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	UserLoginHistoryReportController.$inject = ['$scope', '$sce', '$state', 'Report'];

	function UserLoginHistoryReportController($scope, $sce, $state, Report) {
		var vm = this;
		vm.report = report;
		vm.reporthide = false;
		vm.select2 = select2;
		vm.select2();
		vm.sortResponse = sortResponse;
		vm.downloadUserLastLoginReport = downloadUserLastLoginReport;
		vm.users = loadPage();

		function loadPage() {
			Report.getUsers('', function (response) {
				vm.users = response;
			})
		}

		function report() {
			if (vm.userAgent == 'all') {
				vm.id = 0;
			}
			Report.getUserLastLoginReport({
				id: vm.id
			}, function (response) {

				if (response instanceof Array) {
					vm.reportRecords = response;
				} else {
					vm.reportRecords = [];
					vm.reportRecords.push(response);
				}
				vm.search = "";
				vm.reporthide = true;
			})
		}
		
		function sortResponse() {
			if (vm.userAgent == 'all') {
				vm.id = 0;
			}
			Report.getUserLastLoginReport({
				id: vm.id,
				sortFieldName: vm.predicate,
				sortOrder: vm.reverse ? 'asc' : 'desc'
			}, function (response) {

				if (response instanceof Array) {
					vm.reportRecords = response;
				} else {
					vm.reportRecords = [];
					vm.reportRecords.push(response);
				}
				vm.search = "";
				vm.reporthide = true;
			});
		}

		function downloadUserLastLoginReport() {
			if (vm.userAgent == 'all') {
				vm.id = 0;
			}
			Report.downloadUserLastLoginReport({
				id: vm.id
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/csv' });
				var fileURL = URL.createObjectURL(file);
				// window.open(fileURL, "_blank");
				a.href = fileURL;
				a.download = "user_last_login_report.csv";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}
		
		function select2() {
			$(".select2").select2();
		}
	}
})();
