(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetHealthController", AssetHealthController);

    AssetHealthController.$inject = [
        "$scope",
        "$rootScope",
        "AlertService",
        "UserAccount",
        "UserGeofences",
        "GeofenceIDs",
        "AssetByID",
        "AssetType",
        "BufferGeofencesByMiniID",
        "AssetGroupByBufferGeo",
    ];

    function AssetHealthController(
        $scope,
        $rootScope,
        AlertService,
        UserAccount,
        UserGeofences,
        GeofenceIDs,
        AssetByID,
        AssetType,
        BufferGeofencesByMiniID,
        AssetGroupByBufferGeo
    ) {
        var vm = this;

        var locations = {};
        var isAdmin = false;
        vm.userGeofences = [];
        vm.selectedGeofenceId = 0;
        var markers = [];
        var polylines = [];
        vm.mapAssets = [];
        var latlng = null;
        var activeInfo;
        vm.selectedGeofence = {};
        vm.assetTypes = [];
        vm.previousPolygons = [];
        vm.select2 = select2;
        vm.closeAssetAttributesModal = closeAssetAttributesModal;
        vm.closeThreePointReference = closeThreePointReference;
        vm.saveGeofenceFilter = saveGeofenceFilter;
        vm.showAssetHealth = showAssetHealth;
        var openedInfoWindow = null;
        var prevInfoWindow = null;
        vm.bufferGeoAssets = [];
        var polylines = [];
        vm.assetHealthDistance = [];
        vm.select2();
        load();

        function select2() {
            $(".select2").select2();
        }

        function load() {
            document.getElementById("myspan").textContent = " ";
            /*  var myOptions = {
                zoom: 12,
                center: new google.maps.LatLng(
                    $rootScope.DEFAULT_LATITUDE,
                    $rootScope.DEFAULT_LONGITUDE
                ),
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            }; */

            locations.map = new google.maps.Map(
                document.getElementById("map_canvas"),
                myOption(
                    12,
                    new google.maps.LatLng($rootScope.DEFAULT_LATITUDE, $rootScope.DEFAULT_LONGITUDE)
                )
            );
            geoLocation(locations.map);
            vm.userGeofences = [];
            UserAccount.get(function (data) {
                isAdmin = false;
                data.authorities.forEach(function (val) {
                    if (val == "ROLE_USER_ADMIN") {
                        isAdmin = true;
                    } else if (val == "ROLE_HOD") {
                        isAdmin = true;
                    }
                });
                if (isAdmin) {
                    GeofenceIDs.getAll(function (response) {
                        response.forEach(function (geofence) {
                            vm.userGeofences.push(geofence);

                            if (vm.selectedGeofenceId == null) {
                                vm.selectedGeofenceId = geofence.id;
                            }
                        });
                        vm.countLength = vm.userGeofences.length;
                    });
                } else {
                    UserGeofences.getAll(function (response) {
                        response.forEach(function (geofence) {
                            vm.userGeofences.push(geofence);

                            if (vm.selectedGeofenceId == null) {
                                vm.selectedGeofenceId = geofence.id;
                            }
                        });
                        vm.countLength = vm.userGeofences.length;
                    });
                }
            });
            AssetType.query(
                {
                    page: 0,
                    size: 100,
                },
                onSuccess,
                onError
            );
            function onSuccess(data, headers) {
                vm.assetTypes = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    }
                ],
            });
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function showAssetHealth() {
            vm.mapAssets = [];
            markers = [];
            polylines = [];
            vm.assetHealthDistance = [];

            var myOptions = {
                zoom: 12,
                center: new google.maps.LatLng(
                    $rootScope.DEFAULT_LATITUDE,
                    $rootScope.DEFAULT_LONGITUDE
                ),
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };

            locations.map = new google.maps.Map(
                document.getElementById("map_canvas"),
                myOption(
                    12,
                    new google.maps.LatLng($rootScope.DEFAULT_LATITUDE, $rootScope.DEFAULT_LONGITUDE)
                )
            );
            geoLocation(locations.map);
            document.getElementById("myspan").textContent = "";
            saveGeofenceFilter();

            AssetGroupByBufferGeo.get(
                vm.selectedGeofenceId,
                function (response) {
                    vm.bufferGeoAssets = Object.values(response);
                    var geofenceIds = Object.keys(response);
                    vm.bufferGeoAssets.forEach(function (assetList, index) {
                        drawAssets(assetList, geofenceIds[index]);
                    });
                }
            );
        }

        function drawAssets(response, geofenceId) {
            var normalDistance = 0;
            var okDistance = 0;
            var notOkDistance = 0;
            if (response) {
                var assetList = [];
                $.each(response, function (index, value) {
                    if (value.assetTypeLayout == null) {
                        value.assetTypeName = value.assetType.name;
                        value.assetTypeLayout = value.assetType.layout;
                        value.assetTypeImagePath = value.assetType.imagePath;
                    }
                    if (
                        value.assetTypeLayout != null &&
                        value.assetTypeLayout == "FIXED"
                    ) {
                        if (value.assetCoordinates.length > 1) {
                            var value1 = value;
                            value1.assetCoordinates = [
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ],
                            ];

                            // drawFixedAsset(value1);
                            assetList.push(value1);
                        } else {
                            // drawFixedAsset(value);
                            assetList.push(value);
                        }
                    }
                });
                assetList.forEach(function (value) {
                    drawFixedAsset(value);
                });
                var previousAsset = null;
                assetList.forEach(function (assetVal) {
                    if (previousAsset != null) {
                        if (previousAsset.assetCoordinates != undefined) {
                            var pathColor = "blue";
                            if (
                                previousAsset.healthIndicator == null ||
                                assetVal.healthIndicator == null
                            ) {
                                pathColor = "blue";
                            } else if (
                                previousAsset.healthIndicator &&
                                assetVal.healthIndicator
                            ) {
                                pathColor = "green";
                            } else if (
                                !previousAsset.healthIndicator ||
                                !assetVal.healthIndicator
                            ) {
                                pathColor = "red";
                            }

                            var path = [];

                            path.push({
                                lat: previousAsset.assetCoordinates[0].latitude,
                                lng:
                                    previousAsset.assetCoordinates[0].longitude,
                            });
                            path.push({
                                lat: assetVal.assetCoordinates[0].latitude,
                                lng: assetVal.assetCoordinates[0].longitude,
                            });
                            var dis = distanceCal(
                                path[0].lat,
                                path[0].lng,
                                path[1].lat,
                                path[1].lng
                            );
                            switch (pathColor) {
                                case "green":
                                    okDistance += dis;
                                    break;
                                case "red":
                                    notOkDistance += dis;
                                    break;
                                case "blue":
                                    normalDistance += dis;
                                    break;
                                default:
                                    normalDistance += dis;
                                    break;
                            }
                            drawHealthPolyline(path, pathColor);
                        }
                    }
                    previousAsset = assetVal;
                });
            }
            for (var i = 0; i < vm.assetHealthDistance.length; i++) {
                if (vm.assetHealthDistance[i].id == geofenceId) {
                    vm.assetHealthDistance[i].okDistance = okDistance.toFixed(
                        2
                    );
                    vm.assetHealthDistance[
                        i
                    ].notOkDistance = notOkDistance.toFixed(2);
                    vm.assetHealthDistance[
                        i
                    ].normalDistance = normalDistance.toFixed(2);
                }
            }
        }

        function saveGeofenceFilter() {
            vm.previousPolygons.forEach(function (polygon) {
                polygon.setMap(null);
            });
            vm.previousPolygons = [];
            var canZoomToLocation = true;
            BufferGeofencesByMiniID.get(
                vm.selectedGeofenceId,
                function (response) {
                    response.forEach(function (value) {
                        vm.assetHealthDistance.push({
                            id: value.id,
                            name: value.name,
                        });
                        vm.previousPolygons.push(
                            drawPolygon(value, locations.latlng, "blue", 3)
                        );
                        if (canZoomToLocation) {
                            var path = JSON.parse(value.coordinates);
                            locations.map.setZoom(14);
                            if (path.length > 0) {
                                locations.map.setCenter(path[0]);
                            }
                            canZoomToLocation = false;
                        }
                    });
                }
            );
        }

        function drawPolygon(value, latlang, color, zIndex) {
            var path = JSON.parse(value.coordinates);
            // drawPolylineForGeoFence(path, locations.map, value.name);
            var infowindow = new google.maps.InfoWindow({
                content:
                    '<div class="infoWindowhead">' +
                    "name" +
                    '<div class="infoWindowContent"> <b>Description :</b> ' +
                    "des" +
                    "</div></div>",
                minWidth: 160,
            });

            infowindow.setPosition(latlang);
            var strokeColor = "#ff0000";

            if (color == "green") {
                strokeColor = "#00CC00";
            } else if (color == "blue") {
                strokeColor = "#0066CC";
            }

            var tempPolygon = new google.maps.Polygon({
                paths: path,
                strokeColor: strokeColor,
                strokeOpacity: 0.5,
                strokeWeight: 1.2,
                fillColor: strokeColor,
                fillOpacity: 0.1,
                zIndex: zIndex,
            });
            google.maps.event.addListener(
                tempPolygon,
                "mouseout",
                function (event) {
                    infowindow.close();
                }
            );

            google.maps.event.addListener(
                tempPolygon,
                "click",
                function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent(
                        '<div class="infoWindowhead">' +
                            "<b>Geofence Name : </b><br><br>" +
                            value.name +
                            "</br></div>"
                    );

                    infowindow.mousemove;
                    infowindow.open(locations.map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                }
            );
            tempPolygon.setMap(locations.map);
            return tempPolygon;
        }

        function drawFixedAsset(fixed) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infoWindow = new google.maps.InfoWindow();

                var iconUrl = "";

                var isFixedAsset = false;
                if ($scope.variableHealth) {
                    if (
                        fixed.healthIndicator == true &&
                        fixed.assetTypeName == "Chamber"
                    ) {
                        iconUrl = "content/images/chamber_green.png";
                    } else if (
                        fixed.healthIndicator == false &&
                        fixed.assetTypeName == "Chamber"
                    ) {
                        iconUrl = "content/images/chamber_red.png";
                    } else if (
                        fixed.healthIndicator == true &&
                        fixed.assetTypeName == "Coupler"
                    ) {
                        iconUrl = "content/images/coupler_green.png";
                    } else if (
                        fixed.healthIndicator == false &&
                        fixed.assetTypeName == "Coupler"
                    ) {
                        iconUrl = "content/images/coupler_red.png";
                    } else if (
                        fixed.healthIndicator == true &&
                        fixed.assetTypeName == "Pole"
                    ) {
                        iconUrl = "content/images/pole_green.png";
                    } else if (
                        fixed.healthIndicator == false &&
                        fixed.assetTypeName == "Pole"
                    ) {
                        iconUrl = "content/images/pole_red.png";
                    } else {
                        iconUrl = fixed.assetTypeImagePath;
                    }
                } else {
                    iconUrl = fixed.assetTypeImagePath;
                }

                iconUrl = fixed.assetTypeImagePath;

                var health = "Medium";
                var healthColor = "#0096FF";

                if (fixed.healthIndicator == true) {
                    health = "Good";
                    healthColor = "#00FF7F";
                } else if (fixed.healthIndicator == false) {
                    health = "Bad";
                    healthColor = "#FF5733";
                }

                var iconSize = 36;

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(18, -3),
                    },
                    label: {
                        text: ".",
                        color: healthColor,
                        fontWeight: "bold",
                        fontSize: "40px",
                        opacity: 0.25,
                    },
                });

                var infoWindow2 = new google.maps.InfoWindow();
                var infoStr = "";
                var otdr = "";

                var userContent = document.createElement("div"),
                    button;

                userContent.innerHTML =
                    '<div class="infoWindowhead">' +
                    "<b>Asset Name</b> : " +
                    startMarker.title +
                    '</div><div class="infoWindowContent">' +
                    "<b>Lat</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .latitude +
                    "<br><b>Long</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .longitude +
                    "<br><b>Health</b> :" +
                    health +
                    "<br>" +
                    infoStr +
                    "</div>";

                google.maps.event.addListener(
                    startMarker,
                    "dblclick",
                    function () {
                        infoWindow2.close();
                    }
                );

                var activeInfoWindow;
                var infoWindow2 = new google.maps.InfoWindow({
                    content: userContent,
                });
                // add listener on InfoWindow for click
                // event
                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function () {
                        if (activeInfo != null) {
                            activeInfo.close();
                        }
                        AssetByID.get(fixed.id, function (response) {
                            vm.assetToEdit = response;
                            var infoWindow2 = new google.maps.InfoWindow();
                            var infoStr = "";
                            var otdr = "";
                            var assetTypeAttributeValuesList =
                                response.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function (a, b) {
                                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                    nameB = a.assetTypeAttribute.name.toLowerCase();
                                if (nameA < nameB)
                                    // sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0; // default return value (no sorting)
                            });
                            for (
                                var i = 0;
                                i < assetTypeAttributeValuesList.length;
                                i++
                            ) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr +=
                                    "<br><b>" +
                                    home.assetTypeAttribute.name +
                                    "</b> : " +
                                    home.attributeValue;
                                otdr = home;
                            }

                            var userContent = document.createElement("div"),
                                btnShowAttributes,
                                btnThreePointRef;

                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +
                                "<b>Asset Name</b> : " +
                                startMarker.title +
                                '</div><div class="infoWindowContent">' +
                                "<b>Lat</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].latitude +
                                "<br><b>Long</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].longitude +
                                "<br><b>Health</b> : " +
                                health +
                                "</div><br>";

                            vm.assetTitle = startMarker.title;

                            var attr = document.createAttribute("data-toggle");
                            attr.value = "modal";
                            var attr1 = document.createAttribute("data-target");
                            attr1.value = "#myModalMap";

                            btnShowAttributes = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnShowAttributes.type = "button";
                            btnShowAttributes.value = "More Details";
                            btnShowAttributes.className = "button";

                            btnThreePointRef = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnThreePointRef.type = "button";
                            btnThreePointRef.value = "3 Point Reference";
                            btnThreePointRef.className = "button";

                            google.maps.event.addDomListener(
                                btnShowAttributes,
                                "click",
                                function () {
                                    showAssetAttributesModal(response);
                                }
                            );

                            google.maps.event.addDomListener(
                                btnThreePointRef,
                                "click",
                                function () {
                                    showThreePontReferenceModal(response);
                                }
                            );

                            var infoWindow2 = new google.maps.InfoWindow({
                                content: userContent,
                                minWidth: 350,
                            });
                            if (prevInfoWindow != null) prevInfoWindow.close();
                            infoWindow2.open(locations.map, startMarker);
                            setTimeout(function () {
                                infoWindow2.close();
                            }, 10000);

                            activeInfoWindow = infoWindow2;
                            prevInfoWindow = infoWindow2;
                        });
                    }
                );
                openedInfoWindow = infoWindow2;
            });
        }

        function showAssetAttributesModal(assetVal) {
            $("#modalAssetAttributes").show();
            var assetTypeAttributeValuesList =
                assetVal.assetTypeAttributeValues;
            document.getElementById("assetAttributeTable").innerHTML = "";

            var newTable =
                "<table align='center' style='border: 1px solid black;width:50%;'><tr><th style='border: 1px solid black;text-align: center;'>Name</th><th style='border: 1px solid black;text-align: center;'>Value</th></tr>";

            assetTypeAttributeValuesList.forEach(function (value) {
                if (
                    value.assetTypeAttribute.assetAttributeTypes ==
                    "NON_REFERENCE"
                ) {
                    newTable +=
                        "<tr><td style='border: 1px solid black;text-align: center;'>" +
                        value.assetTypeAttribute.name +
                        "</td><td style='border: 1px solid black;text-align: center;'>" +
                        value.attributeValue +
                        "</td></tr>";
                }
            });
            newTable += "</table>";
            document.getElementById("assetNameAssetAttributes").textContent =
                assetVal.name + " - " + assetVal.assetType.name;
            document.getElementById("assetAttributeTable").innerHTML = newTable;
        }

        function showThreePontReferenceModal(assetVal) {
            $("#modalThreePointReference").show();
            var assetTypeAttributeValuesList =
                assetVal.assetTypeAttributeValues;
            var newTable =
                "<table align='center' style='border: 1px solid black;width:50%;'><tr><th style='border: 1px solid black;text-align: center;'>Object</th><th style='border: 1px solid black;text-align: center;'>Distance (mtr)</th></tr>";
            var ref = ["", "", ""];
            var dist = [0, 0, 0];
            assetTypeAttributeValuesList.forEach(function (value) {
                if (
                    value.assetTypeAttribute.assetAttributeTypes ==
                    "THREE_PNT_REFERENCE"
                ) {
                    if (value.assetTypeAttribute.name == "3 Point Reference1") {
                        ref[0] = value.attributeValue;
                    } else if (
                        value.assetTypeAttribute.name == "3 Point Reference2"
                    ) {
                        ref[1] = value.attributeValue;
                    } else if (
                        value.assetTypeAttribute.name == "3 Point Reference3"
                    ) {
                        ref[2] = value.attributeValue;
                    } else if (value.assetTypeAttribute.name == "Distance1") {
                        dist[0] = value.attributeValue;
                    } else if (value.assetTypeAttribute.name == "Distance2") {
                        dist[1] = value.attributeValue;
                    } else if (value.assetTypeAttribute.name == "Distance3") {
                        dist[2] = value.attributeValue;
                    }
                }
            });

            for (var i = 0; i < 3; i++) {
                newTable +=
                    "<tr><td style='border: 1px solid black;text-align: center;'>" +
                    ref[i] +
                    "</td><td style='border: 1px solid black;text-align: center;'>" +
                    dist[i] +
                    "</td></tr>";
            }
            newTable += "</table>";
            document.getElementById(
                "assetNameThreePointReference"
            ).textContent = assetVal.name + " - " + assetVal.assetType.name;
            document.getElementById(
                "threePointReferenceTable"
            ).innerHTML = newTable;
        }

        function closeAssetAttributesModal() {
            $("#modalAssetAttributes").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalAssetAttributes").hide();
        }

        function closeThreePointReference() {
            $("#modalThreePointReference").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalThreePointReference").hide();
        }

        function drawHealthPolyline(path, color) {
            var strokeColor = "#0000FF";
            if (color == "green") {
                strokeColor = "#008000";
            } else if (color == "red") {
                strokeColor = "#FF0000";
            } else if (color == "blue") {
                strokeColor = "#fd7a24";
            }

            var polyline = new google.maps.Polyline({
                path: path,
                geodesic: true,
                strokeColor: strokeColor,
                strokeOpacity: 0.5,
                strokeWeight: 10,
                fillColor: strokeColor,
                fillOpacity: 0.4,
            });
            polyline.setMap(locations.map);
            polylines.push(polyline);
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                    Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                    Math.cos(degrees_to_radians(lat2)) *
                    Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }
    }
})();
