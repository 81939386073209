(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("DashboardRolloutController", DashboardRolloutController);

    DashboardRolloutController.$inject = [
        "$scope",
        "$rootScope",
        "WorkOrderStats",
        "WorkOrder",
        "WorkFlowListByWorkOrderID",
        "AssetsByWorkFlowID",
        "TrProject",
        "pagingParams",
        "ParseLinks",
        "AlertService",
        "TrRouteListByProjectId",
        "RouteByID",
        "TaskStatusById",
        "SummaryPost",
        "TrRouteForMap",
        "ResourceType",
        "MaterialType"
    ];

    function DashboardRolloutController(
        $scope,
        $rootScope,
        WorkOrderStats,
        WorkOrder,
        WorkFlowListByWorkOrderID,
        AssetsByWorkFlowID,
        TrProject,
        pagingParams,
        ParseLinks,
        AlertService,
        TrRouteListByProjectId,
        RouteByID,
        TaskStatusById,
        SummaryPost,
        TrRouteForMap,
        ResourceType,
        MaterialType
    ) {
        var vm = this;
        var locations = {};
        var openedInfoWindow = null;
        var markers = [];
        var userPaths = {};
        vm.selectedWorkOrderDetails = null;
        vm.mapHeight = $rootScope.mapHeight;
        vm.routeResponse =  []
        var entryMarker =  null

        vm.uomMaterialTransaction = {}
        vm.storeMaterialTransactionName = []

        var centerGeofence = new google.maps.LatLng(
            $rootScope.DEFAULT_LATITUDE,
            $rootScope.DEFAULT_LONGITUDE
        );
        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            myOption(5, centerGeofence)
        );

        /*  WorkOrderStats.get("ROLLOUT",function (response) {
            var totalCount = response.length;
            var redCount = 0;
            var amberCount = 0;
            var greenCount = 0;
            response.forEach(function (markerVal) {
                if (markerVal.ragStatus == "RED") {
                    redCount++;
                } else if (markerVal.ragStatus == "AMBER") {
                    amberCount++;
                } else if (markerVal.ragStatus == "GREEN") {
                    greenCount++;
                }
                drawMarker(markerVal);
            });
            loadWorkOrderStats(
                totalCount,
                redCount,
                amberCount,
                greenCount
            );
        }); */

        function loadWorkOrderStats(
            totalCount,
            redCount,
            amberCount,
            greenCount
        ) {
            var svgtemp = d3.select("#workorder-stats-chart");
            svgtemp.selectAll("*").remove();
            var dataset = [
                {
                    label: "RED",
                    count: redCount,
                    percentage: ((redCount / totalCount) * 100).toFixed(0),
                },
                {
                    label: "AMBER",
                    count: amberCount,
                    percentage: ((amberCount / totalCount) * 100).toFixed(0),
                },
                {
                    label: "GREEN",
                    count: greenCount,
                    percentage: ((greenCount / totalCount) * 100).toFixed(0),
                },
            ];

            var width = 240;
            var height = 300;

            try {
                width =
                    document.getElementById("workorder-stats-chart")
                        .offsetWidth - 380;
                height = document.getElementById("workorder-stats-chart")
                    .offsetHeight;
            } catch (error) {
                // console.log(error);
            }

            // var radius = Math.min(width, height) / 2;
            // var donutWidth = 40;

            var radius = Math.min(width, height) / 1.8;
            var legendRectSize = 12;
            var legendSpacing = 3;

            var color = d3.scale
                .ordinal()
                .range(["#d2222d", "#ffbf00", "#238823"]);

            var svg = d3
                .select("#workorder-stats-chart")
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("shape-rendering", "geometricPrecision")
                .attr(
                    "transform",
                    "translate(" + width / 2 + "," + height / 2 + ")"
                );

            var arc = d3.svg
                .arc()
                .innerRadius(radius - 70)
                .outerRadius(radius - 30);

            var pie = d3.layout
                .pie()
                .value(function (d) {
                    return d.count;
                })
                .sort(null);

            dataset.forEach(function (d) {
                d.count = +d.count;
                d.enabled = true;
            });

            var path = svg
                .selectAll("path")
                .data(pie(dataset))
                .enter()
                .append("path")
                .attr("d", arc)
                .attr("fill", function (d, i) {
                    return color(
                        d.data.label + " - " + d.data.percentage + " %"
                    );
                })
                .each(function (d) {
                    this._current = d;
                });

            var tooltip2 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            path.on("mouseover", function (d) {
                tooltip2
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html(d.data.label + "<br>" + d.data.count);
                return tooltip2.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip2
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip2.style("visibility", "hidden");
                });

            var legend = svg
                .selectAll(".legend")
                .data(color.domain())
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", function (d, i) {
                    var height = legendRectSize + legendSpacing;
                    var offset = (height * color.domain().length) / 2;
                    var horz = -4.5 * legendRectSize;
                    var vert = i * height - offset;
                    return "translate(" + horz + "," + vert + ")";
                });

            legend
                .append("rect")
                .attr("width", legendRectSize)
                .attr("height", legendRectSize)
                .style("fill", color)
                .style("stroke", color)
                .on("click", function (label) {
                    var rect = d3.select(this);
                    var enabled = true;
                    var totalEnabled = d3.sum(
                        dataset.map(function (d) {
                            return d.enabled ? 1 : 0;
                        })
                    );

                    if (rect.attr("class") === "disabled") {
                        rect.attr("class", "");
                    } else {
                        if (totalEnabled < 2) return;
                        rect.attr("class", "disabled");
                        enabled = false;
                    }

                    pie.value(function (d) {
                        if (d.label === label) d.enabled = enabled;
                        return d.enabled ? d.count : 0;
                    });

                    path = path.data(pie(dataset));

                    path.transition()
                        .duration(750)
                        .attrTween("d", function (d) {
                            var interpolate = d3.interpolate(this._current, d);
                            this._current = interpolate(0);
                            return function (t) {
                                return arc(interpolate(t));
                            };
                        });
                });

            legend
                .append("text")
                .attr("x", legendRectSize + legendSpacing)
                .attr("y", legendRectSize - legendSpacing)
                .style("font-family", "sans-serif")
                .style("font-size", "12px")
                .text(function (d) {
                    return d;
                });
        }

        function drawMarker(value) {
            if (openedInfoWindow != null) {
                openedInfoWindow.close();
            }
            var iconUrl = "content/images/red-marker-100.png";

            if (value.ragStatus == "RED") {
                iconUrl = "content/images/red-marker-100.png";
            } else if (value.ragStatus == "AMBER") {
                iconUrl = "content/images/amber-marker-100.png";
            } else if (value.ragStatus == "GREEN") {
                iconUrl = "content/images/green-marker-100.png";
            }
            var infoWindow = new google.maps.InfoWindow();

            var startMarker = new google.maps.Marker({
                map: locations.map,
                position: new google.maps.LatLng(
                    value.latitude,
                    value.longitude
                ),
                title: value.description,
                icon: {
                    scaledSize: new google.maps.Size(36, 36),
                    origin: new google.maps.Point(0, 0),
                    url: iconUrl,
                    anchor: new google.maps.Point(24, 24),
                },
            });

            markers.push(startMarker);

            var infoWindow2 = new google.maps.InfoWindow();

            var userContent = document.createElement("div"),
                button;

            userContent.innerHTML =
                '<div class="infoWindowhead">' +
                startMarker.title +
                '</div><div class="infoWindowContent">' +
                "<b>Lat</b> :" +
                "<br>" +
                "</div>";

            google.maps.event.addListener(startMarker, "dblclick", function () {
                infoWindow2.close();
            });

            var infoWindow2 = new google.maps.InfoWindow({
                content: userContent,
            });
            // add listener on InfoWindow for click
            // event
            google.maps.event.addListener(startMarker, "click", function () {
                WorkFlowListByWorkOrderID.get(
                    value.id,
                    function (workorderDetails) {
                        vm.selectedWorkOrderDetails = null;
                        vm.new = 0;
                        vm.trenching = 0;
                        vm.ducting = 0;
                        vm.fiberifing = 0;
                        vm.lightning = 0;
                        vm.dit = 0;
                        vm.at = 0;
                        vm.workflowCount = 0;
                        vm.newAssets = [];
                        vm.trenchingAssets = [];
                        vm.ductingAssets = [];
                        vm.fiberpullingAssets = [];
                        vm.lightningAssets = [];
                        vm.routeSurveyAssets = [];
                        vm.chamberAssetsCount = 0;
                        vm.couplerAssetsCount = 0;
                        vm.siteAssetsCount = 0;
                        vm.fiberAssetsCount = 0;
                        var barData = {};
                        vm.selectedWorkOrderDetails = workorderDetails[0];
                        vm.selectedWorkOrderDetails.workflows.forEach(function (
                            workflow,
                            index
                        ) {
                            vm.workflowCount++;
                            AssetsByWorkFlowID.get(
                                workflow.id,
                                function (assets) {
                                    var workFlowAssets = [];
                                    assets.forEach(function (asset) {
                                        if (asset.assetTypeLayout == "SPREAD") {
                                            workFlowAssets.push(asset);
                                            if (asset.assetTypeName == "Link") {
                                                vm.fiberAssetsCount++;
                                            } else if (
                                                asset.assetTypeName ==
                                                "KML Link"
                                            ) {
                                                vm.fiberAssetsCount++;
                                            }
                                        } else {
                                            if (
                                                asset.assetTypeName ==
                                                    "Chamber_Latest" ||
                                                asset.assetTypeName == "Chamber"
                                            ) {
                                                vm.chamberAssetsCount++;
                                            } else if (
                                                asset.assetTypeName == "Coupler"
                                            ) {
                                                vm.couplerAssetsCount++;
                                            } else if (
                                                asset.assetTypeName == "Site"
                                            ) {
                                                vm.siteAssetsCount++;
                                            }
                                        }
                                    });
                                    switch (workflow.status) {
                                        case "NEW":
                                            vm.new++;
                                            vm.newAssets = workFlowAssets;
                                            drawPolyline(
                                                workFlowAssets,
                                                "#6495ED",
                                                userPaths.map
                                            );
                                            break;
                                        case "HDDTRENCHINGDONE":
                                            vm.trenching++;
                                            vm.trenchingAssets = workFlowAssets;
                                            drawPolyline(
                                                workFlowAssets,
                                                "#9FE2BF",
                                                userPaths.map
                                            );
                                            break;
                                        case "DUCTINGDONE":
                                            vm.ducting++;
                                            vm.ductingAssets = workFlowAssets;
                                            drawPolyline(
                                                workFlowAssets,
                                                "#FF7F50",
                                                userPaths.map
                                            );
                                            break;
                                        case "FIBERPULLINGDONE":
                                            vm.fiberifing++;
                                            vm.fiberpullingAssets = workFlowAssets;
                                            drawPolyline(
                                                workFlowAssets,
                                                "#FFBF00",
                                                userPaths.map
                                            );
                                            break;
                                        case "LIGHTING":
                                            vm.lightning++;
                                            vm.lightningAssets = workFlowAssets;
                                            drawPolyline(
                                                workFlowAssets,
                                                "#DFFF00",
                                                userPaths.map
                                            );
                                            break;
                                        case "ROUTESURVEYDONE":
                                            vm.dit++;
                                            vm.routeSurveyAssets = workFlowAssets;
                                            drawPolyline(
                                                workFlowAssets,
                                                "#CCCCFF",
                                                userPaths.map
                                            );
                                            break;
                                    }
                                    vm.newPercentage = parseInt(
                                        (vm.new / vm.workflowCount) * 100
                                    );
                                    vm.trenchingPercentage = parseInt(
                                        (vm.trenching / vm.workflowCount) * 100
                                    );
                                    vm.ductingPercentage = parseInt(
                                        (vm.ducting / vm.workflowCount) * 100
                                    );
                                    vm.fiberifingPercentage = parseInt(
                                        (vm.fiberifing / vm.workflowCount) * 100
                                    );
                                    vm.lightningPercentage = parseInt(
                                        (vm.lightning / vm.workflowCount) * 100
                                    );
                                    vm.ditPercentage = parseInt(
                                        (vm.dit / vm.workflowCount) * 100
                                    );
                                    vm.atPercentage = parseInt(
                                        (vm.at / vm.workflowCount) * 100
                                    );
                                    barData = {
                                        geofences: [
                                            {
                                                userStatusCount: {
                                                    Available:
                                                        vm.fiberAssetsCount *
                                                        1.5,
                                                },
                                                label: "Fiber",
                                                consumed: vm.fiberAssetsCount,
                                            },
                                            {
                                                userStatusCount: {
                                                    Available:
                                                        vm.siteAssetsCount *
                                                        1.5,
                                                },
                                                label: "Tower",
                                                consumed: vm.siteAssetsCount,
                                            },
                                            {
                                                userStatusCount: {
                                                    Available:
                                                        vm.couplerAssetsCount *
                                                        1.2,
                                                },
                                                label: "Coupler",
                                                consumed: vm.couplerAssetsCount,
                                            },
                                            {
                                                userStatusCount: {
                                                    Available:
                                                        vm.chamberAssetsCount *
                                                        1.3,
                                                },
                                                label: "Chamber",
                                                consumed: vm.chamberAssetsCount,
                                            },
                                        ],
                                    };
                                    if (index == 0) {
                                        showDashboardData(barData);
                                    }
                                }
                            );
                        });

                        /* var ele = document.getElementById("workorderChartModal");
                    $(ele).modal("show"); */
                        var centerGeofence = new google.maps.LatLng(
                            $rootScope.DEFAULT_LATITUDE,
                            $rootScope.DEFAULT_LONGITUDE
                        );
                        userPaths.map = new google.maps.Map(
                            document.getElementById("workflow_map_canvas"),
                            myOption(10, centerGeofence)
                        );
                    }
                );
            });
            openedInfoWindow = infoWindow2;
        }

        /*  var centerGeofence = new google.maps.LatLng(
            $rootScope.DEFAULT_LATITUDE,
            $rootScope.DEFAULT_LONGITUDE
        );
        userPaths.map = new google.maps.Map(
            document.getElementById("workflow_map_canvas"),
            myOption(10, centerGeofence)
        ); */
        /* var customDiv = document.getElementById("workorder-stats-chart");

        // Add the custom div to the map
        locations.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
            customDiv
        ); */

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    },
                ],
            });
        }

        function drawPolyline(spreadAssets, color, map) {
            var cableCoordinates = [];
            var pos = {};
            for (var i = 0; i < spreadAssets.length; i++) {
                cableCoordinates = [];
                var coord = spreadAssets[i].assetCoordinates;
                pos = {
                    lat: coord[0].latitude,
                    lng: coord[0].longitude,
                };
                map.setZoom(11);
                map.setCenter(pos);
                coord.forEach(function (item) {
                    cableCoordinates.push({
                        lat: item.latitude,
                        lng: item.longitude,
                    });
                });
                var cablePath = new google.maps.Polyline({
                    path: cableCoordinates,
                    strokeColor: color,
                    strokeOpacity: 4,
                    strokeWeight: 10,
                });
                cablePath.setMap(map);
            }
        }

        /* function showDashboardData(data) {
            vm.users = data.users;
            vm.isGeofences = data.geofences.length == 0;
            var svgtempstatus = d3.select("#status");
            svgtempstatus.selectAll("*").remove();
            var svgtemp = d3.select("#stacked-chart-status");
            svgtemp.selectAll("*").remove();
            var tempHeight, tempWidth;

            // geofence bar graph
            var margin2 = { top: 30, right: 10, bottom: 85, left: 30 };

            var width2 = 960;
            var height2 = 300 - margin2.top - margin2.bottom;

            try {
                width2 =
                    document.getElementById("stacked-chart-status")
                        .offsetWidth -
                    margin2.left -
                    margin2.right;

                height2 =
                    document.getElementById("stacked-chart-status")
                        .offsetHeight -
                    margin2.top -
                    margin2.bottom;

                if (width2 < 0 || height2 < 0) {
                    width2 = tempWidth;
                    height2 = tempHeight;
                }
            } catch (error) {
                // console.log(error);
            }
            tempWidth = width2;
            tempHeight = height2;
            var svg = d3
                .select("#stacked-chart-status")
                .append("svg")
                .attr("width", width2 + margin2.left + margin2.right)
                .attr("height", height2 + margin2.top + margin2.bottom)
                .append("g")
                .attr(
                    "transform",
                    "translate(" + margin2.left + "," + margin2.top + ")"
                )
                .attr("shape-rendering", "geometricPrecision");
            // 

            var cases = data.geofences.map(function (d) {
                return {
                    x:
                        d.label.slice(0, 15) +
                        (d.label.length > 15 ? "..." : ""),
                    y: +d.consumed,
                    x1: d.label,
                };
            });
            // Transpose the data into layers
            //actual data
            var dataset = d3.layout.stack()(
                ["Available"].map(function (fruit) {
                    var groupData = data.geofences.slice(0, 40);
                    return groupData.map(function (d) {
                        return {
                            x:
                                d.label.slice(0, 15) +
                                (d.label.length > 15 ? "..." : ""),
                            y: +d.userStatusCount[fruit],
                            x1: fruit,
                        };
                    });
                    // }
                })
            );

            // Set x, y and colors
            var x = d3.scale
                .ordinal()
                .domain(
                    dataset[0].map(function (d) {
                        return d.x;
                    })
                )
                .rangeRoundBands([10, width2 - 50], 0.5);

            var countUsers = d3.max(dataset, function (d) {
                return d3.max(d, function (d) {
                    return d.y0 + d.y;
                });
            });

            if (countUsers >= 1) {
                var y = d3.scale
                    .linear()
                    .domain([
                        0,
                        d3.max(dataset, function (d) {
                            return d3.max(d, function (d) {
                                return d.y0 + d.y;
                            });
                        }),
                    ])
                    .range([height2, 0]);
            } else {
                var y = d3.scale
                    .linear()
                    .domain([
                        0,
                        d3.max(cases, function (d) {
                            return d.y;
                        }),
                    ])
                    .range([height2, 0]);
            }

            var colors = ["#99A98F", "#C1D0B5"];

            // Define and draw axes
            var yAxis = d3.svg
                .axis()
                .scale(y)
                .orient("left")
                .ticks(5)
                .tickSize(-width2, 0, 0)
                .tickFormat(function (d) {
                    if (d % 1 == 0) {
                        return d;
                    }
                });

            var xAxis = d3.svg.axis().scale(x).orient("bottom");
            // .tickFormat(d3.time.format("%y"));

            svg.append("g").attr("class", "y axis").call(yAxis);

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height2 + ")")
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .style("font-family", "sans-serif")
                .style("font-size", "11px")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-45)");

            // Create groups for each series, rects for each segment
            var groups = svg
                .selectAll("g.cost")
                .data(dataset)
                .enter()
                .append("g")
                .attr("class", "cost")
                .style("fill", function (d, i) {
                    return colors[i];
                });

            var rect = groups
                .selectAll("rect")
                .data(function (d) {
                    return d;
                })
                .enter()
                .append("rect")
                .attr("x", function (d) {
                    return x(d.x) + x.rangeBand() / 2;
                })
                .attr("y", function (d) {
                    return y(d.y0 + d.y);
                })
                .attr("height", function (d) {
                    return y(d.y0) - y(d.y0 + d.y);
                })
                .attr("width", x.rangeBand() / 2);

            var caseBar = svg
                .selectAll(".rollOut-bar")
                .data(cases)
                .enter()
                .append("g")
                .attr("class", "rollOut-bar")
                .append("rect")
                .attr("x", function (d) {
                    return x(d.x); // center it
                })
                .attr("width", x.rangeBand() / 2) // make it slimmer
                .attr("y", function (d) {
                    return y(d.y);
                })
                .attr("height", function (d) {
                    return height2 - y(d.y);
                });
            // Draw legend
            var svg1 = d3
                .select("#status")
                .append("svg")
                .attr("width", width2 + margin2.left + margin2.right)
                .attr("height", 15)
                .append("g")
                .attr(
                    "transform",
                    "translate(" + (margin2.right - 25) + "," + -220 + ")"
                )
                .attr("shape-rendering", "geometricPrecision");

            var legend = svg1
                .selectAll(".legend")
                .data(colors)
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("width", 15)
                .attr("height", 15)
                .attr("transform", function (d, i) {
                    if (i == 2) {
                        return "translate(" + (i * 80 - 18) + ",30)";
                    } else {
                        return "translate(" + (i * 80 - 18) + ",30)";
                    }
                })
                // .on("click", function(d) {

                // });
                .on("click", function (d, i) {
                    
                    if (i == 2) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#afafaf")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(5)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#afafaf")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(5)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#afafaf")
                                .style("opacity", 9);
                        }
                    }
                    if (i == 0) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#99A98F")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(3)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#99A98F")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(3)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#99A98F")
                                .style("opacity", 9);
                        }
                    }
                });
            // .attr("transform", function(d,i) {
            // 	return "translate(" + width2 + "," + i * height2 + ")"; })

            legend
                .append("circle")
                .attr("cx", width2 / 2 - 96)
                .attr("cy", height2 + 67)
                .attr("r", 6)
                .style("fill", function (d, i) {
                    return colors[i];
                });

            legend
                .append("text")
                .attr("x", width2 / 2 - 85)
                .attr("y", height2 + 67)
                .attr("dy", ".35em")
                .style("text-anchor", "start")
                .style("font-family", "sans-serif")
                .style("font-size", "12px")
                .style("font-weight", "700")
                .style("cursor", "pointer")
                .text(function (d, i) {
                    switch (i) {
                        case 0:
                            return "Available";
                        case 1:
                            return "Consumed";
                    }
                });

            var tooltip1 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            rect.on("mouseover", function (d) {
                tooltip1
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html(d.x1 + "<br>" + d.y);
                return tooltip1.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip1
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip1.style("visibility", "hidden");
                });

            caseBar
                .on("mouseover", function (d) {
                    tooltip1
                        .style("left", d3.event.pageX - 50 + "px")
                        .style("top", d3.event.pageY - 50 + "px")
                        .style("display", "inline-block")
                        .style("fill", "black")
                        .html("Consumed" + "<br>" + d.y);
                    return tooltip1.style("visibility", "visible");
                })
                .on("mousemove", function () {
                    return tooltip1
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip1.style("visibility", "hidden");
                });
        } */

        // Rollout Dashboard Code Starts From Here
        var routeMap = {};
        var iconSize = 20;
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var markersStore = [];
        var polylinesStore = [];

        $scope.type = {
            route: {
                search: "Search",
                nothingSelected: "Routes",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            },
        };
        $(".select2").select2();

        var centerGeofence = new google.maps.LatLng(
            $rootScope.DEFAULT_LATITUDE,
            $rootScope.DEFAULT_LONGITUDE
        );
        routeMap.map = new google.maps.Map(
            document.getElementById("workflow_map_canvas"),
            myOption(15, centerGeofence)
        );

        vm.height = 411

        vm.storeProjects = []
        callProjects(0, 20);
        function callProjects(page, size) {
            TrProject.query(
                {
                    page: page,
                    size: size,
                    sort: "last_modified_date,desc",
                },
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    if ((vm.links.next <= vm.links.last)) {
                        if (data.length) {
                            /* locations.map.setCenter({
                                lat: data[0].latitude,
                                lng: data[0].longitude,
                            }); */
                            data.forEach(function (each) {
                                if (each.latitude) {
                                    vm.storeProjects.push(each)
                                    addMarker(
                                        each.latitude,
                                        each.longitude,
                                        each
                                    );
                                }
                            });
                        }
                        callProjects(vm.links.next, 20);
                    }else{
                        if (data.length) {
                            /* locations.map.setCenter({
                                lat: data[0].latitude,
                                lng: data[0].longitude,
                            }); */
                            data.forEach(function (each) {
                                if (each.latitude) {
                                    vm.storeProjects.push(each)
                                    addMarker(
                                        each.latitude,
                                        each.longitude,
                                        each
                                    );
                                }
                            });
                        }
                    }
                },
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            );
        }

        // Assuming map is your Google Maps map object
        function addMarker(latitude, longitude, each) {
            var icon = each.ragStatus
                ? "content/images/ROLLOUT_" + each.ragStatus + ".png"
                : "content/images/marker-rollout-project.png";
            var marker = new google.maps.Marker({
                position: { lat: latitude, lng: longitude },
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(36, 36),
                    origin: new google.maps.Point(0, 0),
                    url: icon,
                    anchor: new google.maps.Point(24, 24),
                },
            });

            //bouncingMarker = marker;

            marker.addListener("click", function () {
                //$('#projectSearch').val(null).trigger('change.select2');
                // Handle marker click event here
               /*  if (bouncingMarker) {
                    bouncingMarker.setAnimation(null); // Stop the current bouncing marker
                }else{
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                } */

                if(entryMarker){
                    entryMarker.setMap(null)
                }
                
                var markerIndicator = new google.maps.Marker({
                    icon:  {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        strokeColor: "#FD7A24",
                        fillColor:'#FD7A24',
                        fillOpacity:1,
                        scale: 4,
                        anchor: new google.maps.Point(1, 1) // Set anchor point to the left
                    },
                    map: locations.map,
                    position: {"lat":latitude,
                    "lng":longitude},
                });
                entryMarker = markerIndicator
                markerIndicator.setMap(locations.map)
                
                callProjectDetail(each)
                vm.projectDetail = each.id
                vm.projectList = each

                $('#select2-projectSearch-container').text(each.name);

                //$scope.$apply()
            });

            if(vm.storeProjects[0].id == each.id)
            {   

                var markerIndicator = new google.maps.Marker({
                    icon:  {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        strokeColor: "#FD7A24",
                        fillColor:'#FD7A24',
                        fillOpacity:1,
                        scale: 4,
                        anchor: new google.maps.Point(1, 1) // Set anchor point to the left
                    },
                    map: locations.map,
                    position: {"lat":latitude,
                    "lng":longitude},
                });
                entryMarker = markerIndicator
                markerIndicator.setMap(locations.map)
                
                vm.projectDetail = each.id
                vm.onProjectChange()
                $('#select2-projectSearch-container').text(each.name);
            }
        }

        var coordinateList = [];
        vm.onRouteChange = function () {
            vm.objectTaskStatus = null
            var chainageList = [];
            vm.taskstatus = [];
            if (vm.routeId) {
                // To clear all markers
                markersStore.forEach(function (marker) {
                    marker.setMap(null);
                });
                markersStore = [];

                // To clear all polylines
                polylinesStore.forEach(function (polyline) {
                    polyline.setMap(null);
                });
                polylinesStore = [];
                clearPolyLines();
                coordinateList = [];
                RouteByID.get(vm.routeId, function (data) {
                    vm.trRoute = data;
                    if (
                        vm.trRoute &&
                        vm.trRoute.routePoints != null &&
                        vm.trRoute.routePoints.length
                    ) {
                        vm.trRoute.routePoints.forEach(function (each) {
                            if (each.routePointType == "COORDINATE") {
                                coordinateList.push({
                                    lat: each.latitude,
                                    lng: each.longitude,
                                });
                            } else if (each.routePointType == "CHAINAGE") {
                                chainageList.push({
                                    latitude: each.latitude,
                                    longitude: each.longitude,
                                    type: each.routePointType,
                                    number: each.routePointNumber,
                                });
                            }
                        });

                        if (coordinateList.length) {
                            routeMap.map.setCenter(coordinateList[0]);

                            /* var polyline = new google.maps.Polyline({
                                path: coordinateList,
                                geodesic: true,
                                strokeColor: "#FD7A24",
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                            });

                            // Set the map to display the polyline
                            polyline.setMap(routeMap.map);
                            polylinesStore.push(polyline); */
                        }

                        if (chainageList.length) {
                            routeMap.map.setCenter(chainageList[0]);
                            createMarkers(
                                chainageList,
                                "content/images/chainagePoints.png",
                                routeMap.map,
                                null
                            );
                        }
                    } else {
                        vm.isShowFilter = false;
                    }

                    TrRouteForMap.getTerrainPathByRoute(
                        vm.trRoute.id,
                        function (response) {
                            vm.terrainPathResponse = response;
                            vm.mapFilterList = [];
                            callTerrainPath();
                        },
                        function (err) {
                            callDrawRoute();
                            TrRouteForMap.getRoWPathByRoute(
                                vm.trRoute.id,
                                function (response) {
                                    vm.rowPathResponse = response;
                                    if (response.length) {
                                        vm.mapFilterList.push({
                                            name: "<strong>RoW</strong>",
                                            msGroup: true,
                                        });
                                        callRowPath();
                                    }
                                }
                            );
                        }
                    );
                });

                //Resource Types
                ResourceType.query(
                    {
                        page: 0,
                        size: 30,
                        sort: "update_date,desc",
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(resourceResponse, headers) {
                    /*  vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems; 
                    vm.resourceTypes = data;*/
                    if (resourceResponse.length > 0) {
                        vm.resTxSummary = resourceResponse;
                        var data = [];
                        vm.mapResourceTransaction = {};

                        resourceResponse.forEach(function (item) {
                            if (!vm.mapResourceTransaction[item.name]) {
                                vm.mapResourceTransaction[item.name] = {
                                    item: item.name,
                                    PLANNED: 0,
                                    REQUESTED: 0,
                                    RECEIVED: 0,
                                    UTILIZED: 0,
                                    RECONCILED: 0,
                                };
                                data.push(vm.mapResourceTransaction[item.name]);
                            }
                            vm.mapResourceTransaction[item.name][
                                item.transactionType
                            ] = 0;
                        });
                        d3.selectAll("#RESOURCES > *").remove();
                        //showDashboardData(data,'RESOURCES')
                        SummaryPost.resTx(
                            {
                                projectIds: [vm.projectDetail],
                                routeIds: [vm.routeId],
                            },
                            function (response) {
                                if (response.length) {
                                    vm.resTxSummary = response;
                                    response.forEach(function (item) {
                                        vm.mapResourceTransaction[
                                            item.resourceTypeName
                                        ][item.transactionType] +=
                                            item.totalNoOfResources;
                                    });
                                    showDashboardData(data, "RESOURCES");
                                } else {
                                    showDashboardData(data, "RESOURCES");
                                }
                            },
                            function (error) {
                                showDashboardData(data, "RESOURCES");
                            }
                        );
                    } else {
                        callTransaction("Resource");
                    }
                    //vm.page = pagingParams.page;
                }
                function onError(error) {
                    callTransaction("Resource");
                    AlertService.error(error.data.message);
                }


                //Material Types
                 MaterialType.query(
                    {
                        page: 0,
                        size: 30,
                        sort: "update_date,desc",
                    },
                    function onSuccess(materialResponse, headers) {
                    if (materialResponse.length > 0) {
                        vm.mtrlTxSummary = materialResponse;
                        var data = [];
                        vm.mapMaterialTransaction = {};
                        
                        //vm.quantity = []
                        materialResponse.forEach(function (item) {
                            /* if (item.trUnitOfMeasurement != null && item.trUnitOfMeasurement.name !=null && !vm.mapMaterialTransaction[item.trUnitOfMeasurement]  && !vm.mapMaterialTransaction[item.trUnitOfMeasurement.name]) {
                                vm.mapMaterialTransaction[item.trUnitOfMeasurement.name] = {};
                                //data.push(vm.mapMaterialTransaction[item.trUnitOfMeasurement.name]);
                            } */

                           /*  if (item.trUnitOfMeasurement != null && item.trUnitOfMeasurement.name !=null &&  !vm.mapMaterialTransaction[item.trUnitOfMeasurement]  && !vm.mapMaterialTransaction[item.trUnitOfMeasurement.name][item.name]) {
                                vm.mapMaterialTransaction[item.trUnitOfMeasurement.name][item.name] = {
                                    item: item.name,
                                    PLANNED: 0,
                                    REQUESTED: 0,
                                    RECEIVED: 0,
                                    UTILIZED: 0,
                                    RECONCILED: 0,
                                };
                                data.push(vm.mapMaterialTransaction[item.trUnitOfMeasurement.name][item.name]);
                            }

                            if(item.trUnitOfMeasurement != null && item.trUnitOfMeasurement.name !=null ){
                                vm.mapMaterialTransaction[item.trUnitOfMeasurement.name][item.name][
                                    item.transactionType
                                ] = 0;
                            } */

                            if (!vm.mapMaterialTransaction[item.name]) {
                                var tempUom = null;
                                if(item.trUnitOfMeasurement != null && item.trUnitOfMeasurement.name != null){
                                    tempUom = item.trUnitOfMeasurement.name
                                }
                                 
                                vm.mapMaterialTransaction[item.name] = {
                                    item: item.name,
                                    PLANNED: 0,
                                    REQUESTED: 0,
                                    RECEIVED: 0,
                                    UTILIZED: 0,
                                    RECONCILED: 0,
                                    UOM : tempUom
                                };
                                data.push(vm.mapMaterialTransaction[item.name]);
                            }

                                vm.mapMaterialTransaction[item.name][
                                    item.transactionType
                                ] = 0;
                            

                            //if(item.trUnitOfMeasurement != null && item.trUnitOfMeasurement.name != null){
                            //    vm.quantity.push(item.trUnitOfMeasurement.name)
                            //}
                            
                        });
                        //console.log(vm.quantity)
                        //d3.selectAll("#materialChart > *").remove();
                        //showDashboardData(data,'materialChart')
                        SummaryPost.mtrlTx(
                            {
                                projectIds: [vm.projectDetail],
                                routeIds: [vm.routeId],
                            },
                            function (response) {
                                if (response.length) {
                                    vm.mtrlTxSummary = response;
                                    response.forEach(function (item) {
                                        if(item.unitOfMeasurementName != null){
                                            vm.mapMaterialTransaction[
                                                item.materialTypeName
                                            ][item.transactionType] +=
                                                item.totalNoOfUnits;
                                        }
                                    });

                                   callUOM(data)
                                    //showDashboardData(data, "materialChart");
                                } else {
                                    callUOM(data)
                                }
                            },
                            function (error) {
                                callUOM(data)
                            }
                        );
                    } else {
                        callTransaction("Material");
                    }
                    //vm.page = pagingParams.page;
                },
                     function onError(error) {
                    callTransaction("Material");
                    AlertService.error(error.data.message);
                } 
                ); 
                
               
            }
        };

        function createMarkers(arr, img, map, str) {
            var marker;
            arr.forEach(function (each) {
                marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(marker, "click", function () {
                    //var name = (each.name == (undefined || null)) ? " " : each.name
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent(
                        '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " +
                            each.latitude +
                            "</b></br>" +
                            "<b>Longitude : " +
                            each.longitude +
                            "</b></br>" +
                            "<b>Type : " +
                            each.type +
                            "</b></br>" +
                            "<b>Number : " +
                            each.number +
                            "</b></br>" +
                            "</br></div>"
                    );
                    infowindow.open(map);
                    infowindow.setPosition({
                        lat: each.latitude,
                        lng: each.longitude,
                    });
                    openedInfoWindow = infowindow;
                });

                //arr.push(marker);
                markersStore.push(marker);
            });
        }

        // Create a legend with color and text
        function createLegend(color, text) {
            var legend = document.createElement("div");
            legend.innerHTML =
                '<span style="background-color: ' +
                color +
                '; width: 20px; height: 12px; display: inline-block; margin-right: 5px;"></span>' +
                text;
            return legend;
        }
        addLegends();
        // Add legends to the map
        function addLegends() {
            var legends = [
                { color: "#d2222d", text: "RED" },
                { color: "#ffbf00", text: "AMBER" },
                { color: "#238823", text: "GREEN" },
            ];

            var legendWrapper = document.createElement("div");
            legendWrapper.style.padding = "10px";
            legendWrapper.style.backgroundColor = "rgba(255, 255, 255, 0.7)";
            legendWrapper.style.border = "1px solid #ccc";
            legendWrapper.style.borderRadius = "5px";
            legendWrapper.style.position = "absolute";
            legendWrapper.style.top = "10px";
            legendWrapper.style.right = "10px";

            legends.forEach(function (legend) {
                var legendElement = createLegend(legend.color, legend.text);
                legendWrapper.appendChild(legendElement);
            });

            locations.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                legendWrapper
            );
        }

    
        
        function showDashboardData(data,id) {
            var margin, width, height, svg, x, y, xAxis, yAxis;
            // Your existing code for setting up the c
            margin = { top: 20, right: 30, bottom: 85, left: 40 };

            // Get the current width and height of the window
            width = window.innerWidth * 0.8 - margin.left - margin.right;
            height =
                window.innerHeight * 0.5 - margin.top - margin.bottom - 100;

            x = d3.scale
                .ordinal()
                .rangeRoundBands([0, width], 0.1)
                .domain(
                    data.map(function (d) {
                        return d.item;
                    })
                );

            y = d3.scale
                .linear()
                .range([height, 0])
                .domain([
                    0,
                    d3.max(data, function (d) {
                        return d3.max([
                            d.PLANNED,
                            d.REQUESTED,
                            d.RECEIVED,
                            d.UTILIZED,
                            d.RECONCILED,
                        ]);
                    }),
                ]);

            xAxis = d3.svg.axis().scale(x).orient("bottom");

            //yAxis = d3.svg.axis().scale(y).orient("left");

            yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")
    .ticks(5) // Set the number of ticks
    .tickFormat(d3.format("d")); // Use a format that removes decimals

    
            svg = d3
                .select("#"+ id)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr(
                    "transform",
                    "translate(" + margin.left + "," + margin.top + ")"
                );

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .style("font-family", "sans-serif")
                .style("font-size", "11px")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-35)");

            svg.append("g").attr("class", "y axis").call(yAxis);

             // Add text next to the y-axis
    svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2 - (margin.right + 40))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(id);

            var colors = [
                "#AF7AC5",
                "#7FB3D5",
                "#76D7C4",
                "#E59866",
                "#ffdcf1",
            ];

            var categories = [
                "PLANNED",
                "REQUESTED",
                "RECEIVED",
                "UTILIZED",
                "RECONCILED",
            ];

            var tooltip1 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            

            categories.forEach(function (category, i) {
                svg.selectAll(".bar-" + category)
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", "bar-" + category)
                    .attr("x", function (d) {
                        return (
                            x(d.item) + (x.rangeBand() / categories.length) * i
                        );
                    })
                    .attr("width", x.rangeBand() / categories.length)
                    .attr("y", function (d) {
                        return y(Math.max(0.1, d[category])); 
                    })
                    .attr("height", function (d) {
                        return Math.max(1, height - y(Math.max(0.1, d[category])));  
                    })
                    .style("fill", colors[i])

                    .on("mouseover", function (d) {

                        if(d[category] != 0){
                            tooltip1
                            .style("left", d3.event.pageX - 50 + "px")
                            .style("top", d3.event.pageY - 50 + "px")
                            .style("display", "inline-block")
                            .style("fill", "black")
                            .html( category + ' - ' + d[category]);
                        return tooltip1.style("visibility", "visible");
                        }
                        
                    })
                        .on("mousemove", function () {
                            return tooltip1
                                .style("top", d3.event.pageY - 10 + "px")
                                .style("left", d3.event.pageX + 10 + "px");
                        })
                        .on("mouseout", function () {
                            return tooltip1.style("visibility", "hidden");
                        });
            });

            
           /*  var legend = svg
                .selectAll(".legend")
                .data(categories)
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", function (d, i) {
                    return "translate(" + i * 80 + ", -20)";
                });

            legend
                .append("rect")
                .attr("x", 0)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill", function (d, i) {
                    return colors[i];
                });

            legend
                .append("text")
                .attr("x", 25)
                .attr("y", 9)
                .attr("dy", ".35em")
                .style("text-anchor", "start")
                .text(function (d) {
                    return d;
                }); */

                 /* var element = document.getElementById('getHeight');
                            vm.height = element.clientHeight;  */
        }

        $scope.names = ['PLANNED', 'REQUESTED', 'RECEIVED', 'UTILIZED', 'RECONCILED'];
        $scope.legends = [
            { name: 'PLANNED', color: '#AF7AC5' },
            { name: 'REQUESTED', color: '#7FB3D5' },
            { name: 'RECEIVED', color: '#76D7C4' },
            { name: 'UTILIZED', color: '#E59866' },
            { name: 'RECONCILED', color: '#ffdcf1' }
          ];

          vm.mapFilterList = [];
        var polylines = []
        var tempPath = []
       

          function callTerrainPath() {
            
            TrRouteForMap.getTerrainByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    polylines = []
                    vm.terrainListResponse = nameResponse
                    if (nameResponse.length) {
                        vm.isShowFilter = true
                        vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true,
                            type: "Route"

                        })
                        vm.mapFilterList.push({ name: '<strong>Terrain</strong>', msGroup: true })
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.terrainPathResponse.length) {
                                vm.terrainPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:#' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode,
                                                type: "Terrain"
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }
                            vm.mapFilterList.forEach(function(item){
                                var line = new google.maps.Polyline({
                                    path: item.path,
                                    visible: item.visible,
                                    strokeColor: '#' + item.colorCode,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 4,
                                    name: item.name
                                });

                                google.maps.event.addListener(
                                    line,
                                    "click",
                                    function (event) {
                                       if (openedInfoWindow != null) {
                                        openedInfoWindow.close();
                                    }
                                    infowindow.setContent(
                                        '<div class="infoWindowhead"></br></br>' +
                                            "<b>Name : " +
                                            item.name +
                                            "</b></br>" + "<b>Type : "+ item.type +"</b></br>" +
                                            "</div>"
                                    );
                                    infowindow.open(routeMap.map);
                                    infowindow.setPosition(event.latLng);
                                    openedInfoWindow = infowindow;
                                    }
                                );
                                line.setMap(routeMap.map);
                                polylines.push(line);
                            })
                        });
                        vm.mapFilterList.push({ msGroup: false })

                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                if (response.length) {
                                    vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                    callRowPath()
                                }

                            }
                        );
                    } else{

                        callDrawRoute()
                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                if (response.length) {
                                    vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                    callRowPath()
                                }
        
                            }
                        );
                    }
                },
                function (error) {
                    callDrawRoute()
                    TrRouteForMap.getRoWPathByRoute(
                        vm.trRoute.id,
                        function (response) {
                            vm.rowPathResponse = response
                            if (response.length) {
                                vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                callRowPath()
                            }
    
                        }
                    );
                }
            );
        }

        function callRowPath() {

            TrRouteForMap.getRoWByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    vm.rowListResponse = nameResponse
                    if (nameResponse.length) {
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.rowPathResponse.length) {
                                vm.rowPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:#' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode,
                                                type: "Row"
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            vm.mapFilterList.forEach(function(item){
                                var line = new google.maps.Polyline({
                                    path: item.path,
                                    visible: item.visible,
                                    strokeColor: '#' + item.colorCode,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 4,
                                    name: item.name
                                });

                                google.maps.event.addListener(
                                    line,
                                    "click",
                                    function (event) {
                                       if (openedInfoWindow != null) {
                                        openedInfoWindow.close();
                                    }
                                    infowindow.setContent(
                                        '<div class="infoWindowhead"></br></br>' +
                                            "<b>Name : " +
                                            item.name +
                                            "</b></br>" + "<b>Type :"+ item.type +"</b></br>" +
                                            "</div>"
                                    );
                                    infowindow.open(routeMap.map);
                                    infowindow.setPosition(event.latLng);
                                    openedInfoWindow = infowindow;
                                    }
                                );
                                line.setMap(routeMap.map);
                                polylines.push(line);
                            })
                        });
                        vm.mapFilterList.push({ msGroup: false })
                    }
                }
            );
        }
        $scope.togglePolyline = function () {
            if (vm.selectedTerrainName.length) {
                clearPolyLines()
                vm.selectedTerrainName.forEach(function (each) {
                    for (var i = 0; i < polylines.length; i++) {
                        if (polylines[i].name === each.name) {
                            if (each.visible) {
                                polylines[i].setMap(routeMap.map);
                            }
                        }
                    }
                })
            } else {
                clearPolyLines()
            }
        };


        function clearPolyLines() {
            for (var i = 0; i < polylines.length; i++) {

                polylines[i].setMap(null);

            }
        }

        $scope.type = {
            terrain: {
                search: "Search",
                nothingSelected: "Map Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
        };

        function randomColor() {
            return Math.floor(Math.random() * 16777215).toString(16);
        }

        vm.onWorkOrderChange = function(){
            vm.objectTaskStatus = null
            TaskStatusById.post(
                { routeIds: [vm.routeId],
                 workOrderIds:[vm.workOrderId]
                 },
                function (statusresponse) {
                    vm.taskstatus = statusresponse;

                    if(vm.taskstatus.length){
                        callTaskStatusByStagCat()
                    }  
                }
            );
        }

        vm.onProjectChange = function(){
            vm.projectList = vm.storeProjects.find(function(item) { return item.id == vm.projectDetail} );
            locations.map.setZoom(14);
            locations.map.setCenter({lat: vm.projectList.latitude, lng: vm.projectList.longitude});

            if(entryMarker){
                entryMarker.setMap(null)
            }
            
            var markerIndicator = new google.maps.Marker({
                icon:  {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor: "#FD7A24",
                    fillColor:'#FD7A24',
                    fillOpacity:1,
                    scale: 4,
                    anchor: new google.maps.Point(1, 1) // Set anchor point to the left
                },
                map: locations.map,
                position: {"lat":vm.projectList.latitude,
                "lng":vm.projectList.longitude},
            });
            entryMarker = markerIndicator
            markerIndicator.setMap(locations.map)

            callProjectDetail(vm.projectList)
         }

         function callProjectDetail(each){
            TrRouteListByProjectId.get(each.id, function (data) {
                vm.routeResponse = data;
                if (vm.routeResponse.length > 0) {
                    vm.routeId = vm.routeResponse[0].id;
                    vm.onRouteChange();
                    vm.workorderList = [];

                    TaskStatusById.getworkOrderByRoute(
                        vm.routeId,
                        function (response) {
                            vm.workorderList = response;

                            if(vm.workorderList.length > 0){
                                vm.workOrderId = vm.workorderList[0].id;

                                if(vm.workOrderId){
                                    vm.onWorkOrderChange()
                                }
                                
                            }else{
                                TaskStatusById.post(
                                    { routeIds: [vm.routeId] },
                                    function (statusresponse) {
                                        vm.taskstatus = statusresponse;
                                        if(vm.taskstatus.length){
                                            callTaskStatusByStagCat()
                                        } 
                                    }
                                );
                            }
                            
                        },function (err) {
                            vm.workorderList = [];
                            TaskStatusById.post(
                                { routeIds: [vm.routeId] },
                                function (statusresponse) {
                                    vm.taskstatus = statusresponse;
                                    if(vm.taskstatus.length){
                                        callTaskStatusByStagCat()
                                    } 
                                }
                            );
                        }
                    );
                    
                }else{
                    vm.isShowFilter = false
                    //d3.selectAll("#materialChart > *").remove(); 
                    if(vm.storeMaterialTransactionName.length){
                        vm.storeMaterialTransactionName.forEach(function(eachElement){
                            d3.selectAll("#"+ eachElement + " > *").remove();
                        })
                    }
                    d3.selectAll("#RESOURCES > *").remove();
                    vm.resTxSummary.length = 0

                    vm.mtrlTxSummary.length = 0
                    vm.workorderList = [];
                }
            });
            //$scope.$apply();
         }

         function callDrawRoute(){
            vm.mapFilterList.push({
                name:
                    '<strong>' + vm.trRoute.name + '</strong>',
                path: coordinateList,
                visible: true,
                strokeColor: '<img class="square" style="background-color:#' +
                    'FD7A24' +
                    ';">',
                colorCode: 'FD7A24',
                routeDisable: true
            })

            vm.mapFilterList.forEach(function(item){
                var line = new google.maps.Polyline({
                    path: item.path,
                    visible: item.visible,
                    strokeColor: '#' + item.colorCode,
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                    name: item.name
                });

                google.maps.event.addListener(
                    line,
                    "click",
                    function (event) {
                       if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent(
                        '<div class="infoWindowhead"></br></br>' +
                            "<b>Name : " +
                            item.name +
                            "</b></br>" +  "<b>Type : Route </b></br>" +
                            "</div>"
                    );
                    infowindow.open(routeMap.map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                    }
                );
                line.setMap(routeMap.map);
                polylines.push(line);
            })
         }

         function callTaskStatusByStagCat(){
            vm.objectTaskStatus = {}
            vm.taskstatus.forEach(function (eachElement){
                if(eachElement.taskCategory == "Route-Task"){
                    createObjMapping(eachElement)
                }else if(eachElement.taskCategory == "Section-Task"){
                    createObjMapping(eachElement)
                }
            })
         }

         function createObjMapping(eachElement){
            if (!vm.objectTaskStatus[eachElement.taskCategory]) {
                vm.objectTaskStatus[eachElement.taskCategory] = {};
            }

            if(!vm.objectTaskStatus[eachElement.taskCategory][eachElement.taskStage]){
                vm.objectTaskStatus[eachElement.taskCategory][eachElement.taskStage] = []
            }

            vm.objectTaskStatus[eachElement.taskCategory][eachElement.taskStage].push({taskTypeName : eachElement.taskTypeName, percentage : eachElement.percentage});  
         }

         function callTransaction(str){

            if(str == "Resource"){
                SummaryPost.resTx({
                    "projectIds": [vm.projectDetail],
                    "routeIds": [vm.routeId]
                }, function (response) {
                    if(response.length){ 
                        vm.resTxSummary = response
                        var data = [];
                        vm.mapResourceTransaction = {};
                        
                        response.forEach(function(item) 
                        {
                            if (!vm.mapResourceTransaction[item.resourceName]) {
                                vm.mapResourceTransaction[item.resourceName] = {
                                    item: item.resourceName,
                                    PLANNED: 0,
                                    REQUESTED: 0,
                                    RECEIVED: 0,
                                    UTILIZED: 0,
                                    RECONCILED: 0
                                };
                                data.push(vm.mapResourceTransaction[item.resourceName]);
                            }
                            vm.mapResourceTransaction[item.resourceName][item.transactionType] += item.totalNoOfResources;
                        });
    
                        d3.selectAll("#RESOURCES > *").remove(); 
                        showDashboardData(data,'RESOURCES')
                    }
                })
            }else if(str == "Material"){
                SummaryPost.mtrlTx(
                    {
                        projectIds: [vm.projectDetail],
                        routeIds: [vm.routeId],
                    },
                    function (response) {
                        vm.mtrlTxSummary = response;

                        if (response.length) {
                            var data = [];
                            var map = {};

                            response.forEach(function (item) {
                                if (!map[item.materialName]) {
                                    map[item.materialName] = {
                                        item: item.materialName,
                                        PLANNED: 0,
                                        REQUESTED: 0,
                                        RECEIVED: 0,
                                        UTILIZED: 0,
                                        RECONCILED: 0,
                                    };
                                    data.push(map[item.materialName]);
                                }
                                map[item.materialName][item.transactionType] +=
                                    item.totalNoOfUnits;
                            });

                            //console.log(data)
                            
                            //d3.selectAll("#materialChart > *").remove();
                            callUOM(data)
                            //showDashboardData(data, "materialChart1"); 
                        }
                    })
            }
                
            }

            function callUOM(data){
                vm.uomMaterialTransaction = {}
                data.forEach(function (item){
                    if(item.UOM != null){
                        if(!vm.uomMaterialTransaction[item.UOM]){
                            vm.uomMaterialTransaction[item.UOM] = []
                        }
                        vm.uomMaterialTransaction[item.UOM].push(item)
                    }
                })
                

                if(vm.uomMaterialTransaction){
                    if(vm.storeMaterialTransactionName.length){
                        vm.storeMaterialTransactionName.forEach(function(eachElement){
                            d3.selectAll("#"+ eachElement + " > *").remove();
                        })
                    }
                    var parentContainer = document.getElementById(
                        "parentMaterial"
                    );
                    var newDiv = document.createElement(
                        "div"
                    );
                    Object.keys(vm.uomMaterialTransaction).forEach(function(key){
                        vm.storeMaterialTransactionName.push(key)
                        newDiv.id = key
                        parentContainer.appendChild(newDiv);
                        showDashboardData(
                            vm.uomMaterialTransaction[key],
                            key
                        );
                    })
                }
            }
            
         
    }
})();
