(function () {
  'use strict';

  angular
    .module('trakeyeApp')
    .controller('TrCaseFiberCoreController', TrCaseFiberCoreController)

  TrCaseFiberCoreController.$inject = ['$timeout', '$scope','$rootScope'];

  function TrCaseFiberCoreController($timeout, $scope,$rootScope) {
    var vm = this;
    vm.tubeImagesOrder = tubeImagesOrder;
    vm.colors = ['blue','orange','green','brown','slate','white','red','black','yellow','violet','rose','aqua'];
    var fiberData = {
      madalTube: {
        tubeName: 'Duct#1 Cable',
        tubeModule: '8F * 6 core tubes',
        tubeType: '48F cable wire',
      },
      coreTube: [{
        tubeName: 'Duct#11 Cable',
        parentTube: 'Duct#1 Cable',
        tubeModule: '8F * 3 core tubes',
        tubeType: '24F cable wire'
      },
      {
        tubeName: 'Duct#12 Cable',
        parentTube: 'Duct#1 Cable',
        tubeModule: '8F * 3 core tubes',
        tubeType: '24F cable wire'
      }
      ],
      coreTubeChildA: 
        {
          tubeName: 'Duct#111 Cable',
          parentTube: 'Duct#11 Cable',
          tubeModule: '8F * 1 core tubes',
          tubeType: '8F cable wire'
        },
        // {
        //   tubeName: 'Duct#112 Cable',
        //   parentTube: 'Duct#11 Cable',
        //   tubeModule: '8F * 1 core tubes',
        //   tubeType: '8F cable wire'
        // },
        // {
        //   tubeName: 'Duct#113 Cable',
        //   parentTube: 'Duct#11 Cable',
        //   tubeModule: '8F * 1 core tubes',
        //   tubeType: '8F cable wire'
        // }
      coreTubeChildB: {
        tubeName: 'Duct#121 Cable',
        parentTube: 'Duct#2 Cable',
        tubeModule: '(8 + 4)F core tubes',
        tubeType: '12F cable wire'
      },
      coreTubeChildBZ: {
        tubeName: 'Duct#1211 Cable',
        parentTube: 'Duct#2 Cable',
        tubeModule: '6F core tubes',
        tubeType: '6F cable wire'
      },
      coreTubeChildAZ: {
        tubeName: 'Duct#1111 Cable',
        parentTube: 'Duct#3 Cable',
        tubeModule: '4F core tubes',
        tubeType: '4F cable wire'
      }

    }
    vm.selectedTube = fiberData;
    var modalTube = fiberData.madalTube;
    var coreTubeA = fiberData.coreTube[0];
    var coreTubeB = fiberData.coreTube[1];
    var coreTubeChildA = fiberData.coreTubeChildA;
    var coreTubeChildB = fiberData.coreTubeChildB;
    var coreTubeChildBZ = fiberData.coreTubeChildBZ;
    var coreTubeChildAZ  = fiberData.coreTubeChildAZ;
    $scope.showSelectColor = false;
    
    vm.coreSize = 0;
    
    vm.coreColor = function () { 
      $scope.showSelectColor = true
    }

    function selectedTubes(item) {
      if (item === '48F cable wire') {
        vm.cableId = modalTube.tubeName;
        vm.coreSize = 12;
        vm.tube01 = true;
        vm.tube02 = true;
        vm.tube03 = true;
        vm.tube04 = true;
      } else if (item === 'Duct#11 Cable') {
        vm.cableId = coreTubeA.tubeName
        vm.coreSize = 12;
        vm.tube01 = true;
        vm.tube02 = true;
        vm.tube03 = false;
        vm.tube04 = false;
      } else if (item === 'Duct#12 Cable') {
        vm.coreSize == 12;
        vm.cableId = coreTubeB.tubeName
        vm.tube01 = true;
        vm.tube02 = true;
        vm.tube03 = false;
        vm.tube04 = false;
      }
      else if (item === '12F cable wire') {
        vm.cableId = coreTubeChildA.tubeName
        vm.coreSize = 6;
        vm.tube01 = true;
        vm.tube02 = true;
        vm.tube03 = false;
        vm.tube04 = false;
      }
      else if (item === '8F cable wire') {
        vm.cableId = coreTubeChildB.tubeName
        vm.coreSize = 8;
        vm.tube01 = true;
        vm.tube02 = false;
        vm.tube03 = false;
        vm.tube04 = false;
      }
      else if (item === '6F cable wire'){
        vm.cableId = coreTubeChildBZ.tubeName
        vm.coreSize = 6;
        vm.tube01 = true;
        vm.tube02 = false;
        vm.tube03 = false;
        vm.tube04 = false;
      }else {
        vm.cableId = coreTubeChildAZ.tubeName
        vm.coreSize = 4;
        vm.tube01 = true;
        vm.tube02 = false;
        vm.tube03 = false;
        vm.tube04 = false;
      }
    }

    vm.closeTubeColors = function () {
      $scope.showSelectColor = false;
    }

    function tubeImagesOrder() {
      for (var i = 0; i < 5; i++) {
         tubeImages;
      }
    }

    var mapDiv = document.getElementById("map_canvas");
    var map1 = new google.maps.Map(mapDiv, {
      center: { lat: parseFloat($rootScope.DEFAULT_LATITUDE), lng: parseFloat($rootScope.DEFAULT_LONGITUDE) },
      zoom: 11,
    });
    //  <<<<<<< ========= ######## Duct#1 Cable   ######### ======= >>>>>>
    var flightPath = new google.maps.Polyline({
      strokeColor: "#0000ff",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flightPath.setMap(map1);
    var flightPlanCoordinates = [
      [23.862545, 90.487167],
      [23.851916, 90.468787],
      [23.831900, 90.448787],
      [23.756025, 90.390470]
    ];
    var path = flightPath.getPath();
    for (var i = 0; i < flightPlanCoordinates.length; i++) {
      //var loc = locations[i].split(',');
      var loc = flightPlanCoordinates[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      createInfoWindow(flightPath);
    }
    function createInfoWindow(flightPath) {
      google.maps.event.addListener(flightPath, 'mouseover', function (event) {
        infowindow.setContent(userContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        // selectedTubes(modalTube.tubeType);
      });
      google.maps.event.addListener(flightPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var userContent = document.createElement('div'),
        button;
      userContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + modalTube.tubeName + '</br>' +
        '<b>Core Module : </b> ' + modalTube.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + modalTube.tubeType + '</br>';
      button = userContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: userContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        // debugger;
        selectedTubes(modalTube.tubeType);
        $("#userPathModal").show();
        
      });

    }

    $scope.hideEditModal = function () {
      vm.cableId = " ";
      vm.coreType = " ";
      $scope.showSelectColor = false;
      $("#userPathModal").removeClass("in");
      $(".modal-backdrop").remove();
      $("#userPathModal").hide();
        vm.tube01 = false;
        vm.tube02 = false;
        vm.tube03 = false;
        vm.tube04 = false;
        
    }

    //  <<<<<<< ========= ######## Duct#2A Cable   ######### ======= >>>>>>
    var flyPath = new google.maps.Polyline({
      strokeColor: "#FFA500",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPath.setMap(map1);
    var PlanCoordinates = [
      [23.908254, 90.597167],
      [23.862545, 90.487167],
    ];
    var path = flyPath.getPath();
    for (var i = 0; i < PlanCoordinates.length; i++) {
      var loc = PlanCoordinates[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showInfoWindow(flyPath);
    }
   
    function showInfoWindow(flyPath){
      google.maps.event.addListener(flyPath, 'mouseover', function (event) {
        infowindow.setContent(user24FContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        selectedTubes(coreTubeB.tubeName);
      });
      google.maps.event.addListener(flyPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var user24FContent = document.createElement('div'),
        button;
        user24FContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + coreTubeA.tubeName + '</br>' +
        '<b>ParentName : </b> ' + coreTubeA.parentTube + '</br>' +
        '<b>Core Module : </b> ' + coreTubeA.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + coreTubeA.tubeType + '</br>';
      button = user24FContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: user24FContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        $("#userPathModal").show();
        selectedTubes(coreTubeA.tubeName);
      });

    }
    function showInfoBWindow(flyPath){
      google.maps.event.addListener(flyPath, 'mouseover', function (event) {
        infowindow.setContent(user24FContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        selectedTubes(coreTubeB.tubeName);
      });
      google.maps.event.addListener(flyPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var user24FContent = document.createElement('div'),
        button;
        user24FContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + coreTubeB.tubeName + '</br>' +
        '<b>ParentName : </b> ' + coreTubeB.parentTube + '</br>' +
        '<b>Core Module : </b> ' + coreTubeB.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + coreTubeB.tubeType + '</br>';
      button = user24FContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: user24FContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        selectedTubes(coreTubeB.tubeName);
        $("#userPathModal").show();
      });

    }

    function showDuck03InfoWindow(flyPath){
      google.maps.event.addListener(flyPath, 'mouseover', function (event) {
        infowindow.setContent(user24FContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        selectedTubes(coreTubeChildA.tubeType);
      });
      google.maps.event.addListener(flyPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var user24FContent = document.createElement('div'),
        button;
        user24FContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + coreTubeChildA.tubeName + '</br>' +
        '<b>ParentName : </b> ' + coreTubeChildA.parentTube + '</br>' +
        '<b>Core Module : </b> ' + coreTubeChildA.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + coreTubeChildA.tubeType + '</br>';
      button = user24FContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: user24FContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        selectedTubes(coreTubeChildA.tubeType);
        $("#userPathModal").show();
      });

    }

    function showDuck12fInfoWindow(flyPath){
      google.maps.event.addListener(flyPath, 'mouseover', function (event) {
        infowindow.setContent(user24FContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        selectedTubes(coreTubeChildB.tubeType);
      });
      google.maps.event.addListener(flyPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var user24FContent = document.createElement('div'),
        button;
        user24FContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + coreTubeChildB.tubeName + '</br>' +
        '<b>ParentName : </b> ' + coreTubeChildB.parentTube + '</br>' +
        '<b>Core Module : </b> ' + coreTubeChildB.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + coreTubeChildB.tubeType + '</br>';
      button = user24FContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: user24FContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        selectedTubes(coreTubeChildB.tubeType);
        $("#userPathModal").show();
      });

    }

    function showDuck6fInfoWindow(flyPath){
      google.maps.event.addListener(flyPath, 'mouseover', function (event) {
        infowindow.setContent(user24FContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        selectedTubes(coreTubeChildBZ.tubeType);
      });
      google.maps.event.addListener(flyPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var user24FContent = document.createElement('div'),
        button;
        user24FContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + coreTubeChildBZ.tubeName + '</br>' +
        '<b>ParentName : </b> ' + coreTubeChildBZ.parentTube + '</br>' +
        '<b>Core Module : </b> ' + coreTubeChildBZ.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + coreTubeChildBZ.tubeType + '</br>';
      button = user24FContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: user24FContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        selectedTubes(coreTubeChildBZ.tubeType);
        $("#userPathModal").show();
      });

    }

    function showDuck4fInfoWindow(flyPath){
      google.maps.event.addListener(flyPath, 'mouseover', function (event) {
        infowindow.setContent(user24FContent);
        // infowindow.position = event.latLng;
        infowindow.setPosition(event.latLng);
        infowindow.open(map1);
        selectedTubes(coreTubeChildAZ.tubeType);
      });
      google.maps.event.addListener(flyPath, 'mouseout', function (event) {
        infowindow.close();
      });
      var user24FContent = document.createElement('div'),
        button;
        user24FContent.innerHTML = '<div class="infoWindowhead">' +
        '<b>Name : </b> ' + coreTubeChildAZ.tubeName + '</br>' +
        '<b>ParentName : </b> ' + coreTubeChildAZ.parentTube + '</br>' +
        '<b>Core Module : </b> ' + coreTubeChildAZ.tubeModule + ' </br>' +
        '<b>Cable Type : </b> ' + coreTubeChildAZ.tubeType + '</br>';
      button = user24FContent.appendChild(document.createElement('input'));
      button.type = 'button';
      button.value = 'Show';
      button.className = "button";
      button.style.width = '300px';
      button.style.textAlign = 'right';
      var infowindow = new google.maps.InfoWindow({
        content: user24FContent
      });
      google.maps.event.addDomListener(button, 'click', function () {
        selectedTubes(coreTubeChildAZ.tubeType);
        $("#userPathModal").show();
      });

    }




  //  <<<<<<< ========= ######## Duct#2 Cable   ######### ======= >>>>>>
    var flyPath1 = new google.maps.Polyline({
      strokeColor: "#FFA500",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPath1.setMap(map1);
    var PlanCoordinates1 = [
      [23.938254, 90.487167],
      [23.862545, 90.487167],
    ];
    var path = flyPath1.getPath();
    for (var i = 0; i < PlanCoordinates1.length; i++) {
      var loc = PlanCoordinates1[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showInfoBWindow(flyPath1);
    }
    //  ######## =========  #########

    //  <<<<<<< ========= ######## Duct#3 8f Cable   ######### ======= >>>>>>
    var flyPathA = new google.maps.Polyline({
      strokeColor: "#006400",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPathA.setMap(map1);
    var PlanCoordinatesA = [
      [23.973854,90.639167],
      [23.943254,90.629967],
      [23.908254,90.597167]
    ];
    var path = flyPathA.getPath();
    for (var i = 0; i < PlanCoordinatesA.length; i++) {
      var loc = PlanCoordinatesA[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck03InfoWindow(flyPathA);
    }
    //  ######## =========  #########

    //  <<<<<<< ========= ######## Duct#3 8f Cable   ######### ======= >>>>>>
    var flyPathB = new google.maps.Polyline({
      strokeColor: "#006400",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPathB.setMap(map1);
    var PlanCoordinatesB = [
      [23.982525,90.672459],
      [23.952545,90.659999],
      [23.932254,90.639167],
      [23.908254,90.597167]
    ];
    var path = flyPathB.getPath();
    for (var i = 0; i < PlanCoordinatesB.length; i++) {
      var loc = PlanCoordinatesB[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck03InfoWindow(flyPathB);
    }
    // ##===== #### =======###
    
    //  <<<<<<< ========= ######## Duct#3 8f Cable   ######### ======= >>>>>>
    var flyPathC = new google.maps.Polyline({
      strokeColor: "#006400",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPathC.setMap(map1);
    var PlanCoordinatesC = [
      [23.856254,90.639888],
      [23.893254,90.619167],
      [23.908254,90.597167]
    ];
    var path = flyPathC.getPath();
    for (var i = 0; i < PlanCoordinatesC.length; i++) {
      var loc = PlanCoordinatesC[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck03InfoWindow(flyPathC);
    }

     //  <<<<<<< ========= ######## Duct#3 4f Cable   ######### ======= >>>>>>
     var flyPathC1 = new google.maps.Polyline({
      strokeColor: "#A52A2A",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPathC1.setMap(map1);
    var PlanCoordinatesC1 = [
      [23.826254,90.609888],
      [23.836254,90.619888],
      [23.846254,90.629888],
      [23.856254,90.639888]
    ];
    var path = flyPathC1.getPath();
    for (var i = 0; i < PlanCoordinatesC1.length; i++) {
      var loc = PlanCoordinatesC1[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck4fInfoWindow(flyPathC1);
    }

    //  <<<<<<< ========= ######## Duct#3 4f Cable   ######### ======= >>>>>>
    var flyPathC2 = new google.maps.Polyline({
      strokeColor: "#A52A2A",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPathC2.setMap(map1);
    var PlanCoordinatesC2 = [
      [23.886254,90.669888],
      [23.876254,90.659888],
      [23.866254,90.649888],
      [23.856254,90.639888]
    ];
    var path = flyPathC2.getPath();
    for (var i = 0; i < PlanCoordinatesC2.length; i++) {
      var loc = PlanCoordinatesC2[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck4fInfoWindow(flyPathC2);
    }


    //  <<<<<<< ========= ######## Duct#3 12f Cable   ######### ======= >>>>>>
    
     var flyPath2 = new google.maps.Polyline({
      strokeColor: "#006400",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPath2.setMap(map1);
    var PlanCoordinates2 = [
      [23.998254,90.537167],
      [23.988254,90.517167],
      [23.938254,90.487167]
    ];
    var path = flyPath2.getPath();
    for (var i = 0; i < PlanCoordinates2.length; i++) {
      var loc = PlanCoordinates2[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck12fInfoWindow(flyPath2);
    }
   
     //  <<<<<<< ========= ######## Duct#3 12f Cable   ######### ======= >>>>>>
     var flyPath3 = new google.maps.Polyline({
      strokeColor: "#006400",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPath3.setMap(map1);
    var PlanCoordinates3 = [
      [23.948254,90.557167],
      [23.938254,90.487167]
    ];
    var path = flyPath3.getPath();
    for (var i = 0; i < PlanCoordinates3.length; i++) {
      var loc = PlanCoordinates3[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck12fInfoWindow(flyPath3);
    }

    //  <<<<<<< ========= ######## Duct#3 6f Cable   ######### ======= >>>>>>
     var flyPath4 = new google.maps.Polyline({
      strokeColor: "#A52A2A",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPath4.setMap(map1);
    var PlanCoordinates4 = [
      [23.999254,90.588167],
      [23.969254,90.578167],
      [23.959254,90.568167],
      [23.948254,90.557167]
    ];
    var path = flyPath4.getPath();
    for (var i = 0; i < PlanCoordinates4.length; i++) {
      var loc = PlanCoordinates4[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck6fInfoWindow(flyPath4);
    }
 //  <<<<<<< ========= ######## Duct#3 6f Cable   ######### ======= >>>>>>
    var flyPath5 = new google.maps.Polyline({
      strokeColor: "#A52A2A",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    flyPath5.setMap(map1);
    var PlanCoordinates5 = [
      [23.899254,90.528167],
      [23.929254,90.538167],
      [23.939254,90.548167],
      [23.948254,90.557167]
    ];
    var path = flyPath5.getPath();
    for (var i = 0; i < PlanCoordinates5.length; i++) {
      var loc = PlanCoordinates5[i];
      path.push(new google.maps.LatLng(loc[0], loc[1]));
      showDuck6fInfoWindow(flyPath5);
    }
    

  }
})();




