(function () {
	'use strict';

	angular.module('trakeyeApp').controller('CasesDumpController', CasesDumpController)

	CasesDumpController.$inject = ['$scope', '$state', 'Report'];

	function CasesDumpController($scope, $state, Report) {
		var vm = this;
		vm.downloadType = "";
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.select2 = select2;
		var date = "dd EEE";
		vm.today = today();
		vm.downloadCaseDumpReport = downloadCaseDumpReport;
		vm.showMessage = false;
		vm.selecterange = selectRange;
		vm.select2();

		$('input[type=radio][name=type]').change(function() {
			vm.showMessage = false;
		});

		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		function select2() {
			$(".select2").select2();
		}



		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		function selectRange(range) {

			if (range == 'day') {
				var today = new Date();
				vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				vm.fromDate.setHours(0, 0, 0, 0);
				vm.fromTime = vm.fromDate.getTime();
				vm.toDate.setHours(23, 59, 59, 999);
				vm.toTime = vm.toDate.getTime();

			} else if (range == 'week') {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				// date.setHours(0, 0, 0, 0);
				date.setDate(date.getDate() - 7)
				vm.fromTime = date.getTime();

			} else if (range == 'month') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				// date.setHours(0, 0, 0, 0);
				date.setMonth(date.getMonth() - 1);
				vm.fromTime = date.getTime();
			}
		}

		function downloadCaseDumpReport() {
			if((vm.toTime-vm.fromDate) > 2592000000){
				alert("Date Range exceeded month limit");
			}else{
				vm.showMessage = true;
				if (vm.downloadType == 'opencases') {
					Report.getOpenCasesReportDownload({
						id: 0,
						ageType: vm.dateRange,
						fromTime: vm.fromTime,
						toTime: vm.toTime,
						reportType: 'open'
					}, function (response) {
						var a = document.createElement("a");
						a.style = "display: none";
						var file = new Blob([(response)], { type: 'application/csv' });
						var fileURL = URL.createObjectURL(file);
						a.href = fileURL;
						a.download = "open_cases_report.csv";
						a.click();
						window.URL.revokeObjectURL(fileURL);
					});
				} else if (vm.downloadType == 'closedcases') {
					Report.getClosedCasesReportDownload({
						id: 0,
						ageType: vm.dateRange,
						fromTime: vm.fromTime,
						toTime: vm.toTime,
						reportType: 'closed'
					}, function (response) {
						var a = document.createElement("a");
						a.style = "display: none";
						var file = new Blob([(response)], { type: 'application/csv' });
						var fileURL = URL.createObjectURL(file);
						a.href = fileURL;
						a.download = "closed_cases_report.csv";
						a.click();
						window.URL.revokeObjectURL(fileURL);
					});
				}
			}

		}
	}
})();
