(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('custom-route-type-attribute', {
            parent: 'entity',
            url: '/custom-route-type-attribute?page&sort&search',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ],
                pageTitle: 'trakeyeApp.customRouteTypeAttribute.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attributes.html',
                    controller: 'CustomRouteTypeAttributeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customRouteTypeAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('custom-route-type-attribute-detail', {
            parent: 'custom-route-type-attribute',
            url: '/custom-route-type-attribute/{id}',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ],
                pageTitle: 'trakeyeApp.customRouteTypeAttribute.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-detail.html',
                    controller: 'CustomRouteTypeAttributeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customRouteTypeAttribute');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CustomRouteTypeAttribute', function($stateParams, CustomRouteTypeAttribute) {
                    return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'custom-route-type-attribute',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        /*.state('custom-route-type-attribute-detail.edit', {
            parent: 'custom-route-type-attribute-detail',
            url: '/detail/edit',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CustomRouteTypeAttribute', function(CustomRouteTypeAttribute) {
                            return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })*/
        /*.state('custom-route-type-attribute.new', {
            parent: 'custom-route-type-attribute',
            url: '/new',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('custom-route-type-attribute', null, { reload: 'custom-route-type-attribute' });
                }, function() {
                    $state.go('custom-route-type-attribute');
                });
            }]
        })*/
        
        .state('custom-route-type-attribute.new', {
			parent : 'custom-route-type-attribute',
			url : '/new',
			data : {
				authorities : [ 'ENTITY_CONFIGURATION' ]
			},
			views : {
				'content@' : {
					templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
				}
			},
			resolve : {
				entity : function() {
					return {
						name : null,
						description : null,
						id : null
					};
				}
			}
		})
        /*.state('custom-route-type-attribute.edit', {
            parent: 'custom-route-type-attribute',
            url: '/{id}/edit',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CustomRouteTypeAttribute', function(CustomRouteTypeAttribute) {
                            return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('custom-route-type-attribute', null, { reload: 'custom-route-type-attribute' });
                }, function() {
                    $state.go('^');
                });
            }]
        })*/
		
		.state('custom-route-type-attribute.edit', {
			parent : 'custom-route-type-attribute',
			url : '/{id}/edit',
			data : {
				authorities : [ 'ENTITY_CONFIGURATION' ],
				pageTitle : 'trakeyeApp.customRouteTypeAttribute.home.title'
			},
			views : {
				'content@' : {
					templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('customRouteTypeAttribute');
					return $translate.refresh();
				} ],
				entity : [ '$stateParams', 'CustomRouteTypeAttribute', function($stateParams, CustomRouteTypeAttribute) {
					return CustomRouteTypeAttribute.get({
						id : $stateParams.id
					}).$promise;
				} ],
				previousState : [ "$state", function($state) {
					var currentStateData = {
						name : $state.current.name || 'custom-route-type-attribute',
						params : $state.params,
						url : $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				} ]
			}
		})
        .state('custom-route-type-attribute.delete', {
            parent: 'custom-route-type-attribute',
            url: '/{id}/delete',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-delete-dialog.html',
                    controller: 'CustomRouteTypeAttributeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CustomRouteTypeAttribute', function(CustomRouteTypeAttribute) {
                            return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('custom-route-type-attribute', null, { reload: 'custom-route-type-attribute' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();


/*(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('custom-route-type-attribute', {
            parent: 'entity',
            url: '/custom-route-type-attribute?page&sort&search',
            data: {
                authorities: [ 'ENTITY_CONFIGURATION' ],
                pageTitle: 'trakeyeApp.customRouteTypeAttribute.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attributes.html',
                    controller: 'CustomRouteTypeAttributeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customRouteTypeAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('custom-route-type-attribute-detail', {
            parent: 'custom-route-type-attribute',
            url: '/custom-route-type-attribute/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'trakeyeApp.customRouteTypeAttribute.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-detail.html',
                    controller: 'CustomRouteTypeAttributeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customRouteTypeAttribute');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CustomRouteTypeAttribute', function($stateParams, CustomRouteTypeAttribute) {
                    return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'custom-route-type-attribute',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('custom-route-type-attribute-detail.edit', {
            parent: 'custom-route-type-attribute-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CustomRouteTypeAttribute', function(CustomRouteTypeAttribute) {
                            return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('custom-route-type-attribute.new', {
            parent: 'custom-route-type-attribute',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('custom-route-type-attribute', null, { reload: 'custom-route-type-attribute' });
                }, function() {
                    $state.go('custom-route-type-attribute');
                });
            }]
        })
        .state('custom-route-type-attribute.edit', {
            parent: 'custom-route-type-attribute',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-dialog.html',
                    controller: 'CustomRouteTypeAttributeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CustomRouteTypeAttribute', function(CustomRouteTypeAttribute) {
                            return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('custom-route-type-attribute', null, { reload: 'custom-route-type-attribute' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('custom-route-type-attribute.delete', {
            parent: 'custom-route-type-attribute',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-route-type-attribute/custom-route-type-attribute-delete-dialog.html',
                    controller: 'CustomRouteTypeAttributeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CustomRouteTypeAttribute', function(CustomRouteTypeAttribute) {
                            return CustomRouteTypeAttribute.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('custom-route-type-attribute', null, { reload: 'custom-route-type-attribute' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();*/
