(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('group', {
                parent: 'entity',
                url: '/group?page&sort&search',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER'],
                    pageTitle: 'trakeyeApp.group.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group/groups.html',
                        controller: 'GroupController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'modified_date,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('group');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('group-detail', {
                parent: 'entity',
                url: '/group/{id}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER'],
                    pageTitle: 'trakeyeApp.group.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group/group-detail.html',
                        controller: 'GroupDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('group');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Group', function($stateParams, Group) {
                        return Group.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'group',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

        .state('group.new', {
                parent: 'group',
                url: '/create',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group/group-dialog.html',
                        controller: 'GroupDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function() {
                        return {
                            name: null,
                            description: null,
                            coordinates: null,
                            createdDate: null,
                            modifiedDate: null,
                            id: null
                        };
                    }
                }

            })
            .state('group.edit', {
                parent: 'entity',
                url: '/group/edit/{id}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/group/group-dialog.html',
                        controller: 'GroupDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('group');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Group', function($stateParams, Group) {
                        return Group.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'group',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })


        .state('group.delete', {
            parent: 'group',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/group/group-delete-dialog.html',
                    controller: 'GroupDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: ['Group', function(Group) {
                            return Group.get({ id: $stateParams.id }).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('group', null, { reload: 'group' });
                }, function() {
                    $state.go('^');
                });
            }]
        })

        .state('group.import', {
            parent: 'group',
            url: '/import',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/group/group-import.html',
                    controller: 'GroupImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function() {
                    return {
                        description: null,
                        createDate: null,
                        updateDate: null,
                        pinLat: null,
                        pinLong: null,
                        address: null,
                        escalated: null,
                        status: null,
                        id: null,
                        caseImages: []
                    };
                }
            }

        });
    }

})();