(function () {
    "use strict";
    angular.module("trakeyeApp").factory("TrVendor", TrVendor);

    TrVendor.$inject = ["$resource", "DateUtils"];

    function TrVendor($resource, DateUtils) {
        var resourceUrl = "api/tr-vendors/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                },
            }
        );
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("TrVendorById", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.get = function (id, callback) {
                $http.get("api/tr-vendors/" + id).then(function (response) {
                    callback(response.data);
                });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("TrVendorSearch", TrVendorSearch);

    TrVendorSearch.$inject = ["$resource"];

    function TrVendorSearch($resource) {
        var service = $resource(
            "/api/tr-vendors/searchvalue/:search",
            {},
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllVendorIds', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/tr-vendor-ids').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();