(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrMaterialDeleteController',TrMaterialDeleteController);

    TrMaterialDeleteController.$inject = ['$uibModalInstance', 'entity', 'TrMaterial','$scope'];

    function TrMaterialDeleteController($uibModalInstance, entity, TrMaterial,$scope) {
        var vm = this;

        vm.trMaterial = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	$scope.deletefail = true;
            TrMaterial.delete({id: id},
                function () {
            	
            	 $scope.deletefail = false;
                    $uibModalInstance.close(true);
                });
           
        }
    }
})();
