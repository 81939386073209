(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomTerrainTypeAttributeDetailController', CustomTerrainTypeAttributeDetailController);

    CustomTerrainTypeAttributeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomTerrainTypeAttribute'];

    function CustomTerrainTypeAttributeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomTerrainTypeAttribute) {
        var vm = this;

        vm.customTerrainTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:customTerrainTypeAttributeUpdate', function(event, result) {
            vm.customTerrainTypeAttribute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
