(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrRowDetailController', TrRowDetailController);

    TrRowDetailController.$inject = ['$scope', '$rootScope', 'entity', '$window', '$localStorage'];

    function TrRowDetailController($scope, $rootScope, entity, $window, $localStorage) {
        var vm = this;

        vm.trRow = entity;
        var unsubscribe = $rootScope.$on('trakeyeApp:trRowUpdate', function (event, result) {
            vm.trRow = result;
        });

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

    }
})();
