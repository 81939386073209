(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("SurveyReportController", SurveyReportController);

    SurveyReportController.$inject = ["UserMasterGeofences","MiniGeofencesByMasterID","Report","CustomAssetTypeAttribute"
    ];

    function SurveyReportController(UserMasterGeofences,MiniGeofencesByMasterID,Report,CustomAssetTypeAttribute
    ) {
        var vm = this;
        $(".select2").select2();
        load();
        vm.statuslabel = {
            search          : "Search",
            nothingSelected : "Select",
            selectAll       : "All",
            selectNone      : "None", 
            Done : "Done" 
        }
        vm.statusType = []
        vm.selectedstatusType = null
        var authoritiesList,activityTypeList,terrainTypeList,existingInfraList,mappedAttributes
        var storeAuthority = [], storeactivity = [], storeterrain = [], storeexisting = []

        function load() {
            vm.userMasterGeofences = [];
            UserMasterGeofences.getAll(function (response) {
                vm.userMasterGeofences = response;
                vm.selectedMasterGeofenceId = vm.userMasterGeofences[0].id.toString();
                vm.geofenceMini = [];
                MiniGeofencesByMasterID.get(
                    vm.selectedMasterGeofenceId,
                    function (response) {
                        response.forEach(function (value) {
                            vm.geofenceMini.push(value);
                        });
                    }
                );
            });

            CustomAssetTypeAttribute.query({
                page: 0,
                size: 100,
                sort: 'id,asc'
            }, onSuccess, onError);
            function onSuccess(data) {
                data.forEach(function(item){
                    if(item.name == "Authority"){
                        vm.statusType.push( {   value: '<strong>Authority</strong>' , msGroup: true })
                        item.customAssetTypeAttributeValues.forEach(function(attribute){
                            vm.statusType.push(attribute)
                            storeAuthority.push(attribute.id)
                        })
                        vm.statusType.push( {   msGroup: false })
                    }

                    if(item.name == "Terrain Type"){
                        vm.statusType.push( {   value: '<strong>Terrain Type</strong>' , msGroup: true })
                        item.customAssetTypeAttributeValues.forEach(function(attribute){
                            vm.statusType.push(attribute)
                            storeterrain.push(attribute.id)
                        })
                        vm.statusType.push( {   msGroup: false })
                    }

                    if(item.name == "Activity Type"){
                        vm.statusType.push( {   value: '<strong>Activity Type</strong>' , msGroup: true })
                        item.customAssetTypeAttributeValues.forEach(function(attribute){
                            vm.statusType.push(attribute)
                            storeactivity.push(attribute.id)
                        })
                        vm.statusType.push( {   msGroup: false })
                    }

                    if(item.name == "Existing Infra"){
                        vm.statusType.push( {   value: '<strong>Existing Infra</strong>' , msGroup: true })
                        item.customAssetTypeAttributeValues.forEach(function(attribute){
                            vm.statusType.push(attribute)
                            storeexisting.push(attribute.id)
                        })
                        vm.statusType.push( {   msGroup: false })
                    }
                })
            
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.onMasterChange = function () {
            vm.showMessageError = false;
            vm.showMessage = false;
            vm.selectedGeofenceId="";
            vm.geofenceMini = [];
            MiniGeofencesByMasterID.get(
                vm.selectedMasterGeofenceId,
                function (response) {
                    response.forEach(function (value) {
                        vm.geofenceMini.push(value);
                    });
                }
            );
        };

        vm.downloadSurveyReport = function (){
            Report.getSurveyReportDownload({
                id: vm.selectedGeofenceId,
                "attributeMap": mappedAttributes
            }, function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([(response)], { type: 'application/csv' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = "survey_report.csv";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
        }

        vm.savePriorityStatusFilter = function(){
            authoritiesList = [],activityTypeList = [],terrainTypeList = [],existingInfraList = []
            authoritiesList = vm.selectedstatusType.filter(function(item) {
                return storeAuthority.indexOf(item.id) !== -1;
            }).map(function(item) {
                return item.value;
            });

            activityTypeList = vm.selectedstatusType.filter(function(item) {
                return storeactivity.indexOf(item.id) !== -1;
            }).map(function(item) {
                return item.value;
            });

            terrainTypeList = vm.selectedstatusType.filter(function(item) {
                return storeterrain.indexOf(item.id) !== -1;
            }).map(function(item) {
                return item.value;
            });

            existingInfraList = vm.selectedstatusType.filter(function(item) {
                return storeexisting.indexOf(item.id) !== -1;
            }).map(function(item) {
                return item.value;
            });

            mappedAttributes = {}
        if(authoritiesList.length > 0){
            mappedAttributes.Authority = authoritiesList
        }
        if(activityTypeList.length > 0){
            mappedAttributes["Activity Type"] = activityTypeList
        }
        if(terrainTypeList.length > 0){
            mappedAttributes["Terrain Type"] = terrainTypeList
        }
        if(existingInfraList.length > 0){
            mappedAttributes["Existing Type"] = existingInfraList
        }
        }

        vm.surveyReport = function(){
            vm.surveyTotalLength = 0
            Report.getSurveyReportList({
                id: vm.selectedGeofenceId,
                "attributeMap": mappedAttributes
            },function (response){
                vm.surveyReportList = response
                if(vm.surveyReportList.length > 0){
                    vm.surveyReportList.forEach(function(item){
                        vm.surveyTotalLength += item.asset_length
                    })
                }
            }) 
        }
    }
})();
