(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomTaskTypeAttributeDeleteController',CustomTaskTypeAttributeDeleteController);

    CustomTaskTypeAttributeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomTaskTypeAttribute'];

    function CustomTaskTypeAttributeDeleteController($uibModalInstance, entity, CustomTaskTypeAttribute) {
        var vm = this;

        vm.customTaskTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomTaskTypeAttribute.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
