(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetCaseReportController', AssetCaseReportController)

	AssetCaseReportController.$inject = [ '$timeout', '$scope', 'Report', '$stateParams', '$q', '$filter', 'CaseType', 'AssetSearchForMap', 'AllCaseTypes' ];

	function AssetCaseReportController($timeout, $scope, Report, $stateParams, $q, $filter, CaseType, AssetSearchForMap, AllCaseTypes) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.today = today;
		vm.today();
		vm.assetList = "";
		var usersMap = {};
		vm.caseType = "";
		$scope.reporthide = false;
		vm.search='';
		vm.report;

		$(".select2").select2(); // Added search for select box
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
		}
		vm.caseTypes = loadPage();// CaseType.query();

		function loadPage() {
			AllCaseTypes.getAll(function(response) {
				vm.caseTypes = response;
			});
		}
		

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		$scope.assetReport = function() {
			
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
			$scope.reporthide = true;
			vm.search='';
				Report.getAssetCaseReport(vm.caseType, vm.fromTime, vm.toTime, function(response) {
					vm.reportRecords = response;
				});
		
			
		}
		
		$scope.assetReportSearch = function() {
			if (vm.search != null && vm.search != "" && !angular.isUndefined(vm.search)) {
				Report.getAssetCaseReportSearch(vm.caseType, vm.fromTime, vm.toTime,vm.search, function(response) {
					vm.reportRecords = response;
				});
			}else{
				Report.getAssetCaseReport(vm.caseType, vm.fromTime, vm.toTime, function(response) {
					vm.reportRecords = response;
				});
		}
			
		}

	}
})();
