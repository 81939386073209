(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('MaterialType', MaterialType);

    MaterialType.$inject = ['$resource', 'DateUtils'];

    function MaterialType ($resource, DateUtils) {
        var resourceUrl =  'api/material-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('MaterialTypeSearch', ['$rootScope','$http',function($rootScope,$http){

       var service ={};
	   service.getmaterialtypebyname = function (data,callback){
		$http.get('api/material-types-details/'+data).then(function(response){
    	    callback(response.data);
    	});
	};
	
	return service;
    }]);
})();
(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('MaterialTypeSearch', MaterialTypeSearch);

    MaterialTypeSearch.$inject = ['$resource'];

    function MaterialTypeSearch ($resource) {
        var service = $resource('/api/material-types/searchvalue/:userId',{userId:'@id'},{},{
            'query': {method: 'GET',isArray: true},
        });

        return service;
    }
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllCustomMaterialTypeAttribute', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/all-custom-material-type-attributes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('getUoM', ['$http', function($http) {

		var service = {};
		service.get = function(callback) {
			$http.get('api/rollout/tr-uom').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();