(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("BurriedChamberReportController", BurriedChamberReportController);

    BurriedChamberReportController.$inject = ["UserMasterGeofences","MiniGeofencesByMasterID","Report"
    ];

    function BurriedChamberReportController(UserMasterGeofences,MiniGeofencesByMasterID,Report
    ) {
        var vm = this;
        $(".select2").select2();
        load();
        vm.statuslabel = {
            search          : "Search",
            nothingSelected : "Select",
            selectAll       : "All",
            selectNone      : "None", 
            Done : "Done" 
        }

        vm.ids = [];
        vm.searchMini="";
        function load() {
            vm.userMasterGeofences = [];
            UserMasterGeofences.getAll(function (response) {
                vm.userMasterGeofences = response;
                vm.selectedMasterGeofenceId = vm.userMasterGeofences[0].id.toString();
                vm.geofenceMini = [];

                vm.ids = [];
                MiniGeofencesByMasterID.get(
                    vm.selectedMasterGeofenceId,
                    function (response) {
                        response.forEach(function (value) {
                            vm.geofenceMini.push(value);
                            vm.ids.push(value.id);
                        });
                    }
                );
            });
        }

        vm.onMasterChange = function () {
            vm.showMessageError = false;
            vm.showMessage = false;
            vm.selectedGeofenceId="";
            vm.geofenceMini = [];
            vm.ids = [];
            MiniGeofencesByMasterID.get(
                vm.selectedMasterGeofenceId,
                function (response) {
                    response.forEach(function (value) {
                        vm.geofenceMini.push(value);
                        vm.ids.push(value.id);
                    });
                }
            );
        };

        vm.onMiniChange = function () {
            vm.selectedAssetId = "";
            vm.ids = [];
            if(vm.selectedGeofenceId==""){
                vm.showMessageError = false;
                vm.showMessage = false;
                vm.selectedGeofenceId="";
                vm.ids = [];
                MiniGeofencesByMasterID.get(
                    vm.selectedMasterGeofenceId,
                    function (response) {
                        response.forEach(function (value) {
                            vm.geofenceMini.push(value);
                            vm.ids.push(value.id);
                        });
                    }
                );
            } else {
                vm.ids.push(vm.selectedGeofenceId);
                Report.getAssetNameByGeofences({
                    ids: vm.ids
                }, function (response) {
                   vm.assets = response;
                });
            }
            
        };

        vm.onAssetChange = function () {
            vm.burriedReportList = [];
        };

        vm.downloadBurriedReport = function (){

            Report.getBurriedReportDownload({
                ids: vm.ids,
                id:vm.selectedAssetId
            }, function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([(response)], { type: 'application/csv' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = "burried_report.csv";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
            
        }

        vm.surveyReport = function(){

            vm.surveyTotalLength = 0
            Report.getBurriedReportList({
                ids: vm.ids,
                id:vm.selectedAssetId
            },function (response){
                vm.burriedReportList = response;
            }) 
        }
    }
})();
