(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('GeofenceDialogController', GeofenceDialogController);

    GeofenceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'Geofence', 'UserIDs', 'AssetIDs', 'AssetByID', 'AssetsForMap', 'Principal','$rootScope'];

    function GeofenceDialogController($timeout, $scope,  $stateParams, $state, entity, Geofence, UserIDs, AssetIDs, AssetByID, AssetsForMap, Principal,$rootScope) {
        var vm = this;
        var newFence = false;
        vm.geofence = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.assets = [];
        vm.ownedBy = [];
        vm.assetList = [];
        vm.navigateCreateAssetGeo = navigateCreateAssetGeo;
        loadPage();
        var marker;

        if (vm.geofence.id != null) {
            // if (vm.geofence.zone == true) {
            //     vm.geofenceType = "MASTER";
            // } else {
            //     vm.geofenceType = "MINI";
            // }
            vm.geofenceType = vm.geofence.geofenceType;
        }

        function loadPage() {
            vm.multiuser = [];
            //vm.multiassets = [];

            var selctedUsers = [];
            var selctedAssets = [];

            if (vm.geofence.id != null) {
                vm.geofence.ownedBy.forEach(function (user) {
                    selctedUsers.push(user.id);
                })

                vm.geofence.assets.forEach(function (asset) {
                    selctedAssets.push(asset.id);
                })
            } else {
                Principal.identity().then(function (identity) {
                    selctedUsers.push(identity.id);
                });
            }

            UserIDs.getAdminAll(function (response) {
                response.forEach(function (user) {
                    if (selctedUsers.indexOf(user.id) !== -1) {
                        var userExits = false;
                        vm.multiuser.forEach(function (userVal) {
                            if (userVal.login == user.login) {
                                userExits = true;
                            }
                        });
                        if (!userExits) {
                            vm.multiuser.push({ login: user.login, id: user.id, ticked: true });
                        }
                    } else {
                        var userExits = false;
                        vm.multiuser.forEach(function (userVal) {
                            if (userVal.login == user.login) {
                                userExits = true;
                            }
                        });
                        if (!userExits) {
                            vm.multiuser.push({ login: user.login, id: user.id, ticked: false });
                        }
                    }
                });
            });

            /*AssetsForMap.assetsformap(function(response) {
                vm.assetList = response;
            });*/

            /*AssetIDs.getAll(function(response) {
                response.forEach(function(asset) {
                    if (selctedAssets.indexOf(asset.id) !== -1) {
                        vm.multiassets.push({ name: asset.name, id: asset.id, ticked: true });
                    } else {
                        vm.multiassets.push({ name: asset.name, id: asset.id, ticked: false });
                    }
                });
            });*/

        }


        /*$scope.modernBrowsers = [
            { icon: "<img src=[..]/opera.png.. />",               name: "Opera",              maker: "(Opera Software)",        ticked: true  },
            { icon: "<img src=[..]/internet_explorer.png.. />",   name: "Internet Explorer",  maker: "(Microsoft)",             ticked: false },
            { icon: "<img src=[..]/firefox-icon.png.. />",        name: "Firefox",            maker: "(Mozilla Foundation)",    ticked: true  },
            { icon: "<img src=[..]/safari_browser.png.. />",      name: "Safari",             maker: "(Apple)",                 ticked: false },
            { icon: "<img src=[..]/chrome.png.. />",              name: "Chrome",             maker: "(Google)",                ticked: true  }
        ]; 
        */

        function drawFixedAsset(fixed, map) {
            $.each(fixed.assetCoordinates, function (index, value1) {

                var infoWindow = new google.maps.InfoWindow();
                var startMarker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(value1.latitude, value1.longitude),
                    title: fixed.name,
                    icon: {
                        // size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(20, 20),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // fixed.assetType.image,
                        url: "data:image/png;base64," + vm.assetTypeMap[fixed.assetType.name],

                        anchor: new google.maps.Point(16, 16)
                    },
                });
                var infoWindow2 = new google.maps.InfoWindow();
                startMarker.content = '<div class="infoWindowContent"> <b>Description :</b> ' + fixed.description + '</div>';

                // on mouseout
                google.maps.event.addListener(startMarker, 'mouseout', function () {
                    infoWindow.close();
                });

                var activeInfoWindow;
                // add content to InfoWindow for click event
                infoWindow2.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);

                // add listener on InfoWindow for click event
                google.maps.event.addListener(startMarker, 'click', function () {

                    if (activeInfoWindow != null)
                        activeInfoWindow.close();
                    // Open InfoWindow - on click
                    infoWindow2.open(map, startMarker);
                    $(".userpath").click(function () {
                        var ele = document.getElementById("userPathModal");
                        $(ele).modal('show');
                        vm.drawUserPath($(this).attr('userid'));
                    });

                    // Store new open InfoWindow in global variable
                    activeInfoWindow = infoWindow2;
                });
                // function resizeMap(){
                setTimeout(function () {
                    google.maps.event.trigger(map, "resize");
                }, 100);
                // }
                // resizeAssetMap();

            });

        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    }
                ],
            });
        }
        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title","Locate Me");
			locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon)
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
							infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");	
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function navigateCreateAssetGeo() {
            sessionStorage.setItem("prevPage", "geofence.new");
            $state.go('asset.new');
        }

        vm.createNewFence = function () {
            newFence = true;
            vm.geofence.coordinates = [];
            vm.createfence();
        }
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            //$uibModalInstance.dismiss('cancel');
        }

        vm.clearCoordinates = function () {
            newFence = false;
            vm.geofence.coordinates = [];
        }

        function save() {
            vm.isSaving = true;
            if (vm.geofenceType == "MASTER") {
                vm.geofence.zone = true;
            } else {
                vm.geofence.zone = false;
            }

            vm.geofence.geofenceType = vm.geofenceType;

            if (vm.geofence.id !== null) {
                Geofence.update(vm.geofence, onSaveSuccess, onSaveError);
            } else {
                Geofence.save(vm.geofence, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('trakeyeApp:geofenceUpdate', result);
            // $uibModalInstance.close(result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == 'user-management.new') {
                $state.go('user-management.new');
            } else {
                $state.go('geofence');

            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;


        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        vm.createfence = function () {
            var parent = {};
            var coOrdinates = JSON.parse(entity.coordinates);
            /*for (var i = 0; i < vm.multiassets.length; i++) {
                if (vm.multiassets[i].ticked == true) {
                    parent = vm.multiassets[i];
                    AssetByID.get(parent.id, function(response) {
                        if (response.assetType.layout == 'FIXED') {
                            drawFixedAsset(response, map);
                        }
                        if (response.assetType.layout == 'SPREAD') {
                            drawPolyline(response, map);
                        }
                    });
                }
            }*/
            if (vm.geofence.id !== null) {
                var map = new google.maps.Map(document.getElementById('map_canvas'),
                myOption(12,{ lat: coOrdinates[0].lat, lng: coOrdinates[0].lng } ) /* {
                    // center: { lat: parseFloat($rootScope.DEFAULT_LATITUDE), lng: parseFloat($rootScope.DEFAULT_LONGITUDE) },
                    center: { lat: coOrdinates[0].lat, lng: coOrdinates[0].lng },
                    zoom: 12
                } */);
                geoLocation(map)
            } else {
                var map = new google.maps.Map(document.getElementById('map_canvas'),
                myOption(12,{ lat: parseFloat($rootScope.DEFAULT_LATITUDE), lng: parseFloat($rootScope.DEFAULT_LONGITUDE) }) /* {
                    center: { lat: parseFloat($rootScope.DEFAULT_LATITUDE), lng: parseFloat($rootScope.DEFAULT_LONGITUDE) },
                    // center: { lat: coOrdinates[0].lat, lng: coOrdinates[0].lng },
                    zoom: 12
                } */);
                geoLocation(map)
            }
            /*$.each(vm.assetList, function(index, value) {
                var assetCorRes = value.assetCoordinates;
                var assetCorVal = [];
                for (var i = 0; i < assetCorRes.length; i++) {
                    assetCorVal.push({ lat: assetCorRes[i].latitude, lng: assetCorRes[i].longitude });;
                }
                drawPolylineForGeoFence(assetCorVal, map, value);
            });*/

            var drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['polygon']
                },
                polygonOptions: {
                    fillColor: '#00000',
                    fillOpacity: 0.0,
                    strokeWeight: 5,
                    clickable: false,
                    editable: true,
                    zIndex: 1,
                    geodesic: true
                }
            })
            google.maps.event.addListener(drawingManager, 'polygoncomplete', function (event) {
                google.maps.event.addListener(event.getPath(), 'insert_at', function () {
                    setGoefenceCoordinate(event);
                });
                google.maps.event.addListener(event.getPath(), 'set_at', function (path) {
                    setGoefenceCoordinate(event);
                });

                drawingManager.setOptions({
                    drawingControl: false
                });
                drawingManager.setMap(null);
                setGoefenceCoordinate(event);

            });
            drawingManager.setMap(map);

            if (entity.id && entity.id != null && !newFence) {

                drawPolygon(function (polygon) {
                    polygon.setMap(map);
                    google.maps.event.addListener(polygon.getPath(), 'insert_at', function () {
                        setGoefenceCoordinate(polygon);
                    });
                    google.maps.event.addListener(polygon.getPath(), 'set_at', function (path) {
                        setGoefenceCoordinate(polygon);
                    });

                    drawingManager.setOptions({
                        drawingControl: false
                    });
                    drawingManager.setMap(null);
                })

            }

            function setGoefenceCoordinate(event) {
                var coOrdinates = [];
                var radius = event.getPath();
                for (var i = 0; i < radius.getLength(); i++) {
                    coOrdinates.push({ lat: radius.getAt(i).lat(), lng: radius.getAt(i).lng() });
                    if (i == radius.getLength() - 1) {
                        $scope.$apply(function () {
                            vm.geofence.coordinates = JSON.stringify(coOrdinates);
                        })
                    }
                }
            }

            function drawPolygon(callback) {
                callback(new google.maps.Polygon({
                    path: JSON.parse(entity.coordinates),

                    strokeColor: "#fd7a24",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#fd7a24",
                    fillOpacity: 0.3,
                    editable: true
                }));
            }

            if (entity.id && entity.id != null) {
                $.each(vm.geofence.assets, function (index, value) {

                    //Asset ID object contains light weight object , get detailsed object and plot details

                    AssetByID.get(value.id, function (response) {
                        //response.forEach(function(user) {
                        if (response.assetType.layout == 'FIXED') {
                            drawFixedAsset(response, map);
                        }
                        if (response.assetType.layout == 'SPREAD') {
                            drawPolyline(response, map);
                        }
                        //});
                    });



                });

            }

            function drawPolyline(spread, map) {

                var spreadAssetCoordinates = [];
                $.each(spread.assetCoordinates, function (index, value) {

                    spreadAssetCoordinates.push({
                        lat: value.latitude,
                        lng: value.longitude
                    });
                });
                var midLatLng = {
                    lat: spread.assetCoordinates[0].latitude,
                    lng: spread.assetCoordinates[0].longitude
                };
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' + spread.name + '<div class="infoWindowContent"> <b>Description :</b> ' + spread.description + '</div></div>'
                });
                infowindow.setPosition(midLatLng);
                // infowindow.open(locations.map);
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: spread.assetType.colorcode,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: spread.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: false
                });
                polylinepath.setMap(map);
                setTimeout(function () {

                    google.maps.event.trigger(map, "resize");
                    map.setCenter(midLatLng);
                }, 100);

            }


        };

    }

    function drawPolylineForGeoFence(latlngsToDraw, map, asset) {
        var spreadAssetCoordinates = [];
        $.each(latlngsToDraw, function (index, value) {

            spreadAssetCoordinates.push({
                lat: value.lat,
                lng: value.lng
            });
        });

        if (spreadAssetCoordinates.length > 0) {
            var midLatLng = {
                lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
            };
            var infowindow = new google.maps.InfoWindow({
                content: '<div class="infoWindowhead">' +
                    "name" +
                    '<div class="infoWindowContent"> <b>Description :</b> ' +
                    "des" + '</div></div>'
            });

            infowindow.setPosition(midLatLng);
            var polylineColor = '#000000';

            var spreadAttrValues = asset.assetTypeAttributeValues;
            spreadAttrValues.forEach(function (item, index) {
                if (item.name == "Fiber Status") {
                    if (item.attributeValue == "Planned") {
                        polylineColor = '#0000cc';
                    } else if (item.attributeValue == "On Air") {
                        polylineColor = '#00e658';
                    } else if (item.attributeValue == "Under Deployment") {
                        polylineColor = '#ff0000';
                    }
                }
            });

            var polylinepath = new google.maps.Polyline({
                path: spreadAssetCoordinates,
                geodesic: true,
                strokeColor: polylineColor,
                strokeWeight: 3
            });

            polylinepath.setOptions({
                strokeColor: polylineColor
            });

            google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                infowindow.close();
            });

            google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                infowindow.setContent('<div class="infoWindowhead">' +
                    '<b></b>' + asset.name + '</br></div>'
                );

                infowindow.mousemove;
                infowindow.open(map);
                infowindow.setPosition(event.latLng);
            });
            polylinepath.setMap(map);
        }
    }

})();