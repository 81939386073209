(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('RowTypeDetailController', RowTypeDetailController);

    RowTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RowType', 'User'];

    function RowTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, RowType, User) {
        var vm = this;

        vm.rowType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:rowTypeUpdate', function (event, result) {
            vm.rowType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
