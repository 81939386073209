(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AttributeMapping", AttributeMapping);

    AttributeMapping.$inject = [
        "$state",
        "AttributeMappingList",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "$scope",
        "myservice"
    ];

    function AttributeMapping(
        $state,
        AttributeMappingList,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        $scope,
        myservice
    ) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();
        $scope.passDeleteId = function(id){
            myservice.setValue(id);
        }

        function loadAll() {
            AttributeMappingList.query(
                {
                    page: pagingParams.page - 1,
                    size:  vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.attributeMappingList = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }
    }
})();
