(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrSectionDetailController', TrSectionDetailController);

    TrSectionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrSection', 'User', 'SectionType', 'TrSectionImages', 'TrSectionRecordings', 'AlertService', '$window', '$localStorage', 'getSectionImage', 'Configs', 'Inventory', 'SummaryPost', 'imageRoutePoint', 'TaskRouteById', 'TaskSectionById', 'TaskLogSectionReportData','Summary'];

    function TrSectionDetailController($scope, $rootScope, $stateParams, previousState, entity, TrSection, User, SectionType, TrSectionImages, TrSectionRecordings, AlertService, $window, $localStorage, getSectionImage, Configs, Inventory, SummaryPost, imageRoutePoint, TaskRouteById, TaskSectionById, TaskLogSectionReportData,Summary) {
        var vm = this;

        vm.trSection = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        var unsubscribe = $rootScope.$on('trakeyeApp:trSectionUpdate', function (event, result) {
            vm.trSection = result;
        });
        vm.showSectionAttachment = false
        vm.sectionResource = []
        vm.sectionMaterial = []
        var labelFrom, labelTo
        labelFrom = 'From Section'
        labelTo = 'To Section'
        vm.dailyLogsTxSummary = []
        vm.mtrlTxSummary = []
        vm.resTxSummary = []
        var routePath = {};
        vm.showTaskLogReport = showTaskLogReport;
        var sectionPath = {};
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;

        Configs.get("SectionImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showSectionAttachment = true
                }
            }
        })

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                img +
                '">'
            );
            imgWindow.document.close();
        };

        $scope.newTabDisplay = function (id) {
            var request = new XMLHttpRequest();
            request.open('GET', "api/trakeyesectionimages/" + vm.trSection.id + "/" + id, true);
            request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;

            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    window.open(imageUrl, "_blank")
                }
            }
            request.send();
        }

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrSectionImages.query(
                            {
                                sectionId: vm.trSection.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.sectionImages = data.filter(function (sectionImages) {
                                return sectionImages.image
                            });

                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getSectionImage.get(vm.trSection.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                case "resource":
                    vm.showResource = !vm.showResource;
                    break;

                case "material":
                    vm.showMaterial = !vm.showMaterial;
                    break;

                case "task":
                    vm.showTask = !vm.showTask;
                    break;

                default:
                    console.log("");
            }



			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/section_"+vm.trSection.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrSectionImages.query({
					sectionId : vm.trSection.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.sectionImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/section_"+vm.trSection.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/section_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };


        function decodeImage(img) {
            return window.atob(img);
        }


        /* //Get Tasks by Section Id
        getTask.bySectionId(entity.id,onSuccess,onError)

        function onSuccess(response){
            
        }

        function onError(error){
            AlertService.error(error.data.message);
        }  */


        Inventory.materialTxn(entity.id, function (response) {
            vm.sectionMaterial = response
        })

        Inventory.resourceTxn(entity.id, function (response) {
            vm.sectionResource = response
        })

        Inventory.taskBySection(entity.id, function (response) {
            vm.taskResponse = response
        })


        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };


        $scope.setTab = function (newTab) {
            if (newTab == 2) {
                //Comment Box
                vm.objectComments = {}
                vm.objectComments.entityId = entity.id
                vm.objectComments.entityName = "SECTION"
            }
            if (newTab == 3) {
                showTaskLogReport(vm.trSection.id);
            }
            $scope.tab = newTab;

        }


        var locations = {};
        var markers = [];
        var coordinateList = []
        var routeCoordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );
        if (vm.trSection.route != null) {
            TaskRouteById.get(vm.trSection.route.id, function (routeVal) {
                routePath = routeVal;
                if (routePath.COORDINATE != null) {
                    routePath.COORDINATE.forEach(function (coor) {
                        routeCoordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                    });
                }

                if (routeCoordinateList.length) {
                    locations.map.setCenter(routeCoordinateList[0]);
                    var strokeColor = "#0066CC";

                    strokeColor = "#8a0101";

                    var polyline = new google.maps.Polyline({
                        path: routeCoordinateList,
                        geodesic: true,
                        strokeColor: strokeColor,
                        strokeOpacity: 0.3,
                        strokeWeight: 3,
                        zIndex: 1
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                }
            },function(err){
                console.log(err)
            }); 
        }
        if (vm.trSection != null) {
            TaskSectionById.get(vm.trSection.id, function (sectionVal) {
                sectionPath = sectionVal;
                if (sectionPath.COORDINATE != null) {
                    sectionPath.COORDINATE.forEach(function (coor) {
                        coordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                    });
                }

                if (sectionPath.CHAINAGE != null) {
                    sectionPath.CHAINAGE.forEach(function (chainageVal) {
                        chainageList.push({ latitude: chainageVal.latitude, longitude: chainageVal.longitude, type: chainageVal.routePointType });
                    });
                }

                if (sectionPath.MARKER != null) {
                    sectionPath.MARKER.forEach(function (markerVal) {
                        markerList.push({ lat: markerVal.latitude, lng: markerVal.longitude });
                    });
                }

                if (coordinateList.length) {
                    locations.map.setCenter(coordinateList[0]);

                    var polyline = new google.maps.Polyline({
                        path: coordinateList,
                        geodesic: true,
                        strokeColor: '#FD7A24',
                        strokeOpacity: 1.0,
                        strokeWeight: 3,
                        zIndex: 2
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                }

                /* if(markerList.length){
                    locations.map.setCenter(markerList[0]);
                    createMarkers(markerList,'content/images/markersPoint.png',locations.map)
                } */

                if (chainageList.length) {

                    locations.map.setCenter(chainageList[0]);
                    createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
                }
            });
        }


        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

        function showTaskLogReport(sectionId) {
            vm.isReportGenerated = false;
            TaskLogSectionReportData.query(
                {
                    reportType: "daily",
                    sectionId: sectionId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
        .subheader {\
            margin: auto;\
        }\
        .thReport > tr > th{\
            text-align: center;\
            padding: 10px;\
        }\
        .tdReport > tr > td {\
            text-align: center; \
            padding: 10px; \
        }\
    ";
                document.head.appendChild(styleElement);

                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = data.map(function (item) { return item.name; }).filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.backgroundColor = 'orange';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = 'orange';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = nameData.map(function (item) { return item.type; }).filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    });
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = 'lightgreen';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = 'lightgreen';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = 'lightgray';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = 'lightgray';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};
                Object.keys(groupedData)
                    .sort(function (a, b) { return new Date(a) - new Date(b); })
                    .forEach(function (date, index) {
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.backgroundColor = 'orange';
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.backgroundColor = 'orange';
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = nameData.map(function (item) { return item.type; }).filter(function (value, index, self) {
                                return self.indexOf(value) === index;
                            });

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);
                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            })
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);

                        var remainingDuctingCell = createTableCell('td', 0);
                        tr.appendChild(remainingDuctingCell);

                        var remainingValidationCell = createTableCell('td', 0);
                        tr.appendChild(remainingValidationCell);
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting', 'Material Issued', 'Wastage', 'Stock at Site'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, nameData.map(function (item) { return item.type; }).filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    }));
                });
                var availableTypes = allAvailableTypes.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length + 2;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }

                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = 'lightblue';
                    columnNameCell.style.fontSize = "14px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);

                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    } else {
                        for (var i = 0; i < totalLength - 2; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });

                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        infowindow.setContent(
                            '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "</br></div>"
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;
                    }))

                arr.push(marker);
            })
        }


        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
            {
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#EFEFEF",
                    },
                ],
            }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }


        if ((vm.trSection.fromRoutePoint != null && vm.trSection.toRoutePoint != null)) {
            addMarker({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude })
            addMarker({ lat: vm.trSection.toRoutePoint.latitude, lng: vm.trSection.toRoutePoint.longitude })
        }

        if(vm.trSection && vm.trSection.route.assets.length){
            vm.trSection.route.assets.forEach(function (element){
                        if(element.assetCoordinates.length){
                            locations.map.setCenter({lat:element.assetCoordinates[0].latitude, lng:
                                element.assetCoordinates[0].longitude} );

                            if(element.assetType.layout == "SPREAD"){
                                vm.tempPath = []
                            element.assetCoordinates.sort(function(a, b) { return a.sortOrder - b.sortOrder});
                            element.assetCoordinates.forEach(function (item){
                                vm.tempPath.push({lat:item.latitude,lng:item.longitude})
                            })
                            var polyline = new google.maps.Polyline({
                                path: vm.tempPath,
                                geodesic: true,
                                strokeColor: element.assetType.colorcode,
                                strokeOpacity: 0.6,
                                strokeWeight: 3,
                            });
                            addClick(polyline);
                            // Set the map to display the polyline
                            polyline.setMap(locations.map);
                            }

                            if(element.assetType.layout == "FIXED"){
                                element.assetCoordinates.forEach(function (e) {
                                    var marker = new google.maps.Marker({
                                    position:  {lat:e.latitude,lng:e.longitude},
                                    map: locations.map,
                                    icon: {
                                        scaledSize: new google.maps.Size(iconSize, iconSize),
                                        url: element.assetType.imagePath,
                                        anchor: new google.maps.Point(
                                            iconSize / 2,
                                            iconSize / 2
                                        ),
                                        labelOrigin: new google.maps.Point(25, 22),
                                    },
                                });
                                google.maps.event.addListener(
                                    marker,
                                    "click",
                                    (function () {
                                        //var name = (each.name == (undefined || null)) ? " " : each.name
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        var userContent = document.createElement("div")
                
                                        userContent.innerHTML = '<div class="infoWindowhead"></br></br>' +
                                        /* "<b>Name : "+name+"</b></br>"+ */
                                        "<b>Latitude : "+e.latitude+"</b></br>"+
                                        "<b>Longitude : "+e.longitude+"</b></br>"+
                                        "</br></div>"
                                        infowindow.setContent(
                                            userContent
                                        );
                
                                        infowindow.open(locations.map);
                                        infowindow.setPosition({
                                            lat: e.latitude,
                                            lng: e.longitude,
                                        });
                                        openedInfoWindow = infowindow;
                
                                        if (markers.length < 2) {
                                            btnAddSection = userContent.appendChild(
                                                document.createElement("input")
                                            );
                                            btnAddSection.type = "button";
                
                                            btnAddSection.value = "Add Task Point";
                                            
                                            btnAddSection.className = "button";
                
                                            btnAddSection.addEventListener("click", function () {
                                                if (markers.length < 2) {
                                                    // Add a marker to the map
                                                    addMarker({
                                                        lat: e.latitude,
                                                        lng: e.longitude,
                                                    });
                                    
                                                    // Get the latitude and longitude of the clicked point
                                                    vm.pinLat = e.latitude;
                                                    vm.pinLong = e.longitude;
                                                    openedInfoWindow.close();
                                                } else {
                                                    alert("From and To Task Exists");
                                                }
                                               })
                                         }
                                     }))
                               
                
                                })
                              }
                            }
                            
                        })
               
        }

        function addClick(item) {
            // Add a click event listener to the map
            item.addListener("click", function (event) {
                if (markers.length < 2) {
                    // Add a marker to the map
                    addMarker(event.latLng);

                    // Get the latitude and longitude of the clicked point
                    vm.pinLat = event.latLng.lat();
                    vm.pinLong = event.latLng.lng();
                } else {
                    console.log("Maximum 2 markers allowed.");
                }
            });
        }


        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                }
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

       /*  Summary.resTx(entity.id, function (response) {
            vm.resTxSummary = response
        })

        Summary.mtrlTx(entity.id, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.dailyLogsTx({
            "projectIds": [vm.trSection.projectId],
            "routeIds": [vm.trSection.routeId],
            "sectionIds": [vm.trSection.id]
        }, function (response) {
            vm.dailyLogsTxSummary = response
        })

         */
        
        Summary.dailyLogsTx(entity.id, function (response) {
            vm.dailyLogsTxSummary = response
        })

        SummaryPost.mtrlTx({
            "projectIds": [vm.trSection.projectId],
            "routeIds": [vm.trSection.routeId],
            "sectionIds": [vm.trSection.id]
        }, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.resTx({
            "projectIds": [vm.trSection.projectId],
            "routeIds": [vm.trSection.routeId],
            "sectionIds": [vm.trSection.id]
        }, function (response) {
            vm.resTxSummary = response
        })

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = vm.trSection.routeId
        vm.objectRouteImg.map = locations.map

        vm.autoGenerateTask = function () {
            TaskSectionById.postAutoGenerateTasks(entity, function () {
                Inventory.taskBySection(entity.id, function (response) {
                    vm.taskResponse = response
                })
            })
        }

         if(vm.trSection.assets.length){
            var polylineSection,spreadPoly,polylineTimer;

            if(vm.trSection.assets[0].assetCoordinates.length){
                locations.map.setCenter({lat:vm.trSection.assets[0].assetCoordinates[0].latitude,lng:
                    vm.trSection.assets[0].assetCoordinates[0].longitude
                })
            }
            
            vm.trSection.assets.forEach(function (each){
                var tempCoordinates = []
                var distance = each.assetLength ? "<br><b> Distance </b><br>" + (each.assetLength).toFixed(2) + " km" : "";

                var blockPolyLine = each.block ? "<br><b> Block </b><br>" + each.block  : "";
                if(each.assetCoordinates.length){
                    each.assetCoordinates.sort(function(a, b) { return a.sortOrder - b.sortOrder});

                    each.assetCoordinates.forEach(function (item){
                        tempCoordinates.push({lat:item.latitude,lng:item.longitude})
                    })
                }
                polylineSection = new google.maps.Polyline({
                    path: tempCoordinates,
                    geodesic: true,
                    strokeColor: each.assetColor,
                    strokeOpacity: 0.4,
                    strokeWeight: 6,
                });

                google.maps.event.addListener(
                    polylineSection,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                            if (spreadPoly != null) {
                                spreadPoly.setMap(null);
                            }
                        }
    
                        clearTimeout(polylineTimer);
    
                        spreadPoly = new google.maps.Polyline({
                            path: tempCoordinates,
                            strokeColor: "#A938F3",
                            strokeOpacity: 0.6,
                            strokeWeight: 19,
                        });
    
                        spreadPoly.setMap(locations.map);
    
                        polylineTimer = setTimeout(function () {
                            if (spreadPoly != null) {
                                spreadPoly.setMap(null);
                                spreadPoly = null;
                            }
                        }, 5000);
    
                        var userContent = document.createElement(
                            "div"
                        ),
                            btnEditAsset,
                            brBreak, btnDeleteAsset,btnCreateSection,btnCommonPath;
    
                        userContent.innerHTML =
                            '<div class="infoWindowhead">' +
                            "<b> Name </b><br>" +
                            each.name +  distance + blockPolyLine
                            /* "<br><b>Latitude : </b>" + event.latLng.lat() +
                            "<br><b>Longitude : </b>" + event.latLng.lng() + */
                            "</br></div>";
    
                        infowindow.setContent(userContent);
    
                        infowindow.open(locations.map);
                        infowindow.setPosition(event.latLng);
                        openedInfoWindow = infowindow;
                    }
                );

                polylineSection.setMap(locations.map);
            })
         }  

    }
})();