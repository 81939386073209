(function () {
    'use strict';

    angular.module('trakeyeApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('asset', {
            parent: 'app',
            url: '/asset?page&sort&search',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_ROLLOUT_ADMIN'],
                pageTitle: 'trakeyeApp.asset.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/asset/assets.html',
                    controller: 'AssetController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name',
                    squash: true
                },
                search: null,
                pagesize: 10,
                listgeofenceid: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        pagesize: $stateParams.pagesize,
                        listgeofenceid: $stateParams.listgeofenceid
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('asset');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('asset-detail', {
            parent: 'asset',
            url: '/asset/{id}',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                pageTitle: 'trakeyeApp.asset.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/asset/asset-detail.html',
                    controller: 'AssetDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('asset');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Asset', function ($stateParams, Asset) {
                    return Asset.get({
                        id: $stateParams.id
                    }).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'asset',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('asset-type-site-report', {
            parent: 'asset',
            url: '/asset-type-site-report',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER'],
                pageTitle: 'trakeyeApp.report.asset-type-site-report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/asset/asset-type-site-report.html',
                    controller: 'AssetTypeSiteReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('asset');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Asset', function ($stateParams, Asset) {
                    return Asset.get({
                        id: $stateParams.id
                    }).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'asset',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('asset-route', {
            parent: 'app',
            url: '/asset-route',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_ROLLOUT_ADMIN'],
                pageTitle: 'trakeyeApp.asset.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/asset/asset-route.html',
                    controller: 'AssetRouteController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

            .state('asset-detail.edit', {
                parent: 'asset-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                    pageTitle: 'trakeyeApp.asset.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/asset/asset-dialog.html',
                        controller: 'AssetDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('asset');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Asset', function ($stateParams, Asset) {
                        return Asset.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'asset',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('asset.new', {
                parent: 'asset',
                url: '/create',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/asset/asset-dialog.html',
                        controller: 'AssetDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            name: null,
                            description: null,
                            createDate: null,
                            updateDate: null,
                            userId: null,
                            assetType: null,
                            assetTypeAttributeValues: [],
                            assetCoordinates: [],
                            assetImport: false
                        };
                    }
                }

            })

            .state('asset.import', {
                parent: 'asset',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/asset/asset-import.html',
                        controller: 'AssetImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            name: null,
                            description: null,
                            createDate: null,
                            updateDate: null,
                            userId: null,
                            assetType: null,
                            assetTypeAttributeValues: [],
                            assetCoordinates: [],
                            assetImport: true
                        };
                    }
                }

            })
            /* .state('asset.import', {
                parent: 'asset',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/import/entity-import-dialog.html',
                        controller: 'ImportDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: ["$state", function ($state) {
                        return $state.current.name || 'asset';
                    }]
                }
            }) */

            .state('asset.add', {
                parent: 'asset',
                url: '/add',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/asset/asset-addition.html',
                        controller: 'AssetAdditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            name: null,
                            description: null,
                            createDate: null,
                            updateDate: null,
                            userId: null,
                            assetType: null,
                            assetTypeAttributeValues: [],
                            assetCoordinates: []
                        };
                    }
                }

            })

            .state('asset.connect', {
                parent: 'asset',
                url: '/connect',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_USER', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/asset/asset-connect.html',
                        controller: 'AssetConnectController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            name: null,
                            description: null,
                            createDate: null,
                            updateDate: null,
                            userId: null,
                            assetType: null,
                            assetTypeAttributeValues: [],
                            assetCoordinates: []
                        };
                    }
                }

            })

            .state('asset.edit', {
                parent: 'asset',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                    pageTitle: 'trakeyeApp.asset.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/asset/asset-dialog.html',
                        controller: 'AssetDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('asset');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Asset', function ($stateParams, Asset) {
                        return Asset.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'asset',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('asset.delete', {
                parent: 'asset',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/asset/asset-delete-dialog.html',
                        controller: 'AssetDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Asset', function (Asset) {
                                return Asset.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        setTimeout(function () {
                            $state.go('asset', null, {
                                reload: 'asset'
                            });
                        }, 1000);
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();