(function () {
    "use strict";

    angular.module("trakeyeApp").controller("AssetHistory", AssetHistory);

    AssetHistory.$inject = [
        "History",
        "$state",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "$scope",
        "HistorySearch"
    ];

    function AssetHistory(
        History,
        $state,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        $scope,
        HistorySearch
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.goToPage = goToPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterAssetHistorySearch = filterAssetHistorySearch;

        $("#pagenum").val(pagingParams.page + "");

        loadAll();

        $scope.orderProperty = "assetName";

        $scope.setOrderProperty = function (propertyName) {
            if ($scope.orderProperty === propertyName) {
                $scope.orderProperty = "-" + propertyName;
            } else if ($scope.orderProperty === "-" + propertyName) {
                $scope.orderProperty = propertyName;
            } else {
                $scope.orderProperty = propertyName;
            }
        };

        function loadAll() {
            History.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                },
                onSuccess,
                onError
            );

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;

                vm.page = pagingParams.page;
                getData(data);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function padTo2Digits(num) {
            return num.toString().padStart(2, "0");
        }

        function formatDate(date) {
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join("/");
        }

        function getData(data) {
            vm.assetHistory = [];
            data.forEach(function (asset) {
                var cdate = formatDate(new Date(asset.createdDate));
                var ldate = formatDate(new Date(asset.lastModifiedDate));

                vm.assetHistory.push({
                    assetId: asset.assetId,
                    assetName: asset.assetName,
                    assetTypeName: asset.assetTypeName,
                    createdBy: asset.createdBy,
                    cdate: cdate,
                    lastModifiedBy: asset.lastModifiedBy,
                    ldate: ldate,
                    modeName: asset.modeName,
                    newValue: asset.newValue,
                    oldValue: asset.oldValue,
                    propertyName: asset.propertyName,
                });
            });
        }

        function goToPage() {
            vm.page = parseInt(document.getElementById("pagenum").value);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                pagesize: vm.itemsPerPage,
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function filterAssetHistorySearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                HistorySearch.query(
                    {
                        searchtext: $scope.search,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage
                    },
                    onSuccess,
                    onError
                );

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
    
                    vm.page = pagingParams.page;
                    getData(data);
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }
    }
})();