(function () {
	'use strict';

	angular.module('trakeyeApp').controller('UserAttendaceController', UserAttendaceController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	UserAttendaceController.$inject = ['$scope', '$sce', '$state', "Report"];

	function UserAttendaceController($scope, $sce, $state, Report) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.downloadAttendenceReport = downloadAttendenceReport;
		vm.report = report;
		vm.reporthide = false;
		vm.select2 = select2;
		var date = "dd EEE";
		vm.today = today();
		vm.userType = "gpsUser";
		vm.selecterange = selectRange;

		vm.select2();

		vm.users = loadPage();

		function loadPage() {
			Report.getUsers('', function (response) {
				vm.users = response;
			})
			vm.reportType = 'gpsUser';
		}

		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		function downloadAttendenceReport() {
			if (vm.userAgent == 'all') {
				vm.id = 0;
			}
			Report.getAttendenceReportDownload({
				id: vm.id,
				ageType: vm.dateRange,
				fromTime: vm.fromTime,
				toTime: vm.toTime,
				reportType: vm.userType
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/csv' });
				var fileURL = URL.createObjectURL(file);
				// window.open(fileURL, "_blank");
				a.href = fileURL;
				a.download = "user_report.csv";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

		function report() {
			if (vm.userAgent == 'all') {
				vm.id = 0;
			}
			Report.getAttendenceReport({
				id: vm.id,
				ageType: vm.dateRange,
				fromTime: vm.fromTime,
				toTime: vm.toTime,
				reportType: vm.userType
			}, function (response) {

				if (response instanceof Array) {
					vm.reportRecords = response;
				} else {
					vm.reportRecords = [];
					vm.reportRecords.push(response);
				}
				vm.search = "";
				vm.reporthide = true;
			})
		}

		function select2() {
			$(".select2").select2();
		}



		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		function selectRange(range) {

			if (range == 'day') {
				var today = new Date();
				vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				vm.fromDate.setHours(0, 0, 0, 0);
				vm.fromTime = vm.fromDate.getTime();
				vm.toDate.setHours(23, 59, 59, 999);
				vm.toTime = vm.toDate.getTime();

			} else if (range == 'week') {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				// date.setHours(0, 0, 0, 0);
				date.setDate(date.getDate() - 7)
				vm.fromTime = date.getTime();

			} else if (range == 'month') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				// date.setHours(0, 0, 0, 0);
				date.setMonth(date.getMonth() - 1);
				vm.fromTime = date.getTime();
			} 
		}

	}
})();
