(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "WorkFlowTypeDialogController",
            WorkFlowTypeDialogController
        );

    WorkFlowTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "WorkFlowType",
        "User",
        "WorkFlowTypeSearch",
        "AllCustomWorkFlowTypeAttribute",
    ];

    function WorkFlowTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        WorkFlowType,
        User,
        WorkFlowTypeSearch,
        AllCustomWorkFlowTypeAttribute
    ) {
        var vm = this;
        //workFlowtype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.workFlowType = entity;
        vm.workFlowTypeImageName = workFlowTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customWorkFlowTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");

        vm.checkNumber;

        function workFlowTypeImageName(image) {
            vm.workFlowType.image = image;
        }

        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomWorkFlowTypeAttribute.getAll(function (response) {
                vm.customWorkFlowTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customWorkFlowTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.workFlowType.workFlowTypeAttribute != null &&
            vm.workFlowType.workFlowTypeAttribute.length != 0
        ) {
            vm.items = vm.workFlowType.workFlowTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.workFlowCategory == null) {
                value.workFlowCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.isSaving = true;
            if (vm.workFlowType.id !== null) {
                vm.workFlowType.workFlowTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.workFlowType.workFlowTypeAttribute.push({
                        workFlowCategory: vm.items[i].workFlowCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        workFlowTypeAttribOrder:
                            vm.items[i].workFlowTypeAttribOrder,
                    });
                }
                WorkFlowType.update(
                    vm.workFlowType,
                    onSaveSuccess,
                    onSaveError
                );
            } else {
                vm.workFlowType.workFlowTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.workFlowType.workFlowTypeAttribute.push({
                        workFlowCategory: vm.items[i].workFlowCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        workFlowTypeAttribOrder:
                            vm.items[i].workFlowTypeAttribOrder,
                    });
                }
                WorkFlowType.save(vm.workFlowType, onSaveSuccess, onSaveError);
            }
        }

        $("#workFlowTypeImage").change(function () {
            var filesSelected =
                document.getElementById("workFlowTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.workFlowType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:workFlowTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("work-flow-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.addmore = function (index) {
            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice(index + 1, 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
