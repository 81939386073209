(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomCaseTypeAttributeDeleteController',CustomCaseTypeAttributeDeleteController);

    CustomCaseTypeAttributeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomCaseTypeAttribute'];

    function CustomCaseTypeAttributeDeleteController($uibModalInstance, entity, CustomCaseTypeAttribute) {
        var vm = this;

        vm.customCaseTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomCaseTypeAttribute.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
