(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkFlow", WorkFlow);

    WorkFlow.$inject = ["$resource", "DateUtils"];

    function WorkFlow($resource, DateUtils) {
        var resourceUrl = "api/work-flows/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                },
            }
        );
    }
})();

(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkFlowCheckAsset", WorkFlowCheckAsset);

    WorkFlowCheckAsset.$inject = ["$resource", "DateUtils"];

    function WorkFlowCheckAsset($resource, DateUtils) {
        var resourceUrl = "api/assets/search/:name";

        return $resource(
            resourceUrl,
            {},
            {
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
            }
        );
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("AllWorkFlowTypes", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAll = function (callback) {
                $http.get("api/work-flow-types").then(function (response) {
                    callback(response.data);
                });
            };

            return service;
        },
    ]);
})();


(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrUnresolvedGroupWorkFlowForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAllWorkFlows = function(callback) {
			$http.get('api/unresolvedgroupworkflowsformap').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

// (function() {
// 	'use strict';

// 	angular.module('trakeyeApp').factory('UnresolvedLocGroupWorkFlowForMap', UnresolvedLocGroupWorkFlowForMap);

// 	UnresolvedLocGroupWorkFlowForMap.$inject = [ '$resource' ];

// 	function UnresolvedLocGroupWorkFlowForMap($resource) {
// 		var service = $resource('/api/unresolvedgroupbasednearestworkflowsformap/:lat/:lng/:dis', {},{},{},{}, {
// 			'query' : {
// 				method : 'GET',
// 				isArray : true
// 			},
// 		});

// 		return service;
// 	}

// })();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('UnresolvedLocGroupWorkFlowForMap', UnresolvedLocGroupWorkFlowForMap);

	UnresolvedLocGroupWorkFlowForMap.$inject = ['$resource'];

	function UnresolvedLocGroupWorkFlowForMap($resource) {
		var service = $resource('/api/unresolvedgroupbasednearestworkflowsformap/:lat/:lng/:dis', {
			lat: '@lat',
            lng: '@lng',
			dis: '@dis'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('WorkFlowById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/work-flows/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('WorkFlowSearch', WorkFlowSearch);

	WorkFlowSearch.$inject = ['$resource'];

	function WorkFlowSearch($resource) {
		var service = $resource('/api/work-flows/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();