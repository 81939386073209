(function () {
    "use strict";
    angular.module("trakeyeApp").factory("History", History);

    History.$inject = ["$resource"];

    function History($resource) {
        var resourceUrl = "api/asset-changelog";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );
    }
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('HistorySearch', HistorySearch);

	HistorySearch.$inject = ['$resource'];

	function HistorySearch($resource) {
		var service = $resource('/api/asset-changelog-search/:searchtext', {
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();
