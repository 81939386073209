(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrVendorController", TrVendorController);

    TrVendorController.$inject = [
        "$scope",
        "$state",
        "TrVendor",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants"
    ];

    function TrVendorController(
        $scope,
        $state,
        TrVendor,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        vm.createVendor = createVendor;
        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        if ($scope.tab == 1) {
            filterSearch();
        }

        function filterSearch() {
            loadAll();
        }

        function createVendor() {
            $state.go("tr-vendor.new");
        }

        function loadAll() {
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }
            TrVendor.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.vendors = [];
                    vm.vendors = data;
                    vm.page = pagingParams.page;
                },
                onError
            );
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var searchVal = "";
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ){
                searchVal = $scope.search.replace(/[^\w\s]/g, '_');
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: searchVal
            });
        }
    }
})();
