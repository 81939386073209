(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('Feasibility', Feasibility);

    Feasibility.$inject = ['$rootScope', '$http', 'FeasibilityVerifyFinish'];

    function Feasibility ($rootScope, $http,FeasibilityVerifyFinish) {
        var service = {
           
            fesibilityVerify: fesibilityVerify
            
        };
        return service;
        
        function fesibilityVerify (keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return FeasibilityVerifyFinish.save(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }
        
    }
})();