(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrRouteDialogController", TrRouteDialogController);

    TrRouteDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrRoute",
        "AllRouteTypes",
        "AllCustomRouteTypeAttribute",
        "RouteAttributeMappingId",
        "GeofenceIDs",
        'TrProject',
        'ParseLinks', 'AlertService',
        'pagingParams',
        'KmlToCoordinates',
        '$rootScope',
        'AllAssetsTypes',
        'Asset',
        'Configs',
        'FixedAssetExcelUpload',
        'SpreadAssetExcelUpload',
        'getConnectedNodes'
    ];

    function TrRouteDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrRoute,
        AllRouteTypes,
        AllCustomRouteTypeAttribute,
        RouteAttributeMappingId,
        GeofenceIDs,
        TrProject,
        ParseLinks,
        AlertService,
        pagingParams,
        KmlToCoordinates,
        $rootScope,
        AllAssetsTypes,
        Asset,
        Configs,
        FixedAssetExcelUpload,
        SpreadAssetExcelUpload,
        getConnectedNodes
    ) {
        var vm = this;
        vm.trRoute = entity
        vm.save = save;
        var entity_old = angular.copy(vm.trRoute);
        vm.selectAttributes = selectedRouteTypeAttributes;
        vm.routeType = entity.routeType;
        vm.importRouteType = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        var formData;
        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];

        var CHAINAGE_ID = 1;
        var MARKER_ID = 2;
        var COORDINATE_ID = 3;
        vm.storeFixedResponse = []
        vm.storeSpreadResponse = []
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var polylineTimer,spreadPoly
        var labelFrom, labelTo
        labelFrom = 'A'
        labelTo = 'B'
        var markersConnect = []
        var tempObject = {}

        loadPage();
        var tempGeofenceId, coordinatesSpread, markersPoints, tempPointNumber, chainagePoints
        tempPointNumber = 0
        var locations = {};
        var coordinateList = []
        var markerList = []
        var chainageList = []
        var assetPoints = []
        var iconSize = 20
        var disVal = 0;
        var mappedAssetTemp = {};

        if (!vm.trRoute.id) {
            vm.trRoute.routePoints = [];
            vm.isSaving = true
            
            vm.trRoute.status = "NEW"
            vm.trRoute.phase = "PLANNING"
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        vm.closeModal = function (id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }

        vm.openImport = function (id) {
            $(":file").filestyle({
                buttonBefore: true,
                buttonText: "Browse File",
            });


            if(id == "KML"){
                vm.importxlsx = /^(.*?)\.(kml)$/;
                $("#importRouter").show();
            vm.importRouteType = null;
            $(":file").filestyle("clear");
            }

            if(id == "SPREAD"){
                vm.importxl = /^(.*?)\.(xlsx)$/;
            $("#importRouterSpread").show();
            $(":file").filestyle("clear");

            /* vm.storeDirectionLatlngs.forEach(function(each){
                var tempColor = getRandomColor()
                var markerStart = new google.maps.Marker({
                    icon: {
                        path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                        strokeColor: tempColor,
                        fillColor:tempColor,
                        fillOpacity:1,
                        scale: 4,
                        labelOrigin: new google.maps.Point(1, -7)
                    },
                    map: locations.map,
                    position: each[0],
                    label : {text:"Start",fontSize: '12px',
                   } 
                    
                });
                markerStart.setMap(locations.map);

                var markerEnd = new google.maps.Marker({
                    icon: {
                        path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                        strokeColor: tempColor,
                        fillColor:tempColor,
                        fillOpacity:1,
                        scale: 4,
                        labelOrigin: new google.maps.Point(1, -7)
                    },
                    map: locations.map,
                    position: each[each.length-1],
                    label : {text:"End",fontSize: '12px'
                    } 
                    
                });
                markerEnd.setMap(locations.map);

                var polyline = new google.maps.Polyline({
                    path: each,
                    geodesic: true,
                    strokeColor:tempColor, 
                    strokeOpacity: 0.4,
                    strokeWeight: 5,
                }); 

                polyline.setMap(locations.map) 
              }) */

            }

            if(id == "FIXED"){
                vm.importxl = /^(.*?)\.(xlsx)$/;
                $("#importRouterFixed").show();
            $(":file").filestyle("clear");
            }
            
        }

        vm.loadKmlOnMap = function (id) {
            vm.importRouteType = JSON.parse(vm.importRouteType);
            formData = new FormData();

            formData.append("routename", vm.importRouteType.name);
            formData.append('file', $scope.myFile);

            KmlToCoordinates.getLatLng(formData, function (response, headers) {

                if (response.status == 200 && response.data.length) {
                    vm.error = null;
                    vm.success = 'OK';

                    //vm.trRoute.routePoints = pointList
                    //vm.importRouteType = "";

                    console.log(vm.importRouteType);
                    if (vm.importRouteType.id == COORDINATE_ID) {
                        coordinatesSpread = response.data
                        vm.isSaving = false
                        var spreadCoordinates = response.data;
                        spreadCoordinates.forEach(function (latlng, index) {
                            tempPointNumber++
                            coordinateList.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType.name, latitude: latlng.lat, longitude: latlng.lng });

                        });

                        if (spreadCoordinates.length > 0) {
                            for (var index in spreadCoordinates) {
                                if (index > 0) {
                                    disVal += distanceCal(
                                        spreadCoordinates[index]
                                            .lat,
                                        spreadCoordinates[index]
                                            .lng,
                                        spreadCoordinates[index - 1]
                                            .lat,
                                        spreadCoordinates[index - 1]
                                            .lng
                                    );
                                }
                            }
                        }
                        
                        vm.trRoute.routeLength = parseInt(disVal*1000);

                    }

                    if (vm.importRouteType.id == MARKER_ID) {
                        markersPoints = response.data
                        response.data.forEach(function (latlng, index) {
                            tempPointNumber++
                            markerList.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType.name, latitude: latlng.lat, longitude: latlng.lng });

                        });
                    }

                    if (vm.importRouteType.id == CHAINAGE_ID) {
                        chainagePoints = response.data
                        //vm.isSaving = false

                        response.data.forEach(function (latlng, index) {
                            tempPointNumber++
                            chainageList.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType.name, latitude: latlng.lat, longitude: latlng.lng });

                        });
                    }

                    var assettype = vm.importRouteType;
                    //console.log(assettype);
                    if(assettype.layout == "FIXED" || assettype.layout == "SPREAD"){
                       
                        mappedAssetTemp.name = assettype.name;
                        mappedAssetTemp.description = assettype.description;
                        mappedAssetTemp.assetType = assettype;
                        var date = new Date();
                        mappedAssetTemp.createDate = date.getTime();
                        /* mappedAssetTemp.groups = [
                            {
                                id: 533470,
                                name: "Default Group",
                            }
                        ]; */
                        mappedAssetTemp.assetTypeAttributeValues = getConsolidatedAssetTypeAttributeValue(assettype);
                        mappedAssetTemp.assetCoordinates = []
                        if (assettype.layout == "SPREAD") {
                            //assetPoints = response.data
                            //vm.isSaving = false
    
                            response.data.forEach(function (latlng, index) {
                                //tempPointNumber++
                                //assetPoints.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType, latitude: latlng.lat, longitude: latlng.lng });
                                mappedAssetTemp.assetCoordinates.push({latitude: latlng.lat, longitude: latlng.lng})
                            });
                            //mappedAssetTemp.assetCoordinates = JSON.parse('[{"latitude": 12.98060492816288,"longitude": 77.54060232132213},{"latitude": 12.988299448414443,"longitude": 77.57321798294322},{"latitude": 12.97408112657334,"longitude": 77.58403264969127},{"latitude": 12.968560853090361,"longitude": 77.56068670242564}]');
                        } else if (assettype.layout == "FIXED") {
                            //assetPoints = response.data
                            //vm.isSaving = false
    
                            response.data.forEach(function (latlng, index) {
                                //tempPointNumber++
                                //assetPoints.push({ routePointNumber: tempPointNumber, routePointType: vm.importRouteType, latitude: latlng.lat, longitude: latlng.lng });
                                mappedAssetTemp.assetCoordinates.push({latitude: latlng.lat, longitude: latlng.lng})
                            });

                            locations.map.setCenter({lat:mappedAssetTemp.assetCoordinates[0].latitude, lng:
                                mappedAssetTemp.assetCoordinates[0].longitude} );
                                
                            mappedAssetTemp.assetCoordinates.forEach(function (each) {
                            var marker = new google.maps.Marker({
                            position:  {lat:each.latitude,lng:each.longitude},
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                url: assettype.imagePath,
                                anchor: new google.maps.Point(
                                    iconSize / 2,
                                    iconSize / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                        });

                        //markersPoints.push(marker);
                    })

                            //mappedAssetTemp.assetCoordinates = JSON.parse('[{"latitude": 12.882285734194946,"longitude": 77.52463748554926}]');
                        }
                        /* Asset.save(mappedAssetTemp, onSaveSuccess, onSaveError);
                        
                        function onSaveSuccess(result) {
                            console.log(result);
                        }

                        function onSaveError() {
                            console.log("save error");
                        }
                        console.log(mappedAssetTemp); */
                    }
                    

                    $timeout(function () {
                        vm.success = '';
                    }, 4000);

                }
                if (response.status == 400) {
                    vm.success = null;
                    vm.error = 'error';

                }

                if (vm.importRouteType.name == "COORDINATE" && coordinatesSpread) {

                    locations.map.setCenter(coordinatesSpread[0]);

                    var polyline = new google.maps.Polyline({
                        path: coordinatesSpread,
                        geodesic: true,
                        strokeColor: '#FD7A24',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                }

                if (vm.importRouteType.name == "MARKER" && markersPoints) {

                    locations.map.setCenter(markersPoints[0]);
                    markersPoints.forEach(function (each) {
                        var marker = new google.maps.Marker({
                            position: { lat: each.lat, lng: each.lng },
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                url: "content/images/markersPoint.png",
                                anchor: new google.maps.Point(
                                    iconSize / 2,
                                    iconSize / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                        });

                        markersPoints.push(marker);
                    })

                }

                if (vm.importRouteType.name == "CHAINAGE" && chainagePoints) {


                    locations.map.setCenter(chainagePoints[0]);
                    chainagePoints.forEach(function (each) {
                        var marker = new google.maps.Marker({
                            position: { lat: each.lat, lng: each.lng },
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(iconSize, iconSize),
                                url: "content/images/chainagePoints.png",
                                anchor: new google.maps.Point(
                                    iconSize / 2,
                                    iconSize / 2
                                ),
                                labelOrigin: new google.maps.Point(25, 22),
                            },
                        });

                        chainagePoints.push(marker);
                    })

                }

            });
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }
        function getConsolidatedAssetTypeAttributeValue(assetType) {
            var assetTypeAttributeValuesList = [];

            for (var index in assetType.assetTypeAttributes) {
                var assetTypeAttribute1 = assetType.assetTypeAttributes[index];
                assetTypeAttributeValuesList.push({
                    assetTypeAttribute: assetTypeAttribute1,
                    attributeValue:
                        assetType.assetTypeAttributes[index].defaultValue,
                });
            }

            assetTypeAttributeValuesList.sort(function (a, b) {
                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                    nameB = b.assetTypeAttribute.name.toLowerCase();
                if (nameA < nameB)
                    // sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; // default return value (no sorting)
            });
            return assetTypeAttributeValuesList;
        }

        if (vm.trRoute.id != null) {
            vm.trRouteType = vm.trRoute.routeType;
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                Math.cos(degrees_to_radians(lat2)) *
                Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        function loadPage() {

            TrProject.query(
                {
                    page: 0,
                    size: 100,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.projects = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.projects.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var routeTypeAttributeValuesList =
                vm.trRoute.routeTypeAttributeValues;

            try {
                for (var index in vm.trRoute.routeType.routeTypeAttribute) {
                    if (
                        !checkIfRouteTypeExists(
                            vm.trRoute.routeType.routeTypeAttribute[index],
                            vm.trRoute.routeTypeAttributeValues
                        )
                    ) {
                        var routeTypeAttribute1 =
                            vm.trRoute.routeType.routeTypeAttribute[index];
                        routeTypeAttributeValuesList.push({
                            routeTypeAttribute: routeTypeAttribute1,
                            attributeValue:
                                vm.trRoute.routeType.routeTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfRouteTypeExists(routeType, routeTypeAttrVal) {
                var hasRouteType = false;
                for (var i = 0; i < routeTypeAttrVal.length; i++) {
                    var name = routeTypeAttrVal[i].routeTypeAttribute.name;
                    if (name == routeType.name) {
                        hasRouteType = true;
                        break;
                    }
                }
                return hasRouteType;
            }
            AllRouteTypes.getAll(function (response) {
                vm.routeTypes = response;
            });

            AllCustomRouteTypeAttribute.getAll(function (response) {
                //vm.customRouteTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (arrayItem.customRouteTypeAttributeValues != undefined) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customRouteTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }

                });
                if (vm.trRoute.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });
            AllAssetsTypes.getAll(function (response) {
                vm.assetType = response;
                vm.types = [];
                vm.types.push({id:CHAINAGE_ID, name:"CHAINAGE"});
                vm.types.push({id:MARKER_ID, name:"MARKER"});
                vm.types.push({id:COORDINATE_ID, name:"COORDINATE"});
                response.forEach(function(assetType){
                    if(assetType.layout == "FIXED"){
                        vm.types.push(assetType);
                    }
                });

                //console.log(vm.types);
            });

        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedRouteTypeAttributes() {
            vm.showFilter = true;
            if (vm.routeType) {
                vm.trRoute.routeType = vm.routeType;
                if (
                    entity_old.routeType &&
                    vm.routeType.id === entity_old.routeType.id
                ) {
                    vm.trRoute.routeTypeAttributeValues = [];
                    vm.trRoute.routeTypeAttributeValues =
                        entity_old.routeTypeAttributeValues;
                } else {
                    vm.trRoute.routeTypeAttributeValues = [];
                    $.each(
                        vm.routeType.routeTypeAttribute,
                        function (key, value) {
                            vm.trRoute.routeTypeAttributeValues.push({
                                routeTypeAttribute:
                                    vm.routeType.routeTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {

            if(vm.storeFixedResponse.length && !vm.storeSpreadResponse.length){
                vm.trRoute.assets = vm.storeFixedResponse 
            }else if(vm.storeSpreadResponse.length && !vm.storeFixedResponse.length){
                vm.trRoute.assets = vm.storeSpreadResponse 
            }else if(vm.storeSpreadResponse.length && vm.storeFixedResponse.length){
                vm.trRoute.assets = vm.storeFixedResponse.concat(vm.storeSpreadResponse)
            }
                
               
           
           
            //vm.isSaving = true;
            /* vm.trRoute.routePoints = path.getArray().map(function(latLng) {
                return {
                  latitude: latLng.lat(),
                  longitude: latLng.lng()
                };
              }); */
            /* var spreadAV = vm.trRoute.routeTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].routeTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trRoute.routeTypeAttributeValues = spreadAV; */

            if ((!vm.trRoute.id) && (markerList || coordinateList || chainageList)) {
                vm.trRoute.routePoints = (coordinateList || []).concat(markerList || [], chainageList || []);
            }


            if (vm.trRoute.id !== null) {
                TrRoute.update(vm.trRoute, onSaveSuccess, onSaveError);
            } else {
                TrRoute.save(vm.trRoute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:routeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $state.go("tr-route");
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        $scope.geofencelabel = {
            search: "Search",
            nothingSelected: "Geofence",
        };

        /*  GeofenceIDs.getAll(function (response) {
             vm.geofencesListView = []
             if ($scope.tab == 1) {
                 if (response.length >= 2) {
                     vm.geofencesListView.push({
                         id: 0,
                         name: "All",
                         label: "All",
                     });
                 }
             }
             response.forEach(function (geofence) {
                 geofence.label = geofence.name.substring(0, 15) + "..";
                 geofence.ticked =
                     tempGeofenceId == geofence.id ? true : false;
                 vm.geofencesListView.push(geofence);
                 if (vm.selectedMapGeofenceId == null) {
                     vm.selectedMapGeofenceId = geofence.id;
                 }
             });
             vm.countLength = vm.geofencesListView.length;
         }); */

        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            {
                center: {
                    lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                    lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                },
                zoom: 13,
                fullscreenControl: true,
                styles: mapStyles(),
            }
            //myOption(16, vm.getCenterEnd)
        );



          //Direction API
         
          var directionsService;
          vm.storeDirectionLatlngs = []
             
          directionsService = new google.maps.DirectionsService();

          function callDirectionApi(location){

            var tempColor = "BLUE"
            var tempConnected  = "Connected"

            if(!location.connected){
                tempColor = "#FD7A24"
                tempConnected = "Not Connected"
            }

            var request = {
                origin: location.source,
                destination: location.destination,
                travelMode: 'DRIVING'
              };

              directionsService.route(request).then(function(result)  {
                  if (result.status == google.maps.DirectionsStatus.OK) {
                      vm.storeDirectionLatlngs.push({assetCoordinates:result.routes[0].overview_path, name:location.sourceName + " - " + location.destinationName })
                     
                      /* if(index == (vm.storeLocations.length-1)){
                          callPlotDirectionAPIData()
                      } */
                      
                      var polyline = new google.maps.Polyline({
                         path: result.routes[0].overview_path,
                         geodesic: true,
                         strokeColor:tempColor, 
                         strokeOpacity: 1,
                         strokeWeight: 3,
                     }); 
                     
                    // each.assetCoordinates.forEach(function(latlng){ })
                     
                    google.maps.event.addListener(
                     polyline,
                     "click",
                     function (event) {
                         if (openedInfoWindow != null) {
                             openedInfoWindow.close();
                             if (spreadPoly != null) {
                                 spreadPoly.setMap(null);
                             }
                         }
 
                         clearTimeout(polylineTimer);
 
                         spreadPoly = new google.maps.Polyline({
                             path: result.routes[0].overview_path,
                             strokeColor: "#FFFF00",
                             strokeOpacity: 0.6,
                             strokeWeight: 19,
                         });
 
                         spreadPoly.setMap(locations.map);
 
                         polylineTimer = setTimeout(function () {
                             if (spreadPoly != null) {
                                 spreadPoly.setMap(null);
                                 spreadPoly = null;
                             }
                         }, 5000);
                         
                         var userContent = document.createElement("div"),
                         btnEditAsset,
                         brBreak;

                         
                         userContent.innerHTML =  '<div class="infoWindowhead">' +
                         "<b> Name </b><br>" +
                         location.sourceName + " to " + location.destinationName  + 
                         "<br><b> Status </b><br>" +
                         tempConnected   + 
                         "</br></div>"

                         /* btnEditAsset = userContent.appendChild(
                          document.createElement("input")
                          );

                          btnEditAsset.type = "button";
                          btnEditAsset.value = "Edit Asset";
                          btnEditAsset.className = "button";

                      google.maps.event.addDomListener(
                          btnEditAsset,
                          "click",
                          function () {
                              $(":file").filestyle("clear");
                              $("#assetEditModal").show();
                          }
                      );  */

                         
                         infowindow.setContent(
                          userContent
                         );
     
                         infowindow.open(locations.map);
                         infowindow.setPosition(event.latLng);
                         openedInfoWindow = infowindow;
                     }
                 );
                 /* google.maps.event.addListener(
                     polyline,
                     "mouseout",
                     function (event) {
                         infowindow.close();
                     }
                 ); */
     
                     polyline.setMap(locations.map) 

                     if (markersConnect.length == 2){ 
                        clearMarkers()
                     }
                  }
            }).catch(function(e) {console.log("Directions request failed due to " + e)} );

            
          }
         function getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            do {
              color = '#';
              for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
              }
            } while (color === '#FFFFFF'); // Exclude white color
          
            return color;
          }
         
         


        //geoLocation(locations.map);
        //vm.getCenterEnd = locationsEnd.map.center;

        /* vm.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYLINE,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ["polyline"],
            },
            polylineOptions: {
                fillColor: "#ffff00",
                fillOpacity: 1,
                strokeWeight: 5,
                clickable: true,

                editable: true,
                zIndex: 1,
                geodesic: true,
            },
        });

        

        var polyline,path
        google.maps.event.addListener(vm.drawingManager, 'overlaycomplete', function(event) {
            if (event.type == google.maps.drawing.OverlayType.POLYLINE) {
              // Get the path of the drawn polyline
              polyline = event.overlay;
              path = polyline.getPath();
          
              
            }
          });

          vm.drawingManager.setMap(locations.map);
      
        vm.createfence = function(){
            if (polyline) {
                path = polyline.getPath();
          
                var coordinates = path.getArray().map(function(latLng) {
                  return {
                    lat: latLng.lat(),
                    lng: latLng.lng()
                  };
                });
          
                console.log('Polyline coordinates:', coordinates);
                // You can use the coordinates in any way you need here
              } 
        } */


        if (vm.trRoute.id && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {

            vm.trRoute.routePoints.forEach(function (each) {
                if (each.routePointType == "COORDINATE") {
                    coordinateList.push({ lat: each.latitude, lng: each.longitude })
                } else if (each.routePointType == "MARKER") {
                    markerList.push({ lat: each.latitude, lng: each.longitude })
                } else if (each.routePointType == "CHAINAGE") {
                    chainageList.push({ lat: each.latitude, lng: each.longitude })
                }
            })

            if (coordinateList.length) {
                locations.map.setCenter(coordinateList[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateList,
                    geodesic: true,
                    strokeColor: '#FD7A24',
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
            }

            if (markerList.length) {
                locations.map.setCenter(markerList[0]);
                createMarkers(markerList, 'content/images/markersPoint.png', locations.map)
            }

            if (chainageList.length) {
                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
            }
        }

        if(vm.trRoute.assets != null && vm.trRoute.assets.length){
            var assetsListFixed, assetsListSpread
           
            vm.trRoute.assets.forEach(function(item){
                assetsListFixed = []
                assetsListSpread = []
                if(item.assetType.layout == "FIXED"){
                    if(item.assetCoordinates.length){
                        
                        locations.map.setCenter({lat:item.assetCoordinates[0].latitude, lng:
                            item.assetCoordinates[0].longitude} );
                        item.assetCoordinates.forEach(function (each) {
                            assetsListFixed.push({ lat: each.latitude, lng: each.longitude })
                        })
                        //createMarkers(assetsList, 'content/images/chainagePoints.png', locations.map)
                        createMarkers(assetsListFixed, item.assetType.imagePath , locations.map)
                    }
                    
                }
                if(item.assetType.layout == "SPREAD"){
                    
                    if(item.assetCoordinates.length){ 
                        
                        locations.map.setCenter({lat:item.assetCoordinates[0].latitude, lng:
                            item.assetCoordinates[0].longitude} );
                        item.assetCoordinates.forEach(function (each) {
                                assetsListSpread.push({lat:each.latitude,lng:each.longitude})
                            
                        })
                        var polyline = new google.maps.Polyline({
                            path: assetsListSpread,
                            geodesic: true,
                            strokeColor: item.assetType.colorcode,
                            strokeOpacity: 0.6,
                            strokeWeight: 4,
                        });
    
                        // Set the map to display the polyline
                        polyline.setMap(locations.map);
                    }
                }
                
                
            })
            //locations.map.setCenter(assetsList[0]);
            
        }

        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.lat, lng: each.lng },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                arr.push(marker);
            })
        }

        $('.select2').select2();
 
        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
        });

        Configs.get("RolloutTaskStatus", function (response) {
            vm.rolloutTaskStatus = response;
        }); 
        

        var getFile,formData
        vm.uploadFixedAssets = function () {

            getFile = document.getElementById('uploadfileAssetFixed').files[0]
            formData = new FormData();
            //formData.append("assettype", vm.importassettype.name);
            formData.append("file", getFile);
            
            FixedAssetExcelUpload.assetupload(formData, function (response) {
                vm.success = response.status == 200 ? "success" : ""
                if (vm.success) {
                    setTimeout(function () {
                        vm.success = "";
                        vm.closeModal('importRouterFixed')
                        vm.isSaving = false
                    }, 500);
                }

                if(response.data.length) {

                    vm.tempStoreNodes = []
                    var marker                    
                    vm.storeFixedResponse = response.data
                    locations.map.setCenter({lat:vm.storeFixedResponse[0].assetCoordinates[0].latitude, lng:
                        vm.storeFixedResponse[0].assetCoordinates[0].longitude} );
                        
                vm.storeFixedResponse.forEach(function (each,index) {
                    each.connected = true;
                    if(each.name && each.assetCoordinates){

                        if(each.assetType.name == "GP"){
                            each.assetTypeAttributeValues.forEach(function (value, index) {
                                if (value.assetTypeAttribute.name == "GP Status") {
                                    if(value.attributeValue == 'Not Connected'){
                                        each.connected = false
                                    }
                                }
                            });
                        }
                        vm.tempStoreNodes.push({
                            name:each.name, 
                            assetCoordinates : [{ latitude:each.assetCoordinates[0].latitude, 
                                longitude:each.assetCoordinates[0].longitude}],
                            connected : each.connected,
                            assetTypeName : each.assetType.name
                        })
    
                        marker = new google.maps.Marker({
                        position:  {lat:each.assetCoordinates[0].latitude,lng:each.assetCoordinates[0].longitude},
                        map: locations.map,
                        icon: {
                            scaledSize: new google.maps.Size(iconSize, iconSize),
                            url: each.assetType.imagePath,
                            anchor: new google.maps.Point(
                                iconSize / 2,
                                iconSize / 2
                            ),
                            labelOrigin: new google.maps.Point(25, 22),
                        },
                    });

            var tempConnected  = "Connected"

            if(!each.connected){
                tempConnected = "Not Connected"
            }
                    google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker) {
                            var assetDetails = each;
                            var userContent = document.createElement("div"),btnEditAsset
                            var tempPhase = assetDetails.phase ? assetDetails.phase : ''
                            
                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +
                                assetDetails.name +
                                '</div><div class="infoWindowContent">' +
                                "<b>Asset Type </b> : " +
                                assetDetails.assetType.name +
                                "<br><b>Latitude </b> : " +
                                 assetDetails.assetCoordinates[0].latitude
                                  + "<br><b>Longitude </b> : " +
                                  assetDetails.assetCoordinates[0].longitude
                                   + "<br><b>Phase </b> : " +
                                   tempPhase
                                    + "<br><b>Status </b> : " +
                                    tempConnected
                                     + 
                                "<br>" +
                                "</div><br>";

                                if (markersConnect.length != 2){ 
                                    btnEditAsset = userContent.appendChild(
                                        document.createElement("input")
                                        );
            
                                        btnEditAsset.type = "button";
                                        btnEditAsset.value = "Connect";
                                        btnEditAsset.className = "button"; 
            
                                    google.maps.event.addDomListener(
                                        btnEditAsset,
                                        "click",
                                        function () {
       
                                           
                                               addMarker({
                                                   lat: assetDetails.assetCoordinates[0].latitude,
                                                   lng: assetDetails.assetCoordinates[0].longitude,
                                               },assetDetails.name,each.connected)
                                           
                                           
                                            //$(":file").filestyle("clear");
                                            //$("#connectAssetsModal").show();
                                         
                                        }
                                    ); 
                                }
                               

                            return function () {
                                infowindow.setContent(userContent);
                                infowindow.open(locations.map, marker);
                                infowindow.setPosition(
                                    new google.maps.LatLng(
                                        marker.getPosition().lat(),
                                        marker.getPosition().lng()
                                    )
                                );
                                setTimeout(function () {
                                    infowindow.close();
                                }, 6000);
                            };
                        })(marker, index)
                    );

                    }
                    
                }

                )
                
                getConnectedNodes.post(vm.tempStoreNodes, function(response){
                    if(response.length){
                        vm.storeLocations = []
                        response.forEach(function(item){
                            item.destinationNode.forEach(function(eachDestination){
                                    var isConnected = false

                                    if(item.sourceNode.connected && eachDestination.connected){
                                        isConnected = true
                                    }

                                    vm.storeLocations.push(
                                        { 
                                        source: new google.maps.LatLng(item.sourceNode.assetCoordinates[0].latitude, item.sourceNode.assetCoordinates[0].longitude) , 
                                        destination: new google.maps.LatLng(eachDestination.assetCoordinates[0].latitude, eachDestination.assetCoordinates[0].longitude) ,
                                        sourceName : item.sourceNode.name,
                                        destinationName : eachDestination.name,
                                        connected : isConnected
                                        }
                                        );
                            });
                            
                        })
                        vm.storeLocations.forEach(function(location) {
                            callDirectionApi(location)
                          });
                        
                    }
                })

                }
            });
        };

        function addMarker(location,name,connected) {
            infowindow.close();
            var markerText
            

            if (markersConnect.length == 0) {
                tempObject.source = location
                tempObject.srcName = name
                markerText = labelFrom

                tempObject.isAConnected = connected
            } else if (markersConnect.length == 1) {
                markerText = labelTo
                tempObject.destination = location
                tempObject.desName = name
                tempObject.isBConnected = connected

                if(!tempObject.isBConnected || !tempObject.isAConnected){
                    tempObject.connected = false
                }else{
                    tempObject.connected = true
                }
                callDirectionApi({ 
                    source:  tempObject.source, 
                    destination: tempObject.destination ,
                    sourceName : tempObject.srcName,
                    destinationName : tempObject.desName,
                    connected : tempObject.connected
                    })
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                label: {
                    text: markerText,
                }
            });

            // Add the marker to the markers array
            markersConnect.push(marker);
        }

        function clearMarkers() {
            markersConnect.forEach(function (marker) {
                marker.setMap(null);
            });

            // Clear the markers array
            markersConnect = [];

        }

        vm.uploadSpreadAssets = function () {

            getFile = document.getElementById('uploadfileAssetSpread').files[0]
            formData = new FormData();
            //formData.append("assettype", vm.importassettype.name);
            formData.append("file", getFile);
            
            SpreadAssetExcelUpload.assetupload(formData, function (response) {
                vm.success = response.status == 200 ? "success" : ""
                if (vm.success) {
                    setTimeout(function () {
                        vm.success = "";
                        vm.closeModal('importRouterSpread')
                        vm.isSaving = false
                    }, 1000);
                }

                if(response.data.length) {
                    vm.tempPath = []
                    vm.storeSpreadResponse = response.data
                    locations.map.setCenter({lat:vm.storeSpreadResponse[0].assetCoordinates[0].latitude, lng:
                        vm.storeSpreadResponse[0].assetCoordinates[0].longitude} );
                        
                        vm.storeSpreadResponse.forEach(function(each){
                            
                            if(each.assetCoordinates.length){
                                vm.tempPath.length = 0
                                vm.tempPath = []
                                each.assetCoordinates.forEach(function (item){
                                    vm.tempPath.push({lat:item.latitude,lng:item.longitude})
                                })
                                var spreadCoordinates = each.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(
                                                spreadCoordinates[index]
                                                    .latitude,
                                                spreadCoordinates[index]
                                                    .longitude,
                                                spreadCoordinates[index - 1]
                                                    .latitude,
                                                spreadCoordinates[index - 1]
                                                    .longitude
                                            );
                                        }
                                    }
                                }
                                vm.trRoute.routeLength = parseInt(disVal*1000);
                                var polyline = new google.maps.Polyline({
                                    path: vm.tempPath,
                                    geodesic: true,
                                    strokeColor: each.assetType.colorcode,
                                    strokeOpacity: 0.6,
                                    strokeWeight: 4,
                                });
                                
                                //console.log("Spread Assets Path",vm.tempPath)
                                //console.log("Spread Assets Polyline",polyline)
                                // Set the map to display the polyline
                                polyline.setMap(locations.map);
                            }
                            
                        })
                        
                         }
            });
        };

    }
})();
