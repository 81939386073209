(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("work-flow", {
                parent: "entity",
                url: "/work-flow?page&sort&search",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: "trakeyeApp.workFlow.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/work-flow/work-flows.html",
                        controller: "WorkFlowController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("workFlow");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("work-flow-detail", {
                parent: "work-flow",
                url: "/work-flow/{id}",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: "trakeyeApp.workFlow.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/work-flow/work-flow-detail.html",
                        controller: "WorkFlowDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("workFlow");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "WorkFlow",
                        function ($stateParams, WorkFlow) {
                            return WorkFlow.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "work-flow",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("work-flow.new", {
                url: "/work-flow",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/work-flow/work-flow-dialog.html",
                        controller: "WorkFlowDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                description: $stateParams.desc,
                                createDate: null,
                                updateDate: null,
                                pinLat: parseFloat($stateParams.lat),
                                pinLong: parseFloat($stateParams.lng),
                                address: null,
                                escalated: null,
                                status: null,
                                id: null,
                                caseImages: [],
                            };
                        },
                    ],
                },
            })
            .state('work-flow.edit', {
                parent: 'work-flow',
                url: '/Edit/{id}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: 'trakeyeApp.workFlow.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/work-flow/work-flow-dialog.html',
                        controller: 'WorkFlowDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'WorkFlow', function ($stateParams, WorkFlow) {
                        return WorkFlow.get({ id: $stateParams.id }).$promise;
                    }],


                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'work-flow',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state("work-flow.delete", {
                parent: "work-flow",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_USER"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/work-flow/work-flow-delete-dialog.html",
                                controller: "WorkFlowDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "WorkFlow",
                                        function (WorkFlow) {
                                            return WorkFlow.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("work-flow", null, {
                                        reload: "work-flow",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            });
    }
})();
