(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("NavbarController", NavbarController);

    NavbarController.$inject = [
        "$state",
        "Auth",
        "Principal",
        "ProfileService",
        "LoginService",
        "$rootScope",
        "UserLogout",
        "Configs"
    ];

    function NavbarController(
        $state,
        Auth,
        Principal,
        ProfileService,
        LoginService,
        $rootScope,
        UserLogout,
        Configs
    ) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        var prev = [];
        var prevPlus = [];
        vm.accessRolePlanning = false
        vm.accessRoleRollout = false
        vm.currYear = new Date().getFullYear();
        
        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            UserLogout.post(
                {
                    username: $rootScope.userName,
                    password: "password",
                },
                function (data) {
                    Auth.logout();
                    $rootScope.listAuthorities();
                    $state.go("home");
                }
            );
        }

        vm.isNavbarCollapsed = true;
        function toggleNavbar(id) {
            if (document.getElementById("dashboardtitle")) {
                var getUrl = window.location.hash;
                if (getUrl != "#/login" && getUrl != "#/dashboard") {
                    document.getElementById("dashboardtitle").style.visibility =
                        "hidden";
                }
            }
            
            $(".fa-plus").removeClass("fa-minus");
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            $(".treeview-menu").removeClass("menu-close").addClass("menu-open");
            /* $("#" + id + " > span.pull-right-container > i").toggleClass(
                "down"
            ); */
            prev.unshift(id);
            if (prev[0] != prev[1]) {
                $(
                    "#" + prev[1] + " > span.pull-right-container > i"
                ).removeClass("down");
                prev.length = 2;
            }
        }

        function collapseNavbar(id) {
            if (document.getElementById("dashboardtitle")) {
                if (typeof id === "undefined") {
                    document.getElementById("dashboardtitle").style.visibility =
                        "hidden";
                } else {
                    document.getElementById("dashboardtitle").style.visibility =
                        "visible";
                }
            }

            vm.isNavbarCollapsed = false;
            //$(".fa-plus").removeClass("fa-minus");
            $(".rotate").removeClass("down");
            $(".treeview").removeClass("active");
            $(".treeview-menu").addClass("menu-close").removeClass("menu-open");
            $rootScope.close = {
                display: "none",
            };
        }

        $rootScope.listAuthorities = function () {
            $rootScope.has_ROLE_SUPER_ADMIN = false;
            $rootScope.has_ROLE_USER_ADMIN = false;
            $rootScope.has_ROLE_USER = false;
            $rootScope.has_ROLE_VENDOR_ADMIN = false;
            $rootScope.has_ROLE_HOD = false;
            $rootScope.has_ROLE_CITY_MANAGER = false;
            $rootScope.has_ROLE_CITY_PLANNER = false;
            $rootScope.has_ROLE_ASSISTANT_MANAGER = false;
            $rootScope.has_ROLE_TEAM_LEADER = false;
            $rootScope.has_ROLE_NETWORK_ENGINEER = false;
            $rootScope.has_ROLE_FRT = false;
            $rootScope.has_ROLE_PATROLLER = false;

            $rootScope.has_ROLE_PLAN_ADMIN = false;
            $rootScope.has_ROLE_ROLLOUT_ADMIN = false;
            $rootScope.has_ROLE_ROLLOUT_FE = false;
            $rootScope.has_ROLE_ROLLOUT_MANAGER = false;

            Principal.identity().then(function (identity) {
                vm.accessRolePlanning = vm.accessRoleRollout = vm.accessRoleRollout = vm.accessRolePlanningAdmin = vm.accessRoleRolloutAdmin = vm.groupRollout = vm.groupPlan = false
                
                if(identity != null) {
                    //Checking group  
                    identity.groups.forEach(function (val){
                        var convert = val.name.toLowerCase()
                        var res = convert.replace(/[^a-zA-Z]/g, "");
                        if(res.includes("planning")){
                        vm.groupPlan = true
                    }else if(res.includes("rollout")){
                        vm.groupRollout = true
                    }
                    })

                    identity.authorities.forEach(function (val){
                        if((vm.groupPlan && val.includes("ROLE_PLAN_"))){
                        vm.accessRolePlanning = true
                    }else if( (vm.groupRollout && val.includes("ROLE_ROLLOUT_")) || (vm.groupPlan && val.includes("ROLE_ROLLOUT_")) || (vm.groupRollout && val.includes("ROLE_PLAN_"))){
                        vm.accessRoleRollout = true
                    }
                    })

                    //Access for Entity Configuration
                    identity.authorities.forEach(function (val){
                        if(vm.accessRolePlanning && val == "ROLE_PLAN_ADMIN"){
                        vm.accessRolePlanningAdmin = true
                        vm.accessRoleRolloutAdmin = true
                    }/* else if(vm.accessRoleRollout && val == "ROLE_ROLLOUT_ADMIN"){
                        vm.accessRoleRolloutAdmin = true
                    } */
                    })
                }
                if(identity != null) {
                Configs.get("Features", function (response) {
                    response.filter(function (value){

                        if(value.configKey == "SurveyReport"){
                            if(value.configValue == "True"){
                                vm.showSurveyReport = true
                            }else{
                                vm.showSurveyReport = false
                            }
                        }

                        if(value.configKey == "PatrollingReport"){
                            if(value.configValue == "True"){
                                vm.showAssetHealthStatus = true
                            }else{
                                vm.showAssetHealthStatus = false
                            }
                        }

                        if(value.configKey == "EntityImport"){
                            if(value.configValue == "True"){
                                vm.showEntityImport = true
                            }else{
                                vm.showEntityImport = false
                            }
                        }

                        if(value.configKey == "AssetLinkRequest"){
                            if(value.configValue == "True"){
                                vm.showAssetLinkRequest = true
                            }else{
                                vm.showAssetLinkRequest = false
                            }
                        }
                    })
                });

                
              /* Configs.rolloutFeatures(function (response) {
                $rootScope.storeTemp = response
                        $rootScope.showRolloutProject = false;
                        $rootScope.showRolloutRoute = false;
                        $rootScope.showRolloutTask = false;
                        $rootScope.showRolloutSection = false;
                        $rootScope.showRolloutWorkOrder = false;
                        $rootScope.showRolloutResource = false;
                        $rootScope.showRolloutMaterial = false;
                    
                        if (response && response.length) {
                            response.forEach(function (value) {
                                switch (value.configKey) {
                                    case "PROJECT_SIDEBAR":
                                        $rootScope.showRolloutProject = value.configValue === "TRUE";
                                        break;
                                    case "ROUTE_SIDEBAR":
                                        $rootScope.showRolloutRoute = value.configValue === "TRUE";
                                        break;
                                    case "TASK_SIDEBAR":
                                        $rootScope.showRolloutTask = value.configValue === "TRUE";
                                        break;
                                    case "SECTION_SIDEBAR":
                                        $rootScope.showRolloutSection = value.configValue === "TRUE";
                                        break;
                                    case "WORKORDER_SIDEBAR":
                                        $rootScope.showRolloutWorkOrder = value.configValue === "TRUE";
                                        break; 
                                    case "RESOURCE_SIDEBAR":
                                        $rootScope.showRolloutResource = value.configValue === "TRUE";
                                        break;
                                    case "MATERIAL_SIDEBAR":
                                        $rootScope.showRolloutMaterial = value.configValue === "TRUE";
                                        break;

                                    default: console.log(value.configKey,"Access Denied")
                                }
                            });
                        }
                });  */
                    
               
            }

                if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_SUPER_ADMIN") !== -1
                ) {
                    $rootScope.has_ROLE_SUPER_ADMIN = true;
                    // $state.go('tenant');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_VENDOR") !== -1
                ) {
                    $rootScope.has_ROLE_VENDOR = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_USER_ADMIN") !== -1
                ) {
                    $rootScope.has_ROLE_USER_ADMIN = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_HOD") !== -1
                ) {
                    $rootScope.has_ROLE_HOD = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_CITY_MANAGER") !== -1
                ) {
                    $rootScope.has_ROLE_CITY_MANAGER = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_CITY_PLANNER") !== -1
                ) {
                    $rootScope.has_ROLE_CITY_PLANNER = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_ASSISTANT_MANAGER") !==
                        -1
                ) {
                    $rootScope.has_ROLE_ASSISTANT_MANAGER = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_TEAM_LEADER") !== -1
                ) {
                    $rootScope.has_ROLE_TEAM_LEADER = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_NETWORK_ENGINEER") !== -1
                ) {
                    $rootScope.has_ROLE_NETWORK_ENGINEER = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_FRT") !== -1
                ) {
                    $rootScope.has_ROLE_FRT = true;
                    //$state.go('dashboard');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_PATROLLER") !== -1
                ) {
                    $rootScope.has_ROLE_PATROLLER = true;
                    //$state.go('location-log');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_USER") !== -1
                ) {
                    $rootScope.has_ROLE_USER = true;
                    //$state.go('location-log');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_VENDOR_ADMIN") !== -1
                ) {
                    $rootScope.has_ROLE_VENDOR_ADMIN = true;
                    //$state.go('location-log');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_PLAN_ADMIN") !== -1
                ) {
                    $rootScope.has_ROLE_PLAN_ADMIN = true;
                    //$state.go('location-log');
                }  if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_ROLLOUT_ADMIN") !== -1
                ) {
                    $rootScope.has_ROLE_ROLLOUT_ADMIN = true;
                    //$state.go('location-log');
                }

                if (
                    identity &&
                    identity.authorities &&
                    identity.authorities.indexOf("ROLE_ROLLOUT_FE") !== -1
                ) {
                    $rootScope.has_ROLE_ROLLOUT_FE = true;
                    //$state.go('location-log');
                }

                if (identity && identity.authorities && identity.authorities.indexOf('ROLE_ROLLOUT_MANAGER') !== -1) {
                    $rootScope.has_ROLE_ROLLOUT_MANAGER = true;
                    //$state.go('tr-project');
                }


                if (identity) {
                    $rootScope.userName = identity.login;
                    $rootScope.image = identity.userImage;
                }
            });
        };
        $rootScope.getLocalhour = function (hour) {
            var d = new Date();
            d.setUTCHours(hour);
            d.setUTCMinutes(30);
            return d.getHours();
        };

        $rootScope.getUTChour = function (hour) {
            var d = new Date();
            d.setHours(hour);
            d.setMinutes(0);
            return d.getUTCHours();
        };
        $rootScope.listAuthorities();

        $(document).ready(function () {
            $(window)
                .resize(function () {
                    var width = $(window).width();
                    if (width <= 770) {
                        $(".sidebar-menu>li > a").attr(
                            "data-toggle",
                            "offcanvas"
                        );
                    } else {
                        $(".sidebar-menu>li > a").attr("data-toggle", "");
                    }
                })
                .resize(); //trigger the resize event on page load.
        });
    }
})();
