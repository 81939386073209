(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomAssetTypeAttributeDetailController', CustomAssetTypeAttributeDetailController);

    CustomAssetTypeAttributeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomAssetTypeAttribute'];

    function CustomAssetTypeAttributeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomAssetTypeAttribute) {
        var vm = this;

        vm.customAssetTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:customAssetTypeAttributeUpdate', function(event, result) {
            vm.customAssetTypeAttribute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
