(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrMaterialDialogController", TrMaterialDialogController);

    TrMaterialDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrMaterial",
        "AllMaterialTypes",
        "AllCustomMaterialTypeAttribute",
        "MaterialAttributeMappingId"
    ];

    function TrMaterialDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrMaterial,
        AllMaterialTypes,
        AllCustomMaterialTypeAttribute,
        MaterialAttributeMappingId
    ) {
        var vm = this;
        vm.trMaterial = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trMaterial);
        vm.selectAttributes = selectedMaterialTypeAttributes;
        vm.materialType = entity.materialType;
        
		vm.customAttributesMap = {};
		vm.customAttributesMapp = [];
        loadPage();


        if (vm.trMaterial.id != null) {
            vm.trMaterialType = vm.trMaterial.materialType;
        }

        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                        img +
                        '">'
                );
                imgWindow.document.close();
            };

            var materialTypeAttributeValuesList =
                vm.trMaterial.materialTypeAttributeValues;

            try {
                for (var index in vm.trMaterial.materialType.materialTypeAttribute) {
                    if (
                        !checkIfMaterialTypeExists(
                            vm.trMaterial.materialType.materialTypeAttribute[index],
                            vm.trMaterial.materialTypeAttributeValues
                        )
                    ) {
                        var materialTypeAttribute1 =
                            vm.trMaterial.materialType.materialTypeAttribute[index];
                        materialTypeAttributeValuesList.push({
                            materialTypeAttribute: materialTypeAttribute1,
                            attributeValue:
                                vm.trMaterial.materialType.materialTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) {}

            function checkIfMaterialTypeExists(materialType, materialTypeAttrVal) {
                var hasMaterialType = false;
                for (var i = 0; i < materialTypeAttrVal.length; i++) {
                    var name = materialTypeAttrVal[i].materialTypeAttribute.name;
                    if (name == materialType.name) {
                        hasMaterialType = true;
                        break;
                    }
                }
                return hasMaterialType;
            }
            AllMaterialTypes.getAll(function (response) {
                vm.materialTypes = response;
            });

            AllCustomMaterialTypeAttribute.getAll(function (response) {
                //vm.customMaterialTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if(arrayItem.customMaterialTypeAttributeValues != undefined){
                        vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customMaterialTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }
                  
                });
                if (vm.trMaterial.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });
            
            
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedMaterialTypeAttributes() {
            vm.showFilter = true;
            if (vm.materialType) {
                vm.trMaterial.materialType = vm.materialType;
                if (
                    entity_old.materialType &&
                    vm.materialType.id === entity_old.materialType.id
                ) {
                    vm.trMaterial.materialTypeAttributeValues = [];
                    vm.trMaterial.materialTypeAttributeValues =
                        entity_old.materialTypeAttributeValues;
                } else {
                    vm.trMaterial.materialTypeAttributeValues = [];
                    $.each(
                        vm.materialType.materialTypeAttribute,
                        function (key, value) {
                            vm.trMaterial.materialTypeAttributeValues.push({
                                materialTypeAttribute:
                                    vm.materialType.materialTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            vm.isSaving = true;
            /* var spreadAV = vm.trMaterial.materialTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].materialTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trMaterial.materialTypeAttributeValues = spreadAV; */
                
            if (vm.trMaterial.id !== null) {
                TrMaterial.update(vm.trMaterial, onSaveSuccess, onSaveError);
            } else {
                TrMaterial.save(vm.trMaterial, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:materialUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $state.go("tr-material");
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $('.select2').select2();
    }
})();
