(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("CustomWorkOrderTypeAttribute", CustomWorkOrderTypeAttribute);

    CustomWorkOrderTypeAttribute.$inject = ["$resource"];

    function CustomWorkOrderTypeAttribute($resource) {
        var resourceUrl = "api/custom-workorder-type-attributes/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();
