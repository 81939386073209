(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('RowTypeDeleteController', RowTypeDeleteController);

    RowTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'RowType'];

    function RowTypeDeleteController($uibModalInstance, entity, RowType) {
        var vm = this;

        vm.rowType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            RowType.delete({ id: id },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
