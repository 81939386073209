(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkOrderType", WorkOrderType);

    WorkOrderType.$inject = ["$resource", "DateUtils"];

    function WorkOrderType($resource, DateUtils) {
        var resourceUrl = "api/workorder-types/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();
(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkOrderTypeSearch", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getworkOrdertypebyname = function (data, callback) {
                $http
                    .get("api/workorder-types-details/" + data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();
(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkOrderTypeSearch", WorkOrderTypeSearch);

    WorkOrderTypeSearch.$inject = ["$resource"];

    function WorkOrderTypeSearch($resource) {
        var service = $resource(
            "/api/workorder-types/searchvalue/:userId",
            { userId: "@id" },
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("AllCustomWorkOrderTypeAttribute", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAll = function (callback) {
                $http
                    .get("api/all-custom-workorder-type-attributes")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();
