(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomLogTypeAttributeDialogController', CustomLogTypeAttributeDialogController);

    CustomLogTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$state','$stateParams', 'entity', 'CustomLogTypeAttribute'];

    function CustomLogTypeAttributeDialogController ($timeout, $scope, $state ,$stateParams, entity, CustomLogTypeAttribute) {
        var vm = this;

        vm.customLogTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
       // vm.customlogtypeattributevalues = CustomLogTypeAttributeValue.query();
        
        vm.items = [ {
			value : ''
		} ];
        
        if (vm.customLogTypeAttribute.customLogTypeAttributeValues != null && vm.customLogTypeAttribute.customLogTypeAttributeValues.length != 0) {
			vm.items = vm.customLogTypeAttribute.customLogTypeAttributeValues;
		}
        vm.addmore = function() {
			vm.items.push({
				value : ''
			});
		};

		vm.remove = function(index) {
			vm.items.splice(index, 1);
		};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if  ( vm.customLogTypeAttribute.id !== null) {
            	
            	 vm.customLogTypeAttribute.customLogTypeAttributeValues = [];
				for (var i = 0; i < vm.items.length; i++) {
					 vm.customLogTypeAttribute.customLogTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
                CustomLogTypeAttribute.update(vm.customLogTypeAttribute, onSaveSuccess, onSaveError);
            } else {
            	
            	
            	 vm.customLogTypeAttribute.customLogTypeAttributeValues = [];

				for (var i = 0; i < vm.items.length; i++) {
					 vm.customLogTypeAttribute.customLogTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
				
                CustomLogTypeAttribute.save(vm.customLogTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customLogTypeAttributeUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('custom-log-type-attribute');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
