(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('VehicleManagementController', VehicleManagementController);

    VehicleManagementController.$inject = ['$scope', 'Principal', 'Vehicle', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'UserValueSearch', 'UsersStatusService', '$stateParams', 'VehicleValueSearch', 'UsersHierarchy', 'UserAccount', 'GeofenceIDs', 'UserGeofences'];

    function VehicleManagementController($scope, Principal, Vehicle, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, UserValueSearch, UsersStatusService, $stateParams, VehicleValueSearch, UsersHierarchy, UserAccount, GeofenceIDs, UserGeofences) {
        var vm = this;
        $scope.search = pagingParams.search;
        vm.authorities = ['ROLE_USER', 'ROLE_USER_ADMIN', 'ROLE_VENDOR_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.vehicles = [];
        vm.vehiclesList = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.filterSearch = filterSearch;
        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;

            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function (account) {
            vm.currentAccount = account;
        });


        if ($scope.search === null || $scope.search === "" || angular.isUndefined($scope.search)) {
            loadAll();
        } else {
            filterSearch();
        }

        function filterSearch() {

            if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {

                VehicleValueSearch.query({
                    search: $scope.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

            } else {
                loadAll();
            }
        }



        function loadAll() {

            if ($stateParams.status == undefined) {
                Vehicle.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
        }

        function onSuccess(data, headers) {
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                }
            }
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.vehicles = data;

        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear() {
            vm.vehicle = {
                "id": null,
                "status": null,
                "vehicleType": null,
                "licensePlate": null,
                "gpsimei": null,
                "createdDate": null,
                "lastModifiedBy": null,
                "lastModifiedDate": null
            };
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                // result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search,
                status: pagingParams.status
            });
        }
    }
})();
