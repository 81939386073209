(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrVendorDialogController", TrVendorDialogController);

    TrVendorDialogController.$inject = [
        "$timeout",
        "entity",
        "TrVendor",
        "$window",
        "AlertService",
    ];

    function TrVendorDialogController(
        $timeout,
        entity,
        TrVendor,
        $window,
        AlertService
    ) {
        var vm = this;
        vm.trVendor = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trVendor);
        loadPage();

        function loadPage() {
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function save() {
            if(vm.trVendor.id){
                TrVendor.update(vm.trVendor, onSaveSuccess, onSaveError)
            }else{
                TrVendor.save(vm.trVendor, onSaveSuccess, onSaveError)
            }
        }
        function onSaveSuccess(result) {
            $window.history.back();
        }

        function onSaveError() {
            AlertService.error(error.data.message);
        }
    }
})();
