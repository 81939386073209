(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "AssetGeofenceMappingController",
            AssetGeofenceMappingController
        );

    AssetGeofenceMappingController.$inject = [
        "$scope",
        "AllMiniGeofencesByMasterID",
        "AllTenant",
        "MasterGeofencesByTenantID",
        "AssetsGeofenceMapping"
    ];

    function AssetGeofenceMappingController(
        $scope,
        AllMiniGeofencesByMasterID,
        AllTenant,
        MasterGeofencesByTenantID,
        AssetsGeofenceMapping
    ) {
        var vm = this;

        vm.userMasterGeofences = [];
        vm.geofenceMini = [];
        vm.associateAssetsToGeofence = associateAssetsToGeofence;
        

        load();

        function load() {
            AllTenant.getAll(function (response) {
                vm.tenants = response;
            });
        }

        $scope.onTenantChange = function () {
            vm.userMasterGeofences = [];
            MasterGeofencesByTenantID.get(
                vm.selectedTenantId,
                function (response) {
                    vm.userMasterGeofences = response;
                }
            );
        };

        $scope.onMasterChange = function () {
            vm.showMessageError = false;
            vm.showMessage = false;
            vm.selectedGeofenceId = "";
            vm.geofenceMini = [];
            AllMiniGeofencesByMasterID.get(
                vm.selectedMasterGeofenceId,
                function (response) {
                    response.forEach(function (value) {
                        vm.geofenceMini.push(value);
                    });
                }
            );
        };

        function associateAssetsToGeofence() {
            AssetsGeofenceMapping.query(
                {
                    geofenceId: vm.selectedGeofenceId,
                },
                onSuccess,
                onError
            );
            function onSuccess(data, headers) {
                console.log(data);
                console.log(headers);
            }

            function onError(error) {
                console.log(error);
                AlertService.error(error.data.message);
            }
        }
    }
})();
