(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkOrderDetailController", WorkOrderDetailController);

    WorkOrderDetailController.$inject = [
        "$scope",
        "$rootScope",
        "$stateParams",
        "previousState",
        "entity",
        "WorkOrder",
        "User",
        "CaseType",
        "$window",
    ];

    function WorkOrderDetailController(
        $scope,
        $rootScope,
        $stateParams,
        previousState,
        entity,
        WorkOrder,
        User,
        CaseType,
        $window
    ) {
        var vm = this;

        if (entity.status) {
            entity.status = entity.status.replace("NEW", "PLANNING PHASE");
            entity.status = entity.status.replace(
                "INPROGRESS",
                "ROLL-OUT PHASE"
            );
            entity.status = entity.status.replace(
                "RESOLVED",
                "OPERATION PHASE"
            );
        }
        if (
            entity.createDate == "0" ||
            entity.createDate == undefined ||
            entity.createDate == null
        ) {
            entity.createDate = "";
        }
        vm.workOrder = entity;
        vm.previousState = previousState.name;
        var unsubscribe = $rootScope.$on(
            "trakeyeApp:workOrderUpdate",
            function (event, result) {
                vm.workOrder = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };
    }
})();
