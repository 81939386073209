(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrProjectController", TrProjectController);

    TrProjectController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "$compile",
        "TrProject",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
        "TrProjectSearch",
        "TrRouteListByProjectId",
        "routeDetail",
        "dailyLogByTaskId",
        "Inventory",
        "TrRouteForMap"
    ];

    function TrProjectController(
        $scope,
        $rootScope,
        $state,
        $compile,
        TrProject,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants, 
        TrProjectSearch,
        TrRouteListByProjectId,
        routeDetail,
        dailyLogByTaskId,
        Inventory,
        TrRouteForMap
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        if ($scope.tab == 1) {
            filterSearch();
        }

        function filterSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                var searchVal = $scope.search.replace(/[^\w\s]/g, '_');
                TrProjectSearch.query(
                    {
                        search: searchVal,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.projects = [];
                    data.forEach(function (value) {
                        if (value.zone) {
                            value.type = "MASTER";
                        } else {
                            value.type = "MINI";
                        }
                        vm.projects.push(value);
                    });
                    vm.page = pagingParams.page;
                }
    
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            TrProject.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.projects = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.projects.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var searchVal = "";
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ){
                searchVal = $scope.search.replace(/[^\w\s]/g, '_');
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: searchVal,
            });
        }


        vm.routeResponse = []
        vm.sectionResponse = []
        vm.sectionTaskResponse = []
        vm.terrainResponse = []
        vm.rowResponse = []
        vm.routeTaskResponse = []
        vm.toggle = []
        vm.expand = function (data, str) {
           
            if(str == "project" ){
                vm.projects.forEach(function (item) {
                    item.project = false;
                });

                if(vm.routeResponse.length){
                    vm.routeResponse.forEach(function (item) {
                        item.route = false;
                    });
    
                   
                }

                if(vm.sectionResponse.length){
                    vm.sectionResponse.forEach(function (item) {
                        item.section = false;
                    });
                }

                if(vm.sectionTaskResponse.length){
                    vm.sectionTaskResponse.forEach(function (item) {
                        item.sectionTask = false;
                    });
    
                   
                }

                if(vm.routeTaskResponse.length){
                    vm.routeTaskResponse.forEach(function (item) {
                        item.routeTask = false;
                    });
                }
                
            }

            if(str == "route"){
                vm.routeResponse.forEach(function (item) {
                    item.route = false;
                });
                if(vm.sectionResponse.length){
                    vm.sectionResponse.forEach(function (item) {
                        item.section = false;
                    });
                }
            }

            if(str == "section"){ 
                vm.sectionResponse.forEach(function (item) {
                    item.section = false;
                });
            }
            
           
            var iD = (data.id) ?  data.id : data.sectionId
            if (vm.toggle.includes(iD)) {
                vm.toggle = vm.toggle.filter(function(num){ return num !== iD} );
            } else {
                switch (str) {
                    case "project":
                    
                        vm.headers = null
                        TrRouteListByProjectId.get(data.id,function(data){
                            vm.headers = [
                                { key: "name", display: "Route Name" },
                               
                            ] 
                            vm.routeResponse = data
                        })
                        data.project = true;
                        vm.toggle.push(data.id);
                        break;

                        case "route":
                            vm.sectionheaders = null
                            routeDetail.getRoutesDetails(data.id,function(data){
                                vm.sectionheaders = [
                                    { key: "sectionName", display: "Section Name" },
                                    /* {  display: "Action" }, */
                                ]
                                vm.sectionResponse = data
                            })

                            routeDetail.getTask(data.id,function(data){
                                vm.routeTaskheaders = [
                                    { key: "name", display: "Task Name" },
                                    /* {  display: "Action" }, */
                                ]
                                vm.routeTaskResponse = data
                            })

                            TrRouteForMap.getTerrainByRoute(
                                data.id,
                                function (data) { 
                                    vm.terrainsheaders = [
                                        { key: "name", display: "Terrain Name" }
                                    ]
                                    vm.terrainResponse = data
                                })

                                TrRouteForMap.getRoWByRoute(
                                    data.id,
                                    function (data) { 
                                        vm.rowsheaders = [
                                            { key: "name", display: "Row Name" }
                                        ]
                                        vm.rowResponse = data
                                    })

                            data.route = true;
                            vm.toggle.push(data.id);

                            
                            break;


                            case "section":
                               vm.sectionTaskheaders = null
                               Inventory.taskBySection(data.sectionId,function(response){
                                vm.sectionTaskheaders = [ 
                                    { key: "name", display: "Task Name" },/* 
                                    { key: "userLogin", display: "Assigned To" } */
                                ]
                                vm.sectionTaskResponse = response
                            })
                               data.section = true;
                               vm.toggle.push(data.sectionId);
                               break;


                               case "sectionTask":
                                
                            if(vm.sectionTaskResponse.length){
                                vm.sectionTaskResponse.forEach(function (item) {
                                    item.sectionTask = false;
                                });
                               } 

                              /*  vm.logsheaders = null
                               
                                dailyLogByTaskId.query(
                                    {
                                        search: $scope.search,
                                        page: 0,
                                        size: 10, //vm.itemsPerPage
                                        sort: sort(),
                                        id:data.id
                                    },
                                    function (response,headers) {
                                        vm.links = ParseLinks.parse(headers("Link"));
                                        vm.totalItems = headers("X-Total-Count");
                                        vm.queryCount = vm.totalItems;
                                        vm.page = pagingParams.page;
                                        
                                        vm.logsheaders = [ 
                                            { key: "taskLogType", display: "Log Type" },
                                             
                                            { key: "userLogin", display: "Assigned To" } 
                                        ]

                                        vm.logsResponse = response
                                    }
                                 ); */
                           
                               data.sectionTask = true;
                               vm.toggle.push(data.id);
                               break;



                               case "routeTask":
                                
                            if(vm.routeTaskResponse.length){
                                vm.routeTaskResponse.forEach(function (item) {
                                    item.routeTask = false;
                                });
                               } 

                               //vm.logsheaders = null
                               
                               /*  dailyLogByTaskId.query(
                                    {
                                        search: $scope.search,
                                        page: pagingParams.page - 1,
                                        size: 10, //vm.itemsPerPage
                                        sort: sort(),
                                        id:data.id
                                    },
                                    function (response,headers) {
                                        vm.links = ParseLinks.parse(headers("Link"));
                                        vm.totalItems = headers("X-Total-Count");
                                        vm.queryCount = vm.totalItems;
                                        vm.page = pagingParams.page;
                                        
                                        vm.logsheaders = [ 
                                            { key: "taskLogType", display: "Log Type" }
                                        ]

                                        vm.logsrResponse = response
                                    }
                                 ); */
                           
                               data.routeTask = true;
                               vm.toggle.push(data.id);
                               break;

                    

                    default: console.log("default")
                }
            }

        }
    }
})();
