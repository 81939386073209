(function () {
	'use strict';

	angular.module('trakeyeApp').controller('GeofenceDumpController', GeofenceDumpController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	GeofenceDumpController.$inject = ['$scope', '$sce', '$state', 'AllGeofenceIDs', 'Report'];

	function GeofenceDumpController($scope, $sce, $state, AllGeofenceIDs, Report) {
		var vm = this;
		vm.downloadAttendenceReport = downloadAttendenceReport;
		vm.downloadAttendenceReportKml = downloadAttendenceReportKml;
		vm.select2 = select2;
		vm.select2();
		vm.geofences = [];
		vm.selectedGeofenceId = 0;
		vm.users = loadPage();

		function loadPage() {
			AllGeofenceIDs.getAll(function (response) {
                response.forEach(function (geofence) {
                    vm.geofences.push({ name: geofence.name, id: geofence.id, nameZone: geofence.name + " - " + geofence.geofenceType});
                });
            });
		}

		function downloadAttendenceReport() {
			Report.downloadGeofenceDump({
				id: vm.selectedGeofenceId
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/csv' });
				var fileURL = URL.createObjectURL(file);
				a.href = fileURL;
				a.download = "geofence.csv";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

		function downloadAttendenceReportKml() {
			Report.downloadGeofenceDumpKml({
				id: vm.selectedGeofenceId
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/text' });
				var fileURL = URL.createObjectURL(file);
				a.href = fileURL;
				a.download = "geofence.kml";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

		function select2() {
			$(".select2").select2();
		}

	}
})();
