(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrWorkOrderDetailController', TrWorkOrderDetailController);

    TrWorkOrderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrWorkOrder', 'User', 'TrWorkOrderType', 'TrWorkOrderImages', 'TrWorkOrderRecordings','AlertService','$window','$localStorage','Configs'];

    function TrWorkOrderDetailController($scope, $rootScope, $stateParams, previousState, entity, TrWorkOrder, User, TrWorkOrderType,TrWorkOrderImages,TrWorkOrderRecordings,AlertService, $window,$localStorage,Configs) {
		var vm = this;
		
        vm.trWorkOrder = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        var unsubscribe = $rootScope.$on('trakeyeApp:trWorkOrderUpdate', function(event, result) {
            vm.trWorkOrder = result;
        });
		vm.showTrWorkOrderAttachment = false

		Configs.get("TrWorkOrderImage",function(response){
            if(response.length > 0){
				if(response[0].configValue == "True"){
					vm.showTrWorkOrderAttachment = true
				}
			}
        })
		
        vm.imageInNewTab = function (img) {
		    var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
            );
            imgWindow.document.close(); 
		};

		$scope.newTabDisplay = function(id){
            var request = new XMLHttpRequest();
            request.open('GET',"api/trakeyetrWorkOrderimages/"+vm.trWorkOrder.id+"/"+id, true);
            request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;
            
            function response(e) {
                if (this.status === 200) {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                window.open(imageUrl, "_blank")}
            } 
            request.send();
        } 

        $scope.$on('$destroy', unsubscribe);
        
          $scope.go_back = function() { 
        	  $window.history.back();
        	};
        
        $scope.allimages = false;
//		$scope

		$scope.toggle = function(id) {
			switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrWorkOrderImages.query(
                            {
                                trWorkOrderId: vm.trWorkOrder.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.trWorkOrderImages = data.filter(function(trWorkOrderImages){
                                return trWorkOrderImages.image
                             });
                        
                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;

                    break;

                default:
                    console.log("");
            }


			
			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/trWorkOrder_"+vm.trWorkOrder.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrWorkOrderImages.query({
					trWorkOrderId : vm.trWorkOrder.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.trWorkOrderImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

//		$scope.playAudio = function() {
////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/trWorkOrder_"+vm.trWorkOrder.id+".mp3";
//            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/trWorkOrder_15570572.mp3";
//	        var audio = new Audio(urlStr);
//	        audio.play();
//	    };
		
		
        function decodeImage(img){
     	   return window.atob(img);
        }
       
    }
})();
