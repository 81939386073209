(function () {
	'use strict';

	angular.module('trakeyeApp').factory('EntityImport', ['$http', function ($http) {

		var service = {};
		service.get = function (data,callback) {
			$http.post('api/assetsimportrequests',data).then(function (response) {
				callback(response.data);
			}); 
		};
		return service;
	}]);
})(); 

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('EntityImportList', ['$http', function ($http) {

		var service = {};
		service.get = function (callback) {
			$http.get('api/assetsimportrequests').then(function (response) {
				callback(response.data);
			}); 
		};
		return service;
	}]);
})(); 

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AssetLinkList', ['$http', function ($http) {

		var service = {};
		service.get = function (callback) {
			$http.get('api/link-assets').then(function (response) {
				callback(response.data);
			}); 
		};

		service.post = function (data,callback) {
			$http.post('api/link-assets',data).then(function (response) {
				callback(response);
			}); 
		};
		return service;
	}]);
})(); 

