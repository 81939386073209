(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrRowController", TrRowController);

    TrRowController.$inject = [
        "$scope",
        "$state",
        "TrRow",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
        "TrRowSearch"
    ];

    function TrRowController(
        $scope,
        $state,
        TrRow,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants,
        TrRowSearch
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        vm.createRow = createRow;
        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        if ($scope.tab == 1) {
            filterSearch();
        }

        function filterSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                var searchVal = $scope.search.replace(/[^\w\s]/g, '_');
                TrRowSearch.query(
                    {
                        search: searchVal,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.rows = data;
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function createRow() {
            $state.go("tr-row.new");
        }

        function loadAll() {
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }
            TrRow.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.rows = data;
                    vm.page = pagingParams.page;
                },
                onError
            );
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
        function sort() {
            var result = [
                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
            ];
            if (vm.predicate !== "id") {
                //  result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var searchVal = "";
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ){
                searchVal = $scope.search.replace(/[^\w\s]/g, '_');
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: searchVal
            });
        }
    }
})();
