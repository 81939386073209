(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrProject', TrProject);

	TrProject.$inject = ['$resource', 'DateUtils'];

	function TrProject($resource, DateUtils) {
		var resourceUrl = 'api/tr-projects/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ProjectByID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/tr-projects/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGeofence', TrProjectGeofence);

	TrProjectGeofence.$inject = ['$resource'];

	function TrProjectGeofence($resource) {
		var service = $resource('/api/tr-projects/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectCustomTime', TrProjectCustomTime);

	TrProjectCustomTime.$inject = ['$resource'];

	function TrProjectCustomTime($resource) {
		var service = $resource('/api/tr-projects-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGeofenceCustomTime', TrProjectGeofenceCustomTime);

	TrProjectGeofenceCustomTime.$inject = ['$resource'];

	function TrProjectGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-projects-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrProjectCheckAsset', TrProjectCheckAsset);

	TrProjectCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrProjectCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
    'use strict';
    angular.module('trakeyeApp').factory('ProjectsForAsset', ProjectsForAsset);

    ProjectsForAsset.$inject = ['$resource'];

    function ProjectsForAsset($resource) {
        var resourceUrl = 'api/projects-for-asset/:assetId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-projects/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getProjectImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyeprojectimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectListIdName', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (callback) {
			$http.get('api/tr-projects-list').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id,data,callback) {
			$http.post('api/assets/nearby-fixed/' + id,data).then(function (response) {
				callback(response.data);
			}); 
		};
		return service;
	}]);
})(); 

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-projects-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ProjectAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-project-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectById', TrProjectById);

	TrProjectById.$inject = [ '$resource' ];

	function TrProjectById($resource) {
		var service = $resource('/api/tr-projects/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/projectcountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadProjectReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-projects').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/projectcountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectPriority', TrProjectPriority);

	TrProjectPriority.$inject = ['$resource'];

	function TrProjectPriority($resource) {
		var service = $resource('/api/tr-projects/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, {geofenceId : '@geofenceId'},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectAttendance', TrProjectAttendance);

	TrProjectAttendance.$inject = ['$resource'];

	function TrProjectAttendance($resource) {
		var service = $resource('/api/tr-projects/attendance/:login/:fromTime/:toTime/:projectStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectSearch', TrProjectSearch);

	TrProjectSearch.$inject = ['$resource'];

	function TrProjectSearch($resource) {
		var service = $resource('/api/tr-projects/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectSearchCustom', TrProjectSearchCustom);

	TrProjectSearchCustom.$inject = ['$resource'];

	function TrProjectSearchCustom($resource) {
		var service = $resource('/api/tr-projects-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGeofenceSearch', TrProjectGeofenceSearch);

	TrProjectGeofenceSearch.$inject = ['$resource'];

	function TrProjectGeofenceSearch($resource) {
		var service = $resource('/api/tr-projects/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGeofenceSearchCustom', TrProjectGeofenceSearchCustom);

	TrProjectGeofenceSearchCustom.$inject = ['$resource'];

	function TrProjectGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-projects-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrProjectGroup', TrProjectGroup);

	TrProjectGroup.$inject = ['$resource', 'DateUtils'];

	function TrProjectGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-projects-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGroupSearch', TrProjectGroupSearch);

	TrProjectGroupSearch.$inject = ['$resource'];

	function TrProjectGroupSearch($resource) {
		var service = $resource('/api/tr-projects-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGroupPrioritySearch', TrProjectGroupPrioritySearch);

	TrProjectGroupPrioritySearch.$inject = ['$resource'];

	function TrProjectGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-projects-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectGroupPriority', TrProjectGroupPriority);

	TrProjectGroupPriority.$inject = ['$resource'];

	function TrProjectGroupPriority($resource) {
		var service = $resource('/api/tr-projects-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectImages', TrProjectImages);

	TrProjectImages.$inject = ['$resource'];

	function TrProjectImages($resource) {
		var service = $resource('/api/project-images/:projectId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectsForType', TrProjectsForType);

	TrProjectsForType.$inject = ['$resource'];

	function TrProjectsForType($resource) {
		var service = $resource('/api/trprojectsbytype/:projectTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
 (function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data,success,error) {
			$http.post('api/tr-projects/nearby-bgoh-assets',data).then(function (response) {
				success(response.data);
			},function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();  

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectRecordings', TrProjectRecordings);

	TrProjectRecordings.$inject = ['$resource'];

	function TrProjectRecordings($resource) {
		var service = $resource('/api/project-recordings/:projectId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllProjectTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/project-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectPrioritySearch', TrProjectPrioritySearch);

	TrProjectPrioritySearch.$inject = ['$resource'];

	function TrProjectPrioritySearch($resource) {
		var service = $resource('/api/tr-projects/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrProjectUpdateGroup', TrProjectUpdateGroup);

	TrProjectUpdateGroup.$inject = ['$resource'];

	function TrProjectUpdateGroup($resource) {
		var resourceUrl = 'api/tr-projects/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrProjectBulkDelete', TrProjectBulkDelete);

	TrProjectBulkDelete.$inject = ['$resource'];

	function TrProjectBulkDelete($resource) {
		var resourceUrl = 'api/tr-projects/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrprojectsuser = function (callback) {
			$http.get('api/tr-projects/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrprojectsuserbyid = function (id, callback) {
			$http.get('api/tr-projects/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrprojectsuserbyvalue = function (id, callback) {
			$http.get('api/tr-projects/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrprojectsanduserbypriority = function (priority, callback) {
			$http.get('api/tr-projects/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrprojects = function (callback) {
			$http.get('api/tr-allprojects').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrproject = function (data, callback) {
			$http.post('api/tr-projectedit/' + data.userId + '/' + data.projectId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-projectedit/'+data.userId+'/'+data.projectId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrProjectUpload', TrProjectUpload);

	TrProjectUpload.$inject = ['$http'];

	function TrProjectUpload($http) {

		var service = {};
		service.trprojectupload = function (data, callback) {

			$http.post('api/projectimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupProjectSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrprojectformap = function (search, callback) {
			$http.get('api/tr-groupprojectsformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupProjectForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllProjects = function (callback) {
			$http.get('api/tr-groupprojectsformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrprojectformap = function (search, callback) {
			$http.get('api/tr-projectsformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrprojectformap = function (priority, callback) {
			$http.get('api/tr-projectsformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrProjectLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ProjectsForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.projectsformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-projectsformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrProjectForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllProjects = function (data, callback) {
			// $http.get('api/tr-projectsformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-projectsformap', data).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ShowProjectById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (projectid, callback) {
            $http.put('api/show-project/' + projectid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('HideProjectById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (projectid, callback) {
            $http.put('api/hide-project/' + projectid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('SummaryPost', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};

		
		service.resTx = function (payload, callback) {
			$http.post('api/rollout/res-tx-summary',payload).then(function (response) {
				 	callback(response.data);
				},  function (error){
					callback(error)
				})
		};

		service.mtrlTx = function (payload, callback) {
			$http.post('api/rollout/mtrl-tx-summary',payload).then(function (response) {
				 	callback(response.data);
				}, function (error){
					callback(error)
				})
		};

		service.dailyLogsTx = function (payload, callback) {
			$http.post('api/rollout/daily-task-logs-summary',payload).then(function (response) {
				 	callback(response.data);
				})
		};


		return service;
	}]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('taskConfiguration', ['$http', function ($http) {

        var service = {};
        service.postTerrainConfiguration = function (payload, callback) {
            $http.post('api/terrain-task-map' ,payload)
			.success(function (response) {
				callback(response.data);
            })
            .error(function (err) {
				callback(err);
            });
        };

		service.getTerrainConfiguration = function (projectId, callback) {
			$http.get('api/terrain-task-map/' + projectId).then(function (response) {
				callback(response.data);
			});
        };

		service.postRoWConfiguration = function (payload, callback) {
            $http.post('api/row-task-map' ,payload)
			.success(function (response) {
				callback(response.data);
            })
            .error(function (err) {
				callback(err);
            });
        };

		service.getRoWConfiguration = function (projectId, callback) {
			$http.get('api/row-task-map/' + projectId).then(function (response) {
				callback(response.data);
			});
        };

        return service;
    }]);
})();