(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "CustomWorkOrderTypeAttributeDeleteController",
            CustomWorkOrderTypeAttributeDeleteController
        );

    CustomWorkOrderTypeAttributeDeleteController.$inject = [
        "$uibModalInstance",
        "entity",
        "CustomWorkOrderTypeAttribute",
    ];

    function CustomWorkOrderTypeAttributeDeleteController(
        $uibModalInstance,
        entity,
        CustomWorkOrderTypeAttribute
    ) {
        var vm = this;

        vm.customWorkOrderTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmDelete(id) {
            CustomWorkOrderTypeAttribute.delete({ id: id }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
