(function () {
	'use strict';

	angular.module('trakeyeApp').controller('UserGeofenceReportController', UserGeofenceReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	UserGeofenceReportController.$inject = ['$scope', '$sce', '$state', 'Report', 'UserGeofences'];

	function UserGeofenceReportController($scope, $sce, $state, Report, UserGeofences) {
		var vm = this;
		vm.select2 = select2;
		vm.select2();
		vm.downloadUserGeofenceReport = downloadUserGeofenceReport;
		vm.userGeofences = [];
		vm.users = loadPage();
		vm.userAgent ="username";

		function loadPage() {
			Report.getUsers('', function (response) {
				vm.users = response;
				$(".select2").select2();
			})
			UserGeofences.getAll(function (response) {
				response.forEach(function(geofence){
					if(geofence.geofenceType == "MINI"){
						vm.userGeofences.push(geofence);
					}
				});
				$(".select2").select2();
			});
		}

		function downloadUserGeofenceReport() {
				if(vm.userAgent == 'username'){
					vm.id=vm.userid;
				} else {
					vm.id=vm.geofenceid;
				}
				Report.downloadUserGeofenceReport({
					id: vm.id,
					reportType: vm.userAgent
				}, function (response) {
					var a = document.createElement("a");
					a.style = "display: none";
					var file = new Blob([(response)], { type: 'application/csv' });
					var fileURL = URL.createObjectURL(file);
					// window.open(fileURL, "_blank");
					a.href = fileURL;
					a.download = "user_geofences_report.csv";
					a.click();
					window.URL.revokeObjectURL(fileURL);
				});
			
		}
		
		function select2() {
			$(".select2").select2();
		}
	}
})();
