(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrTaskController", TrTaskController);

    TrTaskController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "$compile",
        "TrTask",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
        "TrProject",
        "filterTask",
        "TrRouteListByProjectId",
        "TrSectionListByRouteId",
        "dailyLogByTaskId",
        "TrTaskSearch",
        "TrUserTask",
        "ActivatedUserSearch",
        "FixedTaskLogExcelUpload"
    ];

    function TrTaskController(
        $scope,
        $rootScope,
        $state,
        $compile,
        TrTask,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants,
        TrProject,
        filterTask,
        TrRouteListByProjectId,
        TrSectionListByRouteId,
        dailyLogByTaskId,
        TrTaskSearch,
        TrUserTask,
        ActivatedUserSearch,
        FixedTaskLogExcelUpload
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        $scope.tab = 1;
        var listRouteId = []
        var listSectionId = []
        vm.sections = []
        vm.selectedProjectId = parseInt(pagingParams.projectId);
        vm.expandResponse = []

        vm.filterActivatedUser = filterActivatedUser;
        //vm.selectedRouteId = pagingParams.routeId;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        if ($scope.tab == 1) {
            filterSearch();
        }

        function filterSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                TrTaskSearch.query(
                    {
                        search: $scope.search,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.tasks = [];
                    vm.tasks = data
                    vm.headers = [
                        { key: "name", display: "Name" },
                        { key: "routeName", display: "Route" },
                        { key: "sectionName", display: "Section" },
                        { key: "taskLength", display: "Task Length (mtrs)" },
                        { key: "taskTypeName", display: "Task Type" },
                        { key: "status", display: "Status" }, { display: "Task Log " }, { display: "Created Date" }, { display: "Actions" }
                    ]
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }

                TrProject.query(
                    {
                        page: 0,
                        size: 500,
                        sort: sort(),
                    },

                    function onSuccess(data, headers) {
                        vm.projects = [];
                        vm.projects = data

                        if (vm.selectedProjectId) {
                            vm.projects.forEach(function (item) {
                                if (item.id == vm.selectedProjectId) {
                                    item.ticked = true;
                                } else {
                                    item.ticked = false;
                                }
                            });
                            vm.clickProject(vm.selectedProjectId)
                        }

                        //vm.page = pagingParams.page;
                    },
                    onError
                );
            } else {
                loadAll();
            }
        }

        function sort() {
            var result = [
                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
            ];
            if (vm.predicate !== "id") {
                //  result.push('id');
            }
            return result;
        }

        function loadAll() {

            TrTask.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.tasks = [];
                    vm.headers = [
                        { key: "name", display: "Name" },
                        { key: "routeName", display: "Route" },
                        { key: "sectionName", display: "Section" },
                        { key: "taskLength", display: "Task Length (mtrs)" },
                        { key: "taskTypeName", display: "Task Type" },
                        { key: "status", display: "Status" }, { display: "Task Log " }, { display: "Created Date" }, { display: "Actions" }
                    ]
                    vm.tasks = data
                    vm.page = pagingParams.page;
                },
                onError

            );



            TrProject.query(
                {
                    page: 0,
                    size: 500,
                    sort: sort(),
                },

                function onSuccess(data, headers) {
                    vm.projects = [];
                    vm.projects = data

                    if (vm.selectedProjectId) {
                        vm.projects.forEach(function (item) {
                            if (item.id == vm.selectedProjectId) {
                                item.ticked = true;
                            } else {
                                item.ticked = false;
                            }
                        });
                        vm.clickProject(vm.selectedProjectId)
                    }

                    //vm.page = pagingParams.page;
                },
                onError
            );

        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                projectId: (vm.selectedProject.length) ? vm.selectedProject[0].id : null,
                routeId: (listRouteId.length) ? listRouteId : null,
            });
        }

        $scope.type = {
            project: {
                search: "Search",
                nothingSelected: "Project",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            },
            route: {
                search: "Search",
                nothingSelected: "Route",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            },
            section: {
                search: "Search",
                nothingSelected: "Section",
                selectAll: "All",
                selectNone: "None",
                Done: "Done",
            }
        };

        vm.clickProject = function (id) {
            //vm.tasks = []
            if (vm.selectedProjectId) {
                TrRouteListByProjectId.get(id, function (response) {
                    vm.routes = response;

                    /* if(vm.selectedRouteId.length){
                        for (var i = 0; i < vm.routes.length; i++) {
                            var item1 = vm.routes[i];
                          
                            // Iterate through arr2
                            for (var j = 0; j < vm.selectedRouteId.length; j++) {
                              var item2 = vm.selectedRouteId[j];
                          
                              // Check if IDs match
                              if (item1.id === item2.id) {
                                // Match found, enable the tick variable
                                item1.tick = true;
                                break; // Break out of the inner loop since we found a match
                              }
                            }
                        }
                    } */
                });
            } else {
                TrRouteListByProjectId.get(vm.selectedProject[0].id, function (response) {
                    vm.routes = response;
                });
            }
            vm.sections.length = 0

        };


        vm.clickRoute = function () {
            callRouteSelect()
        }

        function callRouteSelect() {
            listRouteId = []
            if (vm.selectedRoute.length && vm.selectedProject.length) {
                vm.selectedRoute.forEach(function (item) {
                    listRouteId.push(item.id)
                })

                vm.sections.length = 0
                vm.selectedRoute.forEach(function (each) {
                    TrSectionListByRouteId.get(each.id, function (response) {
                        response.forEach(function (item) {
                            vm.sections.push({ name: item.name, id: item.id })
                        })

                    });
                })

                filterTask.routeFilter(pagingParams.page - 1,
                    vm.itemsPerPage, {
                    "routeIds": listRouteId,
                    "projectIds": [vm.selectedProject[0].id]
                }, function (data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.tasks = [];
                    vm.page = pagingParams.page;
                    vm.tasks = data
                })
            }
        }
        vm.clickSection = function () {
            listSectionId = []

            if (vm.selectedSection.length) {
                vm.selectedSection.forEach(function (item) {
                    listSectionId.push(item.id)
                })
            } else {
                callRouteSelect()
            }

            if (vm.selectedRoute.length && vm.selectedSection.length) {
                filterTask.sectionFilter({
                    "sectionIds": listSectionId,
                    "routeIds": listRouteId,
                    "projectIds": [vm.selectedProject[0].id]
                }, function (data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.tasks = [];
                    vm.page = pagingParams.page;
                    vm.tasks = data
                })
            }

        }

        vm.expand = function (task, id) {
            vm.tasks.forEach(function (item) {
                item.expand = false;
            });

            if (vm.toggle == task.id) {
                vm.toggle = undefined;
            } else {
                task.expand = true;
                vm.toggle = task.id;

                dailyLogByTaskId.query({
                    search: $scope.search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    id: id
                }, function (response) {
                    vm.expandResponse = response
                })
            }

        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }

        vm.taskUserAssign = function (task) {
            vm.trTask = task
            $("#markerUploadModal").show();
        };

        vm.closeAssetMarkerModal = function () {
            $("#markerUploadModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#markerUploadModal").hide();
        };

        function filterActivatedUser() {
            if (vm.trTask.userLogin) {
                ActivatedUserSearch.query({
                    userId: vm.trTask.userLogin,
                }, onSuccess, onError);
            }


            function onSuccess(data, headers) {
                vm.trTask.userId = null
                vm.activatedusers = [];
                data.forEach(function (dataVal) {
                    vm.activatedusers.push(dataVal);
                });
                $scope.showResults = true;
                /* 
                                    angular.forEach(vm.activatedusers, function (value, key) {
                                        usersMap[value.login] = value.id;
                                    }); */
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }


        $scope.assignUser = function (user, id) {
            // Implement the logic to assign the selected user
            vm.trTask.userLogin = user
            vm.trTask.userId = id
            $scope.showResults = false;
            vm.assignToModified = true
        };

        vm.saveAssignTo = function () {
            var data = {
                "id": vm.trTask.userTaskId,
                "task": {
                    "id": vm.trTask.id

                },
                "userId": vm.trTask.userId,
                "tenantId": vm.trTask.tenantId,
                "project": {
                    "id": vm.trTask.projectId

                },
                "route": {
                    "id": vm.trTask.routeId

                },
                "section": {
                    "id": vm.trTask.sectionId

                }
            }
            TrUserTask.update(data, function success() {
                TrTask.query(
                    {
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    function onSuccess(data, headers) {
                        vm.links = ParseLinks.parse(headers("link"));
                        vm.totalItems = headers("X-Total-Count");
                        vm.queryCount = vm.totalItems;
                        vm.tasks = data
                        vm.headers = [
                            { key: "name", display: "Name" },
                            { key: "routeName", display: "Route" },
                            { key: "sectionName", display: "Section" },
                            { key: "taskLength", display: "Task Length (mtrs)" },
                            { key: "taskTypeName", display: "Task Type" },
                            { key: "status", display: "Status" }, { display: "Task Log " }, { display: "Created Date" }, { display: "Actions" }
                        ]
                        vm.page = pagingParams.page;
                    },
                    onError

                );
                vm.closeAssetMarkerModal()
            }, onError)

        }

        vm.openLogs = function () {
            
            $(":file").filestyle({
                buttonBefore: true,
                buttonText: "Browse File",
            });

            vm.importxl = /^(.*?)\.(xlsx)$/;
            $("#importTaskLogs").show();
            $(":file").filestyle("clear");
        };

        vm.closeModal = function (id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        };

        var getFile, formData;
        vm.uploadTaskLogs = function () {
            getFile = document.getElementById("uploadfileTaskLog").files[0];
            formData = new FormData();
            formData.append("file", getFile);

            FixedTaskLogExcelUpload.logupload(formData, function (response) {
                vm.success = response.status == 200 ? "success" : "";
                if (vm.success) {
                    setTimeout(function () {
                        vm.success = "";
                        vm.closeModal("importTaskLogs");
                        vm.isSaving = false;
                    }, 500);
                }

                if (response.data.length) {
                    console.log(response.data);
                }
            });
        };

    }
})();
