(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrSectionController", TrSectionController);

    TrSectionController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "$compile",
        "TrSection",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
        "Inventory",
        "TrSectionSearch"
    ];

    function TrSectionController(
        $scope,
        $rootScope,
        $state,
        $compile,
        TrSection,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants,
        Inventory,
        TrSectionSearch
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        $scope.tab = 1;
        vm.expandResponse = []

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        if ($scope.tab == 1) {
            filterSearch();
        }

        function filterSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                var searchVal = $scope.search.replace(/[^\w\s]/g, '_');
                TrSectionSearch.query(
                    {
                        search: searchVal,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.sections = [];
                    data.forEach(function (value) {
                        if (value.zone) {
                            value.type = "MASTER";
                        } else {
                            value.type = "MINI";
                        }
                        vm.sections.push(value);
                    });
                    vm.page = pagingParams.page;
                }
    
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            TrSection.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.sections = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.sections.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var searchVal = "";
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ){
                searchVal = $scope.search.replace(/[^\w\s]/g, '_');
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: searchVal,
            });
        }


        vm.expand = function (section, str) {
            vm.sections.forEach(function (item) {
                item.material = false;
                item.tasks = false;
                item.resource = false;
            });

            if (vm.toggle == str) {
                vm.toggle = undefined;
                
            } else {
                switch (str) {
                    case "material":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.materialTxn(section.id, function (response) {
                            vm.headers = [
                                { key: "materialName", display: "Material Name" },
                                { key: "unitOfMeasurement", display: "Units of Measurement" },
                                { key: "noOfUnits", display: "Quantity" },
                                { key: "transactionType", display: "Transaction Type" },
                                { key: "transactionDate", display: "Transaction Date" },
                            ]
                            vm.expandResponse = response
                        })
                        section.material = true;
                        vm.toggle = str;
                        break;

                    case "resource":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.resourceTxn(section.id, function (response) {
                            vm.headers = [
                                { key: "resourceName", display: "Resource Name" },
                                { key: "noOfResources", display: "No of Resource" },
                                { key: "numberOfHours", display: "No of Work Hours" },
                                { key: "transactionType", display: "Transaction Type" },
                                { key: "transactionDate", display: "Transaction Date" },
                            ]
                            vm.expandResponse = response
                        })
                        section.resource = true;
                        vm.toggle = str;
                        break;

                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.taskBySection(section.id, function (response) {
                            vm.headers = [
                                { key: "name", display: "Name" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = str;
                        break;

                    default: console.log("default")
                }
            }

        }
    }
})();
