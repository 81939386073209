(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('AttributeMappingDialogController', AttributeMappingDialogController);

        AttributeMappingDialogController.$inject = ['$timeout', '$scope', '$state','$stateParams', 'entity', 'CustomCaseTypeAttribute','CustomCaseTypeAttributeList','pagingParams','Mapping'];

    function AttributeMappingDialogController ($timeout, $scope, $state ,$stateParams, entity, CustomCaseTypeAttribute,CustomCaseTypeAttributeList,pagingParams,Mapping) {
        var vm = this;

        vm.customCaseTypeAttribute = entity;
        vm.predicate = pagingParams.predicate;
        vm.clear = clear;
        vm.save = save;
        vm.searchMini=""

        loadAll();

        function loadAll() {
            CustomCaseTypeAttributeList.query(
                {
                    page: pagingParams.page - 1,
                    size: 100,
                    sort: "id,asc",
                },
                onSuccess,
                onError
            );
            function onSuccess(data, headers) {
                vm.category = []
                data.forEach(function(item, index, object){
                    if(item.name == "STRING" || item.name == "INTEGER" ||  item.name == "FLOAT"){
                        
                    }else{
                        vm.category.push(item)
                    }
                })
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        $scope.funcCategoryLeft=function(){
            vm.subCategoryLeft = []
            vm.selectedCategoryLeft[0].customCaseTypeAttributeValues.forEach(function(val){
                vm.subCategoryLeft.push(val)
            })
            vm.category.forEach(function(val){
                val.disabledRight = false;
                if(val.id == vm.selectedCategoryLeft[0].id){
                    val.disabledRight = true
                }
            })
        }
        $scope.funcCategoryRight=function(){
            vm.subCategoryRight = []
            vm.selectedCategoryRight[0].customCaseTypeAttributeValues.forEach(function(val){
                vm.subCategoryRight.push(val)
            })
            vm.category.forEach(function(val){
                val.disabledLeft = false;
                if(val.id == vm.selectedCategoryRight[0].id){
                    val.disabledLeft = true
                }
            })
        }

        function save () {
            var selectedParentCaseTypeCategory = []
            selectedParentCaseTypeCategory.push({id : vm.selectedCategoryLeft[0].id , value: vm.selectedCategoryLeft[0].name} )
            
            var selectedParentSubCaseTypeCategory = []
            selectedParentSubCaseTypeCategory.push({id : vm.selectedCategoryRight[0].id , value: vm.selectedCategoryRight[0].name} )
            
            var sendMapping = []

            for(var i=0;i<vm.selectedSubCategoryRight.length;i++){
                sendMapping.push({
                    caseTypeCategory : vm.selectedSubCategoryLeft[0],
                    caseTypeSubCategory : vm.selectedSubCategoryRight[i],
                    parentCaseTypeCategory : selectedParentCaseTypeCategory[0],
                    parentCaseTypeSubCategory : selectedParentSubCaseTypeCategory[0],
                    description : ""
                })
            }
            Mapping.postMapping(sendMapping,function (response){
                onSaveSuccess()
            }) 
        }

        function onSaveSuccess () {
            //vm.isSaving = false;
            $state.go('attribute-mapping');
        }

        /* function onSaveError () {
            vm.isSaving = false;
        } */


    }
})();
