(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('ResourceTypeDetailController', ResourceTypeDetailController);

    ResourceTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ResourceType', 'User'];

    function ResourceTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, ResourceType, User) {
        var vm = this;

        vm.resourceType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:resourceTypeUpdate', function(event, result) {
            vm.resourceType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
