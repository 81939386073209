(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('ElasticsearchReindexController', ElasticsearchReindexController);

    ElasticsearchReindexController.$inject = [];

    function ElasticsearchReindexController() {
    }
})();
