(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("ImportController", ImportController);

        ImportController.$inject = ["EntityImportList"];
    function ImportController(EntityImportList) {

        var vm = this;

        EntityImportList.get(function (response){
            vm.displayList = response
        })

        vm.refresh = function(){
            EntityImportList.get(function (response){
                vm.displayList = response
            })
        }
    }
})();
