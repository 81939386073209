(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkOrderImportController", WorkOrderImportController)
        .directive("fileread", [
            function () {
                "use strict";

                return {
                    restrict: "A",

                    link: function ($scope, element) {
                        element.change(function (event) {
                            $scope.$apply(function () {
                                $scope.myFile = element[0].files[0];
                            });
                        });
                    },
                };
            },
        ]);

    WorkOrderImportController.$inject = [
        "$timeout",
        "$scope",
        "WorkOrderUpload",
    ];

    function WorkOrderImportController($timeout, $scope, WorkOrderUpload) {
        var vm = this;

        vm.upload = upload;

        var formData;
        function upload() {
            formData = new FormData();
            formData.append("file", $scope.myFile);

            WorkOrderUpload.workorderupload(
                formData,
                function (response, headers) {
                    if (response.status == 200) {
                        vm.error = null;
                        vm.success = "OK";

                        $timeout(function () {
                            vm.success = "";
                        }, 4000);
                    }
                    if (response.status == 400) {
                        vm.success = null;
                        vm.error = "error";
                    }
                }
            );
        }
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");
    }
})();
