(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('VehicleManagementDetailController', VehicleManagementDetailController);

    VehicleManagementDetailController.$inject = ['$stateParams', 'Vehicle'];

    function VehicleManagementDetailController($stateParams, Vehicle) {
        var vm = this;

        vm.load = load;
        vm.vehicle = {};

        vm.load($stateParams.id);

        function load(id) {
            Vehicle.get({ id: id }, function (result) {
                vm.vehicle = result;
            });
        }

    }
})();
