(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetImportController", AssetImportController);

    AssetImportController.$inject = ["AllAssetsTypes", "AssetUpload"];
    function AssetImportController(AllAssetsTypes, AssetUpload) {

        var vm = this;
        var formData,getFile;

        AllAssetsTypes.getAll(function (response) {
            vm.assettypes = response;
        });

        $(":file").filestyle({
            buttonBefore: true,
            buttonText: "Browse File",
        });

        vm.importxlsx = /^(.*?)\.(xlsx|kml)$/;
        
        vm.upload = function () {

            getFile = document.getElementById('uploadfile').files[0]
            formData = new FormData();
            formData.append("assettype", vm.importassettype.name);
            formData.append("file", getFile);
            
            AssetUpload.assetupload(formData, function (response) {
                vm.success = response.status == 200 ? "success" : ""
                if (vm.success) {
                    setTimeout(function () {
                        vm.success = "";
                    }, 3000);
                }
            });
        };
    }
})();