(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrVendorDetailController', TrVendorDetailController);

    TrVendorDetailController.$inject = ['$stateParams', 'TrVendor'];

    function TrVendorDetailController ($stateParams, TrVendor) {
        var vm = this;
        vm.load = load;
        vm.trVendor = {};
       
        vm.load($stateParams.id);

        function load (id) {
            TrVendor.get({id: id}, function(result) {
                vm.trVendor = result;
            });
        }
       
    }
})();
