(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("ResourceTxnDialogController", ResourceTxnDialogController);

    ResourceTxnDialogController.$inject = ['$state','Inventory','TrResource','$scope','ParseLinks','AlertService',
    'pagingParams','$timeout','$window','entity','sectionId'];

    function ResourceTxnDialogController($state,Inventory,TrResource,
        $scope,
        ParseLinks,
        AlertService,
        pagingParams,
        $timeout,
        $window,
        entity,
        sectionId
        ) {
        var vm = this;
        vm.resourceTxn = {}
        vm.transactionType = ["PLANNED","REQUESTED","RECEIVED","UTILIZED","RECONCILED"]
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        /* var today = new Date();
        var todayFormatted = today.toISOString().split("T")[0];
        document.getElementById("dateInput").max = todayFormatted; */


       /*  if($state.params.sectionId){
            vm.resourceTxn.sectionId  = parseInt($state.params.sectionId, 10)
        } */

        TrResource.query(
            {
                page: 0,
                size: 100,
                sort: sort(),
            },
            onSuccess,
            onError
        );

        function sort() {
            var result = [
                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
            ];
            if (vm.predicate !== "id") {
                //  result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers("link"));
            vm.totalItems = headers("X-Total-Count");
            vm.queryCount = vm.totalItems;
            vm.resources = data
            
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        if(entity){
            Inventory.getResourceTxn(sectionId,entity,function(response){
                vm.resourceTxn = response
                if(vm.resourceTxn[0].id){
                    vm.resourceTxn[0].transactionDate = new Date(vm.resourceTxn[0].transactionDate);
                }
            })
            vm.isShow = true
        }else{
            vm.resourceTxn = [{sectionId:$state.params.sectionId}]
            /* if($state.params.sectionId){
                vm.resourceTxn.sectionId  = parseInt($state.params.sectionId, 10)
            } */
           
        }

        vm.save = function(){
            //vm.resourceTxn.resourceId = parseInt(vm.resourceTxn.resource)

            /* if(vm.resourceTxn.transactionDate){
                vm.resourceTxn.transactionDate = epoch(vm.resourceTxn.transactionDate)
            } */
            
            if(vm.resourceTxn[0].id){
                vm.dateChanged(0)
                Inventory.putResourceTxn(vm.resourceTxn[0],function(){
                        $window.history.back();
                })
             }else{
                Inventory.postBulkResourceTxn(vm.resourceTxn,function(){
                        $window.history.back();
                })

            }
            
        }

        var today = new Date();
        today.setHours(23, 59, 59);
        vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar; 
        vm.options = {
                maxDate: today
            };

            function openCalendar(date) {
                vm.datePickerOpenStatus[date] = true;
            }

           
            
            function epoch (date) {
                return Date.parse(date)
              }


              $scope.addDetails = function () { 
                vm.resourceTxn.push({sectionId:$state.params.sectionId})
              }

              vm.dateChanged = function(index){
                if(vm.resourceTxn[index].transactionDate){
                    vm.resourceTxn[index].transactionDate = epoch(vm.resourceTxn[index].transactionDate)
                }  
              }

              $scope.removeDetails = function (id) {
                vm.resourceTxn.splice(id, 1);
            };

            $('.select2').select2();
       
    }
})();
 