(function () {
    "use strict";
    angular.module("trakeyeApp").factory("TrTerrain", TrTerrain);

    TrTerrain.$inject = ["$resource", "DateUtils"];

    function TrTerrain($resource, DateUtils) {
        var resourceUrl = "api/tr-terrains/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: {
                    method: "PUT",
                },
            }
        );
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("TrTerrainById", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.get = function (id, callback) {
                $http.get("api/tr-terrains/" + id).then(function (response) {
                    callback(response.data);
                });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("TrTerrainSearch", TrTerrainSearch);

    TrTerrainSearch.$inject = ["$resource"];

    function TrTerrainSearch($resource) {
        var service = $resource(
            "/api/tr-terrains/searchvalue/:search",
            {},
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );

        return service;
    }
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllTerrainTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/terrain-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();
