(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomResourceTypeAttributeDetailController', CustomResourceTypeAttributeDetailController);

    CustomResourceTypeAttributeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomResourceTypeAttribute'];

    function CustomResourceTypeAttributeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomResourceTypeAttribute) {
        var vm = this;

        vm.customResourceTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:customResourceTypeAttributeUpdate', function(event, result) {
            vm.customResourceTypeAttribute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
