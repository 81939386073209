(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tr-workorder', {
                parent: 'app',
                url: '/tr-workorder',
                data: {
                    authorities: ['WORKORDER_SIDEBAR'],                    
                    pageTitle: 'trakeyeApp.trWorkOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorders.html',
                        controller: 'TrWorkOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                    listgeofenceid: null,
                    rangeOption : {value:'day'},
                    fromTime : null ,
                    toTime : null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            listgeofenceid: $stateParams.listgeofenceid,
                            rangeOption : $stateParams.rangeOption,
                            fromTime : $stateParams.fromTime ,
                            toTime : $stateParams.toTime
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trWorkOrder');
                        $translatePartialLoader.addPart('trWorkOrderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-workorder-detail', {
                parent: 'tr-workorder',
                url: '/tr-workorder/{id}',
                data: {
                    authorities: ['WORKORDER_DETAIL'],
                    pageTitle: 'trakeyeApp.trWorkOrder.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorder-detail.html',
                        controller: 'TrWorkOrderDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trWorkOrder');
                        $translatePartialLoader.addPart('trWorkOrderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'TrWorkOrder', function ($stateParams, TrWorkOrder) {
                        return TrWorkOrder.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-workorder',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('tr-workorder.priority', {
                parent: 'tr-workorder',
                url: '/tr-workorders/priority/{priority}/{geofenceId}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                    pageTitle: 'trakeyeApp.trWorkOrder.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorders.html',
                        controller: 'TrWorkOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            priority: $stateParams.priority,
                            geofenceId : $stateParams.geofenceId
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trWorkOrder');
                        $translatePartialLoader.addPart('trWorkOrderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-workorder.attendance', {
                parent: 'tr-workorder',
                url: '/tr-workorders/attendance/{login}/{fromTime}/{toTime}/{trWorkOrderStatus}',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER'],
                    pageTitle: 'trakeyeApp.trWorkOrder.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorders.html',
                        controller: 'TrWorkOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null

                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            login: $stateParams.login,
                            fromTime: $stateParams.fromTime,
                            toTime: $stateParams.toTime,
                            trWorkOrderStatus: $stateParams.trWorkOrderStatus
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trWorkOrder');
                        $translatePartialLoader.addPart('trWorkOrderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-workorder.new', {
                parent: 'tr-workorder',
                url: '/create/{lat}/{lng}/{assetdetails}/{desc}/{routeId}/{sectionId}',
                data: {
                    authorities: ['ROLE_ROLLOUT_MANAGER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorder-dialog.html',
                        controller: 'TrWorkOrderDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            description: $stateParams.desc,
                            createDate: null,
                            updateDate: null,
                            pinLat: parseFloat($stateParams.lat),
                            pinLong: parseFloat($stateParams.lng),
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            trWorkOrderImages: []
                            // ,
                            // asset: {name: $stateParams.assetdetails}
                        };
                    }]
                }
            })

        .state('tr-workorder.workflow', {
            url: '/work-flow',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tr-workorder/tr-workorder-work-flow.html',
                    controller: 'TrWorkOrderWorkFlowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        description: $stateParams.desc,
                        createDate: null,
                        updateDate: null,
                        pinLat: parseFloat($stateParams.lat),
                        pinLong: parseFloat($stateParams.lng),
                        address: null,
                        escalated: null,
                        status: null,
                        id: null,
                        trWorkOrderImages: []
                    };
                }]
            }
        })

        .state('tr-workorder.import', {
                parent: 'tr-workorder',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorder-import.html',
                        controller: 'TrWorkOrderImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            trWorkOrderImages: []
                        };
                    }
                }

            })
            .state('tr-workorder.edit', {
                parent: 'tr-workorder',
                url: '/Edit/{id}',
                data: {
                    authorities: ['WORKORDER_EDIT'],
                    pageTitle: 'trakeyeApp.trWorkOrder.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorder-dialog.html',
                        controller: 'TrWorkOrderDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'TrWorkOrder', function ($stateParams, TrWorkOrder) {
                        return TrWorkOrder.get({ id: $stateParams.id }).$promise;
                    }],


                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-workorder',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('tr-workorder.delete', {
                parent: 'tr-workorder',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/tr-workorder/tr-workorder-delete-dialog.html',
                        controller: 'TrWorkOrderDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['TrWorkOrder', function (TrWorkOrder) {
                                return TrWorkOrder.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('tr-workorder', null, { reload: 'tr-workorder' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('tr-workorder.fibercore', {
                parent: 'tr-workorder',
                url: '/fibercore',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/tr-workorder-fiber-core.html',
                        controller: 'TrWorkOrderFiberCoreController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            trWorkOrderImages: []
                        };
                    }
                }

            })

            .state('show-hide-workorder', {
                parent: 'report',
                url: '/show-hide-workorder',
                data: {
                    authorities: ['ROLE_USER_ADMIN'],
                    pageTitle: 'trakeyeApp.report.generic-report'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-workorder/show-hide-workorder.html',
                        controller: 'ShowHideTrWorkOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('logSource');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            
    }
})();