(function() {
	'use strict';
	angular.module('trakeyeApp').factory('TrService', TrService);

	TrService.$inject = [ '$resource', 'DateUtils' ];

	function TrService($resource, DateUtils) {
		var resourceUrl = 'api/tr-services/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrServiceSearch', TrServiceSearch);

	TrServiceSearch.$inject = [ '$resource' ];

	function TrServiceSearch($resource) {

		var service = $resource('/api/tr-services/searchvalue/:search', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrServiceImages', TrServiceImages);

	TrServiceImages.$inject = [ '$resource' ];

	function TrServiceImages($resource) {
		var service = $resource('/api/service-images/:caseId', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrServiceSearchForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.searchserviceinmap = function(serviceId, callback) {

			$http.get('api/tr-services/searchinmap/' + serviceId).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllServiceTypes', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/service-alltypes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();
