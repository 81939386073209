(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('Account', Account);

    Account.$inject = ['$resource','Configs','$rootScope'];

    function Account ($resource,Configs,$rootScope) {
        
       /*  Configs.rolloutFeatures(function (response) {
                $rootScope.Roles = {}
                if (response && response.length) {
                    response.forEach(function (value) {
                        $rootScope.Roles[value.configKey] = true 
                    });
                }
        }); */


        var service = $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            }
        });

        return service;
    }
})();
