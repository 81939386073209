(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("UserReportingController", UserReportingController);
    angular.module("trakeyeApp").filter("trustFilter", [
        "$sce",
        function ($sce) {
            return function (text) {
                return $sce.trustAsHtml(text);
            };
        },
    ]);

    UserReportingController.$inject = ["$scope", "$sce", "$state", "Report"];

    function UserReportingController($scope, $sce, $state, Report) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.downloadAttendenceReport = downloadAttendenceReport;
        vm.report = report;
        vm.reporthide = false;
        vm.select2 = select2;
        var date = "dd EEE";
        vm.today = today();
        vm.reportRecords = [];
        vm.select2();

        vm.users = loadPage();

        vm.img;
        var zoomImage;

        function enable() {
            zoomImage = new ZoomNPan(imgZoom);
            zoomImage.minScale = 100;
        }
        enable();

        function loadPage() {
            Report.getUsers("", function (response) {
                vm.users = response;
            });
        }

        function today() {
            var today = new Date();
            vm.currentDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
            vm.currentDate.setHours(0, 0, 0, 0);
            vm.currentTime = vm.currentDate.getTime();
        }

        function downloadAttendenceReport() {
            Report.downloadReportingReport(
                {
                    id: vm.id,
                    fromTime: vm.currentTime,
                    toTime: vm.currentTime,
                },
                function (response) {
                    var a = document.createElement("a");
                    a.style = "display: none";
                    var file = new Blob([response], {
                        type: "application/csv",
                    });
                    var fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.download = "user_reporting_status_report.csv";
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }
            );
        }

        function report() {
            Report.getReportingReport(
                {
                    id: vm.id,
                    fromTime: vm.currentTime,
                    toTime: vm.currentTime,
                },
                function (response) {
                    vm.reportRecords = response;
                }
            );
        }

        $scope.getImage = function (img) {
            vm.img = img;
        };

        function select2() {
            $(".select2").select2();
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.calculateDate = calculateDate;
        function calculateDate() {
            vm.currentDate.setHours(0, 0, 0, 0);
            vm.currentTime = vm.currentDate.getTime();
        }

        function ZoomNPan(elm) {
            var that = this;

            /** minimum zoom allowed (in percent) */
            this.minScale = 30;
            /** maximun zoom allowed (in percent) */
            this.maxScale = 300;
            /** Snap to 100% if the zoom is in +- this amount (in percent) */
            this.snap = 9;
            /** invert the direction on MacOS */
            this.invertOnMac = false;

            if (elm.hasAttribute("data-zoom-and-pan"))
                return console.error(
                    "ZoomNPan This element is already initialized"
                );
            elm.setAttribute("data-zoom-and-pan", "");

            var currentScale = 100;
            elm.addEventListener("wheel", zoom);
            elm.addEventListener("mousemove", pan);

            function zoom(e) {
                e.preventDefault();
                var y = normalizeWheel(e);

                var step = y * (that.maxScale / 500);
                currentScale += step * -1;
                if (currentScale > that.maxScale) currentScale = that.maxScale;
                if (currentScale < that.minScale) currentScale = that.minScale;

                var s =
                    Math.abs(100 - currentScale) > that.snap
                        ? currentScale
                        : 100; //snap to 100%
                elm.classList.toggle("zoomed-in", s > 100);
                elm.classList.toggle("zoomed-out", s < 100);
                elm.style.backgroundSize = s + "%";
            }

            function pan(e) {
                var panX,
                    panY,
                    pad = 20;
                if (currentScale >= 100) {
                    panX = (e.offsetX - pad) / (e.target.clientWidth - pad * 2);
                    panY =
                        (e.offsetY - pad) / (e.target.clientHeight - pad * 2);
                } else {
                    panX = 0.5;
                    panY = 0.5;
                }
                elm.style.backgroundPositionX = panX * 100 + "%";
                elm.style.backgroundPositionY = panY * 100 + "%";
            }

            function normalizeWheel(ev) {
                var sY = 0;
                if ("detail" in ev) sY = ev.detail;
                if ("wheelDelta" in ev) sY = -ev.wheelDelta / 120;
                if ("wheelDeltaY" in ev) sY = -ev.wheelDeltaY / 120;
                var pY = 10 * sY;
                if ("deltaY" in ev) pY = ev.deltaY;
                if (pY && ev.deltaMode) pY *= ev.deltaMode === 1 ? 40 : 800;
                if (pY && !sY) sY = 1 > pY ? -1 : 1;
                that.invertOnMac &&
                    navigator.platform.match(/mac/i) &&
                    ((sY *= -1), (pY *= -1)); //invert direction on MacOS
                return pY;
            }

            /**
             * theardown the events and remove styles, call this when you don't need it anymore.
             * @public
             */
            this.destroy = function () {
                elm.removeEventListener("wheel", zoom);
                elm.removeEventListener("mousemove", pan);
                elm.removeAttribute("data-zoom-and-pan");
                elm.className = "zoom"; //remove zoomed-in and zoomed-out
                elm.style.backgroundSize = ""; //reset zoom
                elm.style.backgroundPosition = ""; //reset position
            };
        }
    }
})();
