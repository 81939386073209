(function () {
	'use strict';

	angular.module('trakeyeApp').controller('CaseReportController', CaseReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	CaseReportController.$inject = ['$scope', '$sce', '$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval', 'AllCaseTypes', 'DownloadCaseReports'];

	function CaseReportController($scope, $sce, $state, Report, $rootScope, $timeout, $window, $filter, $interval, AllCaseTypes, DownloadCaseReports) {
		var vm = this;
		vm.sortResponse = sortResponse;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.reportTypeChange = reportTypeChanged;
		vm.downloadCaseReport = downloadCaseReport;
		vm.submitCaseReport = submitCaseReport;
		vm.users = [];
		vm.batteryusers = [];
		vm.geofences = [];
		$scope.reporthide = false;
		vm.select2 = select2;
		// vm.selectshow = selectshow;
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		// vm.caseReport = true;
		vm.selectCaseType = 1;
		$scope.reporthide = false;
		// vm.today();
		vm.select2();
		Report.getUsers('', function (response) {
			vm.batteryusers = response;

		})

		AllCaseTypes.getAll(function (response) {
			vm.caseTypes = response;
		});

		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}

		
		function downloadCaseReport() {
			var caseTypeId = 0;
			if (vm.selectCaseType != 'all') {
				caseTypeId = vm.selectedCaseType.id;
			}
			Report.getCaseReport({
				id: caseTypeId,
				fromTime: vm.fromTime,
				toTime: vm.toTime,
				reportType: vm.caseType
			}, function (response) {
				var a = document.createElement("a");
				a.style = "display: none";
				var file = new Blob([(response)], { type: 'application/csv' });
				var fileURL = URL.createObjectURL(file);
				// window.open(fileURL, "_blank");
				a.href = fileURL;
				a.download = "case_report.csv";
				a.click();
				window.URL.revokeObjectURL(fileURL);
			});
		}

		function submitCaseReport() {
			var caseTypeId = 0;
			if (vm.selectCaseType != 'all') {
				caseTypeId = vm.selectedCaseType.id;
			}
			Report.getCaseReportList({
				id: caseTypeId,
				fromTime: vm.fromTime,
				toTime: vm.toTime,
				reportType: vm.caseType
			}, function (response) {
				vm.reportRecords = response;
				$scope.reporthide = true;
			});
		}

		function sortResponse() {
			var caseTypeId = 0;
			if (vm.selectCaseType != 'all') {
				caseTypeId = vm.selectedCaseType.id;
			}
			Report.getCaseReportList({
				id: caseTypeId,
				fromTime: vm.fromTime,
				toTime: vm.toTime,
				sortFieldName: vm.predicate,
				sortOrder: vm.reverse ? 'asc' : 'desc',
				reportType: vm.caseType
			}, function (response) {
				vm.reportRecords = response;
				$scope.reporthide = true;
			});
		}

		//vm.detailedReport = detailedReport;

		vm.selecterange = selectRange;
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		function selectRange(range) {
			if (range == 'week') {
				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setDate(date.getDate() - 7)
				vm.fromTime = date.getTime();

			} else if (range == 'month') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setMonth(date.getMonth() - 1);
				vm.fromTime = date.getTime();
			} else if (range == 'year') {

				var date = new Date();
				date.setHours(23, 59, 59, 999);
				vm.toTime = date.getTime();
				date.setHours(0, 0, 0, 0);
				date.setYear(date.getFullYear() - 1);
				vm.fromTime = date.getTime();
			}
		}
		function select2() {
			$(".select2").select2();
			vm.caseReport = true;
		}
		function reportTypeChanged() {
			$scope.reporthide = false;
			if (vm.reportType == 'AGENT') {
				if (vm.users.length == 0) {
					Report.getUsers('', function (response) {
						vm.users = response;
					})
				}
			} else if (vm.reportType == 'GEOFENCE') {
				if (vm.geofences.length == 0) {
					Report.getGeofences('', function (response) {
						vm.geofences = response;
					})
				}
			}

		}
		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		$scope.report = function () {
			Report.getCasesReport({
				"fromDateTime": vm.fromTime,
				"toDateTime": vm.toTime,
				"caseReportType": vm.reportType
			}, function (response) {
				$scope.myhtml = response;
			});
		}
	}

})();
