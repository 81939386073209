(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrCaseController", TrCaseController);

    TrCaseController.$inject = [
        "$filter",
        "$rootScope",
        "$scope",
        "$state",
        "$stateParams",
        "$compile",
        "$timeout",
        "pagingParams",
        "paginationConstants",
        "TrCase",
        "TrCaseGeofence",
        "ParseLinks",
        "AlertService",
        "TrCaseUser",
        "TrCaseSearch",
        "TrCaseGeofenceSearch",
        "TrCasePriority",
        "TrCasePrioritySearch",
        "TrCaseEdit",
        "TrCaseForMap",
        "TrCaseSearchForMap",
        "TrCaseLastLocation",
        "UserAccount",
        "GeofenceIDs",
        "UserGeofences",
        "AssetGroupES",
        "TrCaseById",
        "AssetByID",
        "TrCaseAttendance",
        "Report",
        "UserMasterGeofences",
        "GeofenceByID",
        "MiniGeofencesByMasterID",
        "$localStorage",
        "Configs",
        "getNearbyFixed",
        "BaseUrl",
        "AssetType",
        "TrCaseCustomTime",
        "TrCaseGeofenceCustomTime",
        "TrCaseSearchCustom",
        "TrCaseGeofenceSearchCustom",
        "CaseType"
    ];

    function TrCaseController(
        $filter,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $compile,
        $timeout,
        pagingParams,
        paginationConstants,
        TrCase,
        TrCaseGeofence,
        ParseLinks,
        AlertService,
        TrCaseUser,
        TrCaseSearch,
        TrCaseGeofenceSearch,
        TrCasePriority,
        TrCasePrioritySearch,
        TrCaseEdit,
        TrCaseForMap,
        TrCaseSearchForMap,
        TrCaseLastLocation,
        UserAccount,
        GeofenceIDs,
        UserGeofences,
        AssetGroupES,
        TrCaseById,
        AssetByID,
        TrCaseAttendance,
        Report,
        UserMasterGeofences,
        GeofenceByID,
        MiniGeofencesByMasterID,
        $localStorage,
        Configs,
        getNearbyFixed,
        BaseUrl,
        AssetType,
        TrCaseCustomTime,
        TrCaseGeofenceCustomTime,
        TrCaseSearchCustom,
        TrCaseGeofenceSearchCustom,
        CaseType
    ) {
        var vm = this;
        $scope.search = pagingParams.search;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.selectedListGeofenceId = parseInt(pagingParams.listgeofenceid);
        if (isNaN(vm.selectedListGeofenceId)) {
            vm.selectedListGeofenceId = 0;
        }
        vm.filterTrcaseSearch = filterTrcaseSearch;
        vm.filterTrCaseSearchMap = filterTrCaseSearchMap;
        vm.selectTrcaseEntity = selectTrcaseEntity;
        vm.selectAllTrCases = selectAllTrCases;
        vm.searchTrCases = searchTrCases;
        vm.selectAllAssets = selectAllAssets;
        vm.filterAssetsOnMapView = filterAssetsOnMapView;
        vm.closeMapFilterModal = closeMapFilterModal;
        vm.saveListFilter = saveListFilter;
        vm.filterTrcasesOnListView = filterTrcasesOnListView;
        vm.closeListFilterModal = closeListFilterModal;
        vm.showPriorityStatusFilter = showPriorityStatusFilter;
        vm.closePriorityStatusFilterModal = closePriorityStatusFilterModal;

        vm.openCalendar = openCalendar;
        vm.usersTable = usersTable;
        vm.saveFilter = saveFilter;
        vm.isExpanded = false;
        vm.expandedCaseId = 0;
        var openedInfoWindow = null;
        vm.usersList = [];
        var prevInfoWindow = null;
        //		 vm.trCases = [];
        var latlng;
        var userPaths = {};
        var locations = {};
        var nearbyAgentsMarkers = [];
        vm.caseAroundUsers = [];
        var markers = [];
        var polylines = [];
        var noDataText = null;
        var activeInfo;
        var infoTimer;
        vm.saveImage = saveImage;
        vm.imageUpload = imageUpload;
        vm.closeImageUpload = closeImageUpload;
        vm.downloadPdfButton = downloadPdfButton;
        vm.fileWarning = false
        vm.newCases = newCases

        vm.userGeofences = [];

        vm.geofencesListView = [];
        vm.userMasterGeofences = [];
        vm.selectedMasterGeofenceId = 0;
        vm.dateFilterTrCaseSearchMap = dateFilterTrCaseSearchMap

        vm.selectedWorkCases = [];
        vm.multgroup = [];
        var selctedGroup = [];
        var tempGeofenceId = null
        vm.groupCases = false;
        vm.showTrCaseActions = false;
        vm.totalTrcaseSelected = 0;
        vm.navigateCaseEdit = navigateCaseEdit;
        vm.goToEditPage = goToEditPage;
        vm.selectedGeofenceId = null;
        vm.mapCases = [];
        vm.items = [
            {
                name: "Update Group Mapping",
            },
        ];

        var isNewCaseSelected = $state.params.rangeOption;
        var fromDateValue = 0;
        var toDateValue = 0;

      //  $(":file").filestyle({ buttonBefore: true });
        //$(":file").filestyle("buttonText", "Browse File");
        // Get the current date and time in epoch format
        var now = Date.now();

        // Calculate the date six months ago in epoch format
        var sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        var sixMonthsAgoEpoch = sixMonthsAgo.setHours(23, 59, 59, 999);

        /* vm.priorityType = ["PRIORITY"];
        vm.statusType = ["STATUS"];
        vm.priorityList = ["HIGH", "MEDIUM", "LOW", "CRITICAL"];
        vm.statusList = [
            "NEW",
            "INPROGRESS",
            "PENDING",
            "ASSIGNED",
            "RESOLVED",
            "CANCELLED",
            "CLOSED",
        ]; */
        vm.datePickerOpenStatus = {};
        function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;

		}

        vm.statusType = [
        {   name: '<strong>PRIORITY</strong>' , msGroup: true }, 
        {   name: 'CRITICAL', selected: true   },
        {   name: 'HIGH', selected : true   },       
        {   name: 'MEDIUM', selected: true   },
        {   name: 'LOW', selected: true   },        
        {   msGroup: false },
        {   name: '<strong>STATUS</strong>', msGroup: true }, 
        {   name: 'NEW', selected: true   },
        {   name: 'INPROGRESS', selected : true   },       
        {   name: 'PENDING', selected: true   },
        {   name: 'ASSIGNED', selected: true   },        
        {   name: 'CANCELLED', selected: false   },
        {   name: 'CLOSED', selected: false   },        
        {   msGroup: false },
        {   name: '<strong>CASE TYPE</strong>', msGroup: true } ]

        CaseType.query({
            page: 0,
            size: 100,
            sort: 'name,asc'
        }, function(data){
            if(data.length){
                data.forEach(function(item){
                    vm.statusType.push({name:item.name,selected:false,value:item.id})
                })
                vm.statusType.push({ msGroup: false})
            }
        }, function(err){});
        vm.selectedPriorityList = [];
        vm.selectedStatusList = [];
        vm.selectedPriorityType = [];
        vm.selectedStatusType = [];
        vm.showCaseAttachment = false

         vm.statusType.forEach(function(val){
            if(val.name == 'CRITICAL' || val.name ==  'HIGH' || val.name == "MEDIUM" || val.name == "LOW" ){
               vm.selectedPriorityList.push(val.name)
           } 
           if(val.name == 'NEW' || val.name ==  'INPROGRESS' || val.name == "PENDING" || val.name == "ASSIGNED"){
               vm.selectedStatusList.push(val.name)
           }
       }) 

        /* vm.priorityList.forEach(function (value) {
            vm.selectedPriorityList.push(value);
        });
        vm.statusList.forEach(function (value) {
            vm.selectedStatusList.push(value);
        });
        vm.priorityType.forEach(function (value) {
            vm.selectedPriorityType.push(value);
        });
        vm.statusType.forEach(function (value) {
            vm.selectedStatusType.push(value);
        }); */

        var pinLat = 0.0;
        var pinLong = 0.0;
        $rootScope.hideCaseEditBtn = false
        $rootScope.createCaseFromAssetMap = null

        $scope.tab = 1;
        $scope.$on("$destroy", function () {
            //            $interval.cancel(updateUserAgentPath);
        });
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        if ($scope.tab == 1) {
            vm.dateRange = $state.params.rangeOption

            if(vm.dateRange == "custom"){
                vm.fromDate = $state.params.fromTime
                vm.toDate = $state.params.toTime
            }
            
            UserAccount.get(function (data) {
                var isAdmin = false;
                data.authorities.forEach(function (val) {
                    if (val == "ROLE_USER_ADMIN") {
                        isAdmin = true;
                    } else if (val == "ROLE_HOD") {
                        isAdmin = true;
                    } else if (val != "ROLE_VENDOR" ){
                       AssetType.query(
                            {
                                page: 0,
                                size: 100,
                                sort: sort(),
                            },
                            onSuccess,
                            onError
                        );
                        function sort() {
                            var result = [
                                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                            ];
                            if (vm.predicate !== "id") {
                                //  result.push('id');
                            }
                            return result;
                        }
                        function onSuccess(data, headers) {
                            vm.assetTypes = data;
                        }
                        function onError(error) {
                            AlertService.error(error.data.message);
                        } 
                    }
                });
                if (isAdmin) {
                    vm.adminFilter = true;
                    filterTrcasesOnListView()
                    filterTrcaseSearch();
                } else {
                    vm.adminFilter = false;
                    filterTrcasesOnListView()
                    filterTrcaseSearch();
                }
            });
            Configs.get("CaseImage",function(response){
                if(response.length > 0){
                    if(response[0].configValue == "True"){
                        vm.showCaseAttachment = true
                    }
                }
            })
        }

        function imageUpload(id){
            vm.caseId = id
            $('#myModalUpload').show()
        }
        
        function saveImage(){
            var photo = document.getElementById("assetImages").files[0]; // file from input
            var req = new XMLHttpRequest();
            var formData = new FormData();
            if(photo){
                if(photo.type == "application/pdf"){
                    var name = photo.name.split('.')[0]
                    var type = photo.name.split('.')[1]
                    vm.fileWarning = false
                    formData.append("file", photo);
                    req.open("POST", "api/trakeyecaseimages/" + vm.caseId.id+"/"+name+"/"+type);
                    req.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
                    req.onload = function(e) {
                        if (this.status === 200) { 
                            closeImageUpload()
                            $('#successImage').show()
                        }else{
                            closeImageUpload()
                            $('#errorImage').show()
                        }}
                    req.send(formData);
                    }else{
                        vm.fileWarning = true 
                    }
            } 
        }

        function closeImageUpload() {
            $("#myModalUpload").removeClass("in");
            $(".modal-backdrop").remove();
            $("#myModalUpload").hide();
            setTimeout(function(){$('#successImage').hide()},2000)
            setTimeout(function(){$('#errorImage').hide()},2000)
        }

        function downloadPdfButton(selectedCaseId) {
            var downloadfileName = "";
            var downloadCaseDesc = "";
			vm.trCases.forEach(function(caseVal){
                if(caseVal.id == selectedCaseId){
                    downloadfileName = caseVal.caseTypeName;
                    downloadCaseDesc = caseVal.description;
                }
            });
            BaseUrl.getBaseUrl(function(baseurl){
				var request = new XMLHttpRequest();
				request.open('GET', baseurl.stringValue+"api/download-splicing-pdf/"+selectedCaseId, true);
				request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
				request.responseType = 'blob';
				
				request.onload = function(e) {
					if (this.status === 200) {
						var blob = this.response;
						if(blob.size > 0){
							var blob = new Blob([this.response], {type: 'application/pdf'});
							var link = document.createElement('a');
							link.href = window.URL.createObjectURL(blob);
							link.download = "document.pdf";
							link.download = downloadfileName+"_"+downloadCaseDesc + ".pdf";
							
							link.click();
							
						}
					}
				};
				request.send();
			});
        }

        function downloadPdfMapButton(caseId, fileName) {
            var downloadfileName = "";
            var downloadCaseDesc = "";
			vm.trCases.forEach(function(caseVal){
                if(caseVal.id == caseId){
                    downloadfileName = caseVal.caseTypeName;
                    downloadCaseDesc = caseVal.description;
                }
            });
            BaseUrl.getBaseUrl(function(baseurl){
				var request = new XMLHttpRequest();
				request.open('GET', baseurl.stringValue+"api/download-splicing-pdf/"+caseId, true);
				request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
				request.responseType = 'blob';
				
				request.onload = function(e) {
					if (this.status === 200) {
						var blob = this.response;
						if(blob.size > 0){
							var blob = new Blob([this.response], {type: 'application/pdf'});
							var link = document.createElement('a');
							link.href = window.URL.createObjectURL(blob);
							link.download = fileName + ".pdf";
							
							link.click();
							
						}
					}
				};
				request.send();
			});
        }


        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    } /* 
                    {
                        elementType: "geometry.fill",
                        stylers: [
                            {
                                color: "#EFEFEF",
                            },
                        ],
                    }, */,
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                color: "#63666A",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {
                                color: "#efefef",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    }
                ],
            });
        }

        $scope.geofencelabel = {
            search          : "Search",
            nothingSelected : "Geofence"      
        }

        $scope.statuslabel = {
            search          : "Search",
            nothingSelected : "Priority/Status",
            selectAll       : "All",
            selectNone      : "None", 
            Done : "Done" 
        }

        vm.showCasesFilter = false
        vm.textFilter = "Show"
        vm.showHideFilter = function (){
            vm.showCasesFilter = !vm.showCasesFilter
            vm.textFilter = vm.showCasesFilter ? "Hide" : "Show"
        }
        $scope.setTab = function (newTab) {
            $scope.search = null;
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                /* filterTrcasesOnListView()
                filterTrcaseSearch(); */
                if(tempGeofenceId){
                    vm.selectedListGeofenceId = tempGeofenceId
                    saveListFilter()
                    vm.geofencesListView.forEach(function (item){
                        if(item.id == vm.selectedListGeofenceId){
                            item.ticked = true
                        }else{
                            item.ticked = false
                        }
                    })
                }
                
            } else if ($scope.tab == 2) {
                /* var myOptions = {
                    zoom: 12,
                    center: new google.maps.LatLng(
                        $rootScope.DEFAULT_LATITUDE,
                        $rootScope.DEFAULT_LONGITUDE
                    ),
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas1"),
                    myOption(12,new google.maps.LatLng(
                        $rootScope.DEFAULT_LATITUDE,
                        $rootScope.DEFAULT_LONGITUDE
                    ))
                );
                geoLocation(locations.map);
                vm.selectedGeofenceId = 0;
                //filterTrCaseSearchMap();
                //vm.mapAssets = [];
                //getGroupAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
                setTimeout(function () {
                    removeMarkers();
                }, 2000);

                statusFilter();
                filterAssetsOnMapView()
                if(tempGeofenceId){
                    vm.selectedGeofenceId = tempGeofenceId
                    saveFilter()
                }
                
            } else if ($scope.tab == 3) {
                filterAssetsOnMapView()
                //vm.selectedGeofenceId = 0;
                //filterTrCaseSearchMap();
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas2"),
                    myOption(12,new google.maps.LatLng(
                        $rootScope.DEFAULT_LATITUDE,
                        $rootScope.DEFAULT_LONGITUDE
                    ))
                );
                geoLocation(locations.map); 
                getAllUsers()
                if(tempGeofenceId){
                    vm.selectedGeofenceId = tempGeofenceId
                    saveFilter()
                }
            } else if ($scope.tab == 4) {
                vm.selectedGeofenceId = 0;
                vm.selectedGeofenceMap = null
                /* var myOptions = {
                    zoom: 12,
                    center: new google.maps.LatLng(
                        $rootScope.DEFAULT_LATITUDE,
                        $rootScope.DEFAULT_LONGITUDE
                    ),
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas4"),
                    myOption(12,new google.maps.LatLng(
                        $rootScope.DEFAULT_LATITUDE,
                        $rootScope.DEFAULT_LONGITUDE
                    ))
                );
                geoLocation(locations.map);

                vm.userMasterGeofences = [];
                UserMasterGeofences.getAll(function (response) {
                    vm.userMasterGeofences = response;
                });
                setTimeout(function () {
                    removeMarkers();
                }, 2000);
                statusFilter();
                vm.selectedMasterGeofenceId = 0;
            }
        };

        $("#masterGeofence").on("change", function () {
            var value = $(this).val();
            vm.selectedMasterGeofenceId = value;
            filterAssetsOnMapView()
        });

        function statusFilter() {
            $scope.togglePriorityTypeSelection = function togglePriorityTypeSelection(
                priorityName
            ) {
                var idx = vm.selectedPriorityType.indexOf(priorityName);

                if (idx > -1) {
                    vm.selectedPriorityType.splice(idx, 1);
                    vm.selectedPriorityList = [];
                } else {
                    vm.selectedPriorityType.push(priorityName);
                    vm.selectedPriorityList = [];
                    vm.priorityList.forEach(function (value) {
                        vm.selectedPriorityList.push(value);
                    });
                }
            };

            $scope.togglePriorityListSelection = function togglePriorityListSelection(
                priorityName
            ) {
                var idx = vm.selectedPriorityList.indexOf(priorityName);

                if (idx > -1) {
                    vm.selectedPriorityList.splice(idx, 1);
                } else {
                    vm.selectedPriorityList.push(priorityName);
                }
            };

            $scope.toggleStatusTypeSelection = function toggleStatusTypeSelection(
                statusName
            ) {
                var idx = vm.selectedStatusType.indexOf(statusName);

                if (idx > -1) {
                    vm.selectedStatusType.splice(idx, 1);
                    vm.selectedStatusList = [];
                } else {
                    vm.selectedStatusType.push(statusName);
                    vm.selectedStatusList = [];
                    vm.statusList.forEach(function (value) {
                        vm.selectedStatusList.push(value);
                    });
                }
            };

            $scope.toggleStatusListSelection = function toggleStatusListSelection(
                statusName
            ) {
                var idx = vm.selectedStatusList.indexOf(statusName);

                if (idx > -1) {
                    vm.selectedStatusList.splice(idx, 1);
                } else {
                    vm.selectedStatusList.push(statusName);
                }
            };
        }

        $scope.savePriorityStatusFilter = function () {
            vm.selectedPriorityList = []
            vm.selectedStatusList = []
            vm.selectedCaseTypeList = []
            vm.selectedstatusType.forEach(function(val){
                 if(val.name == 'CRITICAL' || val.name ==  'HIGH' || val.name == "MEDIUM" || val.name == "LOW" ){
                    vm.selectedPriorityList.push(val.name)
                } else if(val.name == 'NEW' || val.name ==  'INPROGRESS' || val.name == "PENDING" || val.name == "ASSIGNED"
                || val.name ==  'CANCELLED' || val.name == "CLOSED" ){
                    vm.selectedStatusList.push(val.name)
                }else{
                    vm.selectedCaseTypeList.push(val.value)
                }

            })

            if (
                vm.selectedPriorityList.length > 0 &&
                vm.selectedStatusList.length > 0
            ) {
                saveFilter();
            }
        };

        function navigateCaseEdit(selectedId) {
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            $state.go("tr-case.edit", {
                id: vm.editId,
            });
        }

        function goToEditPage(selectedId) {
            $state.go("asset.edit", {
                id: selectedId.id,
            });
        }

        function usersTable(trCase) {
            vm.trCases.forEach(function (item) {
                item.isExpanded = false;
            });
            if (vm.toggle == trCase.id) {
                vm.toggle = undefined;
            } else {
                trCase.isExpanded = true;
                vm.toggle = trCase.id;
            }

            Report.getUsersAroundCase(
                {
                    id: trCase.id,
                    fromTime: trCase.createDate,
                    toTime: trCase.createDate + 1.8e6,
                },
                function (response) {
                    if (response instanceof Array) {
                        vm.caseAroundUsers = response;
                    } else {
                        vm.caseAroundUsers = [];
                        vm.caseAroundUsers.push(response);
                    }
                    vm.search = "";
                    vm.reporthide = true;
                }
            );
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                    Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                    Math.cos(degrees_to_radians(lat2)) *
                    Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        function searchTrCases() {
            if(isNewCaseSelected == 'day') {
                fromDateValue = sixMonthsAgoEpoch;
                toDateValue = now;
            } else {
                fromDateValue = vm.fromDate.setHours(0, 0, 0, 0);
                toDateValue = vm.toDate.setHours(23, 59, 59, 999);
            }
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search) &&
                $scope.search.length != 1 &&
                $scope.search.length != 2 &&
                $scope.search.length != 3 &&
                $scope.search.length != 4
            ) {
                if ($stateParams.priority == undefined) {
                    if (vm.selectedListGeofenceId > 0) {
                        TrCaseGeofenceSearchCustom.query(
                            {
                                search: $scope.search,
                                page: pagingParams.page - 1,
                                size: vm.itemsPerPage,
                                sort: sort(),
                                geofenceId: vm.selectedListGeofenceId,
                                fromDate: fromDateValue,
                                toDate: toDateValue
                            },
                            onSuccess,
                            onError
                        );
                    } else {
                        TrCaseSearchCustom.query(
                            {
                                search: $scope.search,
                                page: pagingParams.page - 1,
                                size: vm.itemsPerPage,
                                sort: sort(),
                                geofenceId: vm.selectedListGeofenceId,
                                fromDate: fromDateValue,
                                toDate: toDateValue
                            },
                            onSuccess,
                            onError
                        );
                    }
                } else {
                    TrCasePrioritySearch.query(
                        {
                            priority: $stateParams.priority,
                            searchtext: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }

                function sort() {
                    var result = [
                        vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                    ];
                    if (vm.predicate !== "id") {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    var trCasesSearchRes = [];
                    data.forEach(function (values) {
                        var FiberStatus = "";
                        var Cost = "";
                        var pb = "";
                        var dis = "";
                        var baseCost = "";
                        var caseTypeAttrVal = values.caseTypeAttributeValues;

                        caseTypeAttrVal.forEach(function (item) {
                            if (item.caseDataTypeName == "Fiber Status") {
                                FiberStatus = item.attributeValue;
                            }
                            if (item.name == "Cost") {
                                Cost = item.attributeValue;
                            } else if (item.name == "Power Budget") {
                                pb = item.attributeValue;
                            } else if (item.name == "Fiber Distance") {
                                dis = item.attributeValue;
                            } else if (item.name == "Base Cost") {
                                baseCost = item.attributeValue;
                            }
                        });

                        //						if (values.status) {
                        //							values.status = values.status.replace("NEW", "PLANNING PHASE");
                        //							values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
                        //							values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
                        //						}

                        var isVerified = false;
                        if (values.verified) {
                            isVerified = true;
                        }
                        var res = {
                            id: values.id,
                            description: values.description,
                            address: values.address,
                            escalated: values.escalated,
                            status: values.status,
                            reportedByUser: values.reportedByUser,
                            assignedToUser: values.assignedToUser,
                            verified: isVerified,
                            caseTypeName: values.caseTypeName,
                            pinLat: values.pinLat,
                            pinLong: values.pinLong,
                            caseType: values.caseType,
                            priority: values.priority,
                            caseNumber: caseNumber.caseNumber,
                            fiberStatus: FiberStatus,
                            groups: values.groups,
                            geofenceName: values.geofenceName,
                            caseAssets: values.caseAssets,
                            Cost: "$" + Cost,
                            pb: pb,
                            dis: dis,
                            baseCost: baseCost,
                        };

                        trCasesSearchRes.push(res);
                    });
                    vm.trCases = trCasesSearchRes;
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function filterTrcaseSearch() {
            if(isNewCaseSelected == 'day') {
                fromDateValue = sixMonthsAgoEpoch;
                toDateValue = now;
            } else {
                if(vm.fromDate && vm.toDate){
                    fromDateValue = vm.fromDate.setHours(0, 0, 0, 0);
                    toDateValue = vm.toDate.setHours(23, 59, 59, 999);
                }
            }
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                if ($stateParams.priority == undefined) {
                    if (vm.selectedListGeofenceId > 0) {
                        TrCaseGeofenceSearchCustom.query(
                            {
                                search: $scope.search,
                                page: pagingParams.page - 1,
                                size: vm.itemsPerPage,
                                sort: sort(),
                                geofenceId: vm.selectedListGeofenceId,
                                fromDate: fromDateValue,
                                toDate: toDateValue,
                            },
                            onSuccess,
                            onError
                        );
                    } else {
                        TrCaseSearchCustom.query(
                            {
                                search: $scope.search,
                                page: pagingParams.page - 1,
                                size: vm.itemsPerPage,
                                sort: sort(),
                                fromDate: fromDateValue,
                                toDate: toDateValue,
                            },
                            onSuccess,
                            onError
                        );
                    }
                } else {
                    TrCasePrioritySearch.query(
                        {
                            priority: $stateParams.priority,
                            searchtext: $scope.search,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }

                function sort() {
                    var result = [
                        vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                    ];
                    if (vm.predicate !== "id") {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    var dateFormat = "dd-MM-yyyy";
                    // vm.trCases = data;
                    var trCaseSearch = [];

                    data.forEach(function (value) {
                        var isVerified = false;
                        if (value.verified) {
                            isVerified = true;
                        }
                        var res = {
                            id: value.id,
                            createDateFormat: $filter("date")(
                                value.createDate,
                                dateFormat
                            ),
                            createDate: value.createDate,
                            description: value.description,
                            address: value.address,
                            escalated: value.escalated,
                            status: value.status,
                            reportedByUser: value.reportedByUser,
                            assignedToUser: value.assignedToUser,
                            verified: isVerified,
                            caseTypeName: value.caseTypeName,
                            pinLat: value.pinLat,
                            pinLong: value.pinLong,
                            caseType: value.caseType,
                            priority: value.priority,
                            caseNumber: value.caseNumber,
                            groups: value.groups,
                            geofenceName: value.geofenceName,
                            caseAssets: value.caseAssets,
                        };
                        trCaseSearch.push(res);
                    });
                    vm.trCases = trCaseSearch;
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function getGroupAssetsFromES(
            geofenceIdVal,
            lastAssetId,
            mapAssetLists
        ) {
            AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
                vm.mapAssets = mapAssetLists;
                data.assets.forEach(function (asset) {
                    var tmpAsset = {};

                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetTypeName;
                    tmpAsset.assetTypeColor = asset.assetColor;
                    tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                    tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;

                    vm.mapAssets.push(tmpAsset);
                });
                drawLatestAssets(vm.mapAssets);
                if (data.lastId != null) {
                    getGroupAssetsFromES(
                        geofenceIdVal,
                        data.lastId,
                        vm.mapAssets
                    );
                } else {
                    if (vm.mapAssets.length > 0) {
                        var assetSelected = vm.mapAssets[0];
                        AssetByID.get(assetSelected.id, function (assetVal) {
                            var assetLatLng = {
                                lat:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].latitude,
                                lng:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].longitude,
                            };
                            locations.map.setCenter(assetLatLng);
                        });
                    }
                }
            });
        }

        $scope.submitDate = function (){
            $scope.search = "";
            $state.params.rangeOption = 'custom'
            isNewCaseSelected = $state.params.rangeOption
            //isNewCaseSelected = false;
            
            if(vm.fromDate && vm.toDate){
                fromDateValue = vm.fromDate.setHours(0, 0, 0, 0);
                toDateValue = vm.toDate.setHours(23, 59, 59, 999);
                if (vm.selectedListGeofenceId > 0) {
                    TrCaseGeofenceCustomTime.query(
                        {
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                            geofenceId: vm.selectedListGeofenceId,
                            fromDate: fromDateValue,
                            toDate: toDateValue,
                        },
                        onSuccess,
                        onError
                    );
                } else {
                    TrCaseCustomTime.query(
                        {
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                            fromDate: fromDateValue,
                            toDate: toDateValue,
                        },
                        onSuccess,
                        onError
                    );
                }
            }
            
        }

        function sort() {
            var result = [
                vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
            ];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers("link"));
            vm.totalItems = headers("X-Total-Count");
            vm.queryCount = vm.totalItems;
            var trCasesRes = [];
            var dateFormat = "dd-MM-yyyy";
            vm.trBreakUps = data;
            data.forEach(function (values) {
                if (values.groups.length > 0) {
                    vm.parentGroup = values.groups[0].name;
                }
                var FiberStatus = "";
                var Cost = "";
                var pb = "";
                var dis = "";
                var baseCost = "";
                var caseTypeAttrVal = values.caseTypeAttributeValues;

                caseTypeAttrVal.forEach(function (item) {
                    if (item.caseDataTypeName == "Fiber Status") {
                        FiberStatus = item.attributeValue;
                    }
                    if (item.name == "Cost") {
                        Cost = item.attributeValue;
                    } else if (item.name == "Power Budget") {
                        pb = item.attributeValue;
                    } else if (item.name == "Fiber Distance") {
                        dis = item.attributeValue;
                    } else if (item.name == "Base Cost") {
                        baseCost = item.attributeValue;
                    }
                });
                var isVerified = false;
                if (values.verified) {
                    isVerified = true;
                }
                var res = {
                    id: values.id,
                    createDateFormat: $filter("date")(
                        values.createDate,
                        dateFormat
                    ),
                    createDate: values.createDate,
                    description: values.description,
                    address: values.address,
                    escalated: values.escalated,
                    status: values.status,
                    reportedByUser: values.reportedByUser,
                    assignedToUser: values.assignedToUser,
                    verified: isVerified,
                    caseTypeName: values.caseTypeName,
                    pinLat: values.pinLat,
                    pinLong: values.pinLong,
                    caseType: values.caseType,
                    priority: values.priority,
                    caseNumber: values.caseNumber,
                    fiberStatus: FiberStatus,
                    groups: values.groups,
                    geofenceName: values.geofenceName,
                    caseAssets: values.caseAssets,
                    Cost: "$" + Cost,
                    pb: pb,
                    dis: dis,
                    baseCost: baseCost,
                };
                trCasesRes.push(res);
            });
            vm.trCases = trCasesRes;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function newCases(){
            $scope.search = "";
            $state.params.rangeOption = vm.dateRange
            isNewCaseSelected = $state.params.rangeOption;
                        
            if(isNewCaseSelected == 'day') {
                fromDateValue = sixMonthsAgoEpoch;
                toDateValue = now;
                vm.fromDate = null
                vm.toDate = null
            } else {
                fromDateValue = vm.fromDate.setHours(0, 0, 0, 0);
                toDateValue = vm.toDate.setHours(23, 59, 59, 999);
            }
            
            if (vm.selectedListGeofenceId > 0) {
                TrCaseGeofenceCustomTime.query(
                    {
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        geofenceId: vm.selectedListGeofenceId,
                        fromDate: fromDateValue,
                        toDate: toDateValue,
                    },
                    onSuccess,
                    onError
                );
            } else {
                TrCaseCustomTime.query(
                    {
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        fromDate: fromDateValue,
                        toDate: toDateValue,
                    },
                    onSuccess,
                    onError
                );
            }
        }
        function loadAll() {
            $scope.search == null;

            if ($stateParams.priority == undefined) {
                vm.groupCases = false;
                vm.showTrCaseActions = false;
                if ($stateParams.caseStatus == undefined) {
                    newCases()
                } else {
                    TrCaseAttendance.query(
                        {
                            login: $stateParams.login,
                            fromTime: $stateParams.fromTime,
                            toTime: $stateParams.toTime,
                            caseStatus: $stateParams.caseStatus,
                            page: pagingParams.page - 1,
                            size: vm.itemsPerPage,
                            sort: sort(),
                        },
                        onSuccess,
                        onError
                    );
                }
            } else {
                TrCasePriority.query(
                    {
                        priority: $stateParams.priority,
                        geofenceId: $stateParams.geofenceId,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
            }

            /* function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    // result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                var trCasesRes = [];
                var dateFormat = "dd-MM-yyyy";
                vm.trBreakUps = data;
                data.forEach(function (values) {
                    if (values.groups.length > 0) {
                        vm.parentGroup = values.groups[0].name;
                    }
                    var FiberStatus = "";
                    var Cost = "";
                    var pb = "";
                    var dis = "";
                    var baseCost = "";
                    var caseTypeAttrVal = values.caseTypeAttributeValues;

                    caseTypeAttrVal.forEach(function (item) {
                        if (item.caseDataTypeName == "Fiber Status") {
                            FiberStatus = item.attributeValue;
                        }
                        if (item.name == "Cost") {
                            Cost = item.attributeValue;
                        } else if (item.name == "Power Budget") {
                            pb = item.attributeValue;
                        } else if (item.name == "Fiber Distance") {
                            dis = item.attributeValue;
                        } else if (item.name == "Base Cost") {
                            baseCost = item.attributeValue;
                        }
                    });
                    var isVerified = false;
                    if (values.verified) {
                        isVerified = true;
                    }
                    var res = {
                        id: values.id,
                        createDateFormat: $filter("date")(
                            values.createDate,
                            dateFormat
                        ),
                        createDate: values.createDate,
                        description: values.description,
                        address: values.address,
                        escalated: values.escalated,
                        status: values.status,
                        reportedByUser: values.reportedByUser,
                        assignedToUser: values.assignedToUser,
                        verified: isVerified,
                        caseTypeName: values.caseTypeName,
                        pinLat: values.pinLat,
                        pinLong: values.pinLong,
                        caseType: values.caseType,
                        priority: values.priority,
                        caseNumber: values.caseNumber,
                        fiberStatus: FiberStatus,
                        groups: values.groups,
                        geofenceName: values.geofenceName,
                        caseAssets: values.caseAssets,
                        Cost: "$" + Cost,
                        pb: pb,
                        dis: dis,
                        baseCost: baseCost,
                    };
                    trCasesRes.push(res);
                });
                vm.trCases = trCasesRes;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            } */
        }

        function saveListFilter() {
            vm.PrevselectedListGeofenceId = vm.selectedListGeofenceId;
            filterTrcaseSearch();
            $("#listFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#listFilterModal").hide();
        }

        $scope.geofenceListView = function(){
            if($scope.tab == 1){
                isNewCaseSelected = vm.dateRange
                vm.selectedListGeofenceId = vm.selectedGeofenceId[0].id
                tempGeofenceId = vm.selectedGeofenceId[0].id
                saveListFilter()
            }else if($scope.tab == 2 || $scope.tab == 3 || $scope.tab == 4){
                $scope.search = ""
                vm.selectedGeofenceId = vm.selectedGeofenceMap[0].id
                tempGeofenceId = vm.selectedGeofenceMap[0].id
                saveFilter()
            }
        }
        function filterTrcasesOnListView() {
            tempGeofenceId = $state.params.listgeofenceid
            vm.geofencesListView = [];
            vm.userGeofences = []
            if (vm.adminFilter) {
                GeofenceIDs.getAll(function (response) {
                    if (response.length >= 2) {
                        vm.geofencesListView.push({ id: 0, name: "All", label : "All" });
                    }
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        geofence.ticked = (tempGeofenceId == geofence.id) ? true : false
                        vm.geofencesListView.push(geofence);
                        vm.userGeofences.push(geofence);
                        /* if (vm.selectedGeofenceId == null) {
                            vm.selectedGeofenceId = geofence.id;
                        } */
                    });
                });
            } else {
                UserGeofences.getAll(function (response) {
                    if (response.length >= 2) {
                        vm.geofencesListView.push({ id: 0, name: "All", label : "All" });
                    }
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        geofence.ticked = (tempGeofenceId == geofence.id) ? true : false
                        vm.userGeofences.push(geofence);
                        vm.geofencesListView.push(geofence);
                        /* if (vm.selectedGeofenceId == null) {
                            vm.selectedGeofenceId = geofence.id;
                        } */
                    });
                });
            }
            //$("#listFilterModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            vm.PrevselectedListGeofenceId = vm.selectedListGeofenceId;
        }

        function closeListFilterModal() {
            $("#listFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#listFilterModal").hide();
            vm.selectedListGeofenceId = vm.PrevselectedListGeofenceId;
        }

        function showPriorityStatusFilter() {
            vm.currentZoom = locations.map.getZoom();

            vm.getCenter = locations.map.center;

            $("#priorityStatusfilterModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function closePriorityStatusFilterModal() {
            $("#priorityStatusfilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#priorityStatusfilterModal").hide();
        }

        function dateFilterTrCaseSearchMap(){
            var tempResponse
            if(vm.geofenceResponse && vm.geofenceResponse.length > 0 && $scope.search){
                tempResponse = vm.geofenceResponse.find(function (element){
                    return $scope.search == element.id
                })
                if(tempResponse){
                    drawResponseMap([tempResponse]);
                }
            }
        }

        function filterTrCaseSearchMap() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                // TrCaseSearchForMap.searchtrcaseformap($scope.search, function (response) {
                //     drawResponseMap(response);
                // });

                TrCaseForMap.getAllCases(
                    {
                        geofenceId: vm.selectedGeofenceId,
                        searchText: $scope.search,
                        priorityList: vm.selectedPriorityList,
                        statusList: vm.selectedStatusList,
                    },
                    function (response) {
                        drawResponseMap(response);
                    }
                );
            } else {
                if ($stateParams.priority == undefined) {
                    // TrCaseForMap.getAllCases(function (response) {
                    //     drawResponseMap(response);
                    // });
                    TrCaseForMap.getAllCases(
                        {
                            geofenceId: vm.selectedGeofenceId,
                            priorityList: vm.selectedPriorityList,
                            statusList: vm.selectedStatusList,
                        },
                        function (response) {
                            drawResponseMap(response);
                        }
                    );
                } else {
                    TrCaseForMap.getAllCases(
                        {
                            geofenceId: vm.selectedGeofenceId,
                            prioritySearch: $stateParams.priority,
                        },
                        function (response) {
                            drawResponseMap(response);
                        }
                    );
                    // TrCaseSearchForMap.prioritysearchtrcaseformap($stateParams.priority, function (response) {
                    //     drawResponseMap(response);
                    // });
                }
            }
        }

        function drawLatestAssets(response) {
            if (response) {
                initializeAssetMap(response, function (latlang) {
                    locations.latlng = latlang;

                    setupAssetMap(function () {
                        $.each(response, function (index, value) {
                            if (value.assetTypeLayout == null) {
                                value.assetTypeName = value.assetType.name;
                                value.assetTypeLayout = value.assetType.layout;
                                value.assetTypeImagePath =
                                    value.assetType.imagePath;
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "FIXED"
                            ) {
                                if (value.assetCoordinates.length > 1) {
                                    drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [
                                        value.assetCoordinates[
                                            value.assetCoordinates.length - 1
                                        ],
                                    ];

                                    drawFixedAsset(value1, false);
                                } else {
                                    drawFixedAsset(value, false);
                                }
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "SPREAD"
                            ) {
                                drawPolyline(value, locations.map);
                            }
                        });
                    });
                });
            }
        }

        function setupAssetMap(callback) {
            if (locations.map == null) {
                /* var myOptions = {
                    zoom: 14,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(14,locations.latlng)
                );

                google.maps.event.addListener(
                    locations.map,
                    "zoom_changed",
                    function () {
                        vm.zoomLevel = locations.map.getZoom();
                        if (
                            (vm.prevZoomLevel == 17 && vm.zoomLevel == 18) ||
                            (vm.prevZoomLevel == 18 && vm.zoomLevel == 17)
                        ) {
                            drawLatestAssets(vm.mapAssets);
                        }
                        vm.prevZoomLevel = vm.zoomLevel;
                    }
                );
            } else {
                // Loop through all the markers and polylines to remove

                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
            }
            locations.overlay = new google.maps.OverlayView();
            locations.overlay.draw = function () {};
            locations.overlay.setMap($scope.map);
            locations.element = document.getElementById("map_canvas");
            callback();
        }

        function initializeAssetMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                var latVal = null;
                var lngVal = null;
                latlangs.forEach(function (value) {
                    if (latVal == null && lngVal == null) {
                        if (value.assetCoordinates.length > 0) {
                            latVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].latitude;
                            lngVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].longitude;
                        }
                    }
                });
                if (latVal != null && lngVal != null) {
                    latlng = new google.maps.LatLng(latVal, lngVal);
                    callback(latlng);
                }
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            // callback(new google.maps.LatLng("12.9716", "77.5946"));
                            callback(
                                new google.maps.LatLng(
                                    $rootScope.DEFAULT_LATITUDE,
                                    $rootScope.DEFAULT_LONGITUDE
                                )
                            );
                        }
                    );
                } else {
                    callback(
                        new google.maps.LatLng(
                            $rootScope.DEFAULT_LATITUDE,
                            $rootScope.DEFAULT_LONGITUDE
                        )
                    );
                }
            }
        }

        function drawFixedAsset(fixed, hasParent) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infoWindow = new google.maps.InfoWindow();

                var iconUrl = "";
                var isFixedAsset = false;
                iconUrl = fixed.assetTypeImagePath;
                isFixedAsset = true;

                var iconSize = 8;
                if (vm.zoomLevel == 10) {
                    iconSize = 8;
                } else if (vm.zoomLevel == 11) {
                    iconSize = 16;
                }

                if (isFixedAsset) {
                    iconSize = 24;
                }

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                    },
                });
                markers.push(startMarker);

                var infoWindow2 = new google.maps.InfoWindow();
                var infoStr = "";
                var otdr = "";

                var userContent = document.createElement("div"),
                    button;

                userContent.innerHTML =
                    '<div class="infoWindowhead">' +
                    startMarker.title +
                    '</div><div class="infoWindowContent">' +
                    "<b>Lat</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .latitude +
                    "<br><b>Long</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .longitude +
                    "<br>" +
                    infoStr +
                    "</div>";

                google.maps.event.addListener(
                    startMarker,
                    "dblclick",
                    function () {
                        infoWindow2.close();
                    }
                );

                var activeInfoWindow;
                var infoWindow2 = new google.maps.InfoWindow({
                    content: userContent,
                });
                // add listener on InfoWindow for click
                // event
                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function () {
                        AssetByID.get(fixed.id, function (response) {
                            var infoWindow2 = new google.maps.InfoWindow();
                            var infoStr = "";
                            var otdr = "";
                            var assetTypeAttributeValuesList =
                                response.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function (a, b) {
                                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                    nameB = a.assetTypeAttribute.name.toLowerCase();
                                if (nameA < nameB)
                                    // sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0; // default return value (no sorting)
                            });
                            for (
                                var i = 0;
                                i < assetTypeAttributeValuesList.length;
                                i++
                            ) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr +=
                                    "<br><b>" +
                                    home.assetTypeAttribute.name +
                                    "</b> : " +
                                    home.attributeValue;
                                otdr = home;
                            }

                            var userContent = document.createElement("div"),
                                buttonA,
                                buttonB,
                                buttonC,
                                buttonD;
                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +
                                "<b>Name</b> : " + startMarker.title +
                                '</div><div class="infoWindowContent">' +
                                "<b>Asset Type</b> : " + fixed.assetTypeName +
                                "<br><b>Latitude</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].latitude +
                                "<br><b>Longitude</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].longitude +
                                "<br>" +
                                infoStr +
                                "</div>";

                            var infoWindow2 = new google.maps.InfoWindow({
                                content: userContent,
                            });
                            if (prevInfoWindow != null) prevInfoWindow.close();
                            infoWindow2.open(locations.map, startMarker);
                            setTimeout(function () {
                                infoWindow2.close();
                            }, 5000);

                            activeInfoWindow = infoWindow2;
                            prevInfoWindow = infoWindow2;
                        });
                    }
                );
                openedInfoWindow = infoWindow2;
            });
        }

        function selectAllAssets() {
            var toggleStatus = vm.isAllAssetSelected;
            if (toggleStatus) {
                vm.showAssetActions = true;
                vm.selectedAssets = [];
                vm.trCases.forEach(function (asset) {
                    vm.selectedAssets.push(asset.id);
                });
            } else {
                vm.showAssetActions = false;
                vm.selectedAssets = [];
            }
            angular.forEach(vm.trCases, function (item) {
                item.isChecked = toggleStatus;
            });
        }

        function filterAssetsOnMapView() {
            vm.userGeofences = [];
            if (vm.adminFilter) {
                if ($scope.tab == 2 || $scope.tab == 3) {
                    GeofenceIDs.getAll(function (response) {
                        response.forEach(function (geofence) {
                            geofence.label = geofence.name.substring(0, 15) + "..";
                            vm.userGeofences.push(geofence);

                            geofence.ticked = (tempGeofenceId == geofence.id) ? true : false
                           /*  if (vm.selectedGeofenceId == null) {
                                vm.selectedGeofenceId = geofence.id;
                            } */
                        });
                        vm.countUserGeofences = vm.userGeofences.length;
                    });
                } else if ($scope.tab == 4) {
                    vm.userGeofences = [];
                    MiniGeofencesByMasterID.get(
                        vm.selectedMasterGeofenceId,
                        function (response) {
                            response.forEach(function (geofence) {
                                geofence.label = geofence.name.substring(0, 15) + "..";
                                vm.userGeofences.push(geofence);

                                //geofence.ticked = (tempGeofenceId == geofence.id) ? true : false
                                /* if (vm.selectedGeofenceId == null) {
                                    vm.selectedGeofenceId = geofence.id;
                                } */
                            });
                            vm.countUserGeofences = vm.userGeofences.length;
                        }
                    );
                }
            } else {
                UserGeofences.getAll(function (response) {
                    response.forEach(function (geofence) {
                        geofence.label = geofence.name.substring(0, 15) + "..";
                        vm.userGeofences.push(geofence);
                        if (vm.selectedGeofenceId == null) {
                            vm.selectedGeofenceId = geofence.id;
                        }
                    });
                    vm.countUserGeofences = vm.userGeofences.length;
                });
            }
            //$("#mapFilterModal").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
            //vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
            /* vm.selectedPriorityList = [];
            vm.priorityList.forEach(function (value) {
                vm.selectedPriorityList.push(value);
            });
            vm.selectedStatusList = [];
            vm.statusList.forEach(function (value) {
                vm.selectedStatusList.push(value);
            }); */
        }

        function closeMapFilterModal() {
            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
            vm.selectedGeofenceId = vm.prevSelectedGeofenceId;
        }

        function saveFilter() {
            if(isNewCaseSelected == 'day') {
                fromDateValue = sixMonthsAgoEpoch;
                toDateValue = now;
            } else {
                fromDateValue = vm.fromDate.setHours(0, 0, 0, 0);
                toDateValue = vm.toDate.setHours(23, 59, 59, 999);
            }
            if ($stateParams.priority == undefined) {
                var sendData
                if (vm.dateRange == 'day'){
                    sendData = {
                        geofenceId: vm.selectedGeofenceId,
                        priorityList: vm.selectedPriorityList,
                        statusList: vm.selectedStatusList,
                        caseTypeIds : vm.selectedCaseTypeList,
                        fromDate: fromDateValue,
                        toDate: toDateValue,
                    }

                }else if(vm.dateRange == 'custom'){
                    sendData = {
                        geofenceId: vm.selectedGeofenceId,
                        priorityList: vm.selectedPriorityList,
                        statusList: vm.selectedStatusList,
                        caseTypeIds : vm.selectedCaseTypeList,
                        fromDate: vm.fromDate.setHours(0, 0, 0, 0),
                        toDate: vm.toDate.setHours(23, 59, 59, 999)
                    }
                }

                TrCaseForMap.getAllCases(
                    sendData,
                    function (response) {
                        vm.geofenceResponse = response
                        drawResponseMap(response);
                        vm.mapAssets = [];
                        markers = [];
                        polylines = [];
                       /*  var myOptions = {
                            zoom: 17,
                            center: locations.latlng,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                        }; */
                        if ($scope.tab == 2) {
                            locations.map = new google.maps.Map(
                                document.getElementById("map_canvas1"),
                                myOption(17,locations.latlng)
                            );
                        } else if ($scope.tab == 3) {
                            locations.map = new google.maps.Map(
                                document.getElementById("map_canvas2"),
                                myOption(17,locations.latlng)
                            );
                        } else if ($scope.tab == 4) {
                            locations.map = new google.maps.Map(
                                document.getElementById("map_canvas4"),
                                myOption(17,locations.latlng)
                            );
                        }

                        geoLocation(locations.map);
                        getGroupAssetsFromES(
                            vm.selectedGeofenceId,
                            null,
                            vm.mapAssets
                        );
                        drawLatestTrCases(response);
                        closePriorityStatusFilterModal();
                        vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
                    }
                );
            }

            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
        }

        function selectTrcaseEntity(trCase) {
            if (trCase.id != null) {
                if (trCase.isChecked == true) {
                    vm.totalTrcaseSelected++;
                    vm.showTrCaseActions = true;
                    vm.isAllTrCaseSelected = vm.trCases.every(function (itm) {
                        return itm.isChecked;
                    });
                    vm.selectedWorkCases.push(trCase.id);
                } else {
                    vm.totalTrcaseSelected--;
                }
                if (vm.totalTrcaseSelected == 0) {
                    vm.groupAssets = true;
                    vm.showTrCaseActions = false;
                    filterTrcaseSearch();
                }
            }
        }

        function selectAllTrCases() {
            var toggleStatus = vm.isAllTrCaseSelected;
            if (toggleStatus) {
                vm.showTrCaseActions = true;
                vm.selectedWorkCases = [];
                vm.trCases.forEach(function (trCase) {
                    vm.selectedWorkCases.push(trCase.id);
                });
            } else {
                vm.showTrCaseActions = false;
                vm.selectedWorkCases = [];
            }
            angular.forEach(vm.trCases, function (itm) {
                itm.isChecked = toggleStatus;
            });
        }

        function animatedMove(marker, t, current, moveto) {
            var lat = current.lat();
            var lng = current.lng();

            var deltalat = (moveto.lat() - current.lat()) / 100;
            var deltalng = (moveto.lng() - current.lng()) / 100;

            var delay = 10 * t;
            for (var i = 0; i < 100; i++) {
                (function (ind) {
                    setTimeout(function () {
                        var lat = marker.position.lat();
                        var lng = marker.position.lng();
                        lat += deltalat;
                        lng += deltalng;
                        latlng = new google.maps.LatLng(lat, lng);
                        marker.setPosition(latlng);
                    }, delay * ind);
                })(i);
            }
        }

        function getAllUsers(){
            TrCaseLastLocation.getlastlogs(function (response) {
                var lastLocByAgents = response;
                vm.usersList = [];
                response.forEach(function (user) {
                    if (user.path.length > 0) {
                        vm.usersList.push(user);
                    }
                });
                var markerIcon = "inactive";
                var imageUrl = "";
                switch (markerIcon) {
                    case "inactive":
                        imageUrl = "content/images/inactive_out.png";
                        break;
                    case "idle":
                        imageUrl = "content/images/marker_idle.png";
                        break;
                    case "active":
                        imageUrl = "content/images/marker_active.png";
                        break;
                    case "active_case":
                        imageUrl = "content/images/marker_active_case.png";
                        break;
                    default:
                        imageUrl = "content/images/green.png";
                        break;
                }

                var lastLocVal = [];
                removeMarkers();
                for (var i = 0; i < lastLocByAgents.length; i++) {
                    var path = lastLocByAgents[i].path;
                    if (path.length > 1) {
                        var marker = new google.maps.Marker({
                            position: new google.maps.LatLng(
                                path[0].lat,
                                path[0].lng
                            ),
                            map: locations.map,
                            icon: {
                                scaledSize: new google.maps.Size(42, 40),
                                origin: new google.maps.Point(0, 0),
                                url: imageUrl,
                                anchor: new google.maps.Point(16, 16),
                            },
                            label: {
                                color: "#000000",
                                fontWeight: "bold",
                                fontSize: "16px",
                                text: lastLocByAgents[i].userName,
                                backgroundcolor: "#ff9800",
                            },
                            title: lastLocByAgents[i].userName,
                        });
                        var markerContent =
                            '<div class="infoWindowhead">' +
                            lastLocByAgents[i].userName +
                            '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                            +path[0].lat +
                            "<br> <b>Lng :</b> " +
                            path[0].lng +
                            "</div>";

                        infowindowUsers(markerContent, marker);

                        animatedMove(
                            marker,
                            50,
                            marker.position,
                            new google.maps.LatLng(path[1].lat, path[1].lng)
                        );
                        nearbyAgentsMarkers.push(marker);
                    }
                }
            });
        }

        function drawResponseMap(data) {
            var myOptions = null;
            var latCase
            var longCase
            data.forEach(function (coOrdinates) {
                if (coOrdinates.pinLat != null && coOrdinates.pinLong != null) {
                     latCase = coOrdinates.pinLat.toString();
                     longCase = coOrdinates.pinLong.toString();
                   /*  myOptions = {
                        zoom: 10,
                        center: new google.maps.LatLng(lat, long),
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                    }; */
                }
            });
            if ($scope.tab == 2 || $scope.tab == 4) {
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas1"),
                    myOption(10,new google.maps.LatLng(latCase, longCase))
                );
                geoLocation(locations.map);
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function () {};
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById("map_canvas1");
                drawLatestTrCases(data);
            } else if ($scope.tab == 3) {
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas2"),
                    myOption(10,new google.maps.LatLng(latCase, longCase))
                );
                geoLocation(locations.map);
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function () {};
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById("map_canvas2");
                drawCasesAndNearByAgents(data);
                getAllUsers()
            }
        }

        function infowindowUsers(markerContent, marker) {
            var infowindow = new google.maps.InfoWindow();
            marker.addListener("click", function () {
                clearTimeout(infoTimer);
                if (activeInfo != null) {
                    activeInfo.close();
                }
                infowindow.setContent(markerContent);
                infowindow.open(locations.map, marker);
                activeInfo = infowindow;
                infoTimer = setTimeout(function () {
                    if (activeInfo != null) {
                        activeInfo.close();
                    }
                }, 8000);
            });
        }

        function removeMarkers() {
            for (var i = 0; i < nearbyAgentsMarkers.length; i++) {
                nearbyAgentsMarkers[i].setMap(null);
            }
            nearbyAgentsMarkers = [];
        }

        $("#searchOnMap").change(function () {
            var userSelected = JSON.parse($("#searchOnMap").val());
            var userLatLng = {
                lat: userSelected.path[0].lat,
                lng: userSelected.path[0].lng,
            };
            locations.map.setZoom(15);
            locations.map.setCenter(userLatLng);
        });

        /*function filterTrcases() {
            vm.itemsPerPage = 1000;
            if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
                if ($stateParams.priority == undefined) {

                    TrCaseSearchCustom.query({
                        search : $scope.search,
                        page : pagingParams.page - 1,
                        size : vm.itemsPerPage,
                        sort : sort()
                    }, onSuccess, onError);
                } else {
                    TrCasePrioritySearch.query({
                        searchtext : $scope.search,
                        priority : $stateParams.priority,
                        page : pagingParams.page - 1,
                        size : vm.itemsPerPage,
                        sort : sort()
                    }, onSuccess, onError);
                }
            } else {
                if ($stateParams.priority == undefined) {

                    TrCase.query({
                        page : pagingParams.page - 1,
                        size : vm.itemsPerPage,
                        sort : sort()
                    }, onSuccess, onError);
                } else {
                    TrCasePriority.query({
                        priority : $stateParams.priority,
                        page : pagingParams.page - 1,
                        size : vm.itemsPerPage,
                        sort : sort()
                    }, onSuccess, onError);
                }
            }
            function sort() {
                var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
                if (vm.predicate !== 'id') {
                    // result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                var myOptions = {
                    zoom : 10,
                    center : new google.maps.LatLng("5.431587","101.128162"),
                    mapTypeId : google.maps.MapTypeId.ROADMAP
                };

                if ($scope.tab == 2) {

                    locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOptions);
                    locations.overlay = new google.maps.OverlayView();
                    locations.overlay.draw = function() {
                    };
                    locations.overlay.setMap($scope.map);
                    locations.element = document.getElementById('map_canvas1');
                    drawLatestTrCases(data);
                } else if ($scope.tab == 3) {

                    locations.map = new google.maps.Map(document.getElementById("map_canvas2"), myOptions);
                    locations.overlay = new google.maps.OverlayView();
                    locations.overlay.draw = function() {
                    };
                    locations.overlay.setMap($scope.map);
                    locations.element = document.getElementById('map_canvas2');
                    drawCasesAndNearByAgents(data);
                }

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

        }*/

        /*if ($scope.search === null || $scope.search === "" || angular.isUndefined($scope.search)) {
            loadAll();
        } else {
            if ($scope.tab == 2) {
                filterTrcases();
            	
            }
            if ($scope.tab == 3) {
                TrCaseUser.gettrcasesuser(function(response) {
                    $scope.trcaseuser = response;
                });
                filterTrcases();
            	
            } else if ($scope.tab == 1) {
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                filterTrcaseSearch();
            }
        }*/

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: $scope.search,
                priority: pagingParams.priority,
                geofenceId : pagingParams.geofenceId,
                login: $stateParams.login,
                fromTime: vm.fromDate,
                toTime: vm.toDate,
                listgeofenceid: vm.selectedListGeofenceId,
                caseStatus: $stateParams.caseStatus,
                rangeOption : vm.dateRange,
            })
        }

        $scope.openInfoWindow = function (e, selectedMarker) {
            e.preventDefault();
            google.maps.event.trigger(selectedMarker, "click");
        };

        function drawPolyline(spread, map) {
            var spreadAssetCoordinates = [];
            var spreadColor = 0;
            var spreadWidth = 0;
            var spreadOpacity = 0;

            vm.assetTypes.forEach(function (assetType) {
                if (assetType.name == spread.assetTypeName) {
                    spreadColor = assetType.colorcode;
                    spreadWidth = assetType.lineWidth * 0.05;
                    spreadOpacity = assetType.transparency * 0.01;
                }
            });

            $.each(spread.assetCoordinates, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].longitude,
                };

                var polylineColor = "";
                var distanceFromPOP = "";
                var fiberCoreVal = "";
                var infowindow = null;
               if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spreadColor;
                }
                var polylineWeight = spreadWidth;
                var strokeOpacityVal = spreadOpacity;

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                google.maps.event.addListener(
                    polylinepath,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var disVal = 0;
                        var cLocations = [];

                        if (
                            spread.description == null ||
                            spread.description == undefined
                        ) {
                            spread.description = "";
                        }

                        if (spread.assetTypeLayout == "SPREAD") {
                            AssetByID.get(spread.id, function (assetVal) {
                                spread = assetVal;
                                var infoStr = "";
                                var assetTypeAttributeValuesList =
                                    spread.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function (
                                    a,
                                    b
                                ) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = b.assetTypeAttribute.name.toLowerCase();
                                    if (nameA < nameB)
                                        // sort string ascending
                                        return -1;
                                    if (nameA > nameB) return 1;
                                    return 0; // default return value (no sorting)
                                });
                                for (
                                    var i = 0;
                                    i < assetTypeAttributeValuesList.length;
                                    i++
                                ) {
                                    var home = assetTypeAttributeValuesList[i];

                                    var attrVal = "";
                                    if (home.attributeValue != null) {
                                        attrVal = home.attributeValue;
                                    }
                                    infoStr +=
                                        "<br><b>" +
                                        home.assetTypeAttribute.name +
                                        "</b> : " +
                                        attrVal;
                                }
                                var spreadCoordinates = spread.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(
                                                spreadCoordinates[index]
                                                    .latitude,
                                                spreadCoordinates[index]
                                                    .longitude,
                                                spreadCoordinates[index - 1]
                                                    .latitude,
                                                spreadCoordinates[index - 1]
                                                    .longitude
                                            );
                                        }
                                    }
                                }

                                var userContent = document.createElement("div"),
                                    button;

                                userContent.innerHTML =
                                    '<div class="infoWindowhead">' +
                                    "<b>Name : </b> " +
                                    spread.name +
                                    "<br><b>Asset Type : </b> " + spread.assetType.name +
                                    "</br>" +
                                    "<b>Distance : </b> " +
                                    parseFloat(disVal).toFixed(2) +
                                    " Km</br>";

                                infowindow = new google.maps.InfoWindow({
                                    content: userContent,
                                });
                            });
                        }
                        if (infowindow != null) {
                            infowindow.setPosition(event.latLng);
                            infowindow.open(locations.map);
                            openedInfoWindow = infowindow;
                        }
                    }
                );

                var polylinepathDuct = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                polylinepath.setMap(locations.map);

                polylines.push(polylinepath);
            }
        }
        

        function drawLatestTrCases(response) {
            if (response) {
                initializeTrcaseMap(response, function (latlang) {
                    locations.latlng = latlang;
                    // function() {
                    var btnDrawLine,brBreak,noAssetsFound,btnDownloadPdf;
                    var entryMarker = []
                    var endMarker = []
                    $.each(response, function (index, value) {
                        var userContent = document.createElement("div"),
                            button;

                        var infoWindow = new google.maps.InfoWindow({
                            content: userContent,
                        });

                        var startMarker = new google.maps.Marker({
                            map: locations.map,
                            position: new google.maps.LatLng(
                                value.pinLat,
                                value.pinLong
                            ),
                            title: value.description,
                        });
                        var infoWindow2 = new google.maps.InfoWindow();
                        // if(value.userCase=='C'){
                        if (value.priority == "LOW") {
                            startMarker.setIcon(
                                "content/images/Pointers-L4.png"
                            );
                        } else if (value.priority == "MEDIUM") {
                            startMarker.setIcon(
                                "content/images/Pointers-M4.png"
                            );
                        } else if (value.priority == "HIGH") {
                            startMarker.setIcon(
                                "content/images/Pointers-H4.png"
                            );
                        } else if (value.priority == "CRITICAL") {
                            startMarker.setIcon(
                                "content/images/Pointer-C4.png"
                            );
                        }

                        userContent.innerHTML =
                            '<div class="infoWindowhead">' +
                            startMarker.title +
                            '</div><div class="infoWindowContent"> <b>Case Id : </b>' +
                            value.id +
                            "<br> <b>Reported By :</b> " +
                            value.reportedByUser +
                            "<br> <b> Assigned To :</b> " +
                            value.assignedToUser +
                            "<br> <b>Address :</b> " +
                            value.address +
                            "</div>";

                        // var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
                        // startMarker.content = '<div class="infoWindowContent"> <b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> ' + value.assignedToUser +
                        //     '<br> <b>Address :</b> ' + value.address + '</div>';

                        button = userContent.appendChild(
                            document.createElement("input")
                        );
                        button.type = "button";
                        button.value = "Case Details";
                        button.className = "button";

                        google.maps.event.addDomListener(
                            button,
                            "click",
                            function () {
                                $state.go("tr-case-detail", {
                                    id: value.id,
                                });
                            }
                        );
                        $compile(userContent)($scope);
                        google.maps.event.addListener(
                            startMarker,
                            "click",
                            function () {
                                // infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + userContent);
                                // infoWindow.open(locations.map, startMarker);
                                TrCaseById.get(value.id, function (response) {
                                    userContent.innerHTML =
                                        '<div class="infoWindowhead">' +
                                        response.description +
                                        '</div><div class="infoWindowContent"> <b>Case Id : </b>' +
                                        value.id +
                                        "<br><b>Description : </b>" +
                                        response.description +
                                        "<br><b>Case Type : </b>" +
                                        response.caseType.name +
                                        "<br> <b>Reported By :</b> " +
                                        response.reportedByUser +
                                        "<br> <b> Assigned To :</b> " +
                                        response.assignedToUser +
                                        "<br> <b>Address :</b> " +
                                        response.address +
                                        "</div>";

                                        if ($scope.tab == 2) { 
                                            brBreak = userContent.appendChild(
                                                document.createElement("br")
                                            );
    
                                            btnDrawLine = userContent.appendChild(
                                                document.createElement("button")
                                            );
                                            btnDrawLine.classList.add("btn");
                                            btnDrawLine.classList.add("btn-sm");
                                            btnDrawLine.classList.add("btn-primary");
                                            btnDrawLine.textContent = "Nearby Assets";
                                            btnDrawLine.style.marginRight = "4px";

                                            btnDrawLine.onclick = function () { 
                                                for (var i = 0; i < entryMarker.length; i++) {
                                                    entryMarker[i].setMap(null);
                                                }
                                                for (var i = 0; i < endMarker.length; i++) {
                                                    endMarker[i].setMap(null);
                                                }

                                                var sendLatLng ={lat: response.pinLat,lng:response.pinLong}
                                                getNearbyFixed.get(vm.selectedGeofenceId,sendLatLng,function (getLatLng){
                                                    
                                                var directionsService = new google.maps.DirectionsService();

                                                if(getLatLng.length > 0){
                                                    var marker = new google.maps.Marker({
                                                        icon:  {
                                                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                            strokeColor: "#808000",
                                                            fillColor:'#808000',
                                                            fillOpacity:1,
                                                            scale: 4,
                                                            labelOrigin: new google.maps.Point(-1, 7)
                                                        },
                                                        map: locations.map,
                                                        position: {"lat":response.pinLat,
                                                        "lng":response.pinLong},
                                                        label: {
                                                            text:"Start",
                                                            fontSize: '15px',
                                                            fontWeight: 'bold'
                                                          }
                                                    });
                                                    entryMarker.push(marker)
                                                    marker.setMap(locations.map)
                                                    infoWindow.close();
                                                    calculateAndDisplayRoute(directionsService,getLatLng);
                                                }else{
                                                    noAssetsFound = userContent.appendChild(
                                                        document.createElement("b")
                                                    );
                                                    noAssetsFound.textContent = "No Nearby Assets";
                                                    noAssetsFound.style.marginRight = "4px";
                                                    noAssetsFound.style.color = "red"
                                                    noAssetsFound.style.fontSize = "15px";
                                                }
                                                })
                                            } 


                                            if(value.caseTypeName=='Termination Details' || value.caseTypeName=='Splicing Details') {
                                                btnDownloadPdf = userContent.appendChild(
                                                    document.createElement("button")
                                                );
                                                btnDownloadPdf.classList.add("btn");
                                                btnDownloadPdf.classList.add("btn-sm");
                                                btnDownloadPdf.classList.add("btn-primary");
                                                btnDownloadPdf.textContent = "Download Pdf";
                                                btnDownloadPdf.style.marginRight = "4px";
                                                btnDownloadPdf.onclick = function () { 
                                                    downloadPdfMapButton(value.id, value.caseTypeName+"_"+value.id);
                                                }
                                            }
                                          
                                            function calculateAndDisplayRoute(
                                                directionsService,
                                                getLatLng
                                              ) {
                                              
                                                for(var i=0;i<getLatLng.length;i++){
                                                    
                                                   directionsService
                                                  .route({
                                                    origin: { lat: response.pinLat,  lng: response.pinLong }, 
                                                    destination: { lat: getLatLng[i].latitude , lng: getLatLng[i].longitude }, 
                                                    travelMode: google.maps.TravelMode.WALKING,
                                                  })
                                                  .then(function(response)  {
                                                        var marker = new google.maps.Marker({
                                                            icon: {
                                                                path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                                                strokeColor: "#7F00FF ",
                                                                fillColor:'#7F00FF ',
                                                                fillOpacity:1,
                                                                scale: 4,
                                                                labelOrigin: new google.maps.Point(1, -7)
                                                            },
                                                            map: locations.map,
                                                            position: { lat: response.request.destination.location.lat() , lng: response.request.destination.location.lng() },
                                                            label : {text:response.routes[0].legs[0].distance.value + "m",fontSize: '15px',
                                                            fontWeight: 'bold'} 
                                                            
                                                        });
                                                        endMarker.push(marker)
                                                        marker.setMap(locations.map);
                                                  })
                                                  .catch(function(e) {console.log("Directions request failed due to " + e)} );
                                                }
                                                
                                              }
                                        }  
                                        
                                    infoWindow = new google.maps.InfoWindow({
                                        content: userContent,
                                    });
                                    infoWindow.open(locations.map, startMarker);
                                    setTimeout(function () {
                                        infoWindow.close();
                                    }, 5000);
                                });
                            }
                        );

                        // }
                        // on mouseout
                        // google.maps.event.addListener(startMarker, 'mouseout', function () {
                        //     infoWindow.close();
                        // });

                        //						var activeInfoWindow;
                        //						// add content to InfoWindow for click event
                        //						infoWindow2.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
                        //
                        //						// add listener on InfoWindow for click event
                        //						google.maps.event.addListener(startMarker, 'click', function() {
                        //
                        //							if (activeInfoWindow != null)
                        //								activeInfoWindow.close();
                        //							// Open InfoWindow - on click
                        //							infoWindow2.open(locations.map, startMarker);
                        //							$(".userpath").click(function() {
                        //								var ele = document.getElementById("userPathModal");
                        //								$(ele).modal('show');
                        //								vm.drawUserPath($(this).attr('userid'));
                        //							});
                        //
                        //							// Store new open InfoWindow in global variable
                        //							activeInfoWindow = infoWindow2;
                        //						});
                        //						resizeTrCaseMap();
                    });
                    // resizeTrCaseMap();
                    // };
                });
            }

            /*
             * function setupTrcaseMap(callback) { var myOptions = { zoom : 10,
             * center : new google.maps.LatLng("5.431587","101.128162"), mapTypeId :
             * google.maps.MapTypeId.ROADMAP }; locations.map = new
             * google.maps.Map(document.getElementById("map_canvas1"),
             * myOptions); locations.overlay = new google.maps.OverlayView();
             * locations.overlay.draw = function() { };
             * locations.overlay.setMap($scope.map); locations.element =
             * document.getElementById('map_canvas1'); callback(); }
             */
        }

        //		function resizeTrCaseMap() {
        //			setTimeout(function() {
        //				google.maps.event.trigger(locations.map, "resize");
        //				locations.map.setCenter(locations.latlng);
        //			}, 1000);
        //		}

        function initializeTrcaseMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                latlng = new google.maps.LatLng(
                    latlangs[0].pinLat,
                    latlangs[0].pinLong
                );
                callback(latlng);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            callback(
                                new google.maps.LatLng("5.431587", "101.128162")
                            );
                        }
                    );
                } else {
                    callback(new google.maps.LatLng("5.431587", "101.128162"));
                }
            }
        }

        /*
         * function drawCasesA() { drawLatestCases($scope.trcaseuser); }
         */
        function drawCasesAndNearByAgents(response) {
            if (response) {
                initializeMap(response, function (latlang) {
                    locations.latlng = latlang;
                    // setupMap(function() {
                    $.each(response, function (index, value) {
                        var infoWindow = new google.maps.InfoWindow();
                        var startMarker = new google.maps.Marker({
                            map: locations.map,
                            position: new google.maps.LatLng(
                                value.pinLat,
                                value.pinLong
                            ),
                            title: value.description,
                        });

                        var infoWindow2 = new google.maps.InfoWindow();

                        if (value.priority == "LOW") {
                            startMarker.setIcon(
                                "content/images/Pointers-L4.png"
                            );
                        } else if (value.priority == "MEDIUM") {
                            startMarker.setIcon(
                                "content/images/Pointers-M4.png"
                            );
                        } else if (value.priority == "HIGH") {
                            startMarker.setIcon(
                                "content/images/Pointers-H4.png"
                            );
                        } else if (value.priority == "CRITICAL") {
                            startMarker.setIcon(
                                "content/images/Pointer-C4.png"
                            );
                        }

                        $compile(startMarker.content)($scope);
                        google.maps.event.addListener(
                            startMarker,
                            "click",
                            function () {
                                TrCaseById.get(value.id, function (response) {
                                    var userContent = document.createElement(
                                        "div"
                                    );

                                    userContent.innerHTML =
                                        '<div class="infoWindowContent"><b>Case Id : </b>' +
                                        response.id +
                                        "<br> <b>Description : </b>" +
                                        response.description +
                                        "<br> <b>Reported By :</b> " +
                                        response.reportedByUser +
                                        "<br> <b> Assigned To :</b> " +
                                        response.assignedToUser +
                                        "<br> <b>Address :</b> " +
                                        response.address +
                                        "</div>";

                                    infoWindow2 = new google.maps.InfoWindow({
                                        content: userContent,
                                    });
                                    infoWindow2.open(
                                        locations.map,
                                        startMarker
                                    );
                                    setTimeout(function () {
                                        infoWindow2.close();
                                    }, 5000);
                                });
                            }
                        );

                        // on mouseout
                        google.maps.event.addListener(
                            startMarker,
                            "mouseout",
                            function () {
                                infoWindow.close();
                            }
                        );

                        resizeMap();
                    });
                    // });
                });
            }
            //			drawAgents();
        }

        function drawAgents() {
            var infoWindow = null;
            var markers = [
                {
                    Lat: "0.0",
                    Lng: "0.0",
                    name: "default",
                },
            ];
            TrCaseUser.getAgents(function (response) {
                $.each(response.liveLogs, function (index, value) {
                    infoWindow = new google.maps.InfoWindow();

                    var imgbg = document.createElement("img");
                    imgbg.className = "marker_back_image";

                    if (value.status == "ACTIVE") {
                        imgbg.src = "content/images/greenmarker.png";
                    } else if (value.status == "INACTIVE") {
                        imgbg.src = "content/images/redmarker.png";
                    } else if (value.status == "IDLE") {
                        imgbg.src = "content/images/yellowmarker.png";
                    }

                    function customMarker() {
                        this.latlng = new google.maps.LatLng(
                            value.latitude,
                            value.longitude
                        );
                        this.setMap(locations.map);
                        this.title = value.login;
                        this.icon = imgbg;
                    }
                    var userContent;
                    if (value.userImage != null) {
                        userContent =
                            '<div class="infoWindowhead">' +
                            value.login +
                            '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' +
                            value.userImage +
                            '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' +
                            value.address +
                            "</p><p>Phone : " +
                            value.phone +
                            "</p><p>Battery :" +
                            value.batteryPercentage +
                            " %</p></div></div></div>";
                    } else {
                        userContent =
                            '<div class="infoWindowhead">' +
                            value.login +
                            '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="content/images/userimg.gif" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' +
                            value.address +
                            "</p><p>Phone : " +
                            value.phone +
                            "</p><p>Battery :" +
                            value.batteryPercentage +
                            " %</p></div></div></div>";
                    }
                    var infowindowPopup = new google.maps.InfoWindow({
                        content: userContent,
                        pixelOffset: new google.maps.Size(0, -50),
                    });
                    infowindowPopup.setPosition(
                        new google.maps.LatLng(value.latitude, value.longitude)
                    );

                    customMarker.prototype = new google.maps.OverlayView();
                    customMarker.prototype.draw = function () {
                        var self = this;
                        var div = this.div;

                        if (!div) {
                            div = this.div = document.createElement("div");
                            div.id = "marker";
                            div.style.width = "100px";
                            div.style.height = "100px";

                            var div_pointer = document.createElement("div");
                            div_pointer.className = "triangle";

                            var image_container = document.createElement("div");
                            image_container.className = "image_container";

                            var img = document.createElement("img");
                            img.className = "marker_image";
                            img.src = "data:image/png;base64,";
                            img.src += value.userImage;
                            if (value.userImage == undefined) {
                                img.src = "content/images/userimg.gif";
                            }

                            var name_container = document.createElement("div");
                            name_container.className = "name_container";

                            div.appendChild(image_container);
                            image_container.appendChild(img);
                            image_container.appendChild(imgbg);

                            name_container.onmouseover = function () {
                                name_container.style.opacity = "0.6";
                                div.style.zIndex = "1000";
                            };
                            name_container.onmouseout = function () {
                                name_container.style.opacity = "0";
                                div.style.zIndex = "100";
                            };

                            google.maps.event.addDomListener(
                                imgbg,
                                "click",
                                function (event) {
                                    google.maps.event.trigger(self, "click");
                                    infowindowPopup.open(locations.map);
                                }
                            );

                            var panes = this.getPanes();

                            panes.overlayImage.appendChild(div);
                        }

                        var point = this.getProjection().fromLatLngToDivPixel(
                            this.latlng
                        );

                        if (point) {
                            div.style.left = point.x - 50 + "px";
                            div.style.top = point.y - 125 + "px";
                        }
                    };

                    customMarker.prototype.getPosition = function () {
                        return this.latlng;
                    };
                    markers.forEach(function (marker) {
                        var overlay = new customMarker();
                    });
                });
            });
        }
        $scope.caseLocation = function (selectedCase) {
            $("#caseLocationModal").show();
            var myCenter = new google.maps.LatLng(
                selectedCase.pinLat,
                selectedCase.pinLong
            );
            var ele = document.getElementById("caseLocationModal");
            $(ele).modal("show");

            var map = new google.maps.Map(
                document.getElementById("map_canvas_case_location"),
                {
                    center: myCenter,
                    zoom: 18,
                }
            );

            var markerIcon = "";
            if (selectedCase.priority == "LOW") {
                markerIcon = "content/images/Pointers-L4.png";
            } else if (selectedCase.priority == "MEDIUM") {
                markerIcon = "content/images/Pointers-M4.png";
            } else if (selectedCase.priority == "HIGH") {
                markerIcon = "content/images/Pointers-H4.png";
            } else if (selectedCase.priority == "CRITICAL") {
                markerIcon = "content/images/Pointer-C4.png";
            }

            var markerContent = null;
            if (selectedCase.caseDistance != null) {
                markerContent =
                    '<div class="infoWindowhead">' +
                    "<b>Case Id : </b>" +
                    selectedCase.id +
                    "<br><b>Description : </b>" +
                    selectedCase.description +
                    "<br> <b>Lat : </b>" +
                    selectedCase.pinLat +
                    "<br> <b>Lng : </b>" +
                    selectedCase.pinLong +
                    "<br><b>Address : </b>" +
                    selectedCase.address +
                    "<br><b>Distance (km) : </b>" +
                    selectedCase.caseDistance +
                    "</div>";
            } else {
                markerContent =
                    '<div class="infoWindowhead">' +
                    "<b>Case Id : </b>" +
                    selectedCase.id +
                    "<br><b>Description : </b>" +
                    selectedCase.description +
                    "<br> <b>Lat : </b>" +
                    selectedCase.pinLat +
                    "<br> <b>Lng : </b>" +
                    selectedCase.pinLong +
                    "<br><b>Address : </b>" +
                    selectedCase.address +
                    "</div>";
            }
            var marker = new google.maps.Marker({
                position: myCenter,
                content: markerContent,
                icon: {
                    url: markerIcon,
                    size: new google.maps.Size(70, 86), //marker image size
                    origin: new google.maps.Point(0, 0), // marker origin
                    anchor: new google.maps.Point(35, 86), // X-axis value (35, half of marker width) and 86 is Y-axis value (height of the marker).
                },
            });

            var infowindow = new google.maps.InfoWindow({
                content: markerContent,
            });
            infowindow.open(map, marker);
            marker.addListener("click", function () {
                // infowindow.open(map, marker);
                TrCaseById.get(selectedCase.id, function (response) {
                    userContent.innerHTML =
                        '<div class="infoWindowhead">' +
                        response.description +
                        '</div><div class="infoWindowContent"> <b>Case Id : </b>' +
                        value.id +
                        "<br> <b>Description : </b>" +
                        value.description +
                        "<br> <b>Reported By :</b> " +
                        response.reportedByUser +
                        "<br> <b> Assigned To :</b> " +
                        response.assignedToUser +
                        "<br> <b>Address :</b> " +
                        response.address +
                        "</div>";

                    infoWindow = new google.maps.InfoWindow({
                        content: userContent,
                    });
                    infoWindow.open(locations.map, startMarker);
                    setTimeout(function () {
                        infoWindow.close();
                    }, 5000);
                });
            });
            marker.setMap(map);

            $("#caseLocationModal").on("show.bs.modal", function (event) {
                $("#location-map-case-location").css("width", "100%");
                $("#map_canvas_case_location").css("width", "100%");
            });
        };

        function resizeMap() {
            setTimeout(function () {
                google.maps.event.trigger(locations.map, "resize");
                locations.map.setCenter(locations.latlng);
            }, 1000);
        }

        function initializeMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                latlng = new google.maps.LatLng(
                    latlangs[0].pinLat,
                    latlangs[0].pinLong
                );
                callback(latlng);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            callback(
                                new google.maps.LatLng("5.431587", "101.128162")
                            );
                        }
                    );
                } else {
                    callback(new google.maps.LatLng("5.431587", "101.128162"));
                }
            }
        }

        $(".select2").select2();

        function assigncasetouser(userId, userLogin) {
            vm.editUserId = userId;
            $scope.edituserLogin = userLogin;
            TrCaseEdit.getalltrcases(function (response) {
                $scope.trallcases = response;
            });
        }
        vm.success = null;
        vm.error = null;

        function editcase() {
            var data = {
                userId: vm.editUserId,
                caseId: vm.seachCaseId,
            };

            /*
             * TrCaseEdit.edittrcase(data, function(response){
             * $scope.edittrcaseresponse=response; vm.success = 'OK';
             *
             * });
             */
            TrCaseEdit.edittrcase(data, function (response, headers) {
                if (response.status == 200) {
                    vm.error = null;
                    vm.success = "OK";
                }
                if (response.status == 400) {
                    vm.success = null;
                    vm.error = "No case found with this case id";
                }
            });
            $timeout(function () {
                $("#assigncaseModal").modal("hide");
                vm.success = null;
                vm.error = null;
                vm.seachCaseId = null;
                vm.caseIds = null;
            }, 2000);
        }
    }

    var uniqueItems = function (data, key) {
        var result = [];

        for (var i = 0; i < data.length; i++) {
            var value = data[i][key];

            if (result.indexOf(value) == -1) {
                result.push(value);
            }
        }
        return result;
    };
})();
