(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("DashboardController", HomeController);

    HomeController.$inject = [
        "$scope",
        "GeoAssetTypeReport",
        "AlertService",
        "ParseLinks",
        "$state",
        "pagingParams",
        "paginationConstants",
        "DashboardServiceGeo",
        "CaseCountByPriority",
        "CaseCountByStatus",
        "$filter",
        "UserListAndDistanceGeo",
        "UserListAndDistanceSearch",
        "$interval",
        "UserMasterGeofences",
        "CaseCountByPriorityGeo",
        "CaseCountByStatusGeo"
    ];

    function HomeController(
        $scope,
        GeoAssetTypeReport,
        AlertService,
        ParseLinks,
        $state,
        pagingParams,
        paginationConstants,
        DashboardServiceGeo,
        CaseCountByPriority,
        CaseCountByStatus,
        $filter,
        UserListAndDistanceGeo,
        UserListAndDistanceSearch,
        $interval,
        UserMasterGeofences,
        CaseCountByPriorityGeo,
        CaseCountByStatusGeo
    ) {
        var vm = this;

        // vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        // vm.transition = transition;
        //vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPage = 4;
        vm.userslist = [];
        vm.filterSearch = filterSearch;
        vm.loaddata = loaddata;

        vm.userMasterGeofences = [];
        vm.selectCityFilter= false;
        vm.currYear = new Date().getFullYear();
        vm.isGeofences = false
        var tempWidth, tempHeight

        loaddata();

        var updateDashboard = $interval(function () {
            // loaddata();
            var date = new Date();

            var weekday = new Array(7);
            weekday[0] = "Sunday";
            weekday[1] = "Monday";
            weekday[2] = "Tuesday";
            weekday[3] = "Wednesday";
            weekday[4] = "Thursday";
            weekday[5] = "Friday";
            weekday[6] = "Saturday";
            vm.day = weekday[date.getDay()];

            var month = new Array();
            month[0] = "January";
            month[1] = "February";
            month[2] = "March";
            month[3] = "April";
            month[4] = "May";
            month[5] = "June";
            month[6] = "July";
            month[7] = "August";
            month[8] = "September";
            month[9] = "October";
            month[10] = "November";
            month[11] = "December";
            vm.month = month[date.getMonth()];
            vm.todayDate = date.getDate();
            vm.year = date.getFullYear();
            var dateFormat = "hh:mm";
            vm.time = $filter("date")(formatAMPM(date), dateFormat);
        }, 10000);
        $scope.$on("$destroy", function () {
            $interval.cancel(updateDashboard);
        });

        var date = new Date();

        var weekday = new Array(7);
        weekday[0] = "Sunday";
        weekday[1] = "Monday";
        weekday[2] = "Tuesday";
        weekday[3] = "Wednesday";
        weekday[4] = "Thursday";
        weekday[5] = "Friday";
        weekday[6] = "Saturday";
        vm.day = weekday[date.getDay()];

        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        vm.month = month[date.getMonth()];
        vm.todayDate = date.getDate();
        vm.year = date.getFullYear();
        var dateFormat = "hh:mm";
        vm.time = $filter("date")(formatAMPM(date), dateFormat);

        $scope.setOrderProperty = function (propertyName) {
            if ($scope.orderProperty === propertyName) {
                $scope.orderProperty = "-" + propertyName;
            } else if ($scope.orderProperty === "-" + propertyName) {
                $scope.orderProperty = propertyName;
            } else {
                $scope.orderProperty = propertyName;
            }
        };

        function loaddata() {
            var geoResult = [];
            var geoGrouped = {};
            var assetTypeResult = [];
            var assetTypeGrouped = {};
            var geofences = [];
            var geoAssetTypeAssetsList = [];

            
            // CaseCountByPriority.get(function (data) {
            //     vm.casepriority = data.casePriority;
            // });

            vm.userMasterGeofences = [];
            UserMasterGeofences.getAll(function (response) {
                if(response.length>0){
                    vm.masterGeofence = response[0].id+"";   
                }
                vm.userMasterGeofences = response;
                CaseCountByPriorityGeo.get(
                    {
                        geofenceId: vm.masterGeofence
                    },
                    onSuccessCasePriorityGeo,
                    onErrorCasePriorityGeo
                );
                function onSuccessCasePriorityGeo(data, headers) {
                    vm.casepriority = data.casePriority;
                }

                function onErrorCasePriorityGeo(error) {
                    AlertService.error(error.data.message);
                }

                UserListAndDistanceGeo.query(
                    {
                        geofenceId: vm.masterGeofence
                    },
                    onSuccessUserListGeo,
                    onErrorUserListGeo
                );
                function onSuccessUserListGeo(data, headers) {
                    vm.userslist = data;
                }
    
                function onErrorUserListGeo(error) {
                    AlertService.error(error.data.message);
                }
                
                DashboardServiceGeo.get(
                    {
                        geofenceId: vm.masterGeofence
                    },
                    onSuccessUserDashboardDataGeo,
                    onErrorUserDashboardDataGeo
                );
                function onSuccessUserDashboardDataGeo(data, headers) {
                    showDashboardData(data);
                }
    
                function onErrorUserDashboardDataGeo(error) {
                    AlertService.error(error.data.message);
                }
                
                CaseCountByStatusGeo.get(
                    {
                        geofenceId: vm.masterGeofence
                    },
                    onSuccessCaseStatusGeo,
                    onErrorCaseStatusGeo
                );
                function onSuccessCaseStatusGeo(data, headers) {
                    showCaseStatus(data);
                }

                function onErrorCaseStatusGeo(error) {
                    AlertService.error(error.data.message);
                }
            });
        }

        function showCaseStatus(data){
            
            var svgtemp = d3.select("#case-chart");
            svgtemp.selectAll("*").remove();

            var dataset = [
                { label: "New", count: data.caseType.NEW },
                { label: "In Progress", count: data.caseType.INPROGRESS },
                { label: "Pending", count: data.caseType.PENDING },
                { label: "Assigned", count: data.caseType.ASSIGNED },
                { label: "Resolved", count: data.caseType.RESOLVED },
                { label: "Cancelled", count: data.caseType.CANCELLED },
                { label: "Closed", count: data.caseType.CLOSED },
            ];

            var width = 240;
            var height = 300;

            try {
                width = document.getElementById("case-chart").offsetWidth;
                height = document.getElementById("case-chart").offsetHeight;
            } catch (error) {
                // console.log(error);
            }

            // var radius = Math.min(width, height) / 2;
            // var donutWidth = 40;

            var radius = Math.min(width, height) / 1.8;
            var legendRectSize = 12;
            var legendSpacing = 3;

            var color = d3.scale
                .ordinal()
                .range([
                    "#FD7A24",
                    "#82eedd",
                    "#C6418F",
                    "#00e658",
                    "#ee8293",
                    "#bbc641",
                ]);

            var svg = d3
                .select("#case-chart")
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("shape-rendering", "geometricPrecision")
                .attr(
                    "transform",
                    "translate(" + width / 2 + "," + height / 2 + ")"
                );
                

            var arc = d3.svg
                .arc()
                .innerRadius(radius - 70)
                .outerRadius(radius - 30);

            var pie = d3.layout
                .pie()
                .value(function (d) {
                    return d.count;
                })
                .sort(null);

            dataset.forEach(function (d) {
                d.count = +d.count;
                d.enabled = true;
            });

            var path = svg
                .selectAll("path")
                .data(pie(dataset))
                .enter()
                .append("path")
                .attr("d", arc)
                .attr("fill", function (d, i) {
                    return color(d.data.label + " - " + d.data.count);
                })
                .each(function (d) {
                    this._current = d;
                });

            var tooltip2 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            path.on("mouseover", function (d) {
                tooltip2
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html(d.data.label + "<br>" + d.value);
                return tooltip2.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip2
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip2.style("visibility", "hidden");
                });

            var legend = svg
                .selectAll(".legend")
                .data(color.domain())
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", function (d, i) {
                    var height = legendRectSize + legendSpacing;
                    var offset = (height * color.domain().length) / 2;
                    var horz = -4.5 * legendRectSize;
                    var vert = i * height - offset;
                    return "translate(" + horz + "," + vert + ")";
                });

            legend
                .append("rect")
                .attr("width", legendRectSize)
                .attr("height", legendRectSize)
                .style("fill", color)
                .style("stroke", color)
                .on("click", function (label) {
                    var rect = d3.select(this);
                    var enabled = true;
                    var totalEnabled = d3.sum(
                        dataset.map(function (d) {
                            return d.enabled ? 1 : 0;
                        })
                    );

                    if (rect.attr("class") === "disabled") {
                        rect.attr("class", "");
                    } else {
                        if (totalEnabled < 2) return;
                        rect.attr("class", "disabled");
                        enabled = false;
                    }

                    pie.value(function (d) {
                        if (d.label === label) d.enabled = enabled;
                        return d.enabled ? d.count : 0;
                    });

                    path = path.data(pie(dataset));

                    path.transition()
                        .duration(750)
                        .attrTween("d", function (d) {
                            var interpolate = d3.interpolate(
                                this._current,
                                d
                            );
                            this._current = interpolate(0);
                            return function (t) {
                                return arc(interpolate(t));
                            };
                        });
                });

            legend
                .append("text")
                .attr("x", legendRectSize + legendSpacing)
                .attr("y", legendRectSize - legendSpacing)
                .style("font-family", "sans-serif")
                .style("font-size", "12px")
                .text(function (d) {
                    return d;
                });
        }

        function showDashboardData(data){
            vm.users = data.users;
            vm.isGeofences = data.geofences.length == 0
            var svgtempstatus = d3.select("#status");
                svgtempstatus.selectAll("*").remove();
            var svgtemp = d3.select("#stacked-chart-status");
                svgtemp.selectAll("*").remove();
            

            // geofence bar graph
            var margin2 = { top: 30, right: 10, bottom: 85, left: 30 };

            var width2 = 960;
            var height2 = 300 - margin2.top - margin2.bottom;

            try {
                width2 =
                    document.getElementById("stacked-chart-status")
                        .offsetWidth -
                    margin2.left -
                    margin2.right;

                
                height2 =
                    document.getElementById("stacked-chart-status")
                        .offsetHeight -
                    margin2.top -
                    margin2.bottom; 

                    if (width2 < 0 || height2 < 0) {
                        width2 = tempWidth;
                        height2 = tempHeight;
                    }
            } catch (error) {
                // console.log(error);
            }
            tempWidth = width2
            tempHeight = height2
            var svg = d3
                .select("#stacked-chart-status")
                .append("svg")
                .attr("width", width2 + margin2.left + margin2.right)
                .attr("height", height2 + margin2.top + margin2.bottom)
                .append("g")
                .attr(
                    "transform",
                    "translate(" + margin2.left + "," + margin2.top + ")"
                )
                .attr("shape-rendering", "geometricPrecision");
            // /* Data in strings like it would be if imported from a csv */

            var cases = data.geofences.map(function (d) {
                return {
                    x:
                        d.label.slice(0, 15) +
                        (d.label.length > 15 ? "..." : ""),
                    y: +d.caseStatusCountDto.critical_priority,
                    x1: d.label,
                };
            });
            // Transpose the data into layers
            //actual data
            var dataset = d3.layout.stack()(
                ["active", "idle", "inactive"].map(function (fruit) {
                    var groupData = data.geofences.slice(0, 40);
                    return groupData.map(function (d) {
                        return {
                            x:
                                d.label.slice(0, 15) +
                                (d.label.length > 15 ? "..." : ""),
                            y: +d.userStatusCount[fruit],
                            x1: d.label,
                        };
                    });
                    // }
                })
            );

            // Set x, y and colors
            var x = d3.scale
                .ordinal()
                .domain(
                    dataset[0].map(function (d) {
                        return d.x;
                    })
                )
                .rangeRoundBands([10, width2 - 50], 0.5);

            var countUsers = d3.max(dataset, function (d) {
                return d3.max(d, function (d) {
                    return d.y0 + d.y;
                });
            })

            if(countUsers >=1){
                var y = d3.scale
                .linear()
                .domain([
                    0,
                    d3.max(dataset, function (d) {
                        return d3.max(d, function (d) {
                            return d.y0 + d.y;
                        });
                    }),
                ])
                .range([height2, 0]);
            }else {
                var y = d3.scale
                .linear()
                .domain([
                    0,
                    d3.max(cases, function (d) {
                            return  d.y;
                    }),
                ])
                .range([height2, 0]);
            }
            
            var colors = ["#00e658", "#FD7A24", "#afafaf"];

            // Define and draw axes
            var yAxis = d3.svg
                .axis()
                .scale(y)
                .orient("left")
                .ticks(5)
                .tickSize(-width2, 0, 0)
                .tickFormat(function (d) { if((d % 1 == 0)){
                    return d
                }});

            var xAxis = d3.svg.axis().scale(x).orient("bottom");
            // .tickFormat(d3.time.format("%y"));

            svg.append("g").attr("class", "y axis").call(yAxis);

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height2 + ")")
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .style("font-family", "sans-serif")
                .style("font-size", "11px")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-45)");

            // Create groups for each series, rects for each segment
            var groups = svg
                .selectAll("g.cost")
                .data(dataset)
                .enter()
                .append("g")
                .attr("class", "cost")
                .style("fill", function (d, i) {
                    return colors[i];
                });

            var rect = groups
                .selectAll("rect")
                .data(function (d) {
                    return d;
                })
                .enter()
                .append("rect")
                .attr("x", function (d) {
                    return x(d.x) + x.rangeBand() / 2;
                })
                .attr("y", function (d) {
                    return y(d.y0 + d.y);
                })
                .attr("height", function (d) {
                    return y(d.y0) - y(d.y0 + d.y);
                })
                .attr("width", x.rangeBand() / 2);

            var caseBar = svg.selectAll(".bar1")
                .data(cases)
                .enter()
                .append("g")
                .attr("class", "bar1")
                .append("rect")
                .attr("x", function (d) {
                    return x(d.x); // center it
                })
                .attr("width", x.rangeBand() / 2) // make it slimmer
                .attr("y", function (d) {
                    return y(d.y);
                })
                .attr("height", function (d) {
                    return height2 - y(d.y);
                });
            // Draw legend
            var svg1 = d3
                .select("#status")
                .append("svg")
                .attr("width", width2 + margin2.left + margin2.right)
                .attr("height", 15)
                .append("g")
                .attr("transform", "translate(" + (margin2.right - 25) + "," + -220 + ")")
                .attr("shape-rendering", "geometricPrecision");

            var legend = svg1
                .selectAll(".legend")
                .data(colors)
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("width", 15)
                .attr("height", 15)
                .attr("transform", function (d, i) {
                    if(i==2){
                        return "translate(" + ((i * 80)-18) + ",30)";
                    }else{
                        return "translate(" + i * 80 + ",30)";
                    }
                })
                // .on("click", function(d) {

                // });
                .on("click", function (d, i) {
                    if (i == 1) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#FD7A24")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(4)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#FD7A24")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(4)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#FD7A24")
                                .style("opacity", 9);
                        }
                    }
                    if (i == 2) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#afafaf")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(5)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#afafaf")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(5)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#afafaf")
                                .style("opacity", 9);
                        }
                    }
                    if (i == 0) {
                        if (
                            d3
                                .select(this)
                                .style("fill" == "#00e658")
                                .style("opacity") == 1
                        ) {
                            d3.selectAll(".cost:nth-child(3)").style(
                                "opacity",
                                0
                            );
                            d3.select(this)
                                .style("fill", "#00e658")
                                .style("opacity", "0.5");
                        } else {
                            d3.selectAll(".cost:nth-child(3)").style(
                                "opacity",
                                9
                            );
                            d3.select(this)
                                .style("fill", "#00e658")
                                .style("opacity", 9);
                        }
                    }
                });
            // .attr("transform", function(d,i) {
            // 	return "translate(" + width2 + "," + i * height2 + ")"; })

            legend
                .append("circle")
                .attr("cx", width2 / 2 - 96)
                .attr("cy", height2 + 67)
                .attr("r", 6)
                .style("fill", function (d, i) {
                    return colors[i];
                });

            legend
                .append("text")
                .attr("x", width2 / 2 - 85)
                .attr("y", height2 + 67)
                .attr("dy", ".35em")
                .style("text-anchor", "start")
                .style("font-family", "sans-serif")
                .style("font-size", "12px")
                .style("font-weight", "700")
                .style("cursor", "pointer")
                .text(function (d, i) {
                    switch (i) {
                        case 0:
                            return "ACTIVE";
                        case 1:
                            return "IDLE";
                        case 2:
                            return "INACTIVE";
                    }
                });

            var tooltip1 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");

            rect.on("mouseover", function (d) {
                tooltip1
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html(d.x1 + "<br>" + d.y);
                return tooltip1.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip1
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip1.style("visibility", "hidden");
                });
                
            caseBar.on("mouseover", function (d) {
                tooltip1
                    .style("left", d3.event.pageX - 50 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("fill", "black")
                    .html("Critical Case Count" + "<br>" + d.y);
                return tooltip1.style("visibility", "visible");
            })
                .on("mousemove", function () {
                    return tooltip1
                        .style("top", d3.event.pageY - 10 + "px")
                        .style("left", d3.event.pageX + 10 + "px");
                })
                .on("mouseout", function () {
                    return tooltip1.style("visibility", "hidden");
                });
        }

        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? "0" + minutes : minutes;
            var strTime = hours + ":" + minutes + " " + ampm;
            return strTime;
        }

        function filterSearch() {
            if (
                vm.search != null &&
                vm.search != "" &&
                !angular.isUndefined(vm.search)
            ) {
                UserListAndDistanceSearch.query({}, onSuccess, onError);
            } else {
                UserListAndDistanceGeo.query(
                    {
                        geofenceId: vm.masterGeofence
                    },
                    onSuccessUserListGeo,
                    onErrorUserListGeo
                );
                function onSuccessUserListGeo(data, headers) {
                    vm.userslist = data;
                }
    
                function onErrorUserListGeo(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                result.push("id");
            }
            return result;
        }

        function onSuccess(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            // vm.totalItems = headers('X-Total-Count');
            // vm.queryCount = vm.totalItems;
            vm.userslist = data;
            // vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        // $('#masterGeofence').on('change', function() {
        $scope.optionChange = function(){
            vm.masterGeofence = document.getElementById("masterGeofence").value;  
            CaseCountByPriorityGeo.get(
                {
                    geofenceId: vm.masterGeofence
                },
                onSuccessCasePriorityGeo,
                onErrorCasePriorityGeo
            );
            function onSuccessCasePriorityGeo(data, headers) {
                vm.casepriority = data.casePriority;
            }

            function onErrorCasePriorityGeo(error) {
                AlertService.error(error.data.message);
            }

            UserListAndDistanceGeo.query(
                {
                    geofenceId: vm.masterGeofence
                },
                onSuccessUserListGeo,
                onErrorUserListGeo
            );
            function onSuccessUserListGeo(data, headers) {
                vm.userslist = data;
            }

            function onErrorUserListGeo(error) {
                AlertService.error(error.data.message);
            }
            
            DashboardServiceGeo.get(
                {
                    geofenceId: vm.masterGeofence
                },
                onSuccessUserDashboardDataGeo,
                onErrorUserDashboardDataGeo
            );
            function onSuccessUserDashboardDataGeo(data, headers) {
                showDashboardData(data);
            }

            function onErrorUserDashboardDataGeo(error) {
                AlertService.error(error.data.message);
            }

            CaseCountByStatusGeo.get(
                {
                    geofenceId: vm.masterGeofence
                },
                onSuccessCaseStatusGeo,
                onErrorCaseStatusGeo
            );
            function onSuccessCaseStatusGeo(data, headers) {
                showCaseStatus(data);
            }

            function onErrorCaseStatusGeo(error) {
                AlertService.error(error.data.message);
            }
        }

        // function loadPage(page) {
        //     vm.page = page;
        //     vm.transition();
        // }

        // function transition() {
        //     $state.transitionTo($state.$current, {
        //         page: vm.page,
        //         sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
        //         search: vm.currentSearch
        //     });
        // }
    }
})();
