(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tr-row', {
                parent: 'app',
                url: '/tr-row',
                data: {
                    authorities: ['SECTION_SIDEBAR'],
                    pageTitle: 'trakeyeApp.row.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-row/tr-rows.html',
                        controller: 'TrRowController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                    projectId: null,
                    routeId : null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            projectId: $stateParams.projectId,
                            routeId :  $stateParams.routeId,
                            rangeOption : $stateParams.rangeOption,
                            fromTime : $stateParams.fromTime ,
                            toTime : $stateParams.toTime
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trRow');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-row-detail', {
                parent: 'tr-row',
                url: '/tr-row/{id}',
                data: {
                    authorities: ['SECTION_DETAIL'],
                    pageTitle: 'trakeyeApp.row.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-row/tr-row-detail.html',
                        controller: 'TrRowDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trRow');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'TrRow', function ($stateParams, TrRow) {
                        return TrRow.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-row',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('tr-row.new', {
                parent: 'tr-row',
                url: '/create/{lat}/{lng}/{assetdetails}/{desc}/{routeId}/{sectionId}',
                data: {
                    authorities: ['SECTION_CREATE']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-row/tr-row-dialog.html',
                        controller: 'TrRowDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            description: $stateParams.desc,
                            createDate: null,
                            updateDate: null,
                            pinLat: parseFloat($stateParams.lat),
                            pinLong: parseFloat($stateParams.lng),
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            rowImages: []
                            // ,
                            // asset: {name: $stateParams.assetdetails}
                        };
                    }],

                    route : ['$stateParams', 'TrRoute', function ($stateParams, TrRoute) {

                        if($stateParams.routeId){
                            return TrRoute.get({ id: $stateParams.routeId }).$promise;
                        }else{
                            return null
                        }
                        
                    }],

                    section : ['$stateParams', 'TrSection', function ($stateParams, TrSection) {

                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                        
                    }]
                }
            })

        .state('tr-row.import', {
                parent: 'tr-row',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-row/tr-row-import.html',
                        controller: 'TrRowImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            rowImages: []
                        };
                    }
                }

            })
            .state('tr-row.edit', {
                parent: 'tr-row',
                url: '/Edit/{id}/{routeId}/{sectionId}',
                data: {
                    authorities: ['SECTION_EDIT'],
                    pageTitle: 'trakeyeApp.row.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-row/tr-row-dialog.html',
                        controller: 'TrRowDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'TrRow', function ($stateParams, TrRow) {
                        return TrRow.get({ id: $stateParams.id }).$promise;
                    }],


                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-row',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],

                    route : ['$stateParams', 'TrRoute', function ($stateParams, TrRoute) {

                        if($stateParams.routeId){
                            return TrRoute.get({ id: $stateParams.routeId }).$promise;
                        }else{
                            return null
                        }
                        
                    }],

                    section : ['$stateParams', 'TrSection', function ($stateParams, TrSection) {

                        if($stateParams.sectionId){
                            return TrSection.get({ id: $stateParams.sectionId }).$promise;
                        }else{
                            return null
                        }
                        
                    }]
                }
            })
    }
})();