(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrTerrainDetailController', TrTerrainDetailController);

    TrTerrainDetailController.$inject = ['$scope', '$rootScope', 'entity', '$window', '$localStorage'];

    function TrTerrainDetailController($scope, $rootScope, entity, $window, $localStorage) {
        var vm = this;

        vm.trTerrain = entity;
        var unsubscribe = $rootScope.$on('trakeyeApp:trTerrainUpdate', function (event, result) {
            vm.trTerrain = result;
        });

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };
        $scope.setTab = function (newTab) {
            if(newTab == 2){
                //Comment Box
            vm.objectComments = {}
            vm.objectComments.entityId = entity.id
            vm.objectComments.entityName = "TERRAIN"
            } 
            $scope.tab = newTab;
        };

       /*  vm.objectComments = {}
        vm.objectComments.entityId = entity.id
        vm.objectComments.entityName = "TERRAIN" */

    }
})();
