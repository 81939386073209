(function () {
    'use strict';

    angular.module('trakeyeApp').controller('VehicleManagementDialogController', VehicleManagementDialogController);

    VehicleManagementDialogController.$inject = ['$rootScope', '$scope', '$state', 'entity', 'Vehicle', 'JhiLanguageService', 'Principal', 'ReportingNeUsers'
    ];

    function VehicleManagementDialogController($rootScope, $scope, $state, entity, Vehicle, JhiLanguageService, Principal, ReportingNeUsers) {
        $scope.IMEI_REGEXP = /^[0-9]+$/;
        var vm = this;

        vm.languages = null;
        vm.save = save;
        vm.vehicle = entity;
        vm.multiUser = [];
        var selectedUsers = [];
        vm.selectedUserId = [];

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        if(vm.vehicle.attachedTo != null){
            selectedUsers = vm.vehicle.attachedTo;
        }

        ReportingNeUsers.getAll(function (response) {
            response.forEach(function (user) {
                // if (user.authorities.includes("ROLE_NETWORK_ENGINEER")) {
                    if (selectedUsers == user.id) {
                        vm.multiUser.push({ login: user.login, id: user.id, ticked: true });
                    } else {
                        vm.multiUser.push({ login: user.login, id: user.id, ticked: false });
                    }
                // }
            });
        });

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go('vehicle-management');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.selectedUserId.forEach(function(user){
                vm.vehicle.attachedTo = user.id;
            });
            vm.isSaving = true;
            if (vm.vehicle.id !== null) {
                Vehicle.update(vm.vehicle, onSaveSuccess, onSaveError);
            } else {
                Vehicle.save(vm.vehicle, onSaveSuccess, onSaveError);
            }
        }
    }
})();