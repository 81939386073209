(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("work-order", {
                parent: "app",
                url: "/work-order",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: "trakeyeApp.workOrder.home.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/work-order/work-orders.html",
                        controller: "WorkOrderController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "update_date,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("workOrder");
                            $translatePartialLoader.addPart("workOrderStatus");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("work-order-detail", {
                parent: "work-order",
                url: "/work-order/{id}",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: "trakeyeApp.workOrder.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/work-order/work-order-detail.html",
                        controller: "WorkOrderDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("workOrder");
                            $translatePartialLoader.addPart("workOrderStatus");
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "WorkOrder",
                        function ($stateParams, WorkOrder) {
                            return WorkOrder.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "work-order",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })

            .state("work-order.priority", {
                parent: "work-order",
                url: "/work-order/{priority}",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: "trakeyeApp.workOrder.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl: "app/entities/work-order/work-orders.html",
                        controller: "WorkOrderController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "update_date,desc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                                priority: $stateParams.priority,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("workOrder");
                            $translatePartialLoader.addPart("workOrderStatus");
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })

            .state("work-order.new", {
                parent: "work-order",
                url: "/create/{lat}/{lng}/{assetdetails}/{desc}",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/work-order/work-order-dialog.html",
                        controller: "WorkOrderDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                description: $stateParams.desc,
                                createDate: null,
                                updateDate: null,
                                pinLat: parseFloat($stateParams.lat),
                                pinLong: parseFloat($stateParams.lng),
                                address: null,
                                escalated: null,
                                status: null,
                                id: null,
                                workOrderImages: [],
                                // ,
                                // asset: {name: $stateParams.assetdetails}
                            };
                        },
                    ],
                },
            })

            .state("work-order.import", {
                parent: "work-order",
                url: "/import",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/work-order/work-order-import.html",
                        controller: "WorkOrderImportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: function () {
                        return {
                            description: null,
                            createDate: null,
                            updateDate: null,
                            pinLat: null,
                            pinLong: null,
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            workOrderImages: [],
                        };
                    },
                },
            })
            .state("work-order.edit", {
                parent: "work-order",
                url: "/Edit/{id}",
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER', 'ROLE_NETWORK_ENGINEER', 'ROLE_FRT', 'ROLE_PATROLLER', 'ROLE_PLAN_ADMIN', 'ROLE_PLAN_INFRA', 'ROLE_PLAN_SURVEY', 'ROLE_ROLLOUT_ADMIN', 'ROLE_ROLLOUT_INFRA', 'ROLE_ROLLOUT_SURVEY'],
                    pageTitle: "trakeyeApp.workOrder.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/work-order/work-order-dialog.html",
                        controller: "WorkOrderDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: [
                        "$stateParams",
                        "WorkOrder",
                        function ($stateParams, WorkOrder) {
                            return WorkOrder.get({ id: $stateParams.id })
                                .$promise;
                        },
                    ],

                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name: $state.current.name || "work-order",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })

            .state("work-order.delete", {
                parent: "work-order",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_USER_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/work-order/work-order-delete-dialog.html",
                                controller: "WorkOrderDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "WorkOrder",
                                        function (WorkOrder) {
                                            return WorkOrder.get({
                                                id: $stateParams.id,
                                            }).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go("work-order", null, {
                                        reload: "work-order",
                                    });
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            });
    }
})();
