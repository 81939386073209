(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrWorkOrder', TrWorkOrder);

	TrWorkOrder.$inject = ['$resource', 'DateUtils'];

	function TrWorkOrder($resource, DateUtils) {
		var resourceUrl = 'api/tr-workorders/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGeofence', TrWorkOrderGeofence);

	TrWorkOrderGeofence.$inject = ['$resource'];

	function TrWorkOrderGeofence($resource) {
		var service = $resource('/api/tr-workorders/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderCustomTime', TrWorkOrderCustomTime);

	TrWorkOrderCustomTime.$inject = ['$resource'];

	function TrWorkOrderCustomTime($resource) {
		var service = $resource('/api/tr-workorders-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGeofenceCustomTime', TrWorkOrderGeofenceCustomTime);

	TrWorkOrderGeofenceCustomTime.$inject = ['$resource'];

	function TrWorkOrderGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-workorders-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrWorkOrderCheckAsset', TrWorkOrderCheckAsset);

	TrWorkOrderCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrWorkOrderCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
    'use strict';
    angular.module('trakeyeApp').factory('WorkOrdersForAsset', WorkOrdersForAsset);

    WorkOrdersForAsset.$inject = ['$resource'];

    function WorkOrdersForAsset($resource) {
        var resourceUrl = 'api/workOrders-for-asset/:assetId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-workorders/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getWorkOrderImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyeworkOrderimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id,data,callback) {
			$http.post('api/assets/nearby-fixed/' + id,data).then(function (response) {
				callback(response.data);
			}); 
		};
		return service;
	}]);
})(); 

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-workorders-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('WorkOrderAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-workorder-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderById', TrWorkOrderById);

	TrWorkOrderById.$inject = [ '$resource' ];

	function TrWorkOrderById($resource) {
		var service = $resource('/api/tr-workorders/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/workOrdercountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadWorkOrderReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-workorders').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/workOrdercountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderPriority', TrWorkOrderPriority);

	TrWorkOrderPriority.$inject = ['$resource'];

	function TrWorkOrderPriority($resource) {
		var service = $resource('/api/tr-workorders/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, {geofenceId : '@geofenceId'},{}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderAttendance', TrWorkOrderAttendance);

	TrWorkOrderAttendance.$inject = ['$resource'];

	function TrWorkOrderAttendance($resource) {
		var service = $resource('/api/tr-workorders/attendance/:login/:fromTime/:toTime/:workOrderStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderSearch', TrWorkOrderSearch);

	TrWorkOrderSearch.$inject = ['$resource'];

	function TrWorkOrderSearch($resource) {
		var service = $resource('/api/tr-workorders/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderSearchCustom', TrWorkOrderSearchCustom);

	TrWorkOrderSearchCustom.$inject = ['$resource'];

	function TrWorkOrderSearchCustom($resource) {
		var service = $resource('/api/tr-workorders-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGeofenceSearch', TrWorkOrderGeofenceSearch);

	TrWorkOrderGeofenceSearch.$inject = ['$resource'];

	function TrWorkOrderGeofenceSearch($resource) {
		var service = $resource('/api/tr-workorders/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGeofenceSearchCustom', TrWorkOrderGeofenceSearchCustom);

	TrWorkOrderGeofenceSearchCustom.$inject = ['$resource'];

	function TrWorkOrderGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-workorders-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrWorkOrderGroup', TrWorkOrderGroup);

	TrWorkOrderGroup.$inject = ['$resource', 'DateUtils'];

	function TrWorkOrderGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-workorders-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGroupSearch', TrWorkOrderGroupSearch);

	TrWorkOrderGroupSearch.$inject = ['$resource'];

	function TrWorkOrderGroupSearch($resource) {
		var service = $resource('/api/tr-workorders-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGroupPrioritySearch', TrWorkOrderGroupPrioritySearch);

	TrWorkOrderGroupPrioritySearch.$inject = ['$resource'];

	function TrWorkOrderGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-workorders-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderGroupPriority', TrWorkOrderGroupPriority);

	TrWorkOrderGroupPriority.$inject = ['$resource'];

	function TrWorkOrderGroupPriority($resource) {
		var service = $resource('/api/tr-workorders-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderImages', TrWorkOrderImages);

	TrWorkOrderImages.$inject = ['$resource'];

	function TrWorkOrderImages($resource) {
		var service = $resource('/api/workOrder-images/:workOrderId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrdersForType', TrWorkOrdersForType);

	TrWorkOrdersForType.$inject = ['$resource'];

	function TrWorkOrdersForType($resource) {
		var service = $resource('/api/trworkOrdersbytype/:workOrderTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
 (function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data,success,error) {
			$http.post('api/tr-workorders/nearby-bgoh-assets',data).then(function (response) {
				success(response.data);
			},function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();  

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderRecordings', TrWorkOrderRecordings);

	TrWorkOrderRecordings.$inject = ['$resource'];

	function TrWorkOrderRecordings($resource) {
		var service = $resource('/api/workOrder-recordings/:workOrderId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllWorkOrderTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/trworkorder-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderPrioritySearch', TrWorkOrderPrioritySearch);

	TrWorkOrderPrioritySearch.$inject = ['$resource'];

	function TrWorkOrderPrioritySearch($resource) {
		var service = $resource('/api/tr-workorders/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrWorkOrderUpdateGroup', TrWorkOrderUpdateGroup);

	TrWorkOrderUpdateGroup.$inject = ['$resource'];

	function TrWorkOrderUpdateGroup($resource) {
		var resourceUrl = 'api/tr-workorders/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrWorkOrderBulkDelete', TrWorkOrderBulkDelete);

	TrWorkOrderBulkDelete.$inject = ['$resource'];

	function TrWorkOrderBulkDelete($resource) {
		var resourceUrl = 'api/tr-workorders/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrworkOrdersuser = function (callback) {
			$http.get('api/tr-workorders/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrworkOrdersuserbyid = function (id, callback) {
			$http.get('api/tr-workorders/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrworkOrdersuserbyvalue = function (id, callback) {
			$http.get('api/tr-workorders/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrworkOrdersanduserbypriority = function (priority, callback) {
			$http.get('api/tr-workorders/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrworkOrders = function (callback) {
			$http.get('api/tr-allworkOrders').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrworkOrder = function (data, callback) {
			$http.post('api/tr-workorderedit/' + data.userId + '/' + data.workOrderId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-workorderedit/'+data.userId+'/'+data.workOrderId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrWorkOrderUpload', TrWorkOrderUpload);

	TrWorkOrderUpload.$inject = ['$http'];

	function TrWorkOrderUpload($http) {

		var service = {};
		service.trworkOrderupload = function (data, callback) {

			$http.post('api/workOrderimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupWorkOrderSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrworkOrderformap = function (search, callback) {
			$http.get('api/tr-groupworkOrdersformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupWorkOrderForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllWorkOrders = function (callback) {
			$http.get('api/tr-groupworkOrdersformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrworkOrderformap = function (search, callback) {
			$http.get('api/tr-workordersformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrworkOrderformap = function (priority, callback) {
			$http.get('api/tr-workordersformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrWorkOrderLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('WorkOrdersForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.workOrdersformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-workordersformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrWorkOrderForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllWorkOrders = function (data, callback) {
			// $http.get('api/tr-workordersformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-workordersformap', data).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ShowWorkOrderById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (workOrderid, callback) {
            $http.put('api/show-workorder/' + workOrderid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('HideWorkOrderById', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.update = function (workOrderid, callback) {
            $http.put('api/hide-workorder/' + workOrderid)
			.success(function (data, status, headers, config) {
				callback(status);
            })
            .error(function (data, status, header, config) {
				callback(status);
            });
        };

        return service;
    }]);
})();

/* (function () {
    'use strict';

    angular.module('trakeyeApp').factory('assignTasks', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};

        service.post = function (payload, callback) {
            $http.post('api/tr-user-bulk-tasks' , payload)
			.success(function (response) {
				callback(response.data);
            })
            .error(function (err) {
				callback(err);
            });
        };

        return service;
    }]);
})(); */
