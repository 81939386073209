(function () {
    "use strict";
    angular.module("trakeyeApp").factory("WorkFlowType", WorkFlowType);

    WorkFlowType.$inject = ["$resource", "DateUtils"];

    function WorkFlowType($resource, DateUtils) {
        var resourceUrl = "api/work-flow-types/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();
(function () {
    "use strict";

    angular.module("trakeyeApp").factory("WorkFlowTypeSearch", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getworkflowtypebyname = function (data, callback) {
                $http
                    .get("api/work-flow-types-details/" + data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();
(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .factory("WorkFlowTypeSearch", WorkFlowTypeSearch);

    WorkFlowTypeSearch.$inject = ["$resource"];

    function WorkFlowTypeSearch($resource) {
        var service = $resource(
            "/api/work-flow-types/searchvalue/:userId",
            { userId: "@id" },
            {},
            {
                query: { method: "GET", isArray: true },
            }
        );

        return service;
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("AllCustomWorkFlowTypeAttribute", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAll = function (callback) {
                $http
                    .get("api/all-custom-workflow-type-attributes")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();
