(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider 
            .state("attribute-mapping", {
                parent: "entity",
                url: "/custom-case-type-mappers?page&sort&search",
                data: {
                    pageTitle: "trakeyeApp.caseType.home.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/attributeMapping/attribute-mapping.html",
                        controller: "AttributeMapping",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart("caseType");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state('attribute-mapping.new', {
                parent : 'attribute-mapping',
                url : '/new',
                data : {
                    authorities : [ 'ROLE_USER_ADMIN' ]
                },
                views : {
                    'content@' : {
                        templateUrl: 'app/entities/attributeMapping/attribute-mapping-dialog.html',
                        controller: 'AttributeMappingDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null
                        };
                    }
                }
            })
            .state('attribute-mapping.delete', {
                parent: 'attribute-mapping',
                url: '/{id}',
                data: {
                    authorities: [ 'ROLE_USER_ADMIN' ],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/attributeMapping/attribute-mapping-delete-dialog.html',
                        controller: 'AttributeMappingDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            
                        }
                    }).result.then(function() {
                        $state.go('attribute-mapping', null, { reload: 'attribute-mapping' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
