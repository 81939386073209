(function() {
    'use strict';

    angular.module('trakeyeApp').controller('ReportController', ReportController)

    ReportController.$inject = ['$scope', '$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval'];

    function ReportController($scope, $state, Report, $rootScope, $timeout, $window, $filter, $interval) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.reportTypeChange = reportTypeChanged;
        vm.users = [];
        vm.batteryusers = [];
        vm.geofences = [];
        $scope.reporthide = false;
        vm.select2 = select2;
        var dateFormat = 'dd EEE HH:mm';
        var date = "dd EEE";
        vm.fromTime = 0;
        vm.toTime = 0;
        vm.today = today();
        // vm.today();
        vm.select2();
        Report.getUsers('', function(response) {
            vm.batteryusers = response;

        })

        function today() {
            var today = new Date();
            vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            vm.fromDate.setHours(0, 0, 0, 0);
            vm.fromTime = vm.fromDate.getTime();
            vm.toDate.setHours(23, 59, 59, 999);
            vm.toTime = vm.toDate.getTime();
        }

        $scope.report = function() {
            if ((vm.reportType == 'geofence' && vm.geofence == 'all') || (vm.reportType == 'agent' && vm.userAgent == 'all')) {
                vm.id = 0;
            }
            Report.getReport({
                id: vm.id,
                fromTime: vm.fromTime,
                toTime: vm.toTime,
                reportType: vm.reportType
            }, function(response) {

                if (response instanceof Array) {
                    vm.reportRecords = response;
                } else {
                    vm.reportRecords = [];
                    vm.reportRecords.push(response);
                }
                $scope.search = "";
                $scope.reporthide = true;
            })

        }
        vm.detailedReport = detailedReport;

        vm.selecterange = selectRange;
        vm.calculateToDate = calculateToDate;
        vm.calculateFromDate = calculateFromDate;

        function calculateFromDate() {

            vm.fromDate.setHours(0, 0, 0, 0);
            vm.fromTime = vm.fromDate.getTime();
        }

        function calculateToDate() {

            vm.toDate.setHours(23, 59, 59, 999);
            vm.toTime = vm.toDate.getTime();
        }

        function selectRange(range) {
            if (range == 'week') {
                var date = new Date();
                date.setHours(23, 59, 59, 999);
                vm.toTime = date.getTime();
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 7)
                vm.fromTime = date.getTime();

            } else if (range == 'month') {

                var date = new Date();
                date.setHours(23, 59, 59, 999);
                vm.toTime = date.getTime();
                date.setHours(0, 0, 0, 0);
                date.setMonth(date.getMonth() - 1);
                vm.fromTime = date.getTime();
            } else if (range == 'year') {

                var date = new Date();
                date.setHours(23, 59, 59, 999);
                vm.toTime = date.getTime();
                date.setHours(0, 0, 0, 0);
                date.setYear(date.getFullYear() - 1);
                vm.fromTime = date.getTime();
            }
        }

        function select2() {
            $(".select2").select2();
        }

        function reportTypeChanged() {
            $scope.reporthide = false;
            if (vm.reportType == 'agent') {
                if (vm.users.length == 0) {
                    Report.getUsers('', function(response) {
                        vm.users = response;
                    })
                }
            } else if (vm.reportType == 'geofence') {
                if (vm.geofences.length == 0) {
                    Report.getGeofences('', function(response) {
                        vm.geofences = response;
                    })
                }
            }

        }
        // Added for generating pdf start
        $scope.close = function() {
            $('#genericReport').show();
            $scope.reporthide = true;
            $('#report').hide();
        }
        $scope.loader = false;
        $(function() {
            $scope.getPdf = function() {
                $scope.loader = true;
                html2canvas(reportpdf, {
                    onrendered: function(canvas) {
                        var img = canvas.toDataURL("image/png");
                        var pdf = new jsPDF('landscape', 'mm', 'a2');
                        pdf.addImage(img, 'JPEG', 0, 0);
                        pdf.save($scope.pdftype);
                        $scope.loader = false;
                    }
                });
                $interval(function() {}, 1000);
            }
        });
        // Added for generating pdf end
        function detailedReport(id) {
            Report.getDetailedReport({
                id: id,
                fromTime: vm.fromTime,
                toTime: vm.toTime,
                reportType: vm.reportType
            }, function(response) {
                vm.detailedReportCounts = response;

                $('#report').show();
                $scope.reporthide = false;
                $('#genericReport').hide();

                if (vm.reportType == 'agent') {
                    $scope.pdftype = 'Agent.pdf';
                    /*var map = new google.maps.Map(document.getElementById('map_canvas'), {
                    	zoom : 13,
                    	center : new google.maps.LatLng(response.locations[0].lat, response.locations[0].lng)
                    });
                    var flightPath = new google.maps.Polyline({
                    	path : response.locations,
                    	geodesic : true,
                    	strokeColor : "#425cf4",
                    	strokeOpacity : 1,
                    	strokeWeight : 2,
                    	icons : [ {
                    		icon : {
                    			path : google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                    		},
                    		offset : '25px',
                    		repeat : '50px'
                    	} ]
                    });
                    var startMarker = new google.maps.Marker({
                    	map : map,
                    	position : new google.maps.LatLng(response.locations[0].lat, response.locations[0].lng),
                    	title : 'Started here'
                    });
                    startMarker.setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png');
                    var endMarker = new google.maps.Marker({
                    	map : map,
                    	position : new google.maps.LatLng(response.locations[response.locations.length - 1].lat, response.locations[response.locations.length - 1].lng),
                    	title : 'Ended here'
                    });
                    endMarker.setIcon('http://maps.google.com/mapfiles/ms/icons/red-dot.png');
                    flightPath.setMap(map);
                    setTimeout(function() {
                    	google.maps.event.trigger(map, "resize");
                    	map.setCenter(new google.maps.LatLng(response.locations[0].lat, response.locations[0].lng));
                    }, 100);*/

                } else if (vm.reportType == 'geofence') {
                    $scope.pdftype = 'Geofence.pdf';
                    var coordicates = JSON.parse(response.coordinates);
                    var map = new google.maps.Map(document.getElementById('map_canvas'), {
                        zoom: 13,
                        center: new google.maps.LatLng(coordicates[0].lat, coordicates[0].lng)
                    });

                    var polygon = new google.maps.Polygon({
                        path: coordicates,
                        strokeColor: "#ff0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#ff0000",
                        fillOpacity: 0.3,
                        editable: true
                    })
                    polygon.setMap(map);
                    setTimeout(function() {
                        google.maps.event.trigger(map, "resize");
                        map.setCenter(new google.maps.LatLng(coordicates[0].lat, coordicates[0].lng));
                    }, 100);
                }

            })
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;
        vm.datePickerOpenStatus.serviceDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.batteryreport = function() {
            vm.fromDate.setHours(0, 0, 0, 0);
            vm.fromTime = vm.fromDate.getTime();
            vm.toDate.setHours(23, 59, 59, 999);
            vm.toTime = vm.toDate.getTime();

            Report.getbatteryReport(vm.userid, vm.fromTime, vm.toTime, function(response) {
                $scope.batteryline = response;
                $scope.battery = [];
                $scope.createTime = [];
                angular.forEach($scope.batteryline, function(value, key) {
                    $scope.battery.push(value.batteryValue);
                    $scope.createTime.push($filter('date')(value.createTime, dateFormat));
                });

                Highcharts.chart('battery_graph', {

                    title: {
                        text: 'Battery Usage Report'
                    },

                    subtitle: {
                        text: ''
                    },

                    yAxis: {
                        title: {
                            text: 'Battery Percentage'
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    xAxis: {
                        categories: $scope.createTime
                    },

                    series: [{
                        name: ' ',
                        data: $scope.battery
                    }],
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [{
                                    textKey: 'downloadPNG',
                                    onclick: function() {
                                        var fileName = "battery_report";
                                        this.exportChart({
                                            filename: fileName
                                        });
                                    }
                                }, {
                                    textKey: 'downloadPDF',
                                    onclick: function() {
                                        this.exportChart({
                                            type: 'application/pdf'
                                        });
                                    }
                                }]
                            }
                        }
                    }

                });

            })
        }

        $scope.distancereport = function() {
            vm.fromDate.setHours(0, 0, 0, 0);
            vm.fromTime = vm.fromDate.getTime();
            vm.toDate.setHours(23, 59, 59, 999);
            vm.toTime = vm.toDate.getTime();

            Report.getDistanceReport(vm.userid, vm.fromTime, vm.toTime, function(response) {
                $scope.distanceData = response;
                $scope.distance = [];
                $scope.date = [];

                angular.forEach($scope.distanceData, function(value, key) {
                    $scope.distance.push(value.distance);

                    // $scope.date.push($filter('date')(value.date,"dd EEE"));
                    $scope.date.push(value.date);
                });

                Highcharts.chart('distance_graph', {

                    title: {
                        text: 'Distance Travelled Report'
                    },

                    subtitle: {
                        text: ''
                    },

                    yAxis: {
                        title: {
                            text: 'Distance Travelled in KM'
                        }
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    xAxis: {
                        categories: $scope.date
                    },
                    series: [{
                        name: 'Distance in km',
                        data: $scope.distance
                    }],
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [{
                                    textKey: 'downloadPNG',
                                    onclick: function() {
                                        var fileName = "distance_report";
                                        this.exportChart({
                                            filename: fileName
                                        });
                                    }
                                }, {
                                    textKey: 'downloadPDF',
                                    onclick: function() {
                                        this.exportChart({
                                            type: 'application/pdf'
                                        });
                                    }
                                }]
                            }
                        }
                    }

                });

            })
        }


        var data = [
            { "date": "2012-01-01", "value": 4.13 },
            { "date": "2012-01-02", "value": 4.88 },
            { "date": "2012-01-03", "value": 5.89 },
            { "date": "2012-01-04", "value": 5.87 },
            { "date": "2012-01-05", "value": 8.54 },
            { "date": "2012-01-06", "value": 4.41 },
            { "date": "2012-01-07", "value": 8.56 },
            { "date": "2012-01-08", "value": 8.05 },
            { "date": "2012-01-09", "value": 8.13 },
            { "date": "2012-01-10", "value": 5.86 }
        ]

        /* Monday 2012 */
        var data1 = data;
        // var title1 = "Line graph";
        var dateformat = "%Y-%m-%d";
        //drawTimeSeriesGraph(data1, dateformat);

        /* 
        Tooltip from: http://bl.ocks.org/d3noob/6eb506b129f585ce5c8a
        line graph from here: http://www.d3noob.org/2012/12/starting-with-basic-d3-line-graph.html
        */

        function drawTimeSeriesGraph(data, dateformat) {

            //Set bounds for red dots
            var lbound = 0.045,
                ubound = 0.075;

            // Set the dimensions of the canvas / graph
            var margin = { top: 50, right: 200, bottom: 30, left: 20 },
                width = document.getElementById('line-chart').offsetWidth - margin.left - margin.right,
                height = document.getElementById('line-chart').offsetHeight - margin.top - margin.bottom;

            // Parse the date / time
            var parseDate = d3.time.format(dateformat).parse,
                formatDate = d3.time.format(dateformat),
                bisectDate = d3.bisector(function(d) { return d.date; }).left;

            // Set the ranges
            var x = d3.time.scale().range([0, width]);
            var y = d3.scale.linear().range([height, 0]);

            // Define the axes
            var xAxis = d3.svg.axis().scale(x)
                .orient("bottom").ticks(10);

            var yAxis = d3.svg.axis().scale(y)
                .orient("left").ticks(10);

            // Define the line
            var valueline = d3.svg.line()
                .x(function(d) { return x(d.date); })
                .y(function(d) { return y(d.value); });

            // Adds the svg canvas
            var svg = d3.select("#line-chart")
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");

            var lineSvg = svg.append("g")
                .attr("shape-rendering", "geometricPrecision");

            var focus = svg.append("g")
                .style("display", "none");

            // Get the data

            data.forEach(function(d) {
                d.date = parseDate(d.date);
                d.value = +d.value;
            });

            // Scale the range of the data
            x.domain(d3.extent(data, function(d) { return d.date; }));
            y.domain([0, d3.max(data, function(d) { return d.value; })]);
            //Use below if instead you want to define the y limits:
            //y.domain([0, 0.11]);

            // Add the valueline path.
            lineSvg.append("path")
                .attr("class", "line")
                .attr("d", valueline(data))
                .attr("stroke", "steelblue")
                .attr("stroke-width", 2)
                .attr("fill", "none")


            // Add the X Axis
            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis);

            // Add the Y Axis
            svg.append("g")
                .attr("class", "y axis")
                .call(yAxis);

            // append the x line
            focus.append("line")
                .attr("class", "x")
                .style("stroke", "blue")
                .style("stroke-dasharray", "3,3")
                .style("opacity", 0.5)
                .attr("y1", 0)
                .attr("y2", height)
                .attr("fill", "none");

            // append the y line
            focus.append("line")
                .attr("class", "y")
                .style("stroke", "blue")
                .style("stroke-dasharray", "3,3")
                .style("opacity", 0.5)
                .attr("x1", width)
                .attr("x2", width)
                .attr("fill", "none");

            // append the circle at the intersection
            focus.append("circle")
                .attr("class", "y")
                .style("fill", "blue")
                .style("stroke", "blue")
                .attr("r", 4);

            // place the value at the intersection
            focus.append("text")
                .attr("class", "y1")
                .style("stroke", "white")
                .style("stroke-width", "3.5px")
                .style("opacity", 0.8)
                .attr("dx", 8)
                .attr("dy", "-.3em");
            focus.append("text")
                .attr("class", "y2")
                .attr("dx", 8)
                .attr("dy", "-.3em");

            // place the date at the intersection
            focus.append("text")
                .attr("class", "y3")
                .style("stroke", "white")
                .style("stroke-width", "3.5px")
                .style("opacity", 0.8)
                .attr("dx", 8)
                .attr("dy", "1em");
            focus.append("text")
                .attr("class", "y4")
                .attr("dx", 8)
                .attr("dy", "1em");

            // append the rectangle to capture mouse
            svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .style("fill", "none")
                .style("pointer-events", "all")
                .on("mouseover", function() { focus.style("display", null); })
                .on("mouseout", function() { focus.style("display", "none"); })
                .on("mousemove", mousemove);

            function mousemove() {
                var x0 = x.invert(d3.mouse(this)[0]),
                    i = bisectDate(data, x0, 1),
                    d0 = data[i - 1],
                    d1 = data[i],
                    d = x0 - d0.date > d1.date - x0 ? d1 : d0;

                focus.select("circle.y")
                    .attr("transform",
                        "translate(" + x(d.date) + "," +
                        y(d.value) + ")");

                focus.select("text.y1")
                    .attr("transform",
                        "translate(" + x(d.date) + "," +
                        y(d.value) + ")")
                    .text(d.value.toFixed(2));

                focus.select("text.y2")
                    .attr("transform",
                        "translate(" + x(d.date) + "," +
                        y(d.value) + ")")
                    .text(d.value.toFixed(2));

                focus.select("text.y3")
                    .attr("transform",
                        "translate(" + x(d.date) + "," +
                        y(d.value) + ")")
                    .text(formatDate(d.date));

                focus.select("text.y4")
                    .attr("transform",
                        "translate(" + x(d.date) + "," +
                        y(d.value) + ")")
                    .text(formatDate(d.date));

                // focus.select(".x")
                // 	.attr("transform",
                // 		  "translate(" + x(d.date) + "," +
                // 						 y(d.value) + ")")
                // 			   .attr("y2", height - y(d.value));

                // focus.select(".y")
                // 	.attr("transform",
                // 		  "translate(" + width * -1 + "," +
                // 						 y(d.value) + ")")
                // 			   .attr("x2", width + width);
            };

            // svg.append("text")
            // 	.attr("x", (width / 2))             
            // 	.attr("y", 0 - (margin.top / 2))
            // 	.attr("text-anchor", "middle")  
            // 	.style("font-size", "16px") 
            // 	.style("text-decoration", "underline")  
            // 	.text(title);
        };


    }

})();