(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('GeofenceImportController', GeofenceImportController)
        .directive("fileread", [function () {
            'use strict';

            return {
                restrict: "A",

                link: function ($scope, element) {

                    element.change(function (event) {
                        $scope.$apply(function () {
                            $scope.myFile = element[0].files[0];

                        });
                    });
                }

            };
        }]);

    GeofenceImportController.$inject = ['$timeout', '$scope', 'GeofenceUpload', 'AllAssetsTypes'];

    function GeofenceImportController($timeout, $scope, GeofenceUpload, AllAssetsTypes) {
        var vm = this;

        vm.upload = upload;
        vm.geofencetype = null;
        var formData;

        function upload() {
            formData = new FormData();
            formData.append('file', $scope.myFile);
            if (vm.geofencetype != null) {
                // var isMaster = false;

                // if (vm.geofencetype == "MASTER") {
                //     isMaster = true;
                // }
                GeofenceUpload.geofenceUpload(formData, vm.geofencetype, function (response, headers) {
                    if (response.status == 200) {
                        vm.error = null;
                        vm.success = 'OK';

                        $timeout(function () {
                            vm.success = '';
                        }, 4000);


                    }
                    if (response.status == 400) {
                        vm.success = null;
                        vm.error = 'error';

                    }
                });
            } else {
                AlertService.error("Please select Geofence type.");
            }
        }
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle('buttonText', 'Browse File');


    }
})();