(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TerrainTypeDeleteController', TerrainTypeDeleteController);

    TerrainTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'TerrainType'];

    function TerrainTypeDeleteController($uibModalInstance, entity, TerrainType) {
        var vm = this;

        vm.terrainType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            TerrainType.delete({ id: id },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
