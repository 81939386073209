(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ReportingUsers', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/reporting-users').then(function (response) {
                callback(response.data);
            });
        };
        return service;
    }]);

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('ReportingNeUsers', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/reporting-ne-users').then(function (response) {
                callback(response.data);
            });
        };
        return service;
    }]);

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('Vendors', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/vendors').then(function (response) {
                callback(response.data);
            });
        };
        return service;
    }]);

})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('NearbyUser', NearbyUser);

    NearbyUser.$inject = ['$resource'];

    function NearbyUser($resource) {
        var resourceUrl = 'api/nearby-users/:latitude/:longitude/:dis';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();