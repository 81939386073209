(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrResourceDialogController", TrResourceDialogController);

    TrResourceDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrResource",
        "AllResourceTypes",
        "AllCustomResourceTypeAttribute",
        "ResourceAttributeMappingId"
    ];

    function TrResourceDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrResource,
        AllResourceTypes,
        AllCustomResourceTypeAttribute,
        ResourceAttributeMappingId
    ) {
        var vm = this;
        vm.trResource = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trResource);
        vm.selectAttributes = selectedResourceTypeAttributes;
        vm.resourceType = entity.resourceType;
        
		vm.customAttributesMap = {};
		vm.customAttributesMapp = [];
        loadPage();


        if (vm.trResource.id != null) {
            vm.trResourceType = vm.trResource.resourceType;
        }

        function loadPage() {

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                        img +
                        '">'
                );
                imgWindow.document.close();
            };

            var resourceTypeAttributeValuesList =
                vm.trResource.resourceTypeAttributeValues;

            try {
                for (var index in vm.trResource.resourceType.resourceTypeAttribute) {
                    if (
                        !checkIfResourceTypeExists(
                            vm.trResource.resourceType.resourceTypeAttribute[index],
                            vm.trResource.resourceTypeAttributeValues
                        )
                    ) {
                        var resourceTypeAttribute1 =
                            vm.trResource.resourceType.resourceTypeAttribute[index];
                        resourceTypeAttributeValuesList.push({
                            resourceTypeAttribute: resourceTypeAttribute1,
                            attributeValue:
                                vm.trResource.resourceType.resourceTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) {}

            function checkIfResourceTypeExists(resourceType, resourceTypeAttrVal) {
                var hasResourceType = false;
                for (var i = 0; i < resourceTypeAttrVal.length; i++) {
                    var name = resourceTypeAttrVal[i].resourceTypeAttribute.name;
                    if (name == resourceType.name) {
                        hasResourceType = true;
                        break;
                    }
                }
                return hasResourceType;
            }
            AllResourceTypes.getAll(function (response) {
                vm.resourceTypes = response;
            });

            AllCustomResourceTypeAttribute.getAll(function (response) {
                //vm.customResourceTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if(arrayItem.customResourceTypeAttributeValues != undefined){
                        vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customResourceTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }
                  
                });
                if (vm.trResource.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });
            
            
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedResourceTypeAttributes() {
            vm.showFilter = true;
            if (vm.resourceType) {
                vm.trResource.resourceType = vm.resourceType;
                if (
                    entity_old.resourceType &&
                    vm.resourceType.id === entity_old.resourceType.id
                ) {
                    vm.trResource.resourceTypeAttributeValues = [];
                    vm.trResource.resourceTypeAttributeValues =
                        entity_old.resourceTypeAttributeValues;
                } else {
                    vm.trResource.resourceTypeAttributeValues = [];
                    $.each(
                        vm.resourceType.resourceTypeAttribute,
                        function (key, value) {
                            vm.trResource.resourceTypeAttributeValues.push({
                                resourceTypeAttribute:
                                    vm.resourceType.resourceTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            vm.isSaving = true;
            var spreadAV = vm.trResource.resourceTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].resourceTypeAttribute
                        .name === "Fiber Distance (km)"
                ) {
                    spreadAV[
                        spreadAssetIndex
                    ].attributeValue = fiberDistance;
                }
            }
            vm.trResource.resourceTypeAttributeValues = spreadAV;
                
            if (vm.trResource.id !== null) {
                TrResource.update(vm.trResource, onSaveSuccess, onSaveError);
            } else {
                TrResource.save(vm.trResource, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:resourceUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $state.go("tr-resource");
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $('.select2').select2();
    }
})();
