(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("CustomWorkFlowTypeAttribute", CustomWorkFlowTypeAttribute);

    CustomWorkFlowTypeAttribute.$inject = ["$resource"];

    function CustomWorkFlowTypeAttribute($resource) {
        var resourceUrl = "api/custom-workflow-type-attributes/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: { method: "GET", isArray: true },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
                update: { method: "PUT" },
            }
        );
    }
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("AllCustomWorkFlowTypeAttribute", [
        "$rootScope",
        "$http",
        function ($rootScope, $http) {
            var service = {};
            service.getAll = function (callback) {
                $http
                    .get("api/custom-workflow-type-attributes")
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();
