(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomTerrainTypeAttributeDeleteController',CustomTerrainTypeAttributeDeleteController);

    CustomTerrainTypeAttributeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomTerrainTypeAttribute'];

    function CustomTerrainTypeAttributeDeleteController($uibModalInstance, entity, CustomTerrainTypeAttribute) {
        var vm = this;

        vm.customTerrainTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomTerrainTypeAttribute.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
