(function () {
	'use strict';

	angular.module('trakeyeApp').controller('SplicingReportController', SplicingReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	SplicingReportController.$inject = ['$scope', '$sce', '$state', '$localStorage', 'AlertService', 'AllCaseTypes', 'TrCasesForType', 'Report', 'BaseUrl'];

	function SplicingReportController($scope, $sce, $state, $localStorage, AlertService, AllCaseTypes, TrCasesForType, Report, BaseUrl) {
		var vm = this;
		vm.downloadSplicingReport = downloadSplicingReport;
		vm.selectedCaseTypeId = null;
		vm.selectedCaseId = null;
		vm.select2 = select2;
		vm.select2();
		vm.caseTypes = [];
		vm.cases = [];

		AllCaseTypes.getAll(function (response) {
			response.forEach(function(caseType){
				if(caseType.name == "Splicing Details" || caseType.name == "Termination Details" ) {
					vm.caseTypes.push(caseType);
				}
			});
		});

		$scope.onCaseTypeChange = function () {
			vm.cases = [];
			TrCasesForType.query(
				{
					caseTypeId: vm.selectedCaseTypeId,
				},
				onSuccess,
				onError
			);

			function onSuccess(data, headers) {
				vm.cases = data;
			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
        };

		function downloadSplicingReport() {
			BaseUrl.getBaseUrl(function(baseurl){
				var request = new XMLHttpRequest();
				request.open('GET', baseurl.stringValue+"api/download-splicing-pdf/"+vm.selectedCaseId, true);
				request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
				request.responseType = 'blob';
				
				request.onload = function(e) {
					if (this.status === 200) {
						var blob = this.response;
						if(blob.size > 0){
							var blob = new Blob([this.response], {type: 'application/pdf'});
							var link = document.createElement('a');
							link.href = window.URL.createObjectURL(blob);
							link.download = "document.pdf";
							vm.caseTypes.forEach(function(value){
								if(value.id == vm.selectedCaseTypeId){
									link.download = value.name + ".pdf";
								}
							});
							
							link.click();
							
						}
					}
				};
				request.send();
			});

		}

		function select2() {
			$(".select2").select2();
		}

	}
})();
