(function() {
	'use strict';

	angular.module('trakeyeApp').controller('TeamReportController', TeamReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    	};
	}]);

	TeamReportController.$inject = [ '$scope','$sce' ,'$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval','Principal' ];

	function TeamReportController($scope, $sce,$state, Report, $rootScope, $timeout, $window, $filter, $interval,Principal) {
		var vm = this;
		vm.users = [];
		
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		vm.openCalendar = openCalendar;
		vm.reportGenerated = false;
		vm.reportGeneratedFileName='';
		
		vm.datePickerOpenStatus = {};
		$scope.reporthide = false;
		vm.reportUser = $rootScope.userName;
		vm.users = loadPage();
		function loadPage(){
			Report.getUsers('', function(response) {
				vm.users = response;
			})
		}
		$scope.loader = false;
		
		$(".select2").select2(); // Added search for select box
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		
		vm.datePickerOpenStatus.createdDate = false;
		vm.datePickerOpenStatus.modifiedDate = false;
		vm.datePickerOpenStatus.serviceDate = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		
		
		$scope.report = function() {
		
			Report.getTeamReport({
					"fromDateTime":vm.fromTime,
					"toDateTime":vm.toTime,
					"login":vm.reportUser,
				}, function(response) {
					$scope.myhtml = response;
				});
			vm.reportGenerated = true;
		}
		
		$scope.download = function() {
			window.location.href = "/images/TeamReport.xls";
		}
		
	}

})();
