(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetConnectController',
			AssetConnectController);

	AssetConnectController.$inject = [ '$timeout', '$scope', '$stateParams','AlertService',
			'entity', 'Asset', 'User', 'AssetType', '$state', 'AssetUpload',
			'AllAssetsTypes', 'AllCustomAssetTypeAttribute', 'AssetIDs','AssetByID' ];

	function AssetConnectController($timeout, $scope, $stateParams, AlertService, entity,
			Asset, User, AssetType, $state, AssetUpload, AllAssetsTypes,
			AllCustomAssetTypeAttribute, AssetIDs,AssetByID) {
		var vm = this;
//		var entity_old = angular.copy(vm.asset);
		vm.asset = entity;
		vm.generatedasset = null;
		vm.childAsset =  null;
		vm.parentName = null;
		vm.clear = clear;
		vm.save = save;
		vm.assetType = entity.assetType;

		vm.upload = upload;
		vm.assettypes = loadPage();
		vm.coOrdinates = [];
		vm.coOrdinate = null;
		vm.isAutomatic = true;
		vm.isRoadLeft = true;
		vm.isSiteASelected = false;
		vm.isSiteBSelected = false;
		vm.selectedSiteA = {};
		vm.selectedSiteB = {};
		var isSaved = false;

		var newFence = false;
		var latlng;
		var locations = {};
		var centerlatlng = null;
		vm.customAttributesMap = {};
		var formData;
		var spreadAssets = [];
		vm.isSaving = true;

		function loadPage() {
            vm.assestList = [];
			vm.multiassets = [];
			var selctedAssets = [];

			if (vm.asset.id != null) {
				vm.asset.ownedBy.forEach(function(asset) {
					selctedAssets.push(asset.id);
				})
			}

			AssetIDs.getAll(function(response) {
				response.forEach(function(asset) {
					vm.assestList.push(asset);
					if(asset.assetTypeName == "Site"){
						if (selctedAssets.indexOf(asset.id) !== -1) {
							vm.multiassets.push({
								lat : asset.assetCoordinates[asset.assetCoordinates.length-1].latitude,
								lng : asset.assetCoordinates[asset.assetCoordinates.length-1].longitude,
								name : asset.name,
								assetType : asset.assetType,
								assetCoordinates : asset.assetCoordinates,
								id : asset.id,
								ownedBy : asset.ownedBy,
								ticked : true
							});
						} else {
							try {
								vm.multiassets.push({
									lat : asset.assetCoordinates[asset.assetCoordinates.length-1].latitude,
									lng : asset.assetCoordinates[asset.assetCoordinates.length-1].longitude,
									name : asset.name,
									assetType : asset.assetType,
									assetCoordinates : asset.assetCoordinates,
									id : asset.id,
									ownedBy : asset.ownedBy,
									ticked : false
								});
							} catch (e) {
								// TODO: handle exception
							}

						}
					}
				});
			});

			AllAssetsTypes.getAll(function(response) {
				vm.assettypes = response;
			});

			AllCustomAssetTypeAttribute.getAll(function(response) {
				response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customAssetTypeAttributeValues;
				});
			});
			$( "#connectTypeRadio1").prop('checked', true);
			$( "#roadSideRadio1").prop('checked', true);
		}

		function upload() {
			formData = new FormData();

			formData.append("assetname", $scope.assetname);
			formData.append('file', $scope.myFile);

			AssetUpload.assetupload(formData, function(response, headers) {
				if (response.status == 200) {
					vm.error = null;
					vm.success = 'OK';
					$scope.spreadassetname = '';

					$timeout(function() {
						vm.success = '';
					}, 4000);

				}
				if (response.status == 400) {
					vm.success = null;
					vm.error = 'error';

				}
			});

		}

        $(".select2").select2();

  		$("#fromAssetsView").change(function () {
			$("#toAssetsView")[0].selectedIndex = 0;
			var result = $("#fromAssetsView").val();
			if(result != null){
				for (var i = 0; i < vm.multiassets.length; i++) {
					if (vm.multiassets[i].id == result) {
						vm.selectedSiteA = vm.multiassets[i];
					}
				}
				vm.isSiteASelected = true;
			}
		});

		$("#toAssetsView").change(function () {
			if(vm.isSiteASelected == true){
				var result = $("#toAssetsView").val();
				if(result != null){
					for (var i = 0; i < vm.multiassets.length; i++) {
					if (vm.multiassets[i].id == result) {
						vm.selectedSiteB = vm.multiassets[i];
					}
				}
					vm.isSiteBSelected = true;
				}
			}else{
				$("#toAssetsView")[0].selectedIndex = 0;
				alert("Please Select Site A");
			}

		});

		/* import end */
		vm.createNewFence = function() {
			newFence = true;
			vm.asset.assetCoordinates = [];
			vm.createfence();
		}

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			//$uibModalInstance.dismiss('cancel');
			
		}

		function save() {
			if(vm.fiberowner != null){
				if(vm.isSiteASelected == false && vm.isSiteBSelected == false){
					alert("Please Select Site A");
				}else if(vm.isSiteASelected == true && vm.isSiteBSelected == false){
					alert("Please Select Site B");
				}else {
					vm.assestList.forEach(function(assetVal){
						if (assetVal.name == vm.asset.name) {
							alert("site A and site B already exist in assetsList");
						} else {
							vm.asset.assetTypeAttributeValues = [];
							var disVal = 0.0;
							var spreadCoordinates = vm.asset.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
                                        }
                                    }
                                }
							vm.asset.assetType.assetTypeAttributes.forEach(function(assetAttrVal){
								if(assetAttrVal.name == "Fiber Owner"){
									vm.asset.assetTypeAttributeValues.push(
										{
											attributeValue : vm.fiberowner,
											assetTypeAttribute : assetAttrVal
										}
									)
								}else if(assetAttrVal.name == "Fiber Distance (km)"){
									vm.asset.assetTypeAttributeValues.push(
										{
											attributeValue : disVal.toFixed(2),
											assetTypeAttribute : assetAttrVal
										}
									)
								}else{
									vm.asset.assetTypeAttributeValues.push(
										{
											attributeValue : assetAttrVal.defaultValue,
											assetTypeAttribute : assetAttrVal
										}
									)
								}
								
							});
							vm.isSaving = true;
							if(!isSaved){
								Asset.save(vm.asset, onSaveSuccess, onSaveError);
								isSaved = true;
							}
						}
					})
				}
			}else{
				alert("Please Enter Fiber Owner Name");
			}
		}

		function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return (dist);
            }
        }


        function moveToLocation(lat, lng, map) {
            var center = new google.maps.LatLng(lat, lng);
            map.panTo(center);
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

		function onSaveSuccess(result) {
			isSaved = true;
			$scope.$emit('trakeyeApp:assetUpdate', result);
			vm.isSaving = false;
			$state.go('asset');
		}

		function onSaveError() {
			vm.isSaving = false;
		}

//		function onSaveSuccess(result) {
//			$scope.$emit('trakeyeApp:assetUpdate', result);
//			vm.isSaving = false;
//			$state.go('asset');
//		}
//
//		function onSaveError() {
//			vm.isSaving = false;
//		}

		vm.createfence = function() {
			if (vm.selectedSiteA.name == vm.selectedSiteB.name) {
				alert("same asset Name is not valid")
			} else {
			var connectTypeVal = $('input[name=connectTypeRdo]:checked').val();
			if(connectTypeVal == "Automatic"){
				vm.isAutomatic = true;
			}else{
				vm.isAutomatic = false;
			}
		
			var roadSiteVal = $('input[name=RoadSideRdo]:checked').val();
			if(roadSiteVal == "Left"){
				vm.isRoadLeft = true;
			}else{
				vm.isRoadLeft = false;
			}

			

			if(vm.isSiteASelected == false && vm.isSiteBSelected == false){
				alert("Please Select Site A");
			}else if(vm.isSiteASelected == true && vm.isSiteBSelected == false){
				alert("Please Select Site B");
			}else{
				var siteALatLng = new google.maps.LatLng(vm.selectedSiteA.assetCoordinates[0].latitude, vm.selectedSiteA.assetCoordinates[0].longitude);
				var siteBLatLng = new google.maps.LatLng(vm.selectedSiteB.assetCoordinates[0].latitude, vm.selectedSiteB.assetCoordinates[0].longitude);
				var map = new google.maps.Map(document.getElementById('map_canvas'), {
					center : siteALatLng,
					zoom : 16,
					fullscreenControl: true
				});
				createMarker(map, vm.selectedSiteA.name, siteALatLng);
				createMarker(map, vm.selectedSiteB.name, siteBLatLng);
			
				if(vm.isAutomatic){
					var generatedAssetVal = {};
					if(vm.isRoadLeft){
						generatedAssetVal = getShortestRoute(vm.selectedSiteA, vm.selectedSiteB, map);
					}else{
						generatedAssetVal = getShortestRoute(vm.selectedSiteB, vm.selectedSiteA, map);
					}
					
					if(generatedAssetVal != null){
						vm.asset = generatedAssetVal;
					}
				}else{
					var newAsset = {
						name: '',
						assetCoordinates: []
					};
					newAsset.name = vm.selectedSiteA.name+"-"+vm.selectedSiteB.name;
					AssetType.query({}, function (data) {
                        data.forEach(function (item) {
                            if (item.name == "Link") {
                                newAsset.assetType = item;
                            }
                        });
					});
					drawingManagerSpread();
					drawingManagerValue('polylinecomplete', map, newAsset);
				}
				
			}
			vm.isSaving = false;
		  }
		};

		function getShortestRoute(SiteA, SiteB, map){
			var pointA = new google.maps.LatLng(SiteA.assetCoordinates[0].latitude, SiteA.assetCoordinates[0].longitude);
            var pointB = new google.maps.LatLng(SiteB.assetCoordinates[0].latitude, SiteB.assetCoordinates[0].longitude);

            var newAsset = {
                name: '',
                assetCoordinates: []
            };
            newAsset.name = SiteA.name+"-"+SiteB.name;

            var directionsService = new google.maps.DirectionsService;
            var myOptions = {
                zoom: 14,
                center: pointA,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            var polylineOptionsActual = new google.maps.Polyline({
                strokeColor: '#ff0000',
                strokeOpacity: 0.5,
                strokeWeight: 4
            });
            var directionsDisplay = new google.maps.DirectionsRenderer({
                map: map,
                polylineOptions: polylineOptionsActual,
                suppressMarkers: true
            });

            directionsService.route({
                origin: pointA,
                destination: pointB,
                avoidTolls: true,
                avoidHighways: false,
                travelMode: google.maps.TravelMode.DRIVING
            }, function (response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    var responsePath = response.routes[0].overview_path;
                    vm.fixedArray = [];
                    responsePath.forEach(function (value) {
                        var coordinates = {};
                        coordinates.latitude = value.lat();
                        coordinates.longitude = value.lng();
                        vm.fixedArray.push(coordinates);
                    });
                    newAsset.assetCoordinates = vm.fixedArray;
                    newAsset.layout == 'SPREAD';

                    AssetType.query({}, function (data) {
                        data.forEach(function (item) {
                            if (item.name == "Link") {
                                newAsset.assetType = item;
                            }
                        });
                    });
                } else {
					window.alert('Directions request failed due to ' + status);
                }
			});
			return newAsset;
		}
		function drawingManagerSpread() {
			vm.drawingManager = new google.maps.drawing.DrawingManager({
				drawingMode : google.maps.drawing.OverlayType.POLYLINE,
				drawingControl : true,
				drawingControlOptions : {
					position : google.maps.ControlPosition.TOP_CENTER,
					drawingModes : [ 'polyline' ]
				},
				polylineOptions : {
					fillColor : '#ffff00',
					fillOpacity : 1,
					strokeWeight : 5,
					clickable : true,

					editable : true,
					zIndex : 1,
					geodesic : true
				}
			});
		}

		function createMarker(map, assetName, myLatLng) {
			var marker = new google.maps.Marker(
					{
						position : myLatLng,
						map : map,
						icon : {
							scaledSize : new google.maps.Size(
									24, 24),
									origin : new google.maps.Point(0, 0),
									url :'content/images/yellow.png',
									anchor : new google.maps.Point(
											16, 16),

						},
						label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: assetName, backgroundcolor: "#ff9800" },
					});
			
		}

		function drawingManagerValue(option, map, assetVal) {
			if (option == 'polylinecomplete') {
				google.maps.event.addListener(vm.drawingManager, option,
						function(event) {
							google.maps.event.addListener(event.getPath(),
									'insert_at', function() {
										setGoefenceCoordinate(event, assetVal);
									});
							google.maps.event.addListener(event.getPath(),
									'set_at', function(path) {
										setGoefenceCoordinate(event, assetVal);
									});
							vm.drawingManager.setOptions({
								drawingControl : false
							});
							vm.drawingManager.setMap(null);
							setGoefenceCoordinate(event, assetVal);
						});
				vm.drawingManager.setMap(map);
			}
		}

		function setGoefenceCoordinate(event, assetVal) {
			assetVal.assetCoordinates = [];
			var radius = event.getPath();
			for (var i = 0; i < radius.getLength(); i++) {
				var coordinates = {};
				coordinates.latitude = radius.getAt(i).lat();
				coordinates.longitude = radius.getAt(i).lng();
				assetVal.assetCoordinates.push(coordinates);
			}
			vm.asset = assetVal;
		}

		function moveToLocation(lat, lng, map) {
			var center = new google.maps.LatLng(lat, lng);
			// using global variable:
			map.panTo(center);
		}

		$scope.mapEvents = {
			"click" : function() {

			}
		}
	}
})();