(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrTaskDeleteController',TrTaskDeleteController);

    TrTaskDeleteController.$inject = ['$uibModalInstance', 'entity', 'TrTask','$scope'];

    function TrTaskDeleteController($uibModalInstance, entity, TrTask,$scope) {
        var vm = this;

        vm.trTask = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
        	$scope.deletefail = true;
            TrTask.delete({id: id},
                function () {
            	
            	 $scope.deletefail = false;
                    $uibModalInstance.close(true);
                });
           
        }
    }
})();
