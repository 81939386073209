(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('DashboardService', DashboardService);

        DashboardService.$inject = ['$http', 'DateUtils'];

    function DashboardService ($http, DateUtils) {
    	var service ={};
    	
    	service.loaddata = function (callback){
    		
    		$http.get('api/dashboard/dashboarddata').then(function(response){
        		callback(response.data);
        	});
    	};
    	
    	
    	return service;
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('DashboardServiceGeo', DashboardServiceGeo);

    DashboardServiceGeo.$inject = ['$resource'];

    function DashboardServiceGeo($resource) {
        var service = $resource('/api/dashboard/dashboarddata/:geofenceId', {
            // searchvalue : '@id'
        }, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });

        return service;
    }

})();

(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('CaseCountByPriority', CaseCountByPriority);

        CaseCountByPriority.$inject = ['$http'];

    function CaseCountByPriority ($http) {
    	var service ={};
    	
    	service.get= function (callback){	
    		$http.get('api/dashboard/case-count-by-priority').then(function(response){
        		callback(response.data);
        	});
    	};
    	
    	return service;
    }
})(); 

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('CaseCountByPriorityGeo', CaseCountByPriorityGeo);

    CaseCountByPriorityGeo.$inject = ['$resource'];

    function CaseCountByPriorityGeo($resource) {
        var service = $resource('/api/dashboard/case-count-by-priority/:geofenceId', {
            // searchvalue : '@id'
        }, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });

        return service;
    }

})();

(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('CaseCountByStatus', CaseCountByStatus);

        CaseCountByStatus.$inject = ['$http'];

    function CaseCountByStatus ($http) {
    	var service ={};
    	
    	service.get= function (callback){	
    		$http.get('api/dashboard/case-count-by-status').then(function(response){
        		callback(response.data);
        	});
    	};
    	
    	return service;
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('CaseCountByStatusGeo', CaseCountByStatusGeo);

    CaseCountByStatusGeo.$inject = ['$resource'];

    function CaseCountByStatusGeo($resource) {
        var service = $resource('/api/dashboard/case-count-by-status/:geofenceId', {
            // searchvalue : '@id'
        }, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });

        return service;
    }

})();

(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('UserListAndDistanceGeo', UserListAndDistanceGeo);

        UserListAndDistanceGeo.$inject = ['$resource'];

    function UserListAndDistanceGeo ($resource) {
        var service = $resource('api/dashboard/userlist/:geofenceId',{geofenceId:'@geofenceId'},{},{
            'query': {method: 'GET',isArray: true},
        });

        return service;
    }
})();

(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('LinkService', LinkService);

    LinkService.$inject = ['$http', 'DateUtils'];

    function LinkService ($http, DateUtils) {
    	var service ={};
    	
    	service.loaddata = function (callback){
    		
    		$http.get('api/dashboard/dashboardlinkdata').then(function(response){
        		callback(response.data);
        	});
    	};
    	
    	
    	return service;
    }
})();

(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('UserListAndDistance', UserListAndDistance);

    UserListAndDistance.$inject = ['$resource'];

    function UserListAndDistance ($resource) {
        var service = $resource('api/dashboard/userlist',{},{
            'query': {method: 'GET',isArray: true},
        });

        return service;
    }
})();

(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('UserListAndDistanceSearch', UserListAndDistanceSearch);

    UserListAndDistanceSearch.$inject = ['$resource'];

    function UserListAndDistanceSearch ($resource) {
        var service = $resource('api/dashboard/userlist/:login',{login:'@login'},{},{
            'query': {method: 'GET',isArray: true},
        });

        return service;
    }
})();

(function() {
    'use strict';
    angular
        .module('trakeyeApp')
        .factory('TaskStatusById', TaskStatusById);

        TaskStatusById.$inject = ['$http'];

    function TaskStatusById ($http) {
    	var service ={};
    	
    	service.post = function (payload,callback){	
    		$http.post('api/rollout/task-status',payload).then(function(response){
        		callback(response.data);
        	});
    	};

        service.getworkOrderByRoute = function (id,callback){	
    		$http.get('api/tr-workorders/route/'+id).then(function(response){
        		callback(response.data);
        	},function (err){
                callback(err);
            });
    	};

    	
    	return service;
    }
})();

//dashboard rollout temp services
(function () {
    'use strict';

    angular.module('trakeyeApp').factory('WorkOrderStats', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (name,callback) {
            $http.get('api/workorderstats/'+name).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();
(function () {
    'use strict';

    angular.module('trakeyeApp').factory('WorkFlowListByWorkOrderID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/workorderworkflows/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AssetsByWorkFlowID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/assetsbywf/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();