angular.module('trakeyeApp').directive('comments', ['taskComment','$rootScope',function (taskComment,$rootScope) {
    return {
      restrict: 'E',
      scope: {
        obj:'=',
      },
      templateUrl: 'app/components/View/comments.html',
      link: function (scope) {

        scope.loginUserName = $rootScope.loginUserName
        scope.comments = [];
        callGetComment();


        scope.addComment = function () {
            if (scope.newComment.trim() !== "") {
                var comment = {
                    entityId: scope.obj.entityId,
                    comment: scope.newComment,
                    entityName: scope.obj.entityName
                };

                //scope.comments.push(comment);
                scope.newComment = "";

                taskComment.post(comment, function () {
                    callGetComment();
                });
            }
        };

        scope.startEditing = function (comment) {
            comment.editing = true;
        };

        scope.saveEdit = function (comment) {
            comment.editing = false;
            taskComment.put(comment, function () {
                callGetComment();
            });
        };

        scope.cancelEdit = function (comment) {
            comment.editing = false;
        };

        scope.deleteComment = function (commentId) {
            $("#deleteComment").show();
            scope.storeComment = commentId;
        };

        scope.deleteCommentConfirm = function () {
            taskComment.delete(scope.storeComment, function () {
                callGetComment();
                scope.closeModal("deleteComment");
            });
        };

        function callGetComment() {
            taskComment.get(scope.obj.entityId,scope.obj.entityName, function (response) {
                scope.comments = response;
            });
        }

        scope.closeModal = function(id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        };
    
      }
      
    };
  }]);
  