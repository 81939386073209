(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetLinkController", AssetLinkController);

        AssetLinkController.$inject = ["AssetLinkList"];
    function AssetLinkController(AssetLinkList) {

        var vm = this;

        AssetLinkList.get(function (response){
            vm.displayList = response
        })

        vm.refresh = function(){
            AssetLinkList.get(function (response){
                vm.displayList = response
            })
        }
    }
})();
