(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomAssetTypeAttributeDialogController', CustomAssetTypeAttributeDialogController);

    CustomAssetTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'CustomAssetTypeAttribute'];

    function CustomAssetTypeAttributeDialogController ($timeout, $scope, $state, $stateParams, entity, CustomAssetTypeAttribute) {
        var vm = this;

        vm.customAssetTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
        //vm.customassettypeattributevalues = CustomAssetTypeAttributeValue.query();
        
        vm.items = [ {
			value : ''
		} ];
        
        if (vm.customAssetTypeAttribute.customAssetTypeAttributeValues != null && vm.customAssetTypeAttribute.customAssetTypeAttributeValues.length != 0) {
			vm.items = vm.customAssetTypeAttribute.customAssetTypeAttributeValues;
		}
        
        vm.addmore = function() {
			vm.items.push({
				value : ''
			});
		};

		vm.remove = function(index) {
			vm.items.splice(index, 1);
		};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
           // $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customAssetTypeAttribute.id !== null) {
            	
            	 vm.customAssetTypeAttribute.customAssetTypeAttributeValues = [];
 				for (var i = 0; i < vm.items.length; i++) {
 					 vm.customAssetTypeAttribute.customAssetTypeAttributeValues.push({
 						"id" : vm.items[i].id,
 						"value" : vm.items[i].value
 					});

 				}
            	
                CustomAssetTypeAttribute.update(vm.customAssetTypeAttribute, onSaveSuccess, onSaveError);
            } else {
            	
            	
            	 vm.customAssetTypeAttribute.customAssetTypeAttributeValues = [];

 				for (var i = 0; i < vm.items.length; i++) {
 					 vm.customAssetTypeAttribute.customAssetTypeAttributeValues.push({
 						"id" : vm.items[i].id,
 						"value" : vm.items[i].value
 					});

 				}
                CustomAssetTypeAttribute.save(vm.customAssetTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customAssetTypeAttributeUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('custom-asset-type-attribute');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
