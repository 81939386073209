(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("MaterialTypeDialogController", MaterialTypeDialogController);

    MaterialTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "MaterialType",
        "User",
        "MaterialTypeSearch",
        "AllCustomMaterialTypeAttribute",
        "getUoM"
    ];

    
    function MaterialTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        MaterialType,
        User,
        MaterialTypeSearch,
        AllCustomMaterialTypeAttribute,
        getUoM
    ) {
        var vm = this;
        //materialtype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.materialType = entity;
        vm.materialTypeImageName = materialTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customMaterialTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");
        vm.checkNumber;

        function materialTypeImageName(image) {
            vm.materialType.image = image;
        }
        
       
        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomMaterialTypeAttribute.getAll(function (response) {
                vm.customMaterialTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customMaterialTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.materialType.materialTypeAttribute != null &&
            vm.materialType.materialTypeAttribute.length != 0
        ) {
            vm.items = vm.materialType.materialTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.materialCategory == null) {
                value.materialCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            //vm.isSaving = true;
            if (vm.materialType.id !== null) {
                vm.materialType.materialTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.materialType.materialTypeAttribute.push({
                        materialCategory: vm.items[i].materialCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        materialTypeAttribOrder: vm.items[i].materialTypeAttribOrder
                    });
                }
                MaterialType.update(vm.materialType, onSaveSuccess, onSaveError);
            } else {
                vm.materialType.materialTypeAttribute = [];
 
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.materialType.materialTypeAttribute.push({
                        materialCategory: vm.items[i].materialCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        materialTypeAttribOrder: vm.items[i].materialTypeAttribOrder
                    });
                }
                MaterialType.save(vm.materialType, onSaveSuccess, onSaveError);
            }
        }

        $("#materialTypeImage").change(function () {
            var filesSelected = document.getElementById("materialTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.materialType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:materialTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("material-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.addmore = function (index) {

            
            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice((index+1), 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });
           
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
           
           
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        if(!vm.materialType.id){
            vm.materialType.trUnitOfMeasurement = {}
        } 

        getUoM.get(function(response){
            vm.optionsUoM = response
        })

        $('.select2').select2();
       
    }
})();
