(function () {
    'use strict';

    angular.module('trakeyeApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('vehicle-management', {
            parent: 'admin',
            url: '/vehicle-management',
            data: {
                authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER'],
                pageTitle: 'vehicleManagement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/vehicle-management/vehicle-management.html',
                    controller: 'VehicleManagementController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vehicle-management');
                    return $translate.refresh();
                }]

            }
        })

            .state('vehicle-management-detail', {
                parent: 'admin',
                url: '/vehicles/:id',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_VENDOR', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER'],
                    pageTitle: 'vehicle-management.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/vehicle-management/vehicle-management-detail.html',
                        controller: 'VehicleManagementDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vehicle-management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('vehicle-management.new', {
                parent: 'vehicle-management',
                url: '/create',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/vehicle-management/vehicle-management-dialog.html',
                        controller: 'VehicleManagementDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            "id": null,
                            "status": null,
                            "vehicleType": null,
                            "licensePlate": null,
                            "gpsimei": null,
                            "createdDate": null,
                            "lastModifiedBy": null,
                            "lastModifiedDate": null
                        };
                    }
                }

            })

            .state('vehicle-management.edit', {
                parent: 'vehicle-management',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER', 'ROLE_ASSISTANT_MANAGER', 'ROLE_TEAM_LEADER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/vehicle-management/vehicle-management-dialog.html',
                        controller: 'VehicleManagementDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('vehicle-management');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Vehicle', function ($stateParams, Vehicle) {
                        return Vehicle.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                }
            });
    }
})();