(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "CustomWorkOrderTypeAttributeDetailController",
            CustomWorkOrderTypeAttributeDetailController
        );

    CustomWorkOrderTypeAttributeDetailController.$inject = [
        "$scope",
        "$rootScope",
        "$stateParams",
        "previousState",
        "entity",
        "CustomWorkOrderTypeAttribute",
    ];

    function CustomWorkOrderTypeAttributeDetailController(
        $scope,
        $rootScope,
        $stateParams,
        previousState,
        entity,
        CustomWorkOrderTypeAttribute
    ) {
        var vm = this;

        vm.customWorkOrderTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on(
            "trakeyeApp:customWorkOrderTypeAttributeUpdate",
            function (event, result) {
                vm.customWorkOrderTypeAttribute = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);
    }
})();
