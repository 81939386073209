(function () {
	'use strict';

	angular.module('trakeyeApp').controller('ShowHideCaseController', ShowHideCaseController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function ($sce) {
		return function (text) {
			return $sce.trustAsHtml(text);
		};
	}]);

	ShowHideCaseController.$inject = ['$scope', '$sce', '$state', 'ShowCaseById', 'HideCaseById'];

	function ShowHideCaseController($scope, $sce, $state, ShowCaseById, HideCaseById) {
		var vm = this;
		vm.showCase = showCase;
		vm.hideCase = hideCase;
		vm.status = 0;

		function showCase() {
			vm.status = 0;
			var caseId = parseInt(vm.caseId);
			if(caseId > 0){
				ShowCaseById.update(caseId, function(response) {
					if(response == 200){
						vm.status = 200;
					}else{
						vm.status = 400;
					}
				});
			}else{
				alert("Please Enter Valid Case ID");
			}
		}

		function hideCase() {
			vm.status = 0;
			var caseId = parseInt(vm.caseId);
			if(caseId > 0){
				HideCaseById.update(caseId, function(response) {
					if(response == 200){
						vm.status = 200;
					}else{
						vm.status = 400;
					}
				});
			}else{
				alert("Please Enter Valid Case ID");
			}
		}
	}
})();
