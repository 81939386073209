(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetTypeSiteReportController', AssetTypeSiteReportController)

	AssetTypeSiteReportController.$inject = [ '$scope', '$state', 'pagingParams','paginationConstants', 'ParseLinks', 'SiteAssets', '$rootScope', '$timeout', '$window', '$filter', '$interval' ];

	function AssetTypeSiteReportController($scope, $state, pagingParams, paginationConstants, ParseLinks, SiteAssets, $rootScope, $timeout, $window, $filter, $interval) {
		var vm = this;
		vm.multiassets = [];
		 vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

		vm.filterSearch=filterSearch;
        
        function filterSearch(){
        	
			if($scope.search!=null && $scope.search!="" && !angular.isUndefined($scope.search)){
			
			}
		}
			
		SiteAssets.query({
                page: pagingParams.page - 1,
                search: $scope.search,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    // result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
				data.forEach(function(asset) {
					if (asset.assetTypeName == "Site") {
						vm.multiassets.push({
							id : asset.name,
							name : asset.siteChildAssetsDTO.siteName,
							WDM : asset.siteChildAssetsDTO.wdmCount,
							NTTN : asset.siteChildAssetsDTO.nttnCount,
							Router : asset.siteChildAssetsDTO.routerCount,
							BackToBack : asset.siteChildAssetsDTO.backtobackCount,
							Others : asset.siteChildAssetsDTO.othersCount
						});
						
						vm.multiassets.sort(function(a, b){
						    var nameA=a.id.toLowerCase(), nameB=b.id.toLowerCase()
						    if (nameA < nameB) //sort string ascending
						        return -1 
						    if (nameA > nameB)
						        return 1
						    return 0 //default return value (no sorting)
						})
                    }
				});
			}
			
			function onError(error) {
                AlertService.error(error.data.message);
            }

		function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search
            });
        }
	}
		

})();