(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .factory('FeasibilityVerifyFinish', FeasibilityVerifyFinish);

    FeasibilityVerifyFinish.$inject = ['$resource'];

    function FeasibilityVerifyFinish($resource) {
        var service = $resource('api/feasibility-checks/record', {}, {});

        return service;
    }
})();
