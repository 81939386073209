(function () {
    'use strict';
    angular.module('trakeyeApp').factory('Geofence', Geofence);

    Geofence.$inject = ['$resource', 'DateUtils'];

    function Geofence($resource, DateUtils) {
        var resourceUrl = 'api/geofences/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            }
        });
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GeofenceByID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/geofences/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('MiniGeofencesByMasterID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/mini-geofences-by-master/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllMiniGeofencesByMasterID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/all-mini-geofences-by-master/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('MasterGeofencesByTenantID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/master-geofences-by-tenant/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('BufferGeofencesByMiniID', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/buffer-geofences-by-mini/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GeofenceSearch', GeofenceSearch);

    GeofenceSearch.$inject = ['$resource'];

    function GeofenceSearch($resource) {
        var service = $resource('/api/geofences/searchvalue/:userId', {
            userId: '@id'
        }, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
        });

        return service;
    }
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllGeofences', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/geofencesall').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('UserMasterGeofences', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/user-geofences-by-type-master').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';
    angular.module('trakeyeApp').factory('GeofenceUpload', GeofenceUpload);

    GeofenceUpload.$inject = ['$http'];

    function GeofenceUpload($http) {

        var service = {};
        service.geofenceUpload = function (data, geoType, callback) {
            $http.post('api/geofencesimport/' + geoType, data, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            }).then(function (response) {

                callback(response, response.headers);
            }, function (error) {
                callback(error, error.headers);
            });
        };

        return service;
    }

})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GeofenceIDs', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/geofencelist').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GeofenceMasterMiniIDs', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/master-mini-geofences').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('MasterGeofences', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/master-geofences').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function () {
    'use strict';

    angular.module('trakeyeApp').factory('AllGeofenceIDs', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/all-geofences').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();


(function () {
    'use strict';

    angular.module('trakeyeApp').factory('UserGeofences', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.getAll = function (callback) {
            $http.get('api/user-geofences-web').then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();
(function () {
    'use strict';

    angular.module('trakeyeApp').factory('GeofenceChildAssets', ['$rootScope', '$http', function ($rootScope, $http) {

        var service = {};
        service.get = function (id, callback) {
            $http.get('api/geofence-users/' + id).then(function (response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();
