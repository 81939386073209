(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "WorkOrderTypeDeleteController",
            WorkOrderTypeDeleteController
        );

    WorkOrderTypeDeleteController.$inject = [
        "$uibModalInstance",
        "entity",
        "WorkOrderType",
    ];

    function WorkOrderTypeDeleteController(
        $uibModalInstance,
        entity,
        WorkOrderType
    ) {
        var vm = this;

        vm.workOrderType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmDelete(id) {
            WorkOrderType.delete({ id: id }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
