(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('LogTypeDetailController', LogTypeDetailController);

    LogTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LogType', 'User'];

    function LogTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, LogType, User) {
        var vm = this;

        vm.logType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:logTypeUpdate', function(event, result) {
            vm.logType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
