(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('SectionTypeDeleteController',SectionTypeDeleteController);

    SectionTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'SectionType'];

    function SectionTypeDeleteController($uibModalInstance, entity, SectionType) {
        var vm = this;

        vm.sectionType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SectionType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
