(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("entity-import", {
                parent: "admin",
                url: "/entity-import",
                data: {
                    authorities: [
                        "ROLE_USER_ADMIN",
                    ],
                    pageTitle: "global.menu.entityImport",
                },
                views: {
                    "content@": {
                        templateUrl: "app/admin/import/entity-import.html",
                        controller: "ImportController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
                        return $translate.refresh();
                    }]
    
                }
            })

            .state('entity-import.new', {
                parent: 'entity-import',
                url: '/import',
                data: {
                    authorities: ['ROLE_USER_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/import/entity-import-dialog.html',
                        controller: 'ImportDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: ["$state", function ($state) {
                        return $state.current.name || 'entity-import';
                    }]
                }
            })
        }
    })();