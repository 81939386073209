(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("ImportDialogController", ImportDialogController);

    ImportDialogController.$inject = ["EntityImport", "$timeout", "$state","previousState"];
    function ImportDialogController(EntityImport, $timeout, $state,previousState) {
        var vm = this;
        var getFile, lastDotPosition, format;
        vm.currentState = previousState

        $(":file").filestyle({
            buttonBefore: true,
            buttonText: "Browse File",
        });
        
        vm.importxlsx = /^(.*?)\.(xlsx|kml)$/;

        vm.onSubmit = function () {
            getFile = document.getElementById("uploadfile").files[0];
            lastDotPosition = getFile.name.lastIndexOf(".");
            format = getFile.name.substring(lastDotPosition + 1);

            var fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                EntityImport.get(
                    {
                        entityName: vm.entity,
                        entityLayout: vm.entityLayout,
                        fileFormat: format,
                        messageType: "ADD",
                        messageStatus: "OPEN",
                        fileImage: window.btoa(fileLoadedEvent.target.result), // imported file
                    },
                    function (response) {
                        vm.showMessageError = false;
                        vm.showMessage = true;
                        vm.trackingId = response.id;
                        $timeout(function () {
                            $state.go(vm.currentState)
                        }, 4000);
                    }
                );
            };
            fileReader.readAsBinaryString(getFile);
        };
    }
})();
