(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("asset-link", {
                parent: "admin",
                url: "/asset-link",
                data: {
                    authorities: [
                        "ROLE_USER_ADMIN",
                    ],
                    pageTitle: "global.menu.assetLink",
                },
                views: {
                    "content@": {
                        templateUrl: "app/admin/asset-link/asset-link-request.html",
                        controller: "AssetLinkController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate) {
                        return $translate.refresh();
                    }]
    
                }
            })
        }
    })();