(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkOrderDeleteController", WorkOrderDeleteController);

    WorkOrderDeleteController.$inject = [
        "$uibModalInstance",
        "entity",
        "WorkOrder",
        "$scope",
    ];

    function WorkOrderDeleteController(
        $uibModalInstance,
        entity,
        WorkOrder,
        $scope
    ) {
        var vm = this;

        vm.workOrder = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmDelete(id) {
            $scope.deletefail = true;
            WorkOrder.delete({ id: id }, function () {
                $scope.deletefail = false;
                $uibModalInstance.close(true);
            });
        }
    }
})();
