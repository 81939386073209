(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomLogTypeAttributeDetailController', CustomLogTypeAttributeDetailController);

    CustomLogTypeAttributeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomLogTypeAttribute'];

    function CustomLogTypeAttributeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomLogTypeAttribute) {
        var vm = this;

        vm.customLogTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:customLogTypeAttributeUpdate', function(event, result) {
            vm.customLogTypeAttribute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
