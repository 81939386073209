(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("ProjectTypeDialogController", ProjectTypeDialogController);

    ProjectTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "ProjectType",
        "User",
        "ProjectTypeSearch",
        "AllCustomProjectTypeAttribute",
    ];

    
    function ProjectTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        ProjectType,
        User,
        ProjectTypeSearch,
        AllCustomProjectTypeAttribute
    ) {
        var vm = this;
        //projecttype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.projectType = entity;
        vm.projectTypeImageName = projectTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customProjectTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");

        

        vm.checkNumber;

        
        
       

        function projectTypeImageName(image) {
            vm.projectType.image = image;
        }
        
       
        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomProjectTypeAttribute.getAll(function (response) {
                vm.customProjectTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customProjectTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.projectType.projectTypeAttribute != null &&
            vm.projectType.projectTypeAttribute.length != 0
        ) {
            vm.items = vm.projectType.projectTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.projectCategory == null) {
                value.projectCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.isSaving = true;
            if (vm.projectType.id !== null) {
                vm.projectType.projectTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.projectType.projectTypeAttribute.push({
                        projectCategory: vm.items[i].projectCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        projectTypeAttribOrder: vm.items[i].projectTypeAttribOrder
                    });
                }
                ProjectType.update(vm.projectType, onSaveSuccess, onSaveError);
            } else {
                vm.projectType.projectTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.projectType.projectTypeAttribute.push({
                        projectCategory: vm.items[i].projectCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        projectTypeAttribOrder: vm.items[i].projectTypeAttribOrder
                    });
                }
                ProjectType.save(vm.projectType, onSaveSuccess, onSaveError);
            }
        }

        $("#projectTypeImage").change(function () {
            var filesSelected = document.getElementById("projectTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.projectType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:projectTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("project-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.addmore = function (index) {

            
            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice((index+1), 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });
           
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
           
           
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

       
    }
})();
