(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomTrWorkOrderTypeAttributeDetailController', CustomTrWorkOrderTypeAttributeDetailController);

    CustomTrWorkOrderTypeAttributeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomTrWorkOrderTypeAttribute'];

    function CustomTrWorkOrderTypeAttributeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomTrWorkOrderTypeAttribute) {
        var vm = this;

        vm.customTrWorkOrderTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:customTrWorkOrderTypeAttributeUpdate', function(event, result) {
            vm.customTrWorkOrderTypeAttribute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
