(function () {
    "use strict";

    angular.module("trakeyeApp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("custom-workorder-type-attribute", {
                parent: "entity",
                url: "/custom-workorder-type-attribute?page&sort&search",
                data: {
                    authorities: ["ROLE_USER_ADMIN","ROLE_PLAN_ADMIN"],
                    pageTitle:
                        "trakeyeApp.customWorkOrderTypeAttribute.home.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/custom-workorder-type-attribute/custom-workorder-type-attributes.html",
                        controller: "CustomWorkOrderTypeAttributeController",
                        controllerAs: "vm",
                    },
                },
                params: {
                    page: {
                        value: "1",
                        squash: true,
                    },
                    sort: {
                        value: "id,asc",
                        squash: true,
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: [
                        "$stateParams",
                        "PaginationUtil",
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage(
                                    $stateParams.page
                                ),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort
                                ),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort
                                ),
                                search: $stateParams.search,
                            };
                        },
                    ],
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart(
                                "customWorkOrderTypeAttribute"
                            );
                            $translatePartialLoader.addPart("global");
                            return $translate.refresh();
                        },
                    ],
                },
            })
            .state("custom-workorder-type-attribute-detail", {
                parent: "custom-workorder-type-attribute",
                url: "/custom-workorder-type-attribute/{id}",
                data: {
                    authorities: ["ROLE_USER_ADMIN","ROLE_PLAN_ADMIN"],
                    pageTitle:
                        "trakeyeApp.customWorkOrderTypeAttribute.detail.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/custom-workorder-type-attribute/custom-workorder-type-attribute-detail.html",
                        controller:
                            "CustomWorkOrderTypeAttributeDetailController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart(
                                "customWorkOrderTypeAttribute"
                            );
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "CustomWorkOrderTypeAttribute",
                        function ($stateParams, CustomWorkOrderTypeAttribute) {
                            return CustomWorkOrderTypeAttribute.get({
                                id: $stateParams.id,
                            }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name:
                                    $state.current.name ||
                                    "custom-workorder-type-attribute",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })

            .state("custom-workorder-type-attribute.new", {
                parent: "custom-workorder-type-attribute",
                url: "/new",
                data: {
                    authorities: ["ROLE_USER_ADMIN","ROLE_PLAN_ADMIN"],
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/custom-workorder-type-attribute/custom-workorder-type-attribute-dialog.html",
                        controller:
                            "CustomWorkOrderTypeAttributeDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            description: null,
                            id: null,
                        };
                    },
                },
            })

            .state("custom-workorder-type-attribute.edit", {
                parent: "custom-workorder-type-attribute",
                url: "/{id}/edit",
                data: {
                    authorities: ["ROLE_USER_ADMIN","ROLE_PLAN_ADMIN"],
                    pageTitle:
                        "trakeyeApp.customWorkOrderTypeAttribute.home.title",
                },
                views: {
                    "content@": {
                        templateUrl:
                            "app/entities/custom-workorder-type-attribute/custom-workorder-type-attribute-dialog.html",
                        controller:
                            "CustomWorkOrderTypeAttributeDialogController",
                        controllerAs: "vm",
                    },
                },
                resolve: {
                    translatePartialLoader: [
                        "$translate",
                        "$translatePartialLoader",
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart(
                                "customWorkOrderTypeAttribute"
                            );
                            return $translate.refresh();
                        },
                    ],
                    entity: [
                        "$stateParams",
                        "CustomWorkOrderTypeAttribute",
                        function ($stateParams, CustomWorkOrderTypeAttribute) {
                            return CustomWorkOrderTypeAttribute.get({
                                id: $stateParams.id,
                            }).$promise;
                        },
                    ],
                    previousState: [
                        "$state",
                        function ($state) {
                            var currentStateData = {
                                name:
                                    $state.current.name ||
                                    "custom-workorder-type-attribute",
                                params: $state.params,
                                url: $state.href(
                                    $state.current.name,
                                    $state.params
                                ),
                            };
                            return currentStateData;
                        },
                    ],
                },
            })
            .state("custom-workorder-type-attribute.delete", {
                parent: "custom-workorder-type-attribute",
                url: "/{id}/delete",
                data: {
                    authorities: ["ROLE_USER_ADMIN","ROLE_PLAN_ADMIN"],
                },
                onEnter: [
                    "$stateParams",
                    "$state",
                    "$uibModal",
                    function ($stateParams, $state, $uibModal) {
                        $uibModal
                            .open({
                                templateUrl:
                                    "app/entities/custom-workorder-type-attribute/custom-workorder-type-attribute-delete-dialog.html",
                                controller:
                                    "CustomWorkOrderTypeAttributeDeleteController",
                                controllerAs: "vm",
                                size: "md",
                                resolve: {
                                    entity: [
                                        "CustomWorkOrderTypeAttribute",
                                        function (
                                            CustomWorkOrderTypeAttribute
                                        ) {
                                            return CustomWorkOrderTypeAttribute.get(
                                                { id: $stateParams.id }
                                            ).$promise;
                                        },
                                    ],
                                },
                            })
                            .result.then(
                                function () {
                                    $state.go(
                                        "custom-workorder-type-attribute",
                                        null,
                                        {
                                            reload: "custom-workorder-type-attribute",
                                        }
                                    );
                                },
                                function () {
                                    $state.go("^");
                                }
                            );
                    },
                ],
            });
    }
})();
