(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrWorkOrderDialogController", TrWorkOrderDialogController);

    TrWorkOrderDialogController.$inject = [
        "$timeout",
        "$scope",
        "$state",
        "entity",
        "TrWorkOrder",
        "AllWorkOrderTypes",
        "AllCustomWorkOrderTypeAttribute",
        "WorkOrderAttributeMappingId",
        "Configs",
        "ProjectByID",
        "RouteByID",
        "TrProjectListIdName",
        "TrRouteListByProjectId",
        "TrSectionListByRouteId",
        "AllVendorIds"
    ];

    function TrWorkOrderDialogController(
        $timeout,
        $scope,
        $state,
        entity,
        TrWorkOrder,
        AllWorkOrderTypes,
        AllCustomWorkOrderTypeAttribute,
        WorkOrderAttributeMappingId,
        Configs,
        ProjectByID,
        RouteByID,
        TrProjectListIdName,
        TrRouteListByProjectId,
        TrSectionListByRouteId,
        AllVendorIds
    ) {
        var vm = this;
        vm.trWorkOrder = entity;
        vm.save = save;
        var entity_old = angular.copy(vm.trWorkOrder);
        vm.selectAttributes = selectedTrWorkOrderTypeAttributes;
        vm.trWorkOrderType = entity.trWorkOrderType;
        vm.selectedProjectId = "";
        if(entity.project != null){
            vm.selectedProjectId = entity.project.id;
        }
        vm.selectedRouteId = "";
        if(entity.route != null){
            vm.selectedRouteId = entity.route.id;
        }
        


        if ($state.params.routeId) {
            vm.trWorkOrder.routeId = parseInt($state.params.routeId, 10);
        }

        if ($state.params.sectionId) {
            vm.trWorkOrder.sectionId = parseInt($state.params.sectionId, 10);
        }

        vm.customAttributesMap = {};
        vm.customAttributesMapp = [];
        loadPage();

        $scope.onProjectChange = function () {
            //console.log("project click");
            TrRouteListByProjectId.get(vm.selectedProjectId, function (response) {
                vm.routes=response;
            });
        };
        
        if (vm.trWorkOrder.id != null) {
            vm.trWorkOrderType = vm.trWorkOrder.trWorkOrderType;
            $scope.onProjectChange()
            //$scope.onRouteChange()
        }

       

        $scope.onRouteChange = function () {
            //console.log("route click");
            TrSectionListByRouteId.get(vm.selectedRouteId, function (response) {
                vm.sections=response;
                vm.sections = []
                if(vm.trWorkOrder.id && vm.trWorkOrder.sections.length){
                    response.forEach(function (eachData) {
                        vm.trWorkOrder.sections.forEach(function (
                            eachdataChecked
                        ) {
                            if (eachdataChecked.id == eachData.id) {
                                eachData.isChecked = true;
                            }
                        });
                        vm.sections.push(eachData);
                    });
                    vm.sections.sort(function(a, b) {
                        // Convert boolean values to numbers (true to 1, false to 0)
                        var isCheckedA = a.isChecked ? 1 : 0;
                        var isCheckedB = b.isChecked ? 1 : 0;

                        // Compare the values
                        return isCheckedB - isCheckedA;
                      });
                }else{
                    vm.sections = response;
                }
            });
        };

        function loadPage() {
            if(vm.selectedProjectId != null){
                ProjectByID.get(vm.selectedProjectId,function(response) {
                    vm.project = response;
                });
            }
            
            if(vm.selectedRouteId != null && vm.selectedRouteId != ""){
                RouteByID.get(vm.selectedRouteId,function(response) {
                    vm.route = response;
                });
            }
            
            TrProjectListIdName.get(function (response) {
                vm.projects = response;
                if(vm.projects.length){
                    vm.projects.sort(function(a, b)  {
                        var nameA = a.name.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
                        var nameB = b.name.toUpperCase();
                      
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0; // Names are equal
                      });
                }
               
            });

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
                );
                imgWindow.document.close();
            };

            var trWorkOrderTypeAttributeValuesList =
                vm.trWorkOrder.trWorkOrderTypeAttributeValues;

            try {
                for (var index in vm.trWorkOrder.trWorkOrderType
                    .trWorkOrderTypeAttribute) {
                    if (
                        !checkIfTrWorkOrderTypeExists(
                            vm.trWorkOrder.trWorkOrderType
                                .trWorkOrderTypeAttribute[index],
                            vm.trWorkOrder.trWorkOrderTypeAttributeValues
                        )
                    ) {
                        var trWorkOrderTypeAttribute1 =
                            vm.trWorkOrder.trWorkOrderType
                                .trWorkOrderTypeAttribute[index];
                        trWorkOrderTypeAttributeValuesList.push({
                            trWorkOrderTypeAttribute: trWorkOrderTypeAttribute1,
                            attributeValue:
                                vm.trWorkOrder.trWorkOrderType
                                    .trWorkOrderTypeAttribute[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) { }

            function checkIfTrWorkOrderTypeExists(
                trWorkOrderType,
                trWorkOrderTypeAttrVal
            ) {
                var hasTrWorkOrderType = false;
                for (var i = 0; i < trWorkOrderTypeAttrVal.length; i++) {
                    var name
                    trWorkOrderTypeAttrVal[i].trWorkOrderTypeAttribute.name;
                    if (name == trWorkOrderType.name) {
                        hasTrWorkOrderType = true;
                        break;
                    }
                }
                return hasTrWorkOrderType;
            }
            AllWorkOrderTypes.getAll(function (response) {
                vm.trWorkOrderTypes = response;
            });

            AllCustomWorkOrderTypeAttribute.getAll(function (response) {
                //vm.customTrWorkOrderTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    if (
                        arrayItem.customTrWorkOrderTypeAttributeValues !=
                        undefined
                    ) {
                        vm.customAttributesMap[arrayItem.name] =
                            arrayItem.customTrWorkOrderTypeAttributeValues;
                        vm.customAttributesMap[arrayItem.name].parentId =
                            arrayItem.id;
                    }
                });
                if (vm.trWorkOrder.id) {
                    $(":file").filestyle({
                        buttonBefore: true,
                    });
                    $(":file").filestyle("buttonText", "Browse File");
                }
            });

            AllVendorIds.getAll(function (response) {

                if(response.length){
                    vm.vendorList = response.sort(function(a, b) {
                        var nameA = a.name.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
                        var nameB = b.name.toUpperCase();
                      
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }
                        return 0; // Names are equal
                      });
                   
                      if(vm.trWorkOrder.vendor != null){
                        vm.vendorList.forEach(function(value){
                            if(vm.trWorkOrder.vendor.id==value.id){
                                vm.selectedVendor=value.id
                            }
                        });
                      } else {
                        vm.selectedVendor = "";
                      }
                      
                    vm.trWorkOrder.vendor = {id:vm.selectedVendor};
    
                } 
                
               
              
                
            });
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function selectedTrWorkOrderTypeAttributes() {
            vm.showFilter = true;
            if (vm.trWorkOrderType) {
                vm.trWorkOrder.trWorkOrderType = vm.trWorkOrderType;
                if (
                    entity_old.trWorkOrderType &&
                    vm.trWorkOrderType.id === entity_old.trWorkOrderType.id
                ) {
                    vm.trWorkOrder.trWorkOrderTypeAttributeValues = [];
                    vm.trWorkOrder.trWorkOrderTypeAttributeValues =
                        entity_old.trWorkOrderTypeAttributeValues;
                } else {
                    vm.trWorkOrder.trWorkOrderTypeAttributeValues = [];
                    $.each(
                        vm.trWorkOrderType.trWorkOrderTypeAttribute,
                        function (key, value) {
                            vm.trWorkOrder.trWorkOrderTypeAttributeValues.push({
                                trWorkOrderTypeAttribute:
                                    vm.trWorkOrderType
                                        .trWorkOrderTypeAttribute[key],
                            });
                        }
                    );
                }
            }
        }

        function save() {
            //vm.isSaving = true;
            /* var spreadAV = vm.trWorkOrder.trWorkOrderTypeAttributeValues;
            for (
                var spreadAssetIndex = 0;
                spreadAssetIndex < spreadAV.length;
                spreadAssetIndex++
            ) {
                if (
                    spreadAV[spreadAssetIndex].trWorkOrderTypeAttribute.name ===
                    "Fiber Distance (km)"
                ) {
                    spreadAV[spreadAssetIndex].attributeValue = fiberDistance;
                }
            }
            vm.trWorkOrder.trWorkOrderTypeAttributeValues = spreadAV; */
            vm.trWorkOrder.projectId = vm.selectedProjectId;
            vm.trWorkOrder.routeId = vm.selectedRouteId;
            vm.trWorkOrder.vendor = {id:vm.selectedVendor};
            if (vm.trWorkOrder.id !== null) {
                TrWorkOrder.update(vm.trWorkOrder, onSaveSuccess, onSaveError);
            } else {
                TrWorkOrder.save(vm.trWorkOrder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:trWorkOrderUpdate", result);
            //vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "user-management.new") {
                $state.go("user-management.new");
            } else {
                $state.go("tr-workorder");
            }
        }

        function onSaveError() {
            //vm.isSaving = false;
        }

        Configs.get("WorkFlowStatus", function (response) {
            vm.workFlowStatus = [];
            var workFlowStatusList = response[0].configValue.split(",");
            workFlowStatusList.forEach(function (value) {
                if (value.length > 0) {
                    vm.workFlowStatus.push({
                        configKey: value,
                        configValue: value,
                    });
                }
            });
        });

        Configs.get("Phases", function (response) {
            vm.workFlowPhase = response;
            //vm.workFlow.phase = "Planning Phase"
            /*  if(vm.workFlow.id == null){
                response.forEach(function(val){
                    if(val.configValue == '01'){
                        vm.workFlow.phase = val.configKey
                    }
                })
            }*/
        });

        /*  ActivatedUserSearch.query(
             {
                 // userId: vm.workFlow.assignedToUser,
                 userId: vm.workFlow.groups[0].id,
                 user: vm.user,
             },
             onSuccess,
             onError
         );
 
         function onSuccess(data, headers) {
             data.forEach(function (users) {
                 vm.assignedUsers.push(users.login);
             });
             vm.activatedusers = data;
 
             angular.forEach(vm.activatedusers, function (value, key) {
                 usersMap[value.login] = value.id;
             });
         }
         function onError(error) {
             AlertService.error(error.data.message);
         } */

        /*  RouteTypeSearch.query({userId:$scope.search,
             page: pagingParams.page - 1,
             size: vm.itemsPerPage,
             sort: sort()
         }, onSuccess, onError);
         function sort() {
             var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
             if (vm.predicate !== 'id') {
               //  result.push('id');
             }
             return result;
         }
         function onSuccess(data, headers) {
             vm.links = ParseLinks.parse(headers('link'));
             vm.totalItems = headers('X-Total-Count');
             vm.queryCount = vm.totalItems;
             vm.routeTypes = data;
             vm.page = pagingParams.page;
     
         }
         function onError(error) {
             AlertService.error(error.data.message);
         } */

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        $scope.selectEntity = function () {
            vm.trWorkOrder.sections = [];
            vm.sections.forEach(function (section) {
                if (section.isChecked) {
                    vm.trWorkOrder.sections.push(section)
                }
            });
        };

        $('.select2').select2();

    }
})();
