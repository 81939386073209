(function () {
    "use strict";
    angular.module("trakeyeApp").factory("Mapping", [
        "$rootScope",
        "$http",

        function ($rootScope, $http) {
            var service = {};

            service.postMapping = function (data, callback) {
                $http
                    .post("api/custom-case-type-mapper", data)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("AttributeMappingList", AttributeMappingList);

    AttributeMappingList.$inject = ["$resource"];

    function AttributeMappingList($resource) {
        var resourceUrl = "api/custom-case-type-mappers";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
            }
        );
    }
})();

(function () {
    angular.module("trakeyeApp").service("myservice", function () {
        var value = null;
        return {
            setValue: function (newValue) {
                value = newValue;
            },
            getValue: function () {
                return value;
            },
        };
    });
})();

(function () {
    "use strict";

    angular.module("trakeyeApp").factory("AttributeMappingDelete", [
        "$http",
        function ($http) {
            var service = {};
            service.delete = function (id, callback) {
                $http
                    .delete("api/custom-case-type-mappers/" + id)
                    .then(function (response) {
                        callback(response.data);
                    });
            };

            return service;
        },
    ]);
})();

(function () {
    "use strict";
    angular
        .module("trakeyeApp")
        .factory("AttributeMappingListId", AttributeMappingListId);

    AttributeMappingListId.$inject = ["$resource"];

    function AttributeMappingListId($resource) {
        var resourceUrl = "api/custom-case-type-mappers/:id";

        return $resource(
            resourceUrl,
            {},
            {
                query: {
                    method: "GET",
                    isArray: true,
                },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    },
                },
            }
        );
    }
})();
