(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "WorkOrderTypeDialogController",
            WorkOrderTypeDialogController
        );

    WorkOrderTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "WorkOrderType",
        "User",
        "WorkOrderTypeSearch",
        "AllCustomWorkOrderTypeAttribute",
    ];

    function WorkOrderTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        WorkOrderType,
        User,
        WorkOrderTypeSearch,
        AllCustomWorkOrderTypeAttribute
    ) {
        var vm = this;
        //workOrdertype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.workOrderType = entity;
        vm.workOrderTypeImageName = workOrderTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customWorkOrderTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");

        function workOrderTypeImageName(image) {
            vm.workOrderType.image = image;
        }

        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomWorkOrderTypeAttribute.getAll(function (response) {
                vm.customWorkOrderTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customWorkOrderTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.workOrderType.workOrderTypeAttribute != null &&
            vm.workOrderType.workOrderTypeAttribute.length != 0
        ) {
            vm.items = vm.workOrderType.workOrderTypeAttribute;
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.isSaving = true;
            if (vm.workOrderType.id !== null) {
                vm.workOrderType.workOrderTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.workOrderType.workOrderTypeAttribute.push({
                        id: vm.items[i].id,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                    });
                }
                WorkOrderType.update(
                    vm.workOrderType,
                    onSaveSuccess,
                    onSaveError
                );
            } else {
                vm.workOrderType.workOrderTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.workOrderType.workOrderTypeAttribute.push({
                        id: vm.items[i].id,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                    });
                }
                WorkOrderType.save(
                    vm.workOrderType,
                    onSaveSuccess,
                    onSaveError
                );
            }
        }

        $("#workOrderTypeImage").change(function () {
            var filesSelected =
                document.getElementById("workOrderTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.workOrderType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:workOrderTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == "work-order.new") {
                $state.go("work-order.new");
            } else {
                $state.go("workorder-type");
            }
            // sessionStorage.removeItem('prevPage');
            // $state.go('workorder-type');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.addmore = function () {
            vm.items.push({ name: "", attributeDataType: {}, value: "" });
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
