(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkFlowController", WorkFlowController);

    WorkFlowController.$inject = [
        "$state",
        "$scope",
        "$stateParams",
        "WorkFlow",
        "ParseLinks",
        "AlertService",
        "paginationConstants",
        "pagingParams",
        "WorkFlowSearch",
        "Configs"
    ];

    function WorkFlowController(
        $state,
        $scope,
        $stateParams,
        WorkFlow,
        ParseLinks,
        AlertService,
        paginationConstants,
        pagingParams,
        WorkFlowSearch,
        Configs
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterWorkFlowSearch = filterWorkFlowSearch;
        
        loadAll();

        function loadAll() {
            WorkFlow.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );
            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    result.push("id");
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                var tempWorkFlows = data;
                vm.workFlows = [];
                vm.page = pagingParams.page;
                Configs.get("WorkFlowStatus", function (response) {
                    tempWorkFlows.forEach(function (val) {
                        response.forEach(function (status) {
                            if (status.configKey == val.status) {
                                val.status = status.configValue;
                            }
                        });
                        val.workFlowTypeAttributeValues.forEach(function (item) {
                            if (item.name == "Fiber Distance")
                                val.distance = item.attributeValue
                        })
                        vm.workFlows.push(val);
                    });
                });

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: vm.currentSearch,
            });
        }

        function filterWorkFlowSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                
                WorkFlowSearch.query(
                    {
                        search: $scope.search,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );

                function sort() {
                    var result = [
                        vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                    ];
                    if (vm.predicate !== "id") {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    var tempWorkFlows = data;
                    vm.workFlows = [];
                    vm.page = pagingParams.page;
                    tempWorkFlows.forEach(function (val){
                        val.workFlowTypeAttributeValues.forEach(function (item){
                            if(item.name == "Fiber Distance")
                            val.distance = item.attributeValue
                         })
                         vm.workFlows.push(val);
                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

    }
})();
