(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomProjectTypeAttributeDeleteController',CustomProjectTypeAttributeDeleteController);

    CustomProjectTypeAttributeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomProjectTypeAttribute'];

    function CustomProjectTypeAttributeDeleteController($uibModalInstance, entity, CustomProjectTypeAttribute) {
        var vm = this;

        vm.customProjectTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomProjectTypeAttribute.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
