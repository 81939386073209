(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrWorkOrderTypeDialogController", TrWorkOrderTypeDialogController);

    TrWorkOrderTypeDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "$state",
        "entity",
        "TrWorkOrderType",
        "User",
        "TrWorkOrderTypeSearch",
        "AllCustomTrWorkOrderTypeAttribute",
    ];

    
    function TrWorkOrderTypeDialogController(
        $timeout,
        $scope,
        $stateParams,
        $state,
        entity,
        TrWorkOrderType,
        User,
        TrWorkOrderTypeSearch,
        AllCustomTrWorkOrderTypeAttribute
    ) {
        var vm = this;
        //trWorkOrdertype attribute types
        $scope.attributetypes = ["INTEGER", "FLOAT", "STRING", "BOOLEAN"];
        //end
        vm.trWorkOrderType = entity;
        vm.trWorkOrderTypeImageName = trWorkOrderTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customTrWorkOrderTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        $scope.allimages = false;
        $scope.toggle = function () {
            $scope.allimages = !$scope.allimages;
        };
        vm.save = save;
        $(":file").filestyle({ buttonBefore: true });
        $(":file").filestyle("buttonText", "Browse File");

        

        vm.checkNumber;

        
        
       

        function trWorkOrderTypeImageName(image) {
            vm.trWorkOrderType.image = image;
        }
        
       
        function decodeImage(img) {
            return window.atob(img);
        }

        function loadPage() {
            AllCustomTrWorkOrderTypeAttribute.getAll(function (response) {
                vm.customTrWorkOrderTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customTrWorkOrderTypeAttributeValues;
                });
            });
        }

        //      vm.users = User.query();
        vm.items = [{ name: "", attributeDataType: {}, value: "" }];

        if (
            vm.trWorkOrderType.trWorkOrderTypeAttribute != null &&
            vm.trWorkOrderType.trWorkOrderTypeAttribute.length != 0
        ) {
            vm.items = vm.trWorkOrderType.trWorkOrderTypeAttribute;
        }
        var tempItems = [];
        vm.items.forEach(function (value) {
            if (value.trWorkOrderCategory == null) {
                value.trWorkOrderCategory = "DEFAULT";
            }
            tempItems.push(value);
        });

        vm.items = tempItems;
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }
        function save() {
            vm.isSaving = true;
            if (vm.trWorkOrderType.id !== null) {
                vm.trWorkOrderType.trWorkOrderTypeAttribute = [];
                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.trWorkOrderType.trWorkOrderTypeAttribute.push({
                        trWorkOrderCategory: vm.items[i].trWorkOrderCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        trWorkOrderTypeAttribOrder: vm.items[i].trWorkOrderTypeAttribOrder
                    });
                }
                TrWorkOrderType.update(vm.trWorkOrderType, onSaveSuccess, onSaveError);
            } else {
                vm.trWorkOrderType.trWorkOrderTypeAttribute = [];

                for (var i = 0; i < vm.items.length; i++) {
                    var attributes = { name: vm.items[i] };
                    vm.trWorkOrderType.trWorkOrderTypeAttribute.push({
                        trWorkOrderCategory: vm.items[i].trWorkOrderCategory,
                        id: vm.items[i].id,
                        mandatory: vm.items[i].mandatory,
                        name: vm.items[i].name,
                        attributeDataType: vm.items[i].attributeDataType,
                        value: vm.items[i].value,
                        trWorkOrderTypeAttribOrder: vm.items[i].trWorkOrderTypeAttribOrder
                    });
                }
                TrWorkOrderType.save(vm.trWorkOrderType, onSaveSuccess, onSaveError);
            }
        }

        $("#trWorkOrderTypeImage").change(function () {
            var filesSelected = document.getElementById("trWorkOrderTypeImage").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var test = window.btoa(fileLoadedEvent.target.result);
                    vm.trWorkOrderType.image = test;
                };
                fileReader.readAsBinaryString(filesSelected[i]);
            }
        });

        function onSaveSuccess(result) {
            $scope.$emit("trakeyeApp:trWorkOrderTypeUpdate", result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            $state.go("trworkorder-type");
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.addmore = function (index) {

            
            //vm.items.push({name: '',attributeDataType:{}, value:''});
            vm.items.splice((index+1), 0, {
                name: "",
                attributeDataType: {},
                value: "",
            });
           
        };

        vm.remove = function (index) {
            vm.items.splice(index, 1);
           
           
        };

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

       
    }
})();
