(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller(
            "CustomWorkFlowTypeAttributeDeleteController",
            CustomWorkFlowTypeAttributeDeleteController
        );

    CustomWorkFlowTypeAttributeDeleteController.$inject = [
        "$uibModalInstance",
        "entity",
        "CustomWorkFlowTypeAttribute",
    ];

    function CustomWorkFlowTypeAttributeDeleteController(
        $uibModalInstance,
        entity,
        CustomWorkFlowTypeAttribute
    ) {
        var vm = this;

        vm.customWorkFlowTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function confirmDelete(id) {
            CustomWorkFlowTypeAttribute.delete({ id: id }, function () {
                $uibModalInstance.close(true);
            });
        }
    }
})();
