(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('AttributeMappingDeleteController',AttributeMappingDeleteController);

        AttributeMappingDeleteController.$inject = ['$uibModalInstance','AttributeMappingDelete','$scope',"myservice"];

    function AttributeMappingDeleteController($uibModalInstance, AttributeMappingDelete,$scope,myservice) {
        var vm = this;

        //vm.customCaseTypeAttribute = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
           $scope.value = myservice.getValue();
           AttributeMappingDelete.delete( $scope.value,
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
