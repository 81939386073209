(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetHealthStatusController", AssetHealthStatusController);

    AssetHealthStatusController.$inject = [
        "$scope",
        "AlertService",
        "UserMasterGeofences",
        "MiniGeofencesByMasterID",
        "AssetStatusListWithGeofence",
        "CasesForAsset",
        "Report"
    ];

    function AssetHealthStatusController(
        $scope,
        AlertService,
        UserMasterGeofences,
        MiniGeofencesByMasterID,
        AssetStatusListWithGeofence,
        CasesForAsset,
        Report
    ) {
        var vm = this;
        
        vm.userMasterGeofences = [];
        vm.geofenceMini = [];
        vm.caseTable = caseTable;
        vm.downloadAssetHealthStatusReport = downloadAssetHealthStatusReport;
        vm.sortResponse = sortResponse;

        load();
        

        function load() {
            vm.userMasterGeofences = [];
            UserMasterGeofences.getAll(function (response) {
                vm.userMasterGeofences = response;
                vm.selectedMasterGeofenceId = vm.userMasterGeofences[0].id.toString();
                vm.geofenceMini = [];
                MiniGeofencesByMasterID.get(
                    vm.selectedMasterGeofenceId,
                    function (response) {
                        response.forEach(function (value) {
                            vm.geofenceMini.push(value);
                        });
                    }
                );
            });
        }

        $scope.onMasterChange = function () {
            vm.showMessageError = false;
            vm.showMessage = false;
            vm.selectedGeofenceId="";
            vm.geofenceMini = [];
            MiniGeofencesByMasterID.get(
                vm.selectedMasterGeofenceId,
                function (response) {
                    response.forEach(function (value) {
                        vm.geofenceMini.push(value);
                    });
                }
            );
        };
        vm.predicate = 'assetId';
        $scope.onMiniChange = function () {
            Report.getAssetHealthListWithGeoReport({
				id: vm.selectedGeofenceId,
                sortFieldName: vm.predicate,
				sortOrder: vm.reverse ? 'asc' : 'desc',
			}, function (response) {
                vm.assetRecords = response;
			});
        };

        function caseTable(assetRecord) {
            vm.assetRecords.forEach(function (item) {
                item.isExpanded = false;
            });
            if (vm.toggle == assetRecord.assetId) {
                vm.toggle = undefined;
            } else {
                assetRecord.isExpanded = true;
                vm.toggle = assetRecord.assetId;
            }
            CasesForAsset.query(
                {
                    assetId: assetRecord.assetId
                },
                onSuccessList,
                onErrorList
            );
            function onSuccessList(data) {
                vm.caseRecords = data;
            }

            function onErrorList(error) {
                AlertService.error(error.data.message);
            }
        }
        function downloadAssetHealthStatusReport() {
            Report.getAssetHealthStatusReportDownload({
                id: vm.selectedGeofenceId
            }, function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([(response)], { type: 'application/csv' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = "asset_health_status_report.csv";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
		}

        function sortResponse() {
			Report.getAssetHealthListWithGeoReport({
				id: vm.selectedGeofenceId,
                sortFieldName: vm.predicate,
				sortOrder: vm.reverse ? 'asc' : 'desc',
			}, function (response) {
                vm.assetRecords = response;
			});
		}
    }
})();
