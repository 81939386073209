(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("WorkFlowDetailController", WorkFlowDetailController);

    WorkFlowDetailController.$inject = [
        "$scope",
        "$rootScope",
        "previousState",
        "entity",
        "$window",
        "Configs"
    ];

    function WorkFlowDetailController(
        $scope,
        $rootScope,
        previousState,
        entity,
        $window,
        Configs
    ) {
        var vm = this;

        vm.workFlow = entity;
        vm.previousState = previousState.name;
        Configs.get("WorkFlowStatus", function (response) {
            response.forEach(function (status) {
                if (status.configKey == vm.workFlow.status) {
                    vm.workFlow.status = status.configValue;
                }
            });
        });
        var unsubscribe = $rootScope.$on(
            "trakeyeApp:workFlowUpdate",
            function (event, result) {
                vm.workFlow = result;
            }
        );
        $scope.$on("$destroy", unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };
    }
})();
